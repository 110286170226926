.c-swiper {
  overflow: visible;

  @include breakpoint-pc {
//    overflow: hidden;
  }
  
  &.-frontservice {
    position: relative;
    width: 100%;
    height: 100%;

    @include breakpoint-pc {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(-7%);
      width: 100%;
      height: 100%;
    }
    
    @include breakpoint-content {
      width: calc( 50vw -  5.5555555555556vw );  //(20px + 30px + 40px) / 1620px * 100%
    }
    @include breakpoint-max {
      transform: translateX(-40px);
      width: calc( 100% * (720 / 570) );  //720px / 570px * 100%
    }
    
    .c-swiper_slide {
      width: auto;
      height: auto;
//      height: 290vw;
      
      @include breakpoint-tb {
//        height: 150px / 480px * 100vw; //150px / 480px * 100
      }
      @include breakpoint-contentinnerhalf {
//        height: 210px / 600px * 100vw; //210px / 600px * 100
      }
      @include breakpoint-w720 {
//        height: 210px / 720px * 100vw; //210px / 480px * 100
      }
      @include breakpoint-pc {
        height: 29.62962962962963vw;  //480px / 1620px * 100
      }
      @include breakpoint-max {
        height: 480px;
      }
    }
  }
  
  &.-front-blog {
    padding-bottom: 120px;
    box-sizing: border-box;
    
    @include breakpoint-w720 {
      padding-bottom: 130px;
    }
    @include breakpoint-pc {
      padding-bottom: 90px;
    }
    @include breakpoint-narrowinner {
      padding-bottom: 130px;
    }
    @include breakpoint-max {
      padding-bottom: 170px;
    }

    .swiper-slide-duplicate {
      display: block;

      @include breakpoint-pc {
//        display: none;  //800px以上の場合、複製されたスライドは非表示にする(トップページのBlogのみ)
      }
    }
  }

  &.-workspickup {
//    overflow: hidden;
    padding-bottom: 45px;
    box-sizing: border-box;

    @include breakpoint-pc {
      padding-bottom: 90px;
    }

    .c-swiper_slide {
      opacity: 0 !important;
      transition: opacity 0.15s ease-in-out;
      transition-property: opacity;

      &.swiper-slide-active {
        opacity: 1 !important;
      }
    }
    
    .c-swiper_nav {
//      z-index: 11;
    }

    // スライドショー内にスライドインボタンがある時、左・右側にはみ出す帯部分を非表示にする
/*
    .p0-btn {
      &.-btn2 {
        overflow: hidden !important;
  
        .p0-btn_inner, a {
          &:before {
            display: none !important;
          } 
        }
      }
    }
*/
  }
  
  &.-service-works {
    padding-bottom: 120px;
    box-sizing: border-box;
    
    @include breakpoint-w720 {
      padding-bottom: 130px;
    }
    @include breakpoint-pc {
      padding-bottom: 90px;
    }
    @include breakpoint-narrowinner {
      padding-bottom: 130px;
    }
    @include breakpoint-max {
      padding-bottom: 170px;
    }
  }


  &_wrapper {
    @include breakpoint-max {
    }
  }

  &_slide {
  }
  
  ///// prev、pagination、nextを入れるナビゲーションボックス /////
  &_nav {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-center;
    position: relative;
    bottom: -35px !important;

    @include breakpoint-narrowinner {
      bottom: -45px !important;
    }
    @include breakpoint-max {
      bottom: -55px !important;
    }
    
    &.-left {
      @include flex-justify-flex-start;
      
      &.-pickup {
        @include flex-justify-center;
        bottom: -15px !important;
//        padding-left: 5vw;
        box-sizing: border-box;
        
        @include breakpoint-tb {
          @include flex-justify-flex-start;
        }
        @include breakpoint-pc {
          bottom: -15px !important;
//          padding-left: 5vw;
        }
        @include breakpoint-narrowinner {
          bottom: -15px !important;
        }
        @include breakpoint-max {
          bottom: -15px !important;
//          padding-left: 100px;
        }
        
        .c-swiper_nav_prev, .c-swiper_nav_next {
          display: none;
          
          @include breakpoint-w720 {
            @include flexbox;
          }
        }
      }
    }
  }
  
  ///// bullet /////
  &_pagination {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-center;
    position: relative;
    bottom: 0 !important;
    width: auto !important;
    margin: 0 3px;
    
    &.swiper-pagination-bullets {
      .swiper-pagination-bullet {
        position: relative;
        z-index: 0;
        width: 10px;
        height: 10px;
        background-color: color-wh(1);
        opacity: 1 !important;
        
        &.swiper-pagination-bullet-active {
          &:after {
            background-color: color-blue02(1);
          }
        }
        
        &:after {
          display: inline;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          box-sizing: border-box;
          background-color: color-blue02(0.4);
        }
      }
    }
  }
  
  ///// スライドショーの左右に配置するprev、nextボタン(bulletと横並びに配置するprev、nextとは別デザインのもの) /////
  &_btn {
    &_prev, &_next {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include align-items-center;
      position: absolute;
      top: 50%;
      width: 27px;
      height: 120px;
      background-color: color-blue02(1);

      @include breakpoint-sp {
        width: 31px;
        height: 140px;
      }
      @include breakpoint-w720 {
        display: none;
      }
    }
    
    &_prev {
      left: -5vw;
      transform: translate(0, -50%);
    }
    
    &_next {
      right: -5vw;
      transform: translate(0, -50%);
    }
  }
}


///// インスタグラムスライドショー /////
#ig-slide-row1 {
  #sb_instagram {
    padding-bottom: 0 !important;
    
    .swiper-wrapper{
      transition-timing-function: linear;
    }

    #sbi_images {
      .sbi_item {
        padding: 10px !important;
        box-sizing: border-box;

        @include breakpoint-contentinnerhalf {
          padding: 10px !important;
        }
        @include breakpoint-pc {
          padding: 15px !important;
        }
        @include breakpoint-narrow {
          padding: 20px !important;
        }
        
        .sbi_photo {
          overflow: hidden;
          border-radius: 60px;
          box-sizing: border-box;
          
          .fa-clone { //アイコンをずらす場合は、以下の数値を変える。デフォルトは、right:12px; top:12px;
            display: none !important;
//            right: 20px;
//            top: 20px;
          }
        }
      }
    }
  }
}