.p0-decoration {
  display: block;

  & img {
    display: block;
    width: 100%;
    height: auto;
  }
  
  //円形ロゴ(回転)
  &.-circlelogo01 {
    position: relative;
    width: 27vw;
    
    @include breakpoint-tb {
      width: 132px;
    }
    @include breakpoint-contentinnerhalf {
      width: 30vw;
    }
    @include breakpoint-pc {
      width: 33.75vw;
    }
    @include breakpoint-narrowinner {
      width: 303px;
    }
    
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-image: url("../images/front/about/circle_text.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      animation: rotateLogo 20s infinite linear;
    }
    
    &.-rotate {
      img {
        animation: rotateLogo 10s infinite linear;
      }
    }
    
    // サービスページ調整
    &.-service {
      margin-top: -15px;
      margin-right: 40px / 1120px * 100%;
      margin-bottom: -80px / 1120px * 100vw;
      margin-left: auto;

      @include breakpoint-pc {
        margin-top: -40px;
        margin-bottom: -80px;
      }
      @include breakpoint-contentinner {
        margin-right: 40px;
        margin-bottom: -80px;
      }
    }

    // aboutページ調整
    &.-about {
      margin-left: auto;
      margin-right: 30px;
      transform: translateY(-41%);

      @include breakpoint-pc {
      }
      @include breakpoint-narrow {
        margin-right: 30px;
      }
    }
  }
  
  //花火
  &.-firework01 {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint-contentinnerhalf {
      width: 88%;
    }
    @include breakpoint-pc {
      width: 86%;
      margin-left: 0;
    }
    @include breakpoint-narrowinner {
      width: 73%;
      margin-left: auto;
    }
    @include breakpoint-narrow {
      left: 40px;
      width: 314px;
      margin-left: 0;
    }
  }


  ///// About Usページのの画像と画像を繋ぐ点線 /////
  &.-asline1, &.-asline2 {
    display: none;
    position: absolute;
    z-index: -1;
    width: 600px;
    height: 15px;
    background-image: url("../images/about/dotline.png");
    background-position: center center;
    background-repeat: repeat-x;
    background-size: auto 100%;

    @include breakpoint-w720 {
      display: block;
    }
    @include breakpoint-pc {
      width: 700px;
    }
    @include breakpoint-narrowinner {
      width: 800px;
    }
    @include breakpoint-contentinner {
      width: 900px;
    }
    @include breakpoint-content {
      width: 1000px;
    }
  }

  &.-asline1 {  //右始点
    top: 50%;
    right: 50%;
    transform: translate(7.5px, -7.5px) rotate(-51deg);
    transform-origin: top right;

    @include breakpoint-pc {
      transform: rotate(-51deg);
    }
    @include breakpoint-narrowinner {
      transform: rotate(-48deg);
    }
    @include breakpoint-contentinner {
      transform: rotate(-45deg);
    }
    @media all and (min-width: 1230px) {
      transform: rotate(-44.5deg);
    }
    @media all and (min-width: 1260px) {
      transform: rotate(-44deg);
    }
    @media all and (min-width: 1290px) {
      transform: rotate(-43.5deg);
    }
    @include breakpoint-content {
      transform: rotate(-43deg);
    }
  }

  &.-asline2 {  //左始点
    top: 50%;
    left: 50%;
    transform: translate(-7.5px, -7.5px) rotate(51deg);
    transform-origin: top left;

    @include breakpoint-pc {
      transform: rotate(51deg);
    }
    @include breakpoint-narrowinner {
      transform: rotate(48deg);
    }
    @include breakpoint-contentinner {
      transform: rotate(45deg);
    }
    @media all and (min-width: 1230px) {
      transform: rotate(45deg);
    }
    @media all and (min-width: 1230px) {
      transform: rotate(44.5deg);
    }
    @media all and (min-width: 1260px) {
      transform: rotate(44deg);
    }
    @media all and (min-width: 1290px) {
      transform: rotate(43.5deg);
    }
    @include breakpoint-content {
      transform: rotate(43deg);
    }
  }


  ///// 動物 /////
  // 無印の-animalはトップページ
  &.-animal01 {
    position: absolute;
    bottom: -165px;
    right: -30px;
    width: 101px;

    @include breakpoint-tb {
      display: block;
      width: 101px;
    }
    @include breakpoint-contentinnerhalf {
      bottom: -150px;
      right: 40px;
      width: 125px;
    }
    @include breakpoint-w720 {
      bottom: -105px;
      right: auto;
      left: -60px;
      width: 140px;
    }
    @include breakpoint-pc {
      bottom: -70px;
      left: -85px;
      width: 157px;
    }
    @include breakpoint-narrowinner {
      width: 214px;
    }
    @include breakpoint-max {
      bottom: -70px;
      left: -85px;
    }
  }

  &.-animal02 {
    position: absolute;
    top: 0;
    right: 20px;
    transform: translateY(-100%);
    width: 105px;

    @include breakpoint-tb {
      top: -20px;
    }
    @include breakpoint-contentinnerhalf {
      right: 40px;
      width: 105px;
    }
    @include breakpoint-w720 {
      width: 119px;
    }
    @include breakpoint-pc {
      top: -30px;
      left: 30px;
      transform: translateY(0%);
      width: 135px;
    }
    @include breakpoint-narrowinner {
      top: -50px;
      left: 55px;
      width: 184px;
    }
    
    &.-sp {
      display: block;
      
      @include breakpoint-pc {
        display: none;
      }
    }

    &.-pc {
      display: none;
      
      @include breakpoint-pc {
        display: block;
      }
    }
  }

  &.-animal03 {
    position: absolute;
    top: -70px; //PSDとは違うが、この位置で良いとの指示あり
    left: 20px;
    transform: translateY(-100%);
    width: 102px;

    @include breakpoint-tb {
      width: 102px;
    }
    @include breakpoint-contentinnerhalf {
      left: 25px;
      width: 110px;
    }
    @include breakpoint-w720 {
      width: 137px;
    }
    @include breakpoint-pc {
      left: 40px;
      width: 155px;
    }
    @include breakpoint-narrowinner {
      left: 50px;
      width: 232px;
    }
    @include breakpoint-narrow {
      left: 110px;
    }
    @include breakpoint-max {
      left: 280px;
    }
  }

  &.-animal04 {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
//    transform: translateY(-100%);
    width: 83px;

    @include breakpoint-tb {
      display: block;
      right: 15px;
      width: 83px;
    }
    @include breakpoint-contentinnerhalf {
      right: 30px;
      width: 93px;
    }
    @include breakpoint-w720 {
      right: 25px;
      width: 110px;
    }
    @include breakpoint-pc {
      right: 20px;
    }
    @include breakpoint-narrowinner {
      right: 0;
      width: 144px;
    }
    @include breakpoint-max {
      right: 28px;
    }
  }

  &.-animal05 {
    display: none;
    position: absolute;
    top: 50%;
    right: 330px / 430px * 100%;
    transform: translateY(-100%);
    width: 81px;

    @include breakpoint-tb {
      display: block;
    }
    @include breakpoint-contentinnerhalf {
      right: 390px / 540px * 100%;
    }
    @include breakpoint-w720 {
      right: 390px / 650px * 100%;
      width: 86px;
    }
    @include breakpoint-pc {
      top: 0;
      right: 30px;
    }
    @include breakpoint-narrowinner {
      right: 50px;
      width: 112px;
    }
  }

  &.-animal06 {
    display: none;
    position: absolute;
    top: 0;
    left: 72%;
//    transform: translateY(-100%);
    width: 94px;

    @include breakpoint-sp {
      display: block;
    }
    @include breakpoint-contentinnerhalf {
      width: 116px;
    }
    @include breakpoint-narrowinner {
      width: 160px;
    }
    @include breakpoint-narrow {
      top: -20px;
    }
  }

  &.-animal07 {
    display: none;
    position: absolute;
    transform: translateY(-100%);

    @include breakpoint-tb {
      display: block;
      top: 50%;
      left: 300px / 440px * 100%;
      width: 113px;
    }
    @include breakpoint-contentinnerhalf {
      left: 360px / 540px * 100%;
      width: 135px;
    }
    @include breakpoint-w720 {
      left: 380px / 650px * 100%;
      width: 160px;
    }
    @include breakpoint-pc {
      left: 430px / 720px * 100%;
    }
    @include breakpoint-narrowinner {
      top: 0;
      right: auto;
      left: -5px;
      transform: translateY(-100%);
      width: 215px;
    }
    
    ///// ブログ無しの時、スライドインボタンを右に配置するので、動物装飾の位置も左右逆にする(以下が、その左右逆バージョン。ブログを実装したら、以下は消して良い) /////
    &.-rightver {
      transform: translate(-100%, -100%);

      @include breakpoint-tb {
        left: 140px / 440px * 100%;
      }
      @include breakpoint-contentinnerhalf {
        left: 180px / 540px * 100%;
      }
      @include breakpoint-w720 {
        left: 270px / 650px * 100%;
      }
      @include breakpoint-pc {
        left: 290px / 720px * 100%;
      }
      @include breakpoint-narrowinner {
        right: auto;
        left: -5px;
        transform: translate(0, -100%);
      }
    }
    ///// ブログ無しの時、スライドインボタンを右に配置するので、動物装飾の位置も左右逆にする(以上が、その左右逆バージョン。ブログを実装したら、以上は消して良い) /////
  }
  
  // -animal_worksはWorksページ関連
  &.-animal_works01 {
    position: absolute;
    bottom: -20px;
    right: 20px / 430px * 100%;
    transform: translate(0, 100%);
    @include animalImgWidth2(116px);
//    padding-top: 40px;
    box-sizing: border-box;
    
    @include breakpoint-tb {
      position: absolute;
      right: auto;
      bottom: -15px;
      left: 100px / 430px * 100%;
      transform: translate(0, 0);
      padding-top: 0;
    }
    @include breakpoint-contentinnerhalf {
      bottom: -10px;
      left: 100px / 540px * 100%;
    }
    @include breakpoint-w720 {
      left: 150px / 640px * 100%;
    }
    @include breakpoint-pc {
      bottom: -5px;
      left: 60px / 720px * 100%;
    }
    @include breakpoint-narrowinner {
      left: 150px / 900px * 100%;
    }
    @include breakpoint-narrow {
      left: 200px / 1000px * 100%;
    }
    @include breakpoint-contentinner {
      bottom: 40px;
      left: 300px / 1200px * 100%;
      transform: translate(0, 100%);
    }
  }
  &.-animal_works02 {
    display: none;
    position: absolute;
    bottom: 20px;
    right: 20px / 430px * 100%;
    @include animalImgWidth1(178px);
    
    @include breakpoint-sp {
      display: block;
    }
    @include breakpoint-pc {
      bottom: 20px;
      right: 100px / 1200px * 100%;
    }
  }
  &.-animal_works03 {
    position: relative;
    bottom: 0;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);
    @include animalImgWidth1(232px);
    padding-top: 20px;
    box-sizing: border-box;
    
    @include breakpoint-tb {
      position: absolute;
      right: auto;
      bottom: -5px;
      left: 30px / 430px * 100%;
      transform: translateX(0);
      padding-top: 0;
    }
    @include breakpoint-contentinnerhalf {
      bottom: -10px;
      left: 30px / 540px * 100%;
    }
    @include breakpoint-pc {
      bottom: -5px;
      left: 60px / 720px * 100%;
    }
    @include breakpoint-contentinner {
      bottom: -30px;
      left: 150px / 1200px * 100%;
    }
  }
  &.-animal_works04 {
    display: block;
    position: absolute;
    top: 0;
    left: 130px / 1200px * 100%;
    z-index: 1;
    transform: translateY(-90%);
    @include animalImgWidth1(62px);
    
    @include breakpoint-sp {
    }
    @include breakpoint-pc {
      top: 0;
      left: 130px / 1200px * 100%;
    }
  }
  &.-animal_works05 {
    display: block;
    position: absolute;
    top: 0;
    right: 280px / 1200px * 100%;
    z-index: 1;
    transform: translateY(-50%);
    @include animalImgWidth1(52px);
    
    @include breakpoint-sp {
    }
    @include breakpoint-tb {
//      transform: translateY(-20%);
    }
    @include breakpoint-contentinnerhalf {
//      transform: translateY(-25%);
    }
    @include breakpoint-w720 {
//      transform: translateY(-30%);
    }
    @include breakpoint-pc {
      right: 280px / 1200px * 100%;
//      transform: translateY(-25%);
    }
  }
  &.-animal_works06 {
    display: block;
    position: absolute;
    bottom: -20px;
    left: 290px / 1200px * 100%;
    z-index: 1;
    transform: translateY(100%);
    @include animalImgWidth1(85px);
    
    @include breakpoint-sp {
    }
    @include breakpoint-pc {
      bottom: -40px;
      left: 290px / 1200px * 100%;
    }
  }


  // -animal_aboutはABOUT USページ
  &.-animal_about01 {
    display: block;
    position: relative;
    left: 100%;
    transform: translate(-100%, 0);
    @include animalImgWidth3(205px);
    box-sizing: border-box;

    @include breakpoint-sp {
      position: absolute;
      left: auto;
      top: 20px;
      left: 220px;
      transform: translate(0, 0);
      margin-bottom: 0;
    }
    @include breakpoint-tb {
      left: 240px;
    }
    @include breakpoint-pc {
      top: 10px;
      left: 400px;
    }
  }
  &.-animal_about02 {
    display: block;
    position: relative;
    left: 100%;
    transform: translate(-100%, 0);
    @include animalImgWidth3(158px);
    box-sizing: border-box;

    @include breakpoint-sp {
      position: absolute;
      left: auto;
      top: 0;
      right: 110px / 1200px * 100%;
      transform: translate(0, 0);
    }
  }
  &.-animal_about03 {
    display: block;
    position: relative;
    left: 100%;
    transform: translate(-100%, 0);
    @include animalImgWidth3(160px);
    box-sizing: border-box;

    @include breakpoint-sp {
      position: absolute;
      top: 30px;
    }
    @include breakpoint-tb {
      left: 340px;
      transform: translate(0, 0);
    }
    @include breakpoint-w720 {
      left: 50%;
    }
    @include breakpoint-pc {
      top: 75px;
    }
  }

  &.-animal_aboutmember {
    display: none;
    position: absolute;
    bottom: 15px;
    right: 0;
    box-sizing: border-box;

    @include breakpoint-tb {
      display: block;
    }
    @include breakpoint-contentinnerhalf {
      bottom: 20px;
    }
    @include breakpoint-w720 {
      bottom: 25px;
    }
    @include breakpoint-pc {
      bottom: 15px;
      right: 20px;
    }
    @include breakpoint-narrowinner {
      bottom: 30px;
      right: 40px;
    }
    @include breakpoint-narrow {
      right: 60px;
    }
    
    &.-m01 {
      @include animalImgWidth4(214px);
    }
    &.-m02 {
      @include animalImgWidth4(182px);

      @include breakpoint-pc {
        transform: translateX(-10px);
      }
    }
    &.-m03 {
      @include animalImgWidth4(254px);

      @include breakpoint-pc {
        transform: translateX(10px);
      }
    }
  }


  // -animal_serviceはサービスページ
  &.-animal_service01 {
    display: none;
    @include animalImgWidth1(267px);
    box-sizing: border-box;

    @media all and (min-width: 900px) {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(0);
      padding-top: 0;
    }
    @include breakpoint-narrowinner {
      right: 30px / 1200px * 100%;
    }
    @include breakpoint-narrow {
      right: 60px / 1200px * 100%;
    }
  }
  &.-animal_service02 {
    display: block;
    position: absolute;
    top: 0;
    left: 200px / 1200px * 100%;
    z-index: 1;
    transform: translateY(-95%);
    @include animalImgWidth1(146px);
    
    @include breakpoint-sp {
    }
    @include breakpoint-pc {
      top: 0;
      left: 200px / 1200px * 100%;
    }
  }
  &.-animal_service03 {
    display: block;
    position: absolute;
    bottom: -20px;
    right: 160px / 1200px * 100%;
    z-index: 1;
    transform: translateY(100%);
    @include animalImgWidth1(76px);
    
    @include breakpoint-sp {
    }
    @include breakpoint-pc {
      bottom: -20px;
      right: 160px / 1200px * 100%;
    }
  }


  // -animal_topicsは新着情報ポリシーページ
  &.-animal_topics01 {
    position: relative;
//    bottom: 0;
//    left: 50%;
//    transform: translateX(-50%);
    bottom: -20px;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);

    @include animalImgWidth1(229px);
//    padding-top: 40px;
    box-sizing: border-box;

    @include breakpoint-tb {
      position: absolute;
      bottom: 5px;
      left: auto;
      right: 55vw;
      transform: translateX(0);
      padding-top: 0;
    }
    @include breakpoint-w720 {
      bottom: 3.3vw;
    }
    @include breakpoint-content {
      right: 730px / 1200px * 100%;
      bottom: 40px;
    }
  }


  // -animal_contactはお問い合わせページ
  &.-animal_contact01 {
    position: relative;
    bottom: 0;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);
    @include animalImgWidth1(138px);
    padding-top: 20px;
    box-sizing: border-box;
    
    @include breakpoint-tb {
      position: absolute;
      right: auto;
      bottom: -5px;
      left: 30px / 430px * 100%;
      transform: translateX(0);
      padding-top: 0;
    }
    @include breakpoint-contentinnerhalf {
      bottom: -10px;
      left: 30px / 540px * 100%;
    }
    @include breakpoint-pc {
      bottom: -5px;
      left: 60px / 720px * 100%;
      @include animalImgWidth1(331px);
    }
    @include breakpoint-content {
      left: 150px / 1200px * 100%;
    }
  }


  // -animal_privacyはプライバシーポリシーページ
  &.-animal_privacy01 {
    position: relative;
//    bottom: 0;
//    left: 50%;
//    transform: translateX(-50%);
    bottom: -20px;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);

    @include animalImgWidth1(110px);
//    padding-top: 40px;
    box-sizing: border-box;

    @include breakpoint-tb {
      position: absolute;
      bottom: 0;
      left: auto;
      right: 55vw;
      transform: translateX(0);
      padding-top: 0;
    }
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-content {
      right: 590px / 1200px * 100%;
    }
  }


  // -animal_blogはブログページ
  &.-animal_blog01 {
    position: relative;
//    bottom: 0;
//    left: 50%;
//    transform: translateX(-50%);
    bottom: -20px;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);

    @include animalImgWidth3(417px);
//    padding-top: 40px;
    box-sizing: border-box;

    @include breakpoint-tb {
      position: absolute;
      bottom: -20px;
      left: auto;
      right: 680px / 1200px * 100%;
      transform: translateX(0);
      padding-top: 0;
    }
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-pc {
      bottom: -35px;
    }
    @include breakpoint-content {
    }
  }
  &.-animal_blog02 {
    position: relative;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);
    @include animalImgWidth3(270px);
    box-sizing: border-box;

    @include breakpoint-tb {
      position: absolute;
      bottom: 20px;
      left: auto;
      right: 170px / 1200px * 100%;
      transform: translateX(0);
      padding-top: 0;
    }
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-pc {
      bottom: 40px;
    }
    @include breakpoint-content {
    }
  }


  // -animal_recruitは採用情報ページ
  &.-animal_recruit01 {
    position: relative;
//    bottom: 0;
//    left: 50%;
//    transform: translateX(-50%);
    bottom: -30px;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);

    @include animalImgWidth3(171px);
//    padding-top: 40px;
    box-sizing: border-box;

    @include breakpoint-tb {
      position: absolute;
      bottom: 5px;
      left: auto;
      right: 760px / 1200px * 100%;
      transform: translateX(0);
      padding-top: 0;
    }
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-pc {
      bottom: 5px;
    }
    @include breakpoint-content {
    }
  }


  // -animal_errorはエラーページ
  &.-animal_error01 {
    position: relative;
//    bottom: 0;
//    left: 50%;
//    transform: translateX(-50%);
    bottom: -30px;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);

    @include animalImgWidth3(346px);
//    padding-top: 40px;
    box-sizing: border-box;

    @include breakpoint-tb {
      position: absolute;
      bottom: -10px;
      left: auto;
      right: 50vw;
      transform: translateX(0);
      padding-top: 0;
    }
    @include breakpoint-contentinnerhalf {
      right: 50vw;
    }
    @include breakpoint-pc {
      bottom: -10px;
    }
    @include breakpoint-narrow {
    }
    @include breakpoint-contentinner {
      right: 610px;
    }
  }
  &.-animal_error02 {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    @include breakpoint-sp {
      @include animalImgWidth3(709px);
    }
  }

}