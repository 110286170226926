.p0-hero {
  @include breakpoint-pc {
  }

  &_bg {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    @include breakpoint-pc {
    }
    
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
//      width: auto;
//      height: 100%;
      min-width: 100%;
      min-height: 100%;
    }
  }

  ///// トップページ /////
  &.-front {
    position: relative;
    width: 100%;
    height: calc(100vh - (#{$btn2-slide-height-sp} / 2));
    padding: 0;
    box-sizing: border-box;
    background-image: url("../images/front/kv/kv_pc.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint-tb {
      height: calc(100vh - (#{$btn2-slide-height-tb} / 2));
    }
    @include breakpoint-contentinnerhalf {
      height: calc(100vh - (#{$btn2-slide-height-contentinnerhalf} / 2));
    }
    @include breakpoint-pc {
      height: calc(100vh - (#{$btn2-slide-height-pc} / 2));
    }
    @include breakpoint-narrowinner {
      height: calc(100vh - (#{$btn2-slide-height-narrowinner} / 2));
    }
    
    & .p0-hero_bg {
//      z-index: 1;

      video {
        height: 100%;
        
        @include breakpoint-pc {
          height: auto;
        }
      }
    }
    
    & .p0-hero_content {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
//      width: 100%;
      height: 100%;
    }
    
    & .p0-hero_inner {
      position: absolute;
      top: 56.4%; //220px / (220px + 170px) * 100//上余白 / (上余白 + 下余白) * 100
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
//      height: 100%;
      color: color-white02(1);
      mix-blend-mode: difference; //差の絶対値
      
      @include breakpoint-w720 {
        left: 0;
        transform: translate(0, -50%);
        width: auto;
      }
      @include breakpoint-narrow {
        left: 110px;
      }
    }
    
    & .p0-hero_logo {
      position: relative;
      width: 170px;
      margin-bottom: 25px;
      margin-left: auto;
      margin-right: auto;
      opacity: 0;

      @include breakpoint-w720 {
        margin-left: 0;
      }
      @include breakpoint-pc {
        margin-bottom: 25px;
      }
      @include breakpoint-narrow {
        width: 200px;
      }
      
      img {
        width: 100%;
        height: auto;
      }
    }
    
    & .p0-hero_catchcopy {
      position: relative;
      opacity: 0; //非表示にする(検索用に、テキストデータとしては残しておく)

      @include breakpoint-tb {
      }
      @include breakpoint-narrow {
      }
    }
  }


  ///// 下層ページ /////
  &.-lowerpage {
    position: relative;
    width: 100%;
    padding: 0 0 30px 0;
    box-sizing: border-box;

    @include breakpoint-tb {
      padding-bottom: 0;
    }
    
    // 背景が左右で2色にセパレートされているタイプ(bgtype04)になっている場合、padding-bottomを入れる場所を変える(親要素にpbを入れると、幅100%で背景色があるエリアができてしまう為)
    &.-bg_bgtype04 {
      padding-bottom: 0;
      
      .l-section_bg.-bgtype04 {
        padding-bottom: 30px;
        
        @include breakpoint-tb {
          padding-bottom: 0;
        }
      }
    }

    &:before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 410px;
      content: "";
      background-color: color-gray01(1);

      @include breakpoint-tb {
        height: 48.3vw;
        min-height: 290px;
        max-height: 480px;
      }
      @include breakpoint-w720 {
        height: 47.2vw;
      }
      @include breakpoint-pc {
        height: 46.25vw;
      }
      @include breakpoint-narrowinner {
        height: 45vw;
      }
      @include breakpoint-narrow {
        height: 480px;
      }
    }

    & .p0-hero_inner {
      position: relative;
      width: 100%;
      height: 100%;
    }

    & .p0-hero_content {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-column;
      @include flex-justify-space-between;
      @include align-items-stretch;

      position: relative;
      height: 100%;
      padding: 100px 0 0;
      box-sizing: border-box;

      @include breakpoint-tb {
        @include flex-direction-row;
        padding-top: 110px;
      }
      @include breakpoint-w720 {
        padding-top: 130px;
      }
      @include breakpoint-narrowinner {
        padding-top: 150px;
      }
    }
    
    & .p0-hero_headline {
      z-index: 1;
    }
    
    & .p0-hero_kvimg {
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 280px;
      margin: 30px auto 0;
      
      @include breakpoint-tb {
        width: 50vw; //240px / 480px * 100
        max-width: 500px;
        margin-top: 0;
        margin-right: -5vw;
      }
      @include breakpoint-contentinnerhalf {
        width: 45vw; //360px / 800px * 100
        margin-right: 0;
      }
      @include breakpoint-narrowinner {
        width: 45vw; //450px / 1000px * 100
      }
      @include breakpoint-narrow {
        width: 500px; //1120px以上の時は、width:500;で固定
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  
  ///// 作品事例記事ページ、新着情報記事ページ、ブログ記事ページ /////
  &.-workssingle, &.-topicssingle, &.-blogsingle {
//    min-height: 480px;
//    padding: 130px 0 45px;
    padding: 130px 0 15px;
    box-sizing: border-box;
    background-color: color-wh(1);

    @include breakpoint-pc {
      padding: 130px 0 45px;
    }
  }


  ///// About /////
  &.-about {
    & .p0-hero_inner {
    }
  }
}