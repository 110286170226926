.c-card {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;

  @include breakpoint-narrow {
    padding-bottom: 30px;
  }
  
  &:hover {
    .c-card_separator {
      &:after {
//        animation: 0.4s linear 0.01s 1 both hoverCardSeparator; //2022/12/26、セパレーターのアニメーションを削除
      }
    }
    .c-card_btm, .c-card_btm.-bgwh {
      &:after {
        -webkit-transition-duration: 0.1s;  //ホバーした時にフェードインする速度
        transition-duration: 0.1s;  //ホバーした時にフェードインする速度
        -webkit-animation: 0.8s cubic-bezier(.19, 1, .22, 1) 0.01s both hoverCardBtmBorder;
        animation: 0.8s cubic-bezier(.19, 1, .22, 1) 0.01s both hoverCardBtmBorder;
//        animation: 0.4s linear 0.01s 1 both hoverCardBtmBorder;
      }
    }
    .c-card_btm {
      &:after {
        background-image: url("../images/common/card/hover_arw_cy.png");
      }
    }
    .c-card_btm.-bgwh {
      &:after {
        background-image: url("../images/common/card/hover_arw_bg.png");
      }
    }
  }
  
  ///// トップページの作品事例スライドショー専用のPROJECT連番表記 /////
  &_no {
    position: absolute;
    top: 0;
    right: -3px;
    transform: translateX(100%);
    @include tategaki("sideways");
    
    &_num {
      display: inline-block;
      transform: translateX(2px);
    }
  }

  &_img {
    position: relative;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &.-deco {
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        border: 10px solid color-gray05(1);
        box-sizing: border-box;
      }
    }
    
    &_size {
      width: 100%;
      height: auto;
    }
/*
    &_thm {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      object-position: 50% 50%;
    }
*/
  }
  
  &_ttl, &_client {
//    max-height: 3.5em; //1.75(line-height) * 2(行分) = 3.5em
//    overflow: hidden;
    @include ellipsisSetLine(2);
  }
  
  // 区切り縦線
  &_separator {
    display: block;
    position: relative;
    width: 1px; //2022/12/26、幅を1pxに変更
//    width: 2px;
    height: 20px;
    margin-left: auto;
    margin-right: auto;
    
    @include breakpoint-w720 {
      height: 30px;
    }
    @include breakpoint-pc {
      height: 20px;
    }
    @include breakpoint-narrowinner {
      height: 30px;
    }
    @include breakpoint-narrow {
//      height: 40px; //2022/12/26、最大の高さを30pxに変更
    }

    &:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-color: color-blue03(0.4); //2022/12/26、濃度を100%→40%に変更
//      background-color: color-blue03(1);
    }
    
    &.-bgwh {
      &:after {
        background-color: color-blue02(0.4); //2022/12/26、濃度を100%→40%に変更   
//        background-color: color-blue02(1);    
      }
    }
  }
  
  // 執筆者(ブログのみで使用)
  &_author {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-center;
    margin-bottom: -5px;
    
    @include breakpoint-narrow {
      margin-bottom: -10px;
    }

    &_img {
      width: 24px;
      height: 24px;
      overflow: hidden;
      margin-right: 10px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      box-sizing: border-box;
      
      @include breakpoint-w720 {
        width: 32px;
        height: 32px;
      }
      @include breakpoint-pc {
        width: 24px;
        height: 24px;
      }
      @include breakpoint-narrowinner {
        width: 32px;
        height: 32px;
      }
      @include breakpoint-narrow {
        width: 40px;
        height: 40px;
      }

      img {
        width: 100%;
        height: auto;
        opacity: 0;
      }
    }
  }
  
  // 最下部の線・矢印
  &_btm {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 16px;

    &:after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 15px; //2022/12/26、線の太さを2px→1pxに変更したのに伴い、画像の高さを16px→15pxに変更
//      height: 16px;
      background-image: url("../images/common/card/border_cy.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: auto 100%;

      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out;
      -webkit-transition-duration: 0.3s;  //ホバーを外した時にフェードアウトする速度
      transition-duration: 0.3s;  //ホバーを外した時にフェードアウトする速度
      -webkit-transition-property: background-image;
      transition-property: background-image;
    }
    
    &.-bgwh {
      &:after {
        background-image: url("../images/common/card/border_bg.png");
      }
    }
  }
}