.p1-logo {
  position: relative;
//  z-index: 1200;
  height: 100%;
  box-sizing: border-box;
  pointer-events: all;

  &.-gnav {
    @include flex-shrink-0;
    width: 90px;

    @include breakpoint-narrowinner {
      width: 110px;
    }
    @include breakpoint-narrow {
      margin-left: -15px;
    }
    
    .p1-logo_item {
      padding: 12px;
      background-color: color-bk(1);

      @include breakpoint-narrowinner {
        padding: 15px;
      }
    }
  }
  
  &.-drawer {
    display: block;
    width: 152px;
    height: auto;
    margin-bottom: 25px;

    @include breakpoint-pc {
      margin-bottom: 50px;
    }
    @include breakpoint-narrowinner {
      width: 186px;
    }
  }

  &_item {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  &_img {
    display: block;
    width: 100%;
    height: auto;
  }
}