.p3-img {
  // 幅100%、高さは縦横比維持の可変画像の汎用画像クラス
  &.-i01 {
    width: 100%;
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
  &.-igit01 {
    position: absolute;
    bottom: 4.166666666666667vw; //20px / 480px * 100
    left: 50%;
    transform: translate(-50%, 5vw); //textcurveを下に5vwずらしている分、ロゴも下に5vwずらす
    width: 21.666666666666667vw; //104px / 480px * 100
    height: auto;

    @include breakpoint-tb {
//      width: 104px;
    }
    @include breakpoint-contentinnerhalf {
//      width: 130px;
    }
    @include breakpoint-w720 {
//      width: 141px;
    }
    @include breakpoint-pc {
//      width: 160px;
    }
    @include breakpoint-narrowinner {
      bottom: 80px;
      transform: translate(-50%, 0); //1000px以上では、textcurveはずらしていないので、ロゴもずらさない
      width: 199px;
    }
  }
  
  &.-spacer01, &.-spacer02 {
    position: relative;
    width: 100%;
    height: 185px / 600px * 100vw;
    min-height: 185px;
    max-height: 500px;
    background-position: center center;
    background-repeat: repeat-x;
    background-size: auto 100%;

    @include breakpoint-narrowinner {
      height: 346px;
    }
    @include breakpoint-max {
      height: 500px;
    }
  }

  &.-spacer01 {
    background-image: url("../images/front/spacer01.jpg");
  }

  &.-spacer02 {
    background-image: url("../images/about/spacer02.jpg");
  }


  ///// Worksの親タグの英語表記 /////
  &.-workstagname {
    pointer-events: none;
    
    //worksトップのpickup
    &.-pickup {
      width: auto;
      height: 108px;

      @include breakpoint-contentinnerhalf {
        height: 108px;
      }
      @include breakpoint-contentinnerhalf {
        height: 128px;
      }
      @include breakpoint-w720 {
        height: 75px;
      }
      @include breakpoint-pc {
        height: 80px;
      }
      @include breakpoint-narrowinner {
        height: 106px;
      }
      @include breakpoint-narrow {
        height: 128px;
      }
      @include breakpoint-content {
        height: 128px;
      }
    }

    //singleページ
    &.-single {
      display: none;
      position: relative;
//      position: absolute;
//      bottom: 60px;
//      right: 30px;
      height: calc(135px * 0.75 * 1.25);
      transform: rotate(-11.15deg);
      margin-top: auto; //下に寄せる
      margin-bottom: 30px;

      @include breakpoint-pc {
        display: block;
//        bottom: 0;
//        right: 60px;
        height: 7.2vw;
      }
      @include breakpoint-narrowinner {
//        bottom: 5vw;
        height: 6.7vw;
        margin-bottom: 5vw;
      }
      @include breakpoint-narrow {
//        bottom: 7vw;
        height: 7.7vw;  //102px / 1320px * 100
        margin-bottom: 7vw;
      }
      @include breakpoint-contentinner {
//        bottom: 9vw;
        margin-bottom: 9vw;
      }
      @include breakpoint-content {
//        bottom: 80px;
//        right: 70px;
        height: 102px;  // 96px(singleページの「Brand Design」の高さ) / 119px(Worksトップページの「Brand Design」の高さ ≒ 0.8、128px(画像の高さ) * 0.8 = 102.4
        margin-bottom: 80px;
      }
      

      img {
        position: absolute;
        top: 0;
        right: 0;
        width: auto;
        height: 100%;
      }
    }
  }

  ///// Worksのsingleページのメイン画像・動画 /////
  &.-worksmainimg, &.-worksmainvideo {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    @include breakpoint-pc {
      width: calc( 100vw - ( ( 100vw - (100% + 260px) ) / 2 ) - 260px );  //260pxは、p0-splitboxの-worksheadの幅
    }
    @include breakpoint-narrow {
      width: calc( 100vw - ( ( 100vw - (100% + 310px) ) / 2 ) - 310px );  //310pxは、p0-splitboxの-worksheadの幅
    }
    @include breakpoint-max {
      width: $narrowinner;
    }
  }
  &.-worksmainvideo {
    position: relative;
    width: 100%;
//    padding-top: 56.25%;
    
    video {
      position: relative;
      width: 100%;
      height: auto;
    }
  }
  
  ///// Worksのsingleページのクライアント(コメント)画像 /////
  &.-workscomment {
    width: 100%;
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
  
  ///// ServiceページのHow〜の画像 /////
  &.-serviceimg01, &.-serviceimg02 {
    box-sizing: border-box;
    text-align: center;
    
    @include breakpoint-pc {
      text-align: left;
    }

    img {
      width: 100%;
    }
  }

  &.-serviceimg01 {
    img {
      max-width: 300px;
//      padding-bottom: 20px;
  
      @include breakpoint-pc {
        width: 25vw; //300px / 1200px * 100
        padding-bottom: 0;
        padding-left: 3.3vw; //40px / 1200px * 100
      }
      @include breakpoint-content {
        padding-left: 40px;
      }
    }
  }

  &.-serviceimg02 {
    @include breakpoint-pc {
      text-align: right;
    }

    img {
      max-width: 380px;
//      padding-bottom: 20px;
  
      @include breakpoint-pc {
        width: 31.6vw; //380px / 1200px * 100
        margin-left: auto;
        margin-right: 0;
        padding-top: 25px;
        padding-bottom: 0;
        padding-right: 3.3vw; //40px / 1200px * 100
      }
      @include breakpoint-max {
        padding-right: 40px;
      }
    }
  }
  
  
  ///// ABOUT USのSPIRITS /////
  // 円形画像
  &.-aboutspirits {
    text-align: center;

    img {
      width: 100%;
      max-width: 304px;
      height: auto;

      @include breakpoint-w720 {
        max-width: none;
      }
    }
  }

  // 下部の写真
  &.-aboutspiritsbtm {
    text-align: center;

    img {
      width: 100%;
      height: auto;

      @include breakpoint-w720 {
      }
    }
  }
  
  ///// ABOUT USのSERVICE /////
  &.-aboutservice {
    img {
      width: 100%;
      height: auto;
      box-sizing: border-box;

      @include breakpoint-w720 {
        width: 84.7%;
        padding-left: 10px;
      }
    }
  }
}