@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Shippori+Mincho:wght@400;500&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  outline: 0;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

html {
  font-size: 62.5%; }

body {
  margin: 0;
  padding: 0; }

#defs {
  display: none; }

img {
  width: 100%; }

ul li {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

svg {
  display: block; }

.disp-block-pc {
  display: none; }
  @media all and (min-width: 800px) {
    .disp-block-pc {
      display: block; } }

.disp-block-sptb {
  display: block; }
  @media all and (min-width: 800px) {
    .disp-block-sptb {
      display: none; } }

.disp-block-pctb {
  display: none; }
  @media all and (min-width: 480px) {
    .disp-block-pctb {
      display: block; } }

.disp-block-sp {
  display: block; }
  @media all and (min-width: 480px) {
    .disp-block-sp {
      display: none; } }

.disp-block-pcsp {
  display: none; }
  @media all and (min-width: 375px) {
    .disp-block-pcsp {
      display: block; } }

.disp-block-sponly {
  display: block; }
  @media all and (min-width: 375px) {
    .disp-block-sponly {
      display: none; } }

.disp-inline-pc {
  display: none; }
  @media all and (min-width: 800px) {
    .disp-inline-pc {
      display: inline; } }

.disp-inline-sptb {
  display: inline; }
  @media all and (min-width: 800px) {
    .disp-inline-sptb {
      display: none; } }

.disp-inline-pctb {
  display: none; }
  @media all and (min-width: 480px) {
    .disp-inline-pctb {
      display: inline; } }

.disp-inline-sp {
  display: inline; }
  @media all and (min-width: 480px) {
    .disp-inline-sp {
      display: none; } }

.disp-inline-pcsp {
  display: none; }
  @media all and (min-width: 375px) {
    .disp-inline-pcsp {
      display: inline; } }

.disp-inline-sponly {
  display: inline; }
  @media all and (min-width: 375px) {
    .disp-inline-sponly {
      display: none; } }

/* 2022/12/09変更前
@mixin sweepButtonGradient(){
    background: rgb(0,0,0);
  background: -moz-linear-gradient(90deg,  color-brown01(1) 0%, color-blue01(1) 94%, color-brown01(1) 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,color-brown01(1)), color-stop(94%,color-blue01(1)), color-stop(100%,color-brown01(1)));
  background: -webkit-linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  background: -o-linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  background: -ms-linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  background: linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004656', endColorstr='#004656',GradientType=1 );
}
*/
/*
// Gradient mixin for Rise button
@mixin riseButtonGradient(){
  background: color-brown01(1);
  background: -moz-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: -webkit-gradient(linear, left, right, color-stop(0%, color-brown01(1)), color-stop(25%, rgba(53,50,48,1)), color-stop(43.5%, color-blue01(1)), color-stop(50%, color-blue01(1)), color-stop(56.5%, color-blue01(1)), color-stop(75%, rgba(53,50,48,1)), color-stop(100%, color-brown01(1)),);
  background: -webkit-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: -o-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: -ms-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%,  color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b664e', endColorstr='#004656',GradientType=1 );
}
*/
@font-face {
  font-family: "Bebas Regular";
  src: url("/assets/font/Bebas-Regular.eot?") format("eot"), url("/assets/font/Bebas-Regular.woff") format("woff"), url("/assets/font/Bebas-Regular.ttf") format("truetype"), url("/assets/font/Bebas-Regular.svg#Bebas-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Bebas Neue Regular";
  src: url("/assets/font/BebasNeue-Regular.eot?") format("eot"), url("/assets/font/BebasNeue-Regular.woff") format("woff"), url("/assets/font/BebasNeue-Regular.ttf") format("truetype"), url("/assets/font/BebasNeue-Regular.svg#BebasNeue-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Bebas Neue Light";
  src: url("/assets/font/BebasNeue-Light.eot?") format("eot"), url("/assets/font/BebasNeue-Light.woff") format("woff"), url("/assets/font/BebasNeue-Light.ttf") format("truetype"), url("/assets/font/BebasNeue-Light.svg#BebasNeue-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Bebas Neue Book";
  src: url("/assets/font/bebas_neue_book-webfont.eot");
  src: url("/assets/font/bebas_neue_book-webfont.eot?#iefix") format("embedded-opentype"), url("/assets/font/bebas_neue_book-webfont.woff2") format("woff2"), url("/assets/font/bebas_neue_book-webfont.woff") format("woff"), url("/assets/font/bebas_neue_book-webfont.svg#bebas_neuebook") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "RockvilleSolidRegular";
  src: url("/assets/font/Rockville_Solid.eot?") format("eot"), url("/assets/font/Rockville_Solid.woff") format("woff"), url("/assets/font/Rockville_Solid.ttf") format("truetype"), url("/assets/font/Rockville_Solid.svg#RockvilleSolidRegular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Linux Libertine Display Capitals";
  src: url("/assets/font/LinLibertine_aDRS.eot?") format("eot"), url("/assets/font/LinLibertine_aDRS.woff") format("woff"), url("/assets/font/LinLibertine_aDRS.ttf") format("truetype"), url("/assets/font/LinLibertine_aDRS.svg#LinLibertineDisplayCapitals") format("svg");
  font-weight: normal;
  font-style: normal; }

.align-l {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-l {
      text-align: left !important; } }

.align-r {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-r {
      text-align: right !important; } }

.align-c {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-c {
      text-align: center !important; } }

.br {
  display: none; }
  @media all and (min-width: 800px) {
    .br {
      display: inline-block; } }

/*
｡ : &#xFF61;
､ : &#xFF64;
･(和文) : &#xFF65;
·(欧文) : &#x00B7;
*/
.hover {
  filter: alpha(opacity=100);
  opacity: 1;
  transition: all 0.3s ease-in-out; }
  .hover:hover, .hover:focus {
    filter: alpha(opacity=70);
    opacity: 0.7; }
  .hover.-underline:hover, .hover.-underline:focus {
    filter: alpha(opacity=100);
    opacity: 1;
    text-decoration: underline; }
  .hover.-borderbtm:hover, .hover.-borderbtm:focus {
    filter: alpha(opacity=100);
    opacity: 1; }
    .hover.-borderbtm:hover .-borderbtm_target, .hover.-borderbtm:focus .-borderbtm_target {
      text-decoration: underline; }
  .hover.-ccblue03:hover, .hover.-ccblue03:focus {
    filter: alpha(opacity=100);
    opacity: 1;
    color: #3de7f7; }
    .hover.-ccblue03:hover div, .hover.-ccblue03:hover p, .hover.-ccblue03:focus div, .hover.-ccblue03:focus p {
      color: #3de7f7; }

.anchor {
  clear: both;
  position: relative;
  top: -60px;
  display: block; }
  @media all and (min-width: 800px) {
    .anchor {
      top: -72px; } }
  @media all and (min-width: 1320px) {
    .anchor {
      top: -80px; } }

.p0-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .p0-icon.-bk {
    color: black; }
  .p0-icon.-wh {
    color: white; }
  .p0-icon.-white02 {
    color: #e9e9e9; }
  .p0-icon.-blue02 {
    color: #1888a1; }
  .p0-icon.-blue03 {
    color: #3de7f7; }
  .p0-icon.-ml1::before {
    margin-left: 1px; }
  .p0-icon.-mr1::before {
    margin-right: 1px; }
  .p0-icon.-fs120per {
    font-size: 120%; }
  .p0-icon.-b-fs120per::before {
    font-size: 120%; }
  .p0-icon.-fs20 {
    font-size: 2rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-fs20 {
        font-size: 2rem; } }
  .p0-icon.-b-fs20::before {
    font-size: 2rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-b-fs20::before {
        font-size: 2rem; } }
  .p0-icon.-fs26 {
    font-size: 2.6rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-fs26 {
        font-size: 2.6rem; } }
  .p0-icon.-b-fs26::before {
    font-size: 2.6rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-b-fs26::before {
        font-size: 2.6rem; } }
  .p0-icon.-fs30 {
    font-size: 3rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-fs30 {
        font-size: 3rem; } }
  .p0-icon.-b-fs30::before {
    font-size: 3rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-b-fs30::before {
        font-size: 3rem; } }
  .p0-icon.-fs42 {
    font-size: 4.2rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-fs42 {
        font-size: 4.2rem; } }
  .p0-icon.-b-fs42::before {
    font-size: 4.2rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-b-fs42::before {
        font-size: 4.2rem; } }
  .p0-icon.-fs56 {
    font-size: 5.6rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-fs56 {
        font-size: 5.6rem; } }
  .p0-icon.-b-fs56::before {
    font-size: 5.6rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-b-fs56::before {
        font-size: 5.6rem; } }
  .p0-icon.-fs60 {
    font-size: 6rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-fs60 {
        font-size: 6rem; } }
  .p0-icon.-b-fs60::before {
    font-size: 6rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-b-fs60::before {
        font-size: 6rem; } }
  .p0-icon.-headercontact {
    font-size: 5.2rem; }
    @media all and (min-width: 1000px) {
      .p0-icon.-headercontact {
        font-size: 6rem; } }
  .p0-icon.-servicecircle {
    font-size: 3.36rem; }
    @media all and (min-width: 800px) {
      .p0-icon.-servicecircle {
        font-size: 4.2rem; } }
  .p0-icon.-rotate90 {
    transform: rotate(90deg); }
  .p0-icon.-rotate180 {
    transform: rotate(180deg); }
  .p0-icon.-rotate270 {
    transform: rotate(270deg); }
  .p0-icon.-chevronleft::before {
    font-family: 'Material Icons';
    content: '\e5cb'; }
  .p0-icon.-chevronright::before {
    font-family: 'Material Icons';
    content: '\e5cc'; }

@media all and (min-width: 375px) {
  .break.-minsp:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 480px) {
  .break.-mintb:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 600px) {
  .break.-mincih:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 800px) {
  .break.-minpc:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 1000px) {
  .break.-minni:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 1120px) {
  .break.-minnarrow:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 1320px) {
  .break.-mincontent:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(1000px - 1px)) {
  .break.-maxni:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(800px - 1px)) {
  .break.-maxpc:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(600px - 1px)) {
  .break.-maxcih:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(480px - 1px)) {
  .break.-maxtb:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(375px - 1px)) {
  .break.-maxsp:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 720px) {
  .break.-aboutspiritsbox1:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 1000px) {
  .break.-aboutspiritsbox1:after {
    content: "";
    white-space: normal; } }

body {
  width: 100%;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  color: #333333;
  background-color: #f8f8f8; }

a {
  color: #333333;
  text-decoration: none; }

::selection {
  background: #004656; }

::-moz-selection {
  background: #004656; }

.js-tellink a {
  color: inherit; }

.l-header {
  width: 100%;
  height: 94px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none; }
  @media all and (min-width: 1000px) {
    .l-header {
      height: 115px; } }
  .l-header_inner {
    position: relative;
    width: 90%;
    height: 100%;
    margin: 0 auto; }
    @media all and (min-width: 1620px) {
      .l-header_inner {
        width: calc(100% - 60px - 60px); } }

.l-wrap {
  overflow: hidden; }

.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative; }
  @media all and (min-width: 800px) {
    .l-section {
      padding: 60px 0; } }
  .l-section.-visible {
    overflow: visible; }
  .l-section.-hidden {
    overflow: hidden; }
  .l-section.-pad0 {
    padding: 0; }
  .l-section.-pt0 {
    padding: 0 0 30px; }
    @media all and (min-width: 800px) {
      .l-section.-pt0 {
        padding: 0 0 60px; } }
  .l-section.-pb0 {
    padding: 30px 0 0; }
    @media all and (min-width: 800px) {
      .l-section.-pb0 {
        padding: 60px 0 0; } }
  .l-section.-smallSpace {
    padding: 15px 0; }
    @media all and (min-width: 800px) {
      .l-section.-smallSpace {
        padding: 30px 0; } }
    .l-section.-smallSpace.-pb0 {
      padding: 15px 0 0; }
      @media all and (min-width: 800px) {
        .l-section.-smallSpace.-pb0 {
          padding: 30px 0 0; } }
  .l-section.-largeSpace {
    padding: 45px 0; }
    @media all and (min-width: 800px) {
      .l-section.-largeSpace {
        padding: 50px 0; } }
    @media all and (min-width: 1000px) {
      .l-section.-largeSpace {
        padding: 80px 0; } }
    @media all and (min-width: 1120px) {
      .l-section.-largeSpace {
        padding: 90px 0; } }
    .l-section.-largeSpace.-pb0 {
      padding: 45px 0 0; }
      @media all and (min-width: 800px) {
        .l-section.-largeSpace.-pb0 {
          padding: 50px 0 0; } }
      @media all and (min-width: 1000px) {
        .l-section.-largeSpace.-pb0 {
          padding: 80px 0 0; } }
      @media all and (min-width: 1120px) {
        .l-section.-largeSpace.-pb0 {
          padding: 90px 0 0; } }
  .l-section.-hugeSpace {
    padding: 50px 0; }
    @media all and (min-width: 1000px) {
      .l-section.-hugeSpace {
        padding: 90px 0; } }
    @media all and (min-width: 1120px) {
      .l-section.-hugeSpace {
        padding: 120px 0; } }
    .l-section.-hugeSpace.-pb0 {
      padding: 50px 0 0; }
      @media all and (min-width: 1000px) {
        .l-section.-hugeSpace.-pb0 {
          padding: 90px 0 0; } }
      @media all and (min-width: 1120px) {
        .l-section.-hugeSpace.-pb0 {
          padding: 120px 0 0; } }
  .l-section.-gitSpace {
    padding: 75px 0; }
    @media all and (min-width: 600px) {
      .l-section.-gitSpace {
        padding: 80px 0; } }
    @media all and (min-width: 720px) {
      .l-section.-gitSpace {
        padding: 52px 0; } }
    @media all and (min-width: 800px) {
      .l-section.-gitSpace {
        padding: 95px 0; } }
    @media all and (min-width: 1000px) {
      .l-section.-gitSpace {
        padding: 120px 0; } }
    .l-section.-gitSpace.-pb0 {
      padding: 75px 0 0; }
      @media all and (min-width: 600px) {
        .l-section.-gitSpace.-pb0 {
          padding: 80px 0 0; } }
      @media all and (min-width: 720px) {
        .l-section.-gitSpace.-pb0 {
          padding: 52px 0 0; } }
      @media all and (min-width: 800px) {
        .l-section.-gitSpace.-pb0 {
          padding: 95px 0 0; } }
      @media all and (min-width: 1000px) {
        .l-section.-gitSpace.-pb0 {
          padding: 120px 0 0; } }
  .l-section.-lowerpage {
    padding: 40px 0; }
    @media all and (min-width: 800px) {
      .l-section.-lowerpage {
        padding: 80px 0; } }
    .l-section.-lowerpage.-pb0 {
      padding: 40px 0 0; }
      @media all and (min-width: 800px) {
        .l-section.-lowerpage.-pb0 {
          padding: 80px 0 0; } }
  .l-section_hidden {
    overflow: hidden; }
  .l-section_wrap {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .l-section_wrap.-max {
      width: 100%; }
      @media all and (min-width: 1620px) {
        .l-section_wrap.-max {
          width: 1620px; } }
  .l-section.-bgtype01 {
    background-color: white; }
  .l-section.-bgtype02 {
    background-color: #2b2b2b; }
  .l-section.-bgtype03 {
    background-image: url("../images/front/works/works_bg2.jpg");
    background-position: center center;
    background-repeat: repeat-x;
    background-size: auto 100%; }
    .l-section.-bgtype03.-marquee {
      background-position: center top;
      background-size: auto 200%; }
  .l-section.-bgtype04 {
    background: linear-gradient(90deg, #f8f8f8 0%, #f8f8f8 45.5%, white 45.5%, white 100%); }
  .l-section.-bgtype05 {
    background-image: url("../images/about/spacer02.jpg");
    background-position: center center;
    background-repeat: repeat-x;
    background-size: auto 100%; }
    .l-section.-bgtype05.-marquee {
      background-position: center top;
      background-size: auto 117%; }
  .l-section_bg {
    position: relative;
    width: 100%; }
    .l-section_bg.-bgtype01 {
      background-color: white; }
    .l-section_bg.-bgtype02 {
      background-color: #2b2b2b; }
    .l-section_bg.-bgtype03 {
      background: linear-gradient(180deg, transparent 10vw, #2b2b2b 10vw); }
      @media all and (min-width: 1620px) {
        .l-section_bg.-bgtype03 {
          background: linear-gradient(180deg, transparent 170px, #2b2b2b 170px); } }
    .l-section_bg.-bgtype04 {
      background: linear-gradient(90deg, #f8f8f8 0%, #f8f8f8 45.5%, white 45.5%, white 100%); }
      .l-section_bg.-bgtype04.-zim1 {
        z-index: -1; }
    .l-section_bg.-bgtype05 {
      background-image: url("../images/front/works/works_bg2.jpg");
      background-position: center center;
      background-repeat: repeat-x;
      background-size: auto 100%; }
  .l-section.-zi1 {
    z-index: 1; }
  .l-section.-zi2 {
    z-index: 2; }
  .l-section.-zi3 {
    z-index: 3; }
  .l-section.-zi4 {
    z-index: 4; }
  .l-section.-zi5 {
    z-index: 5; }
  .l-section.-zi6 {
    z-index: 6; }
  .l-section.-zi7 {
    z-index: 7; }
  .l-section.-zi8 {
    z-index: 8; }
  .l-section.-zi9 {
    z-index: 9; }
  .l-section.-zi10 {
    z-index: 10; }
  .l-section_content {
    width: 90%;
    margin: 0 auto;
    position: relative; }
    @media all and (min-width: 1320px) {
      .l-section_content {
        width: 1200px; } }
    @media all and (min-width: 1120px) {
      .l-section_content.-narrow {
        width: 1000px; } }
    @media all and (min-width: 1320px) {
      .l-section_content.-cards {
        width: 90%; } }
    @media all and (min-width: 1500px) {
      .l-section_content.-cards {
        width: 1360px; } }
    @media all and (min-width: 1320px) {
      .l-section_content.-wide {
        width: 90%; } }
    @media all and (min-width: 1620px) {
      .l-section_content.-wide {
        width: 1500px; } }
    .l-section_content.-full {
      width: 100%; }
      @media all and (min-width: 1320px) {
        .l-section_content.-full {
          width: 100%; } }
    .l-section_content.-wide_full {
      width: 100%; }
      .l-section_content.-wide_full .l-section_content_inner {
        width: 90%;
        margin-left: auto;
        margin-right: auto; }
        @media all and (min-width: 1120px) {
          .l-section_content.-wide_full .l-section_content_inner.-narrow {
            width: 1000px; } }
        .l-section_content.-wide_full .l-section_content_inner.-contentinner {
          width: 100%; }
          @media all and (min-width: 1200px) {
            .l-section_content.-wide_full .l-section_content_inner.-contentinner {
              width: 1120px; } }
      @media all and (min-width: 1620px) {
        .l-section_content.-wide_full {
          width: 1500px; }
          .l-section_content.-wide_full .l-section_content_content {
            width: 100%; } }
    .l-section_content.-visible {
      overflow: visible; }
    .l-section_content.-zi1 {
      z-index: 1; }
    .l-section_content.-zi2 {
      z-index: 2; }
    .l-section_content.-zi3 {
      z-index: 3; }
    .l-section_content.-zi4 {
      z-index: 4; }
    .l-section_content.-zi5 {
      z-index: 5; }
    .l-section_content.-zi6 {
      z-index: 6; }
    .l-section_content.-zi7 {
      z-index: 7; }
    .l-section_content.-zi8 {
      z-index: 8; }
    .l-section_content.-zi9 {
      z-index: 9; }
    .l-section_content.-zi10 {
      z-index: 10; }

.l-footer {
  padding: 120px 0 20px 0;
  box-sizing: border-box;
  background-color: #2b2b2b; }
  @media all and (min-width: 600px) {
    .l-footer {
      padding: 130px 0 40px 0; } }
  .l-footer_gototop {
    display: inline-block;
    position: absolute;
    bottom: 40px;
    right: 5%;
    transform: translate(15px, 20px); }
    @media all and (min-width: 1620px) {
      .l-footer_gototop {
        bottom: 40px;
        right: 60px;
        transform: translate(15px, 20px); } }
    @media all and (min-width: 1620px) {
      .l-footer_gototop {
        bottom: 40px;
        right: 60px;
        transform: translate(15px, 20px); } }
    .l-footer_gototop a {
      display: inline-block;
      width: 100%;
      height: 100%; }
  .l-footer_btm {
    display: block;
    position: relative;
    width: 100%;
    height: 30px;
    content: "";
    background-color: black; }
    @media all and (min-width: 1000px) {
      .l-footer_btm {
        height: 50px; } }

.c-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
  .c-flex_item.-box2 {
    width: 100%; }
    @media all and (min-width: 800px) {
      .c-flex_item.-box2 {
        width: 47.5%; } }

/*
.c-flex02 {
  @include flexbox;
  @include flex-direction-column;
  @include flex-wrap-wrap;

  @include breakpoint-pc {
    @include flex-direction-row;
    @include flex-wrap-nowrap;
    @include flex-justify-space-between;
  }
  &_item {
    &.-item01 {
      width: 60vw;
      max-width: 500px;
      margin: 0 auto;
      order: 2;
      display: none;
      @include breakpoint-pc {
        width: 40%;
        padding: 0 5% 0 0;
        order: 1;
        display: block;
      }
      @include breakpoint-pc {
        width: 50%;
      }
      @media (min-width:900px) {
        width: 500px;
        padding: 0 60px 0 0;
      }
    }
    &.-item02 {
      width: 100%;
      order: 1;
      margin-bottom: 6vw;
      @include breakpoint-pc {
        width: 60%;
        order: 2;
        margin-bottom: 0;
      }
      @include breakpoint-pc {
        width: 50%;
      }
      @media (min-width:900px) {
        width: 500px;
      }
    }
  }
}
*/
.c-card {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  box-sizing: border-box; }
  @media all and (min-width: 1120px) {
    .c-card {
      padding-bottom: 30px; } }
  .c-card:hover .c-card_btm:after, .c-card:hover .c-card_btm.-bgwh:after {
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-animation: 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.01s both hoverCardBtmBorder;
    animation: 0.8s cubic-bezier(0.19, 1, 0.22, 1) 0.01s both hoverCardBtmBorder; }
  .c-card:hover .c-card_btm:after {
    background-image: url("../images/common/card/hover_arw_cy.png"); }
  .c-card:hover .c-card_btm.-bgwh:after {
    background-image: url("../images/common/card/hover_arw_bg.png"); }
  .c-card_no {
    position: absolute;
    top: 0;
    right: -3px;
    transform: translateX(100%);
    writing-mode: vertical-rl;
    text-align: left;
    text-orientation: upright;
    text-orientation: sideways; }
    .c-card_no .combine {
      text-combine-upright: all; }
    .c-card_no_num {
      display: inline-block;
      transform: translateX(2px); }
  .c-card_img {
    position: relative;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    /*
    &_thm {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: cover;
      object-position: 50% 50%;
    }
*/ }
    .c-card_img.-deco:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      border: 10px solid #d4d4d4;
      box-sizing: border-box; }
    .c-card_img_size {
      width: 100%;
      height: auto; }
  .c-card_ttl, .c-card_client {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; }
  .c-card_separator {
    display: block;
    position: relative;
    width: 1px;
    height: 20px;
    margin-left: auto;
    margin-right: auto; }
    @media all and (min-width: 720px) {
      .c-card_separator {
        height: 30px; } }
    @media all and (min-width: 800px) {
      .c-card_separator {
        height: 20px; } }
    @media all and (min-width: 1000px) {
      .c-card_separator {
        height: 30px; } }
    .c-card_separator:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(61, 231, 247, 0.4); }
    .c-card_separator.-bgwh:after {
      background-color: rgba(24, 136, 161, 0.4); }
  .c-card_author {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: -5px; }
    @media all and (min-width: 1120px) {
      .c-card_author {
        margin-bottom: -10px; } }
    .c-card_author_img {
      width: 24px;
      height: 24px;
      overflow: hidden;
      margin-right: 10px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      box-sizing: border-box; }
      @media all and (min-width: 720px) {
        .c-card_author_img {
          width: 32px;
          height: 32px; } }
      @media all and (min-width: 800px) {
        .c-card_author_img {
          width: 24px;
          height: 24px; } }
      @media all and (min-width: 1000px) {
        .c-card_author_img {
          width: 32px;
          height: 32px; } }
      @media all and (min-width: 1120px) {
        .c-card_author_img {
          width: 40px;
          height: 40px; } }
      .c-card_author_img img {
        width: 100%;
        height: auto;
        opacity: 0; }
  .c-card_btm {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 16px; }
    .c-card_btm:after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 15px;
      background-image: url("../images/common/card/border_cy.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: auto 100%;
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: background-image;
      transition-property: background-image; }
    .c-card_btm.-bgwh:after {
      background-image: url("../images/common/card/border_bg.png"); }

/*
.hovicon.effect-2 {
  color: #eea303;
  box-shadow: 0 0 0 3px #fff;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}
.hovicon.effect-2:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  background: #fff;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s;
}
.hovicon.effect-2.sub-b:hover:after {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.2s;
  -moz-transition: -moz-transform 0.4s, opacity 0.2s;
  transition: transform 0.4s, opacity 0.2s;
}
.hovicon.effect-2.sub-b:hover, .hovicon.effect-2.sub-b:hover i {
  color: #fff;
}
*/
.c-circle {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #2b2b2b;
  box-sizing: border-box;
  background-color: #2b2b2b;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s; }
  .c-circle:hover {
    background-color: white; }
    .c-circle:hover .c-circle_name {
      color: #151515; }
    .c-circle:hover .c-circle_slug {
      color: #8b664e; }
      .c-circle:hover .c-circle_slug::before {
        animation: 0.3s linear 0.1s 1 both hoverCircleBorder; }
      .c-circle:hover .c-circle_slug_txt {
        opacity: 0;
        -webkit-transition: opacity 0.15s;
        -moz-transition: opacity 0.15s;
        transition: opacity 0.15s; }
      .c-circle:hover .c-circle_slug_hovertxt {
        opacity: 1;
        -webkit-transition: opacity 0.2s;
        -moz-transition: opacity 0.2s;
        transition: opacity 0.2s;
        transition-delay: 0.15s; }
    .c-circle:hover .c-circle_icon {
      color: #2b2b2b; }
  .c-circle_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 18px;
    box-sizing: border-box;
    text-align: center; }
  .c-circle_name, .c-circle_slug, .c-circleicon {
    -webkit-transition: color 0.2s;
    -moz-transition: color 0.2s;
    transition: color 0.2s; }
  .c-circle_name {
    height: 2.4em;
    padding: 0 .5em;
    box-sizing: border-box;
    color: white; }
  .c-circle_slug {
    margin-bottom: 15px;
    padding-bottom: 8px;
    box-sizing: border-box;
    color: white; }
    .c-circle_slug::before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 1px;
      background-color: white; }
    .c-circle_slug::after {
      display: none;
      position: absolute;
      top: 0;
      content: "WORKS";
      opacity: 0; }
    .c-circle_slug_txt {
      position: relative;
      opacity: 1;
      -webkit-transition: opacity 0.2s;
      -moz-transition: opacity 0.2s;
      transition: opacity 0.2s;
      transition-delay: 0.15s; }
    .c-circle_slug_hovertxt {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      text-align: center;
      -webkit-transition: opacity 0.15s;
      -moz-transition: opacity 0.15s;
      transition: opacity 0.15s; }
      .c-circle_slug_hovertxt::after {
        font-size: 140%;
        font-family: 'Material Icons';
        content: '\e5cc\e5cc';
        letter-spacing: -0.75em; }
  .c-circle_icon {
    color: white; }

.c-tag {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  gap: 5px 5px; }
  .c-tag.-oneline {
    overflow: hidden;
    height: 19px; }
    @media all and (min-width: 800px) {
      .c-tag.-oneline {
        height: 20px; } }
  .c-tag.-workssingle {
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
    .c-tag.-workssingle .c-tag_item {
      margin-right: 5px;
      padding: 1px 0;
      border-style: none;
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 400;
      font-size: 1rem; }
      @media all and (min-width: 800px) {
        .c-tag.-workssingle .c-tag_item {
          font-size: 1.1rem; } }
  .c-tag_item {
    display: inline-block;
    padding: 1px 5px 4px 5px;
    border: 2px solid transparent;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .c-tag_item {
        font-size: 1.1rem; } }
    .c-tag_item.-pickup {
      color: white;
      background-color: #1888a1; }
    .c-tag_item.-works1 {
      color: white;
      background-color: #8b664e;
      border-color: #8b664e; }
    .c-tag_item.-works2 {
      color: #8b664e;
      background-color: transparent;
      border-color: #8b664e; }
    .c-tag_item.-blog1 {
      color: white;
      border-color: #8b664e;
      background-color: #8b664e; }
    .c-tag_item.-blog2 {
      color: #8b664e;
      border-color: #8b664e;
      background-color: transparent; }

.c-pickup {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0;
  box-sizing: border-box; }
  @media all and (min-width: 800px) {
    .c-pickup {
      padding: 0; } }
  @media all and (min-width: 1120px) {
    .c-pickup {
      padding: 0; } }
  @media all and (min-width: 1620px) {
    .c-pickup {
      padding: 0 40px; } }
  .c-pickup_head {
    margin-bottom: 15px; }
    @media all and (min-width: 600px) {
      .c-pickup_head {
        margin-bottom: 25px; } }
    @media all and (min-width: 720px) {
      .c-pickup_head {
        margin-bottom: 15px; } }
    @media all and (min-width: 800px) {
      .c-pickup_head {
        margin-bottom: 35px; } }
    @media all and (min-width: 1620px) {
      .c-pickup_head {
        margin-bottom: 55px; } }
  .c-pickup_body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto; }
    @media all and (min-width: 720px) {
      .c-pickup_body {
        width: 100%; } }
  .c-pickup_ttl {
    margin-bottom: 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; }
    @media all and (min-width: 480px) {
      .c-pickup_ttl {
        margin-bottom: 3px; } }
    @media all and (min-width: 600px) {
      .c-pickup_ttl {
        margin-bottom: 10px; } }
    @media all and (min-width: 720px) {
      .c-pickup_ttl {
        margin-bottom: 15px; } }
    @media all and (min-width: 800px) {
      .c-pickup_ttl {
        margin-bottom: 10px; } }
  .c-pickup_client:before {
    display: inline-block;
    position: relative;
    content: "Client:";
    margin-right: 5px;
    font-family: 'Linux Libertine Display Capitals';
    font-weight: normal;
    font-style: normal;
    font-size: 1.4rem;
    font-style: italic;
    letter-spacing: 0.01em; }
    @media all and (min-width: 600px) {
      .c-pickup_client:before {
        font-size: 1.6rem; } }
    @media all and (min-width: 1000px) {
      .c-pickup_client:before {
        font-size: 1.7rem; } }
  .c-pickup_imgbox {
    position: relative;
    overflow: visible;
    width: 100%;
    height: 60vw;
    margin-bottom: 20px; }
    @media all and (min-width: 720px) {
      .c-pickup_imgbox {
        width: 52.77778%;
        height: auto;
        margin-bottom: 0; } }
    @media all and (min-width: 800px) {
      .c-pickup_imgbox {
        width: 55%; } }
    @media all and (min-width: 1000px) {
      .c-pickup_imgbox {
        width: 62%; } }
    @media all and (min-width: 1120px) {
      .c-pickup_imgbox {
        width: 64.28571%; } }
    .c-pickup_imgbox.-img {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover; }
      .c-pickup_imgbox.-img img {
        width: 100%;
        height: auto; }
    .c-pickup_imgbox.-video img {
      width: 100%;
      height: auto; }
    .c-pickup_imgbox.-video video {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .c-pickup_txtbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    width: 100%;
    padding-bottom: 55px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .c-pickup_txtbox {
        padding-bottom: 60px; } }
    @media all and (min-width: 720px) {
      .c-pickup_txtbox {
        width: 42.36111%;
        padding-bottom: 0;
        padding-left: 40px; } }
    @media all and (min-width: 800px) {
      .c-pickup_txtbox {
        width: 40%; } }
    @media all and (min-width: 1000px) {
      .c-pickup_txtbox {
        width: 33%;
        padding-left: 50px; } }
    @media all and (min-width: 1120px) {
      .c-pickup_txtbox {
        width: 30.35714%;
        padding-left: 60px; } }
  .c-pickup_tag {
    display: none;
    position: absolute; }
    @media all and (min-width: 800px) {
      .c-pickup_tag {
        display: block;
        top: -20px;
        left: 15px;
        opacity: 1; } }
    @media all and (min-width: 1000px) {
      .c-pickup_tag {
        left: auto;
        top: 10px;
        right: 3vw; } }
    @media all and (min-width: 1120px) {
      .c-pickup_tag {
        right: -20px; } }
    @media all and (min-width: 1620px) {
      .c-pickup_tag {
        top: 30px;
        right: -20px; } }
    .c-pickup_tag img {
      width: auto;
      height: 100%; }
  .c-pickup_txt {
    display: block;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; }
    @media all and (min-width: 720px) {
      .c-pickup_txt {
        top: 35px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6; } }
    @media all and (min-width: 800px) {
      .c-pickup_txt {
        top: 55px; } }
    @media all and (min-width: 1000px) {
      .c-pickup_txt {
        top: 130px; } }
    @media all and (min-width: 1120px) {
      .c-pickup_txt {
        top: 190px; } }
  .c-pickup_nav {
    display: block;
    position: relative;
    z-index: 11;
    margin-top: 35px; }
    @media all and (min-width: 480px) {
      .c-pickup_nav {
        display: inline-block; } }
    @media all and (min-width: 600px) {
      .c-pickup_nav {
        margin-top: 40px; } }
    @media all and (min-width: 720px) {
      .c-pickup_nav {
        margin-top: 0;
        margin-left: -5px; } }

.c-swiper {
  overflow: visible; }
  .c-swiper.-frontservice {
    position: relative;
    width: 100%;
    height: 100%; }
    @media all and (min-width: 800px) {
      .c-swiper.-frontservice {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(-7%);
        width: 100%;
        height: 100%; } }
    @media all and (min-width: 1320px) {
      .c-swiper.-frontservice {
        width: calc( 50vw -  5.5555555555556vw); } }
    @media all and (min-width: 1620px) {
      .c-swiper.-frontservice {
        transform: translateX(-40px);
        width: calc( 100% * (720 / 570)); } }
    .c-swiper.-frontservice .c-swiper_slide {
      width: auto;
      height: auto; }
      @media all and (min-width: 800px) {
        .c-swiper.-frontservice .c-swiper_slide {
          height: 29.62962962962963vw; } }
      @media all and (min-width: 1620px) {
        .c-swiper.-frontservice .c-swiper_slide {
          height: 480px; } }
  .c-swiper.-front-blog {
    padding-bottom: 120px;
    box-sizing: border-box; }
    @media all and (min-width: 720px) {
      .c-swiper.-front-blog {
        padding-bottom: 130px; } }
    @media all and (min-width: 800px) {
      .c-swiper.-front-blog {
        padding-bottom: 90px; } }
    @media all and (min-width: 1000px) {
      .c-swiper.-front-blog {
        padding-bottom: 130px; } }
    @media all and (min-width: 1620px) {
      .c-swiper.-front-blog {
        padding-bottom: 170px; } }
    .c-swiper.-front-blog .swiper-slide-duplicate {
      display: block; }
  .c-swiper.-workspickup {
    padding-bottom: 45px;
    box-sizing: border-box;
    /*
    .p0-btn {
      &.-btn2 {
        overflow: hidden !important;
  
        .p0-btn_inner, a {
          &:before {
            display: none !important;
          } 
        }
      }
    }
*/ }
    @media all and (min-width: 800px) {
      .c-swiper.-workspickup {
        padding-bottom: 90px; } }
    .c-swiper.-workspickup .c-swiper_slide {
      opacity: 0 !important;
      transition: opacity 0.15s ease-in-out;
      transition-property: opacity; }
      .c-swiper.-workspickup .c-swiper_slide.swiper-slide-active {
        opacity: 1 !important; }
  .c-swiper.-service-works {
    padding-bottom: 120px;
    box-sizing: border-box; }
    @media all and (min-width: 720px) {
      .c-swiper.-service-works {
        padding-bottom: 130px; } }
    @media all and (min-width: 800px) {
      .c-swiper.-service-works {
        padding-bottom: 90px; } }
    @media all and (min-width: 1000px) {
      .c-swiper.-service-works {
        padding-bottom: 130px; } }
    @media all and (min-width: 1620px) {
      .c-swiper.-service-works {
        padding-bottom: 170px; } }
  .c-swiper_nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    bottom: -35px !important; }
    @media all and (min-width: 1000px) {
      .c-swiper_nav {
        bottom: -45px !important; } }
    @media all and (min-width: 1620px) {
      .c-swiper_nav {
        bottom: -55px !important; } }
    .c-swiper_nav.-left {
      -webkit-justify-content: flex-start;
      justify-content: flex-start; }
      .c-swiper_nav.-left.-pickup {
        -webkit-justify-content: center;
        justify-content: center;
        bottom: -15px !important;
        box-sizing: border-box; }
        @media all and (min-width: 480px) {
          .c-swiper_nav.-left.-pickup {
            -webkit-justify-content: flex-start;
            justify-content: flex-start; } }
        @media all and (min-width: 800px) {
          .c-swiper_nav.-left.-pickup {
            bottom: -15px !important; } }
        @media all and (min-width: 1000px) {
          .c-swiper_nav.-left.-pickup {
            bottom: -15px !important; } }
        @media all and (min-width: 1620px) {
          .c-swiper_nav.-left.-pickup {
            bottom: -15px !important; } }
        .c-swiper_nav.-left.-pickup .c-swiper_nav_prev, .c-swiper_nav.-left.-pickup .c-swiper_nav_next {
          display: none; }
          @media all and (min-width: 720px) {
            .c-swiper_nav.-left.-pickup .c-swiper_nav_prev, .c-swiper_nav.-left.-pickup .c-swiper_nav_next {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex; } }
  .c-swiper_pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    bottom: 0 !important;
    width: auto !important;
    margin: 0 3px; }
    .c-swiper_pagination.swiper-pagination-bullets .swiper-pagination-bullet {
      position: relative;
      z-index: 0;
      width: 10px;
      height: 10px;
      background-color: white;
      opacity: 1 !important; }
      .c-swiper_pagination.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
        background-color: #1888a1; }
      .c-swiper_pagination.swiper-pagination-bullets .swiper-pagination-bullet:after {
        display: inline;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: rgba(24, 136, 161, 0.4); }
  .c-swiper_btn_prev, .c-swiper_btn_next {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 50%;
    width: 27px;
    height: 120px;
    background-color: #1888a1; }
    @media all and (min-width: 375px) {
      .c-swiper_btn_prev, .c-swiper_btn_next {
        width: 31px;
        height: 140px; } }
    @media all and (min-width: 720px) {
      .c-swiper_btn_prev, .c-swiper_btn_next {
        display: none; } }
  .c-swiper_btn_prev {
    left: -5vw;
    transform: translate(0, -50%); }
  .c-swiper_btn_next {
    right: -5vw;
    transform: translate(0, -50%); }

#ig-slide-row1 #sb_instagram {
  padding-bottom: 0 !important; }
  #ig-slide-row1 #sb_instagram .swiper-wrapper {
    transition-timing-function: linear; }
  #ig-slide-row1 #sb_instagram #sbi_images .sbi_item {
    padding: 10px !important;
    box-sizing: border-box; }
    @media all and (min-width: 600px) {
      #ig-slide-row1 #sb_instagram #sbi_images .sbi_item {
        padding: 10px !important; } }
    @media all and (min-width: 800px) {
      #ig-slide-row1 #sb_instagram #sbi_images .sbi_item {
        padding: 15px !important; } }
    @media all and (min-width: 1120px) {
      #ig-slide-row1 #sb_instagram #sbi_images .sbi_item {
        padding: 20px !important; } }
    #ig-slide-row1 #sb_instagram #sbi_images .sbi_item .sbi_photo {
      overflow: hidden;
      border-radius: 60px;
      box-sizing: border-box; }
      #ig-slide-row1 #sb_instagram #sbi_images .sbi_item .sbi_photo .fa-clone {
        display: none !important; }

.c-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 100% !important;
  height: 85vh;
  max-height: 85vh !important;
  padding: 0 !important;
  box-sizing: border-box;
  background-color: transparent !important; }
  @media all and (min-width: 800px) {
    .c-modal {
      width: calc(100% - 160px - 160px);
      max-width: calc(100% - 160px - 160px) !important; } }
  .c-modal.-movie .c-modal_closebg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .c-modal.-movie .c-modal_inner {
    position: relative;
    max-width: 100%;
    max-height: 100%; }
    .c-modal.-movie .c-modal_inner video {
      position: relative;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; }
  .c-modal_openbtn {
    cursor: pointer; }
  .c-modal_close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .c-modal .remodal-close {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: relative;
    transform: translateY(-100%);
    width: 28px;
    height: 24px;
    background-image: url("../images/common/icon/modal_close.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat; }
    .c-modal .remodal-close:before {
      display: none; }
    .c-modal .remodal-close:focus {
      outline: 0; }

.remodal-wrapper {
  max-height: 100%;
  padding: 30px 0 !important; }
  @media all and (min-width: 1620px) {
    .remodal-wrapper {
      padding: 30px 0 !important; } }

.remodal-overlay {
  background-color: black !important;
  cursor: pointer; }

.c-movetext {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  box-sizing: border-box; }
  .c-movetext:before, .c-movetext:after {
    display: block;
    position: relative;
    width: 100vw;
    height: 98px;
    content: "";
    background-color: #2b2b2b; }
    @media all and (min-width: 375px) {
      .c-movetext:before, .c-movetext:after {
        height: 112px; } }
    @media all and (min-width: 480px) {
      .c-movetext:before, .c-movetext:after {
        height: 126px; } }
    @media all and (min-width: 800px) {
      .c-movetext:before, .c-movetext:after {
        height: 140px; } }
  .c-movetext.-about {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%); }
    .c-movetext.-about:before, .c-movetext.-about:after {
      display: none; }
  .c-movetext_inner {
    position: relative; }
  .c-movetext_item.-works {
    height: 53px; }
    @media all and (min-width: 375px) {
      .c-movetext_item.-works {
        height: 63px; } }
    @media all and (min-width: 480px) {
      .c-movetext_item.-works {
        height: 84px; } }
    @media all and (min-width: 800px) {
      .c-movetext_item.-works {
        height: 106px; } }
  .c-movetext_item.-service {
    height: 64px; }
    @media all and (min-width: 375px) {
      .c-movetext_item.-service {
        height: 77px; } }
    @media all and (min-width: 480px) {
      .c-movetext_item.-service {
        height: 103px; } }
    @media all and (min-width: 800px) {
      .c-movetext_item.-service {
        height: 129px; } }
  .c-movetext_item.-about {
    height: 64px; }
    @media all and (min-width: 375px) {
      .c-movetext_item.-about {
        height: 77px; } }
    @media all and (min-width: 480px) {
      .c-movetext_item.-about {
        height: 103px; } }
    @media all and (min-width: 800px) {
      .c-movetext_item.-about {
        height: 129px; } }
  .c-movetext_item img {
    width: auto;
    height: 100%; }

.p0-btn {
  display: block;
  position: relative;
  box-sizing: border-box;
  clear: both;
  cursor: pointer; }
  .p0-btn_inner, .p0-btn a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box; }
  .p0-btn.-bk a {
    background-color: black;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    color: white; }
    .p0-btn.-bk a:hover {
      background-color: white;
      color: black; }
      .p0-btn.-bk a:hover .p0-icon {
        color: black; }
  .p0-btn.-bk .p0-icon {
    color: white; }
  .p0-btn.-center {
    text-align: center; }
  .p0-btn.-btn1 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2.4rem; }
    @media all and (min-width: 800px) {
      .p0-btn.-btn1 {
        font-size: 2.4rem; } }
    .p0-btn.-btn1 .p0-btn_inner {
      padding: 14px 0 15px; }
    .p0-btn.-btn1 .p0-btn_txt {
      margin-left: 15px; }
  .p0-btn.-btn2 {
    height: 60px;
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 2.125rem;
    line-height: 1;
    letter-spacing: 0.05em;
    background-color: white; }
    @media all and (min-width: 800px) {
      .p0-btn.-btn2 {
        font-size: 2.5rem; } }
    @media all and (min-width: 480px) {
      .p0-btn.-btn2 {
        height: 60px; } }
    @media all and (min-width: 600px) {
      .p0-btn.-btn2 {
        height: 70px; } }
    @media all and (min-width: 800px) {
      .p0-btn.-btn2 {
        height: 100px; } }
    @media all and (min-width: 1000px) {
      .p0-btn.-btn2 {
        height: 100px; } }
    .p0-btn.-btn2.-bottom {
      position: absolute;
      bottom: 0; }
    .p0-btn.-btn2.-ajusthalf {
      margin-bottom: calc( -60px / 2); }
      @media all and (min-width: 480px) {
        .p0-btn.-btn2.-ajusthalf {
          margin-bottom: calc( -60px / 2); } }
      @media all and (min-width: 600px) {
        .p0-btn.-btn2.-ajusthalf {
          margin-bottom: calc( -70px / 2); } }
      @media all and (min-width: 800px) {
        .p0-btn.-btn2.-ajusthalf {
          margin-bottom: calc( -100px / 2); } }
      @media all and (min-width: 1000px) {
        .p0-btn.-btn2.-ajusthalf {
          margin-bottom: calc( -100px / 2); } }
    .p0-btn.-btn2 .p0-btn_wrap {
      position: absolute;
      width: 100vw;
      height: 100%;
      overflow: hidden; }
    .p0-btn.-btn2 .p0-btn_inner {
      height: 100%;
      padding: 0 40px;
      box-sizing: border-box;
      color: white;
      background-color: #8b664e; }
      .p0-btn.-btn2 .p0-btn_inner .gradient {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 2340px;
        height: 100%;
        bottom: auto;
        margin: auto;
        z-index: 0;
        background: black;
        background: -moz-linear-gradient(90deg, #8b664e 0%, #d5ac72 94%, #8b664e 100%);
        background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #8b664e), color-stop(94%, #d5ac72), color-stop(100%, #8b664e));
        background: -webkit-linear-gradient(90deg, #8b664e 0%, #d5ac72 94%, #8b664e 100%);
        background: -o-linear-gradient(90deg, #8b664e 0%, #d5ac72 94%, #8b664e 100%);
        background: -ms-linear-gradient(90deg, #8b664e 0%, #d5ac72 94%, #8b664e 100%);
        background: linear-gradient(90deg, #8b664e 0%, #d5ac72 94%, #8b664e 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b664e', endColorstr='#8b664e',GradientType=1 );
        transform: translateX(-100%);
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
        -webkit-transition-duration: 0s;
        transition-duration: 0s;
        -webkit-transition-property: transform;
        transition-property: transform; }
      .p0-btn.-btn2 .p0-btn_inner:hover .gradient, .p0-btn.-btn2 .p0-btn_inner:focus .gradient {
        transform: translateX(100vw);
        -webkit-transition-duration: 1.2s;
        transition-duration: 1.2s; }
    .p0-btn.-btn2 .p0-btn_txt {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      z-index: 1; }
      .p0-btn.-btn2 .p0-btn_txt:after {
        position: relative;
        transform: translateY(1px);
        content: "";
        width: 10px;
        height: 16px;
        margin-left: 10px;
        background-image: url("../images/common/icon/right_dottri_wh.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain; }
        @media all and (min-width: 800px) {
          .p0-btn.-btn2 .p0-btn_txt:after {
            width: 11px;
            height: 18px; } }
    .p0-btn.-btn2.-slide {
      width: 100%;
      max-width: 335px; }
      @media all and (min-width: 480px) {
        .p0-btn.-btn2.-slide {
          width: 61.11111%;
          max-width: none; } }
      @media all and (min-width: 600px) {
        .p0-btn.-btn2.-slide {
          width: 60%; } }
      @media all and (min-width: 720px) {
        .p0-btn.-btn2.-slide {
          width: 52.5%; } }
      @media all and (min-width: 1000px) {
        .p0-btn.-btn2.-slide {
          width: 47.5%; } }
    .p0-btn.-btn2.-left {
      margin-right: auto; }
      .p0-btn.-btn2.-left:before {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-110%);
        content: "";
        width: 100vw;
        height: 100%;
        background-color: white; }
      .p0-btn.-btn2.-left .p0-btn_wrap {
        right: 0; }
      .p0-btn.-btn2.-left .p0-btn_inner, .p0-btn.-btn2.-left a {
        -webkit-justify-content: flex-end;
        justify-content: flex-end; }
        .p0-btn.-btn2.-left .p0-btn_inner:before, .p0-btn.-btn2.-left a:before {
          position: absolute;
          top: 0;
          left: 0;
          transform: translateX(-110%);
          content: "";
          width: 100vw;
          height: 100%;
          background-color: #8b664e; }
    .p0-btn.-btn2.-right {
      margin-left: auto; }
      .p0-btn.-btn2.-right:before {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(110%);
        content: "";
        width: 100vw;
        height: 100%;
        background-color: white; }
      .p0-btn.-btn2.-right .p0-btn_wrap {
        left: 0; }
      .p0-btn.-btn2.-right .p0-btn_inner, .p0-btn.-btn2.-right a {
        -webkit-justify-content: flex-start;
        justify-content: flex-start; }
        .p0-btn.-btn2.-right .p0-btn_inner:before, .p0-btn.-btn2.-right a:before {
          position: absolute;
          top: 0;
          right: 0;
          transform: translateX(110%);
          content: "";
          width: 100vw;
          height: 100%;
          background-color: #8b664e; }
  .p0-btn.-btn3 {
    position: relative;
    width: 176px;
    height: 176px;
    border-radius: 100vh;
    background-color: #1888a1; }
    @media all and (min-width: 800px) {
      .p0-btn.-btn3 {
        width: 176px;
        height: 176px; } }
    .p0-btn.-btn3.-ajusthalf {
      margin-bottom: calc( -176px / 2); }
      @media all and (min-width: 800px) {
        .p0-btn.-btn3.-ajusthalf {
          margin-bottom: calc( -176px / 2); } }
    .p0-btn.-btn3 .p0-btn_inner {
      position: relative;
      width: 100%;
      height: 100%;
      color: white;
      background-color: #1888a1; }
      .p0-btn.-btn3 .p0-btn_inner:before {
        position: absolute;
        top: -37px;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, 0);
        content: "";
        width: 2px;
        height: 35px;
        background-color: #1888a1;
        animation: 2s linear 0s infinite both running moveLineUpDonw10; }
    .p0-btn.-btn3 .p0-btn_arw {
      position: absolute;
      top: -2px;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, 0);
      width: 22px;
      height: auto;
      animation: 2s linear 0s infinite both running moveArwUpDonw10; }
    .p0-btn.-btn3 .p0-btn_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 37px;
      height: auto; }
    .p0-btn.-btn3 .p0-btn_txt {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Linux Libertine Display Capitals';
      font-weight: normal;
      font-style: normal;
      font-size: 2rem;
      line-height: 1;
      letter-spacing: 0.01em; }
      @media all and (min-width: 800px) {
        .p0-btn.-btn3 .p0-btn_txt {
          font-size: 2rem; } }
  .p0-btn.-btn4 {
    width: 280px;
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 1.75rem;
    line-height: 1;
    letter-spacing: 0.05em;
    background-color: white;
    cursor: pointer; }
    @media all and (min-width: 800px) {
      .p0-btn.-btn4 {
        font-size: 2.5rem; } }
    .p0-btn.-btn4 .p0-btn_inner {
      padding: 8px 0 9px;
      color: white;
      background-color: #8b664e; }
    .p0-btn.-btn4 .p0-btn_txt {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      z-index: 1;
      padding-left: 8px; }
      .p0-btn.-btn4 .p0-btn_txt:after {
        position: relative;
        transform: translateY(1px);
        content: "";
        width: 16px;
        height: 10px;
        margin-left: 12px;
        background-image: url("../images/common/icon/down_dottri_wh.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain; }
        @media all and (min-width: 800px) {
          .p0-btn.-btn4 .p0-btn_txt:after {
            width: 18px;
            height: 11px;
            margin-left: 18px; } }
  .p0-btn.-btn5 .p0-btn_icon {
    margin-left: -8px;
    font-size: 2.4rem; }
    @media all and (min-width: 800px) {
      .p0-btn.-btn5 .p0-btn_icon {
        font-size: 3rem; } }
    @media all and (min-width: 800px) {
      .p0-btn.-btn5 .p0-btn_icon {
        margin-left: -10px; } }
  .p0-btn.-btn5 .p0-btn_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .p0-btn.-btn5 .p0-btn_txt {
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 1.4rem;
    line-height: 1;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p0-btn.-btn5 .p0-btn_txt {
        font-size: 1.75rem; } }
  .p0-btn.-btn6 {
    padding: 6px 16px 10px;
    border-bottom: 1px solid #151515;
    box-sizing: border-box;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1.84rem;
    line-height: 1.6;
    text-align: center; }
    @media all and (min-width: 800px) {
      .p0-btn.-btn6 {
        font-size: 2.3rem; } }
    @media all and (min-width: 800px) {
      .p0-btn.-btn6 {
        line-height: 1.85; } }
    @media all and (min-width: 800px) {
      .p0-btn.-btn6 {
        padding: 8px 20px;
        text-align: left; } }
  .p0-btn.-btn7 {
    position: relative;
    height: 50px;
    background-image: url("../images/contact/btn_circle.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain; }
    .p0-btn.-btn7:before, .p0-btn.-btn7:after {
      display: block;
      position: absolute;
      content: "";
      pointer-events: none;
      transition: transform 0.1s linear; }
    .p0-btn.-btn7:before {
      top: calc(50% - 1px);
      right: 18px;
      z-index: -1;
      width: 66px;
      height: 2px;
      background-color: #1888a1; }
    .p0-btn.-btn7:after {
      top: 0;
      right: 18px;
      width: 32px;
      height: 100%;
      background-image: url("../images/contact/btn_arw_wh.png");
      background-position: right center;
      background-repeat: no-repeat;
      background-size: auto 16px; }
    .p0-btn.-btn7:hover:before {
      transform: translateX(5px); }
    .p0-btn.-btn7:hover:after {
      transform: translateX(5px); }
    .p0-btn.-btn7 input {
      position: relative;
      height: 100%;
      padding-right: 110px;
      box-sizing: border-box;
      font-family: 'Bebas Regular';
      font-weight: normal;
      font-style: normal;
      font-size: 2rem;
      line-height: 1;
      letter-spacing: 0.05em;
      vertical-align: middle;
      color: #1888a1; }
      @media all and (min-width: 800px) {
        .p0-btn.-btn7 input {
          font-size: 2.5rem; } }
  .p0-btn.-btn8 {
    padding: 0 20px;
    border-bottom: 2px solid #1888a1;
    box-sizing: border-box;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 1.28rem;
    line-height: 1.6;
    letter-spacing: 0.05em;
    text-align: center;
    color: #1888a1; }
    @media all and (min-width: 800px) {
      .p0-btn.-btn8 {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p0-btn.-btn8 {
        line-height: 1.85; } }
    .p0-btn.-btn8 input, .p0-btn.-btn8 a {
      color: #1888a1; }

.p0-flexbtns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
  @media all and (min-width: 800px) {
    .p0-flexbtns {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; } }
  .p0-flexbtns.-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
  .p0-flexbtns.-center {
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    @media all and (min-width: 800px) {
      .p0-flexbtns.-center {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch; } }
  .p0-flexbtns.-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }
  .p0-flexbtns_item {
    margin-bottom: 20px; }
    @media all and (min-width: 800px) {
      .p0-flexbtns_item {
        margin-bottom: 0;
        margin-right: 2vw; } }
    @media all and (min-width: 1320px) {
      .p0-flexbtns_item {
        margin-right: 20px; } }
    .p0-flexbtns_item:last-of-type {
      margin-bottom: 0; }
      @media all and (min-width: 800px) {
        .p0-flexbtns_item:last-of-type {
          margin-right: 0; } }
      @media all and (min-width: 1320px) {
        .p0-flexbtns_item:last-of-type {
          margin-right: 0; } }

.p0-hero_bg {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%; }
  .p0-hero_bg video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%; }

.p0-hero.-front {
  position: relative;
  width: 100%;
  height: calc(100vh - (60px / 2));
  padding: 0;
  box-sizing: border-box;
  background-image: url("../images/front/kv/kv_pc.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media all and (min-width: 480px) {
    .p0-hero.-front {
      height: calc(100vh - (60px / 2)); } }
  @media all and (min-width: 600px) {
    .p0-hero.-front {
      height: calc(100vh - (70px / 2)); } }
  @media all and (min-width: 800px) {
    .p0-hero.-front {
      height: calc(100vh - (100px / 2)); } }
  @media all and (min-width: 1000px) {
    .p0-hero.-front {
      height: calc(100vh - (100px / 2)); } }
  .p0-hero.-front .p0-hero_bg video {
    height: 100%; }
    @media all and (min-width: 800px) {
      .p0-hero.-front .p0-hero_bg video {
        height: auto; } }
  .p0-hero.-front .p0-hero_content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    height: 100%; }
  .p0-hero.-front .p0-hero_inner {
    position: absolute;
    top: 56.4%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #e9e9e9;
    mix-blend-mode: difference; }
    @media all and (min-width: 720px) {
      .p0-hero.-front .p0-hero_inner {
        left: 0;
        transform: translate(0, -50%);
        width: auto; } }
    @media all and (min-width: 1120px) {
      .p0-hero.-front .p0-hero_inner {
        left: 110px; } }
  .p0-hero.-front .p0-hero_logo {
    position: relative;
    width: 170px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    opacity: 0; }
    @media all and (min-width: 720px) {
      .p0-hero.-front .p0-hero_logo {
        margin-left: 0; } }
    @media all and (min-width: 800px) {
      .p0-hero.-front .p0-hero_logo {
        margin-bottom: 25px; } }
    @media all and (min-width: 1120px) {
      .p0-hero.-front .p0-hero_logo {
        width: 200px; } }
    .p0-hero.-front .p0-hero_logo img {
      width: 100%;
      height: auto; }
  .p0-hero.-front .p0-hero_catchcopy {
    position: relative;
    opacity: 0; }

.p0-hero.-lowerpage {
  position: relative;
  width: 100%;
  padding: 0 0 30px 0;
  box-sizing: border-box; }
  @media all and (min-width: 480px) {
    .p0-hero.-lowerpage {
      padding-bottom: 0; } }
  .p0-hero.-lowerpage.-bg_bgtype04 {
    padding-bottom: 0; }
    .p0-hero.-lowerpage.-bg_bgtype04 .l-section_bg.-bgtype04 {
      padding-bottom: 30px; }
      @media all and (min-width: 480px) {
        .p0-hero.-lowerpage.-bg_bgtype04 .l-section_bg.-bgtype04 {
          padding-bottom: 0; } }
  .p0-hero.-lowerpage:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 410px;
    content: "";
    background-color: #2b2b2b; }
    @media all and (min-width: 480px) {
      .p0-hero.-lowerpage:before {
        height: 48.3vw;
        min-height: 290px;
        max-height: 480px; } }
    @media all and (min-width: 720px) {
      .p0-hero.-lowerpage:before {
        height: 47.2vw; } }
    @media all and (min-width: 800px) {
      .p0-hero.-lowerpage:before {
        height: 46.25vw; } }
    @media all and (min-width: 1000px) {
      .p0-hero.-lowerpage:before {
        height: 45vw; } }
    @media all and (min-width: 1120px) {
      .p0-hero.-lowerpage:before {
        height: 480px; } }
  .p0-hero.-lowerpage .p0-hero_inner {
    position: relative;
    width: 100%;
    height: 100%; }
  .p0-hero.-lowerpage .p0-hero_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    height: 100%;
    padding: 100px 0 0;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-hero.-lowerpage .p0-hero_content {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        padding-top: 110px; } }
    @media all and (min-width: 720px) {
      .p0-hero.-lowerpage .p0-hero_content {
        padding-top: 130px; } }
    @media all and (min-width: 1000px) {
      .p0-hero.-lowerpage .p0-hero_content {
        padding-top: 150px; } }
  .p0-hero.-lowerpage .p0-hero_headline {
    z-index: 1; }
  .p0-hero.-lowerpage .p0-hero_kvimg {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 280px;
    margin: 30px auto 0; }
    @media all and (min-width: 480px) {
      .p0-hero.-lowerpage .p0-hero_kvimg {
        width: 50vw;
        max-width: 500px;
        margin-top: 0;
        margin-right: -5vw; } }
    @media all and (min-width: 600px) {
      .p0-hero.-lowerpage .p0-hero_kvimg {
        width: 45vw;
        margin-right: 0; } }
    @media all and (min-width: 1000px) {
      .p0-hero.-lowerpage .p0-hero_kvimg {
        width: 45vw; } }
    @media all and (min-width: 1120px) {
      .p0-hero.-lowerpage .p0-hero_kvimg {
        width: 500px; } }
    .p0-hero.-lowerpage .p0-hero_kvimg img {
      width: 100%;
      height: auto; }

.p0-hero.-workssingle, .p0-hero.-topicssingle, .p0-hero.-blogsingle {
  padding: 130px 0 15px;
  box-sizing: border-box;
  background-color: white; }
  @media all and (min-width: 800px) {
    .p0-hero.-workssingle, .p0-hero.-topicssingle, .p0-hero.-blogsingle {
      padding: 130px 0 45px; } }

.p0-splitbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
  width: 100%; }
  .p0-splitbox.-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .p0-splitbox.-reversepc {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    @media all and (min-width: 800px) {
      .p0-splitbox.-reversepc {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; } }
  .p0-splitbox.-reversew720 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    @media all and (min-width: 720px) {
      .p0-splitbox.-reversew720 {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; } }
  .p0-splitbox.-reversesp {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
    @media all and (min-width: 800px) {
      .p0-splitbox.-reversesp {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; } }
  .p0-splitbox.-reversecolsp {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }
    @media all and (min-width: 800px) {
      .p0-splitbox.-reversecolsp {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; } }
  .p0-splitbox.-half .p0-splitbox_item {
    width: 100%; }
    @media all and (min-width: 800px) {
      .p0-splitbox.-half .p0-splitbox_item {
        width: 50%; } }
  @media all and (min-width: 800px) {
    .p0-splitbox.-half.-gapx05 {
      gap: 0 5%; } }
  @media all and (min-width: 1620px) {
    .p0-splitbox.-half.-gapx05 {
      gap: 0 60px; } }
  .p0-splitbox.-half.-gapx05 .p0-splitbox_item {
    width: 100%; }
    @media all and (min-width: 800px) {
      .p0-splitbox.-half.-gapx05 .p0-splitbox_item {
        width: calc(50% - 2.5%); } }
    @media all and (min-width: 1620px) {
      .p0-splitbox.-half.-gapx05 .p0-splitbox_item {
        width: calc(50% - 30px); } }
  .p0-splitbox.-halfonly .p0-splitbox_item {
    width: 50%; }
  .p0-splitbox.-halfonly.-gapx05 {
    gap: 0 5%; }
    @media all and (min-width: 1620px) {
      .p0-splitbox.-halfonly.-gapx05 {
        gap: 0 60px; } }
    .p0-splitbox.-halfonly.-gapx05 .p0-splitbox_item {
      width: calc(50% - 2.5%); }
      @media all and (min-width: 1620px) {
        .p0-splitbox.-halfonly.-gapx05 .p0-splitbox_item {
          width: calc(50% - 30px); } }
  .p0-splitbox.-card {
    gap: 5vw 5%; }
    @media all and (min-width: 800px) {
      .p0-splitbox.-card {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap; } }
    @media all and (min-width: 1620px) {
      .p0-splitbox.-card {
        gap: 80px 80px; } }
    .p0-splitbox.-card .p0-splitbox_item.-col4 {
      width: calc( (100% / 3) - 3.33333%); }
      @media all and (min-width: 1620px) {
        .p0-splitbox.-card .p0-splitbox_item.-col4 {
          width: calc( (100% / 3) - 53.33333px); } }
      .p0-splitbox.-card .p0-splitbox_item.-col4.-res {
        width: 100%; }
        @media all and (min-width: 480px) {
          .p0-splitbox.-card .p0-splitbox_item.-col4.-res {
            width: calc( 50% - 2.5%); } }
        @media all and (min-width: 800px) {
          .p0-splitbox.-card .p0-splitbox_item.-col4.-res {
            width: calc( (100% / 3) - 3.33333%); } }
        @media all and (min-width: 1620px) {
          .p0-splitbox.-card .p0-splitbox_item.-col4.-res {
            width: calc( (100% / 3) - 53.33333px); } }
    .p0-splitbox.-card .p0-splitbox_item.-col6 {
      width: calc( (100% / 2) - 2.5%); }
      @media all and (min-width: 1620px) {
        .p0-splitbox.-card .p0-splitbox_item.-col6 {
          width: calc( (100% / 2) - 40px); } }
  .p0-splitbox.-slider {
    gap: 0 5%; }
    @media all and (min-width: 1620px) {
      .p0-splitbox.-slider {
        gap: 0 80px; } }
  .p0-splitbox.-workstagcat {
    gap: 2.5vw 0; }
    @media all and (min-width: 1120px) {
      .p0-splitbox.-workstagcat {
        gap: 40px 0; } }
    .p0-splitbox.-workstagcat .p0-splitbox_item {
      padding: 10px 20px 15px;
      border-left: 1px solid white;
      box-sizing: border-box; }
      @media all and (min-width: 800px) {
        .p0-splitbox.-workstagcat .p0-splitbox_item {
          padding: 15px 30px 20px; } }
      @media all and (min-width: 1120px) {
        .p0-splitbox.-workstagcat .p0-splitbox_item {
          padding: 15px 40px 20px; } }
  .p0-splitbox.-circle .p0-splitbox_item {
    width: 100%;
    padding: 0 5px 7.5px;
    box-sizing: border-box; }
    @media all and (min-width: 375px) {
      .p0-splitbox.-circle .p0-splitbox_item {
        width: 50%; } }
    @media all and (min-width: 480px) {
      .p0-splitbox.-circle .p0-splitbox_item {
        padding: 0 1.3vw 1.7vw; } }
    @media all and (min-width: 600px) {
      .p0-splitbox.-circle .p0-splitbox_item {
        width: calc(100% / 3); } }
    @media all and (min-width: 1000px) {
      .p0-splitbox.-circle .p0-splitbox_item {
        width: 25%; } }
    @media all and (min-width: 1120px) {
      .p0-splitbox.-circle .p0-splitbox_item {
        padding: 0 15px 20px; } }
  .p0-splitbox.-aboutspirits {
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
    margin-bottom: 30px; }
    .p0-splitbox.-aboutspirits:after {
      display: block;
      position: relative;
      width: 100%;
      height: 15px;
      content: "";
      margin-top: 30px;
      background-image: url("../images/about/dotline.png");
      background-position: left center;
      background-repeat: repeat-x;
      background-size: auto 100%; }
      @media all and (min-width: 720px) {
        .p0-splitbox.-aboutspirits:after {
          display: none;
          margin-top: 0; } }
    @media all and (min-width: 720px) {
      .p0-splitbox.-aboutspirits {
        margin-bottom: 60px; } }
    @media all and (min-width: 800px) {
      .p0-splitbox.-aboutspirits {
        margin-bottom: 80px; } }
    @media all and (min-width: 1000px) {
      .p0-splitbox.-aboutspirits {
        margin-bottom: 100px; } }
    @media all and (min-width: 1200px) {
      .p0-splitbox.-aboutspirits {
        margin-bottom: 200px; } }
    @media all and (min-width: 720px) {
      .p0-splitbox.-aboutspirits.-last {
        margin-bottom: 30px; } }
    @media all and (min-width: 800px) {
      .p0-splitbox.-aboutspirits.-last {
        margin-bottom: 40px; } }
    @media all and (min-width: 1000px) {
      .p0-splitbox.-aboutspirits.-last {
        margin-bottom: 50px; } }
    @media all and (min-width: 1200px) {
      .p0-splitbox.-aboutspirits.-last {
        margin-bottom: 100px; } }
    .p0-splitbox.-aboutspirits.-last:after {
      display: none;
      margin-top: 0; }
    .p0-splitbox.-aboutspirits .p0-splitbox_item {
      position: relative; }
      .p0-splitbox.-aboutspirits .p0-splitbox_item.-img {
        width: 100%; }
        @media all and (min-width: 720px) {
          .p0-splitbox.-aboutspirits .p0-splitbox_item.-img {
            width: 47.5%; } }
        @media all and (min-width: 1200px) {
          .p0-splitbox.-aboutspirits .p0-splitbox_item.-img {
            width: 39.1%; } }
      .p0-splitbox.-aboutspirits .p0-splitbox_item.-txt {
        width: 100%;
        margin-bottom: 20px; }
        @media all and (min-width: 480px) {
          .p0-splitbox.-aboutspirits .p0-splitbox_item.-txt {
            margin-bottom: 30px; } }
        @media all and (min-width: 720px) {
          .p0-splitbox.-aboutspirits .p0-splitbox_item.-txt {
            width: 47.5%;
            margin-bottom: 0; } }
        @media all and (min-width: 1200px) {
          .p0-splitbox.-aboutspirits .p0-splitbox_item.-txt {
            width: 52.5%; } }
  .p0-splitbox.-aboutservice .p0-splitbox_item {
    box-sizing: border-box; }
    .p0-splitbox.-aboutservice .p0-splitbox_item.-txt {
      width: 100%;
      margin-bottom: 35px; }
      @media all and (min-width: 720px) {
        .p0-splitbox.-aboutservice .p0-splitbox_item.-txt {
          width: 61.66667%;
          margin-bottom: 0;
          padding-bottom: 100px; } }
      @media all and (min-width: 800px) {
        .p0-splitbox.-aboutservice .p0-splitbox_item.-txt {
          padding-bottom: 115px; } }
    .p0-splitbox.-aboutservice .p0-splitbox_item.-img {
      width: 100%;
      max-width: 240px;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto; }
      @media all and (min-width: 600px) {
        .p0-splitbox.-aboutservice .p0-splitbox_item.-img {
          margin-bottom: 35px; } }
      @media all and (min-width: 720px) {
        .p0-splitbox.-aboutservice .p0-splitbox_item.-img {
          width: 38.33333%;
          max-width: none;
          margin-bottom: 0;
          margin-left: 0; } }
  .p0-splitbox.-aboutmissionbtm {
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p0-splitbox.-aboutmissionbtm {
        padding-bottom: 20px; } }
    @media all and (min-width: 1200px) {
      .p0-splitbox.-aboutmissionbtm {
        margin-left: auto;
        margin-right: 0; } }
    @media all and (min-width: 1320px) {
      .p0-splitbox.-aboutmissionbtm {
        margin-left: -4vw;
        margin-right: auto; } }
    @media all and (min-width: 1500px) {
      .p0-splitbox.-aboutmissionbtm {
        margin-left: -7.5%; } }
    .p0-splitbox.-aboutmissionbtm .p0-splitbox_item {
      position: relative;
      box-sizing: border-box; }
      .p0-splitbox.-aboutmissionbtm .p0-splitbox_item.-img1 {
        width: 61.62791%; }
        @media all and (min-width: 720px) {
          .p0-splitbox.-aboutmissionbtm .p0-splitbox_item.-img1 {
            width: 67.69231%; } }
        @media all and (min-width: 1120px) {
          .p0-splitbox.-aboutmissionbtm .p0-splitbox_item.-img1 {
            width: 64.28571%; } }
      .p0-splitbox.-aboutmissionbtm .p0-splitbox_item.-img2 {
        width: 33.72093%;
        padding-top: 35px; }
        @media all and (min-width: 600px) {
          .p0-splitbox.-aboutmissionbtm .p0-splitbox_item.-img2 {
            padding-top: 40px; } }
        @media all and (min-width: 720px) {
          .p0-splitbox.-aboutmissionbtm .p0-splitbox_item.-img2 {
            width: 29.23077%;
            padding-top: 90px; } }
        @media all and (min-width: 800px) {
          .p0-splitbox.-aboutmissionbtm .p0-splitbox_item.-img2 {
            padding-top: 100px; } }
        @media all and (min-width: 1000px) {
          .p0-splitbox.-aboutmissionbtm .p0-splitbox_item.-img2 {
            padding-top: 170px; } }
        @media all and (min-width: 1120px) {
          .p0-splitbox.-aboutmissionbtm .p0-splitbox_item.-img2 {
            width: 27.67857%;
            padding-top: 180px; } }
        @media all and (min-width: 1200px) {
          .p0-splitbox.-aboutmissionbtm .p0-splitbox_item.-img2 {
            padding-top: 200px; } }
  .p0-splitbox.-aboutspiritsbtm {
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    z-index: 1;
    width: 100%;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 75px;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p0-splitbox.-aboutspiritsbtm {
        margin-bottom: 150px; } }
    @media all and (min-width: 1200px) {
      .p0-splitbox.-aboutspiritsbtm {
        margin-left: auto;
        margin-right: 0; } }
    @media all and (min-width: 1320px) {
      .p0-splitbox.-aboutspiritsbtm {
        margin-left: 10.66vw;
        margin-right: auto; } }
    @media all and (min-width: 1500px) {
      .p0-splitbox.-aboutspiritsbtm {
        margin-left: 13.3%; } }
    .p0-splitbox.-aboutspiritsbtm .p0-splitbox_item {
      position: relative;
      box-sizing: border-box; }
      .p0-splitbox.-aboutspiritsbtm .p0-splitbox_item.-img1 {
        width: 33.72093%;
        padding-top: 35px; }
        @media all and (min-width: 600px) {
          .p0-splitbox.-aboutspiritsbtm .p0-splitbox_item.-img1 {
            padding-top: 40px; } }
        @media all and (min-width: 720px) {
          .p0-splitbox.-aboutspiritsbtm .p0-splitbox_item.-img1 {
            width: 29.23077%;
            padding-top: 90px; } }
        @media all and (min-width: 800px) {
          .p0-splitbox.-aboutspiritsbtm .p0-splitbox_item.-img1 {
            padding-top: 100px; } }
        @media all and (min-width: 1000px) {
          .p0-splitbox.-aboutspiritsbtm .p0-splitbox_item.-img1 {
            padding-top: 170px; } }
        @media all and (min-width: 1120px) {
          .p0-splitbox.-aboutspiritsbtm .p0-splitbox_item.-img1 {
            width: 27.67857%;
            padding-top: 180px; } }
        @media all and (min-width: 1200px) {
          .p0-splitbox.-aboutspiritsbtm .p0-splitbox_item.-img1 {
            padding-top: 200px; } }
      .p0-splitbox.-aboutspiritsbtm .p0-splitbox_item.-img2 {
        width: 61.62791%; }
        @media all and (min-width: 720px) {
          .p0-splitbox.-aboutspiritsbtm .p0-splitbox_item.-img2 {
            width: 67.69231%; } }
        @media all and (min-width: 1120px) {
          .p0-splitbox.-aboutspiritsbtm .p0-splitbox_item.-img2 {
            width: 64.28571%; } }
  .p0-splitbox_item {
    position: relative;
    width: 100%;
    box-sizing: border-box; }
    .p0-splitbox_item.-col1 {
      width: calc(100% / 12); }
    .p0-splitbox_item.-col2 {
      width: calc(100% / 6); }
    .p0-splitbox_item.-col3 {
      width: 25%; }
    .p0-splitbox_item.-col4 {
      width: calc(100% / 3); }
      .p0-splitbox_item.-col4.-res {
        width: 100%; }
        @media all and (min-width: 480px) {
          .p0-splitbox_item.-col4.-res {
            width: 50%; } }
        @media all and (min-width: 800px) {
          .p0-splitbox_item.-col4.-res {
            width: calc(100% / 3); } }
      .p0-splitbox_item.-col4.-wtcat {
        width: 100%; }
        @media all and (min-width: 600px) {
          .p0-splitbox_item.-col4.-wtcat {
            width: 50%; } }
        @media all and (min-width: 1120px) {
          .p0-splitbox_item.-col4.-wtcat {
            width: calc(100% / 3); } }
    .p0-splitbox_item.-col6 {
      width: 50%; }
    .p0-splitbox_item.-col9 {
      width: 75%; }
    .p0-splitbox_item.-col12 {
      width: 100%; }
    .p0-splitbox_item.-topicshead {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: center;
      align-content: center;
      width: 100%;
      margin-bottom: 25px; }
      @media all and (min-width: 1000px) {
        .p0-splitbox_item.-topicshead {
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
          width: 200px;
          margin-bottom: 0; } }
      @media all and (min-width: 1120px) {
        .p0-splitbox_item.-topicshead {
          width: 250px; } }
    .p0-splitbox_item.-topicsbody {
      width: 100%; }
      @media all and (min-width: 1000px) {
        .p0-splitbox_item.-topicsbody {
          width: calc(100% - 200px); } }
      @media all and (min-width: 1120px) {
        .p0-splitbox_item.-topicsbody {
          width: calc(100% - 250px); } }
    .p0-splitbox_item.-workshead {
      width: 100%;
      box-sizing: border-box; }
      @media all and (min-width: 800px) {
        .p0-splitbox_item.-workshead {
          width: 260px; } }
      @media all and (min-width: 1120px) {
        .p0-splitbox_item.-workshead {
          width: 310px; } }
      .p0-splitbox_item.-workshead.-lead {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        margin-bottom: 25px;
        padding: 15px 30px 15px 0; }
        @media all and (min-width: 800px) {
          .p0-splitbox_item.-workshead.-lead {
            margin-bottom: 0;
            padding: 30px 60px 30px 0; } }
        @media all and (min-width: 1320px) {
          .p0-splitbox_item.-workshead.-lead {
            padding-right: 70px; } }
      .p0-splitbox_item.-workshead.-comment {
        margin-bottom: 30px; }
        @media all and (min-width: 800px) {
          .p0-splitbox_item.-workshead.-comment {
            margin-bottom: 0; } }
    .p0-splitbox_item.-worksbody {
      width: 100%;
      box-sizing: border-box; }
      @media all and (min-width: 800px) {
        .p0-splitbox_item.-worksbody {
          width: calc(100% - 260px); } }
      @media all and (min-width: 1120px) {
        .p0-splitbox_item.-worksbody {
          width: calc(100% - 310px); } }
      .p0-splitbox_item.-worksbody.-comment {
        padding-left: 0; }
        @media all and (min-width: 800px) {
          .p0-splitbox_item.-worksbody.-comment {
            padding-left: 60px; } }
    .p0-splitbox_item.-absolute {
      position: absolute; }
      .p0-splitbox_item.-absolute.-comment {
        bottom: 48px;
        left: 0; }
        @media all and (min-width: 800px) {
          .p0-splitbox_item.-absolute.-comment {
            bottom: 30px; } }
    .p0-splitbox_item.-servicenarrow {
      width: 100%; }
      @media all and (min-width: 800px) {
        .p0-splitbox_item.-servicenarrow {
          width: 38.33333%; } }
    .p0-splitbox_item.-servicewide {
      width: 100%; }
      @media all and (min-width: 800px) {
        .p0-splitbox_item.-servicewide {
          width: 61.66667%; } }

.p0-topics_list {
  display: block; }
  .p0-topics_list_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 10px 10px;
    border-bottom: 1px solid #c3c3c3;
    box-sizing: border-box; }
    @media all and (min-width: 1000px) {
      .p0-topics_list_item {
        padding: 17px 10px; } }
    .p0-topics_list_item:last-of-type {
      border-style: none; }
  .p0-topics_list_dt {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 75px;
    padding-right: 20px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-topics_list_dt {
        width: 85px; } }
    @media all and (min-width: 600px) {
      .p0-topics_list_dt {
        width: 105px; } }
    @media all and (min-width: 800px) {
      .p0-topics_list_dt {
        width: 110px;
        padding: 0 20px 0 0; } }
  .p0-topics_list_ttl {
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

.p0-decoration {
  display: block; }
  .p0-decoration img {
    display: block;
    width: 100%;
    height: auto; }
  .p0-decoration.-circlelogo01 {
    position: relative;
    width: 27vw; }
    @media all and (min-width: 480px) {
      .p0-decoration.-circlelogo01 {
        width: 132px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-circlelogo01 {
        width: 30vw; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-circlelogo01 {
        width: 33.75vw; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-circlelogo01 {
        width: 303px; } }
    .p0-decoration.-circlelogo01:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-image: url("../images/front/about/circle_text.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      animation: rotateLogo 20s infinite linear; }
    .p0-decoration.-circlelogo01.-rotate img {
      animation: rotateLogo 10s infinite linear; }
    .p0-decoration.-circlelogo01.-service {
      margin-top: -15px;
      margin-right: 3.57143%;
      margin-bottom: -7.14286vw;
      margin-left: auto; }
      @media all and (min-width: 800px) {
        .p0-decoration.-circlelogo01.-service {
          margin-top: -40px;
          margin-bottom: -80px; } }
      @media all and (min-width: 1200px) {
        .p0-decoration.-circlelogo01.-service {
          margin-right: 40px;
          margin-bottom: -80px; } }
    .p0-decoration.-circlelogo01.-about {
      margin-left: auto;
      margin-right: 30px;
      transform: translateY(-41%); }
      @media all and (min-width: 1120px) {
        .p0-decoration.-circlelogo01.-about {
          margin-right: 30px; } }
  .p0-decoration.-firework01 {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    @media all and (min-width: 600px) {
      .p0-decoration.-firework01 {
        width: 88%; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-firework01 {
        width: 86%;
        margin-left: 0; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-firework01 {
        width: 73%;
        margin-left: auto; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-firework01 {
        left: 40px;
        width: 314px;
        margin-left: 0; } }
  .p0-decoration.-asline1, .p0-decoration.-asline2 {
    display: none;
    position: absolute;
    z-index: -1;
    width: 600px;
    height: 15px;
    background-image: url("../images/about/dotline.png");
    background-position: center center;
    background-repeat: repeat-x;
    background-size: auto 100%; }
    @media all and (min-width: 720px) {
      .p0-decoration.-asline1, .p0-decoration.-asline2 {
        display: block; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-asline1, .p0-decoration.-asline2 {
        width: 700px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-asline1, .p0-decoration.-asline2 {
        width: 800px; } }
    @media all and (min-width: 1200px) {
      .p0-decoration.-asline1, .p0-decoration.-asline2 {
        width: 900px; } }
    @media all and (min-width: 1320px) {
      .p0-decoration.-asline1, .p0-decoration.-asline2 {
        width: 1000px; } }
  .p0-decoration.-asline1 {
    top: 50%;
    right: 50%;
    transform: translate(7.5px, -7.5px) rotate(-51deg);
    transform-origin: top right; }
    @media all and (min-width: 800px) {
      .p0-decoration.-asline1 {
        transform: rotate(-51deg); } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-asline1 {
        transform: rotate(-48deg); } }
    @media all and (min-width: 1200px) {
      .p0-decoration.-asline1 {
        transform: rotate(-45deg); } }
    @media all and (min-width: 1230px) {
      .p0-decoration.-asline1 {
        transform: rotate(-44.5deg); } }
    @media all and (min-width: 1260px) {
      .p0-decoration.-asline1 {
        transform: rotate(-44deg); } }
    @media all and (min-width: 1290px) {
      .p0-decoration.-asline1 {
        transform: rotate(-43.5deg); } }
    @media all and (min-width: 1320px) {
      .p0-decoration.-asline1 {
        transform: rotate(-43deg); } }
  .p0-decoration.-asline2 {
    top: 50%;
    left: 50%;
    transform: translate(-7.5px, -7.5px) rotate(51deg);
    transform-origin: top left; }
    @media all and (min-width: 800px) {
      .p0-decoration.-asline2 {
        transform: rotate(51deg); } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-asline2 {
        transform: rotate(48deg); } }
    @media all and (min-width: 1200px) {
      .p0-decoration.-asline2 {
        transform: rotate(45deg); } }
    @media all and (min-width: 1230px) {
      .p0-decoration.-asline2 {
        transform: rotate(45deg); } }
    @media all and (min-width: 1230px) {
      .p0-decoration.-asline2 {
        transform: rotate(44.5deg); } }
    @media all and (min-width: 1260px) {
      .p0-decoration.-asline2 {
        transform: rotate(44deg); } }
    @media all and (min-width: 1290px) {
      .p0-decoration.-asline2 {
        transform: rotate(43.5deg); } }
    @media all and (min-width: 1320px) {
      .p0-decoration.-asline2 {
        transform: rotate(43deg); } }
  .p0-decoration.-animal01 {
    position: absolute;
    bottom: -165px;
    right: -30px;
    width: 101px; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal01 {
        display: block;
        width: 101px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal01 {
        bottom: -150px;
        right: 40px;
        width: 125px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal01 {
        bottom: -105px;
        right: auto;
        left: -60px;
        width: 140px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal01 {
        bottom: -70px;
        left: -85px;
        width: 157px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal01 {
        width: 214px; } }
    @media all and (min-width: 1620px) {
      .p0-decoration.-animal01 {
        bottom: -70px;
        left: -85px; } }
  .p0-decoration.-animal02 {
    position: absolute;
    top: 0;
    right: 20px;
    transform: translateY(-100%);
    width: 105px; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal02 {
        top: -20px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal02 {
        right: 40px;
        width: 105px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal02 {
        width: 119px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal02 {
        top: -30px;
        left: 30px;
        transform: translateY(0%);
        width: 135px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal02 {
        top: -50px;
        left: 55px;
        width: 184px; } }
    .p0-decoration.-animal02.-sp {
      display: block; }
      @media all and (min-width: 800px) {
        .p0-decoration.-animal02.-sp {
          display: none; } }
    .p0-decoration.-animal02.-pc {
      display: none; }
      @media all and (min-width: 800px) {
        .p0-decoration.-animal02.-pc {
          display: block; } }
  .p0-decoration.-animal03 {
    position: absolute;
    top: -70px;
    left: 20px;
    transform: translateY(-100%);
    width: 102px; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal03 {
        width: 102px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal03 {
        left: 25px;
        width: 110px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal03 {
        width: 137px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal03 {
        left: 40px;
        width: 155px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal03 {
        left: 50px;
        width: 232px; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal03 {
        left: 110px; } }
    @media all and (min-width: 1620px) {
      .p0-decoration.-animal03 {
        left: 280px; } }
  .p0-decoration.-animal04 {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 83px; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal04 {
        display: block;
        right: 15px;
        width: 83px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal04 {
        right: 30px;
        width: 93px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal04 {
        right: 25px;
        width: 110px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal04 {
        right: 20px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal04 {
        right: 0;
        width: 144px; } }
    @media all and (min-width: 1620px) {
      .p0-decoration.-animal04 {
        right: 28px; } }
  .p0-decoration.-animal05 {
    display: none;
    position: absolute;
    top: 50%;
    right: 76.74419%;
    transform: translateY(-100%);
    width: 81px; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal05 {
        display: block; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal05 {
        right: 72.22222%; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal05 {
        right: 60%;
        width: 86px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal05 {
        top: 0;
        right: 30px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal05 {
        right: 50px;
        width: 112px; } }
  .p0-decoration.-animal06 {
    display: none;
    position: absolute;
    top: 0;
    left: 72%;
    width: 94px; }
    @media all and (min-width: 375px) {
      .p0-decoration.-animal06 {
        display: block; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal06 {
        width: 116px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal06 {
        width: 160px; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal06 {
        top: -20px; } }
  .p0-decoration.-animal07 {
    display: none;
    position: absolute;
    transform: translateY(-100%); }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal07 {
        display: block;
        top: 50%;
        left: 68.18182%;
        width: 113px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal07 {
        left: 66.66667%;
        width: 135px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal07 {
        left: 58.46154%;
        width: 160px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal07 {
        left: 59.72222%; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal07 {
        top: 0;
        right: auto;
        left: -5px;
        transform: translateY(-100%);
        width: 215px; } }
    .p0-decoration.-animal07.-rightver {
      transform: translate(-100%, -100%); }
      @media all and (min-width: 480px) {
        .p0-decoration.-animal07.-rightver {
          left: 31.81818%; } }
      @media all and (min-width: 600px) {
        .p0-decoration.-animal07.-rightver {
          left: 33.33333%; } }
      @media all and (min-width: 720px) {
        .p0-decoration.-animal07.-rightver {
          left: 41.53846%; } }
      @media all and (min-width: 800px) {
        .p0-decoration.-animal07.-rightver {
          left: 40.27778%; } }
      @media all and (min-width: 1000px) {
        .p0-decoration.-animal07.-rightver {
          right: auto;
          left: -5px;
          transform: translate(0, -100%); } }
  .p0-decoration.-animal_works01 {
    position: absolute;
    bottom: -20px;
    right: 4.65116%;
    transform: translate(0, 100%);
    width: 78.88px;
    box-sizing: border-box; }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_works01 {
        width: 116px; } }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_works01 {
        position: absolute;
        right: auto;
        bottom: -15px;
        left: 23.25581%;
        transform: translate(0, 0);
        padding-top: 0; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_works01 {
        bottom: -10px;
        left: 18.51852%; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_works01 {
        left: 23.4375%; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_works01 {
        bottom: -5px;
        left: 8.33333%; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_works01 {
        left: 16.66667%; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal_works01 {
        left: 20%; } }
    @media all and (min-width: 1200px) {
      .p0-decoration.-animal_works01 {
        bottom: 40px;
        left: 25%;
        transform: translate(0, 100%); } }
  .p0-decoration.-animal_works02 {
    display: none;
    position: absolute;
    bottom: 20px;
    right: 4.65116%;
    width: 89px; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_works02 {
        width: 92.56px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_works02 {
        width: 110.36px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_works02 {
        width: 131.72px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_works02 {
        width: 178px; } }
    @media all and (min-width: 375px) {
      .p0-decoration.-animal_works02 {
        display: block; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_works02 {
        bottom: 20px;
        right: 8.33333%; } }
  .p0-decoration.-animal_works03 {
    position: relative;
    bottom: 0;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);
    width: 116px;
    padding-top: 20px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_works03 {
        width: 120.64px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_works03 {
        width: 143.84px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_works03 {
        width: 171.68px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_works03 {
        width: 232px; } }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_works03 {
        position: absolute;
        right: auto;
        bottom: -5px;
        left: 6.97674%;
        transform: translateX(0);
        padding-top: 0; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_works03 {
        bottom: -10px;
        left: 5.55556%; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_works03 {
        bottom: -5px;
        left: 8.33333%; } }
    @media all and (min-width: 1200px) {
      .p0-decoration.-animal_works03 {
        bottom: -30px;
        left: 12.5%; } }
  .p0-decoration.-animal_works04 {
    display: block;
    position: absolute;
    top: 0;
    left: 10.83333%;
    z-index: 1;
    transform: translateY(-90%);
    width: 31px; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_works04 {
        width: 32.24px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_works04 {
        width: 38.44px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_works04 {
        width: 45.88px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_works04 {
        width: 62px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_works04 {
        top: 0;
        left: 10.83333%; } }
  .p0-decoration.-animal_works05 {
    display: block;
    position: absolute;
    top: 0;
    right: 23.33333%;
    z-index: 1;
    transform: translateY(-50%);
    width: 26px; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_works05 {
        width: 27.04px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_works05 {
        width: 32.24px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_works05 {
        width: 38.48px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_works05 {
        width: 52px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_works05 {
        right: 23.33333%; } }
  .p0-decoration.-animal_works06 {
    display: block;
    position: absolute;
    bottom: -20px;
    left: 24.16667%;
    z-index: 1;
    transform: translateY(100%);
    width: 42.5px; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_works06 {
        width: 44.2px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_works06 {
        width: 52.7px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_works06 {
        width: 62.9px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_works06 {
        width: 85px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_works06 {
        bottom: -40px;
        left: 24.16667%; } }
  .p0-decoration.-animal_about01 {
    display: block;
    position: relative;
    left: 100%;
    transform: translate(-100%, 0);
    width: 90.2px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_about01 {
        width: 90.2px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_about01 {
        width: 114.8px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_about01 {
        width: 135.3px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_about01 {
        width: 147.6px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_about01 {
        width: 184.5px; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal_about01 {
        width: 205px; } }
    @media all and (min-width: 375px) {
      .p0-decoration.-animal_about01 {
        position: absolute;
        left: auto;
        top: 20px;
        left: 220px;
        transform: translate(0, 0);
        margin-bottom: 0; } }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_about01 {
        left: 240px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_about01 {
        top: 10px;
        left: 400px; } }
  .p0-decoration.-animal_about02 {
    display: block;
    position: relative;
    left: 100%;
    transform: translate(-100%, 0);
    width: 69.52px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_about02 {
        width: 69.52px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_about02 {
        width: 88.48px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_about02 {
        width: 104.28px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_about02 {
        width: 113.76px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_about02 {
        width: 142.2px; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal_about02 {
        width: 158px; } }
    @media all and (min-width: 375px) {
      .p0-decoration.-animal_about02 {
        position: absolute;
        left: auto;
        top: 0;
        right: 9.16667%;
        transform: translate(0, 0); } }
  .p0-decoration.-animal_about03 {
    display: block;
    position: relative;
    left: 100%;
    transform: translate(-100%, 0);
    width: 70.4px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_about03 {
        width: 70.4px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_about03 {
        width: 89.6px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_about03 {
        width: 105.6px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_about03 {
        width: 115.2px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_about03 {
        width: 144px; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal_about03 {
        width: 160px; } }
    @media all and (min-width: 375px) {
      .p0-decoration.-animal_about03 {
        position: absolute;
        top: 30px; } }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_about03 {
        left: 340px;
        transform: translate(0, 0); } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_about03 {
        left: 50%; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_about03 {
        top: 75px; } }
  .p0-decoration.-animal_aboutmember {
    display: none;
    position: absolute;
    bottom: 15px;
    right: 0;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_aboutmember {
        display: block; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_aboutmember {
        bottom: 20px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_aboutmember {
        bottom: 25px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_aboutmember {
        bottom: 15px;
        right: 20px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_aboutmember {
        bottom: 30px;
        right: 40px; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal_aboutmember {
        right: 60px; } }
    .p0-decoration.-animal_aboutmember.-m01 {
      width: 94.16px; }
      @media all and (min-width: 480px) {
        .p0-decoration.-animal_aboutmember.-m01 {
          width: 96.3px; } }
      @media all and (min-width: 600px) {
        .p0-decoration.-animal_aboutmember.-m01 {
          width: 107px; } }
      @media all and (min-width: 720px) {
        .p0-decoration.-animal_aboutmember.-m01 {
          width: 128.4px; } }
      @media all and (min-width: 800px) {
        .p0-decoration.-animal_aboutmember.-m01 {
          width: 151.94px; } }
      @media all and (min-width: 1000px) {
        .p0-decoration.-animal_aboutmember.-m01 {
          width: 181.9px; } }
      @media all and (min-width: 1120px) {
        .p0-decoration.-animal_aboutmember.-m01 {
          width: 214px; } }
    .p0-decoration.-animal_aboutmember.-m02 {
      width: 80.08px; }
      @media all and (min-width: 480px) {
        .p0-decoration.-animal_aboutmember.-m02 {
          width: 81.9px; } }
      @media all and (min-width: 600px) {
        .p0-decoration.-animal_aboutmember.-m02 {
          width: 91px; } }
      @media all and (min-width: 720px) {
        .p0-decoration.-animal_aboutmember.-m02 {
          width: 109.2px; } }
      @media all and (min-width: 800px) {
        .p0-decoration.-animal_aboutmember.-m02 {
          width: 129.22px; } }
      @media all and (min-width: 1000px) {
        .p0-decoration.-animal_aboutmember.-m02 {
          width: 154.7px; } }
      @media all and (min-width: 1120px) {
        .p0-decoration.-animal_aboutmember.-m02 {
          width: 182px; } }
      @media all and (min-width: 800px) {
        .p0-decoration.-animal_aboutmember.-m02 {
          transform: translateX(-10px); } }
    .p0-decoration.-animal_aboutmember.-m03 {
      width: 111.76px; }
      @media all and (min-width: 480px) {
        .p0-decoration.-animal_aboutmember.-m03 {
          width: 114.3px; } }
      @media all and (min-width: 600px) {
        .p0-decoration.-animal_aboutmember.-m03 {
          width: 127px; } }
      @media all and (min-width: 720px) {
        .p0-decoration.-animal_aboutmember.-m03 {
          width: 152.4px; } }
      @media all and (min-width: 800px) {
        .p0-decoration.-animal_aboutmember.-m03 {
          width: 180.34px; } }
      @media all and (min-width: 1000px) {
        .p0-decoration.-animal_aboutmember.-m03 {
          width: 215.9px; } }
      @media all and (min-width: 1120px) {
        .p0-decoration.-animal_aboutmember.-m03 {
          width: 254px; } }
      @media all and (min-width: 800px) {
        .p0-decoration.-animal_aboutmember.-m03 {
          transform: translateX(10px); } }
  .p0-decoration.-animal_service01 {
    display: none;
    width: 133.5px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_service01 {
        width: 138.84px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_service01 {
        width: 165.54px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_service01 {
        width: 197.58px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_service01 {
        width: 267px; } }
    @media all and (min-width: 900px) {
      .p0-decoration.-animal_service01 {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateX(0);
        padding-top: 0; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_service01 {
        right: 2.5%; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal_service01 {
        right: 5%; } }
  .p0-decoration.-animal_service02 {
    display: block;
    position: absolute;
    top: 0;
    left: 16.66667%;
    z-index: 1;
    transform: translateY(-95%);
    width: 73px; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_service02 {
        width: 75.92px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_service02 {
        width: 90.52px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_service02 {
        width: 108.04px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_service02 {
        width: 146px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_service02 {
        top: 0;
        left: 16.66667%; } }
  .p0-decoration.-animal_service03 {
    display: block;
    position: absolute;
    bottom: -20px;
    right: 13.33333%;
    z-index: 1;
    transform: translateY(100%);
    width: 38px; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_service03 {
        width: 39.52px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_service03 {
        width: 47.12px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_service03 {
        width: 56.24px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_service03 {
        width: 76px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_service03 {
        bottom: -20px;
        right: 13.33333%; } }
  .p0-decoration.-animal_topics01 {
    position: relative;
    bottom: -20px;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);
    width: 114.5px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_topics01 {
        width: 119.08px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_topics01 {
        width: 141.98px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_topics01 {
        width: 169.46px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_topics01 {
        width: 229px; } }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_topics01 {
        position: absolute;
        bottom: 5px;
        left: auto;
        right: 55vw;
        transform: translateX(0);
        padding-top: 0; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_topics01 {
        bottom: 3.3vw; } }
    @media all and (min-width: 1320px) {
      .p0-decoration.-animal_topics01 {
        right: 60.83333%;
        bottom: 40px; } }
  .p0-decoration.-animal_contact01 {
    position: relative;
    bottom: 0;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);
    width: 69px;
    padding-top: 20px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_contact01 {
        width: 71.76px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_contact01 {
        width: 85.56px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_contact01 {
        width: 102.12px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_contact01 {
        width: 138px; } }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_contact01 {
        position: absolute;
        right: auto;
        bottom: -5px;
        left: 6.97674%;
        transform: translateX(0);
        padding-top: 0; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_contact01 {
        bottom: -10px;
        left: 5.55556%; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_contact01 {
        bottom: -5px;
        left: 8.33333%;
        width: 165.5px; } }
  @media all and (min-width: 800px) and (min-width: 480px) {
    .p0-decoration.-animal_contact01 {
      width: 172.12px; } }
  @media all and (min-width: 800px) and (min-width: 600px) {
    .p0-decoration.-animal_contact01 {
      width: 205.22px; } }
  @media all and (min-width: 800px) and (min-width: 720px) {
    .p0-decoration.-animal_contact01 {
      width: 244.94px; } }
  @media all and (min-width: 800px) and (min-width: 1000px) {
    .p0-decoration.-animal_contact01 {
      width: 331px; } }
    @media all and (min-width: 1320px) {
      .p0-decoration.-animal_contact01 {
        left: 12.5%; } }
  .p0-decoration.-animal_privacy01 {
    position: relative;
    bottom: -20px;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);
    width: 55px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_privacy01 {
        width: 57.2px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_privacy01 {
        width: 68.2px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_privacy01 {
        width: 81.4px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_privacy01 {
        width: 110px; } }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_privacy01 {
        position: absolute;
        bottom: 0;
        left: auto;
        right: 55vw;
        transform: translateX(0);
        padding-top: 0; } }
    @media all and (min-width: 1320px) {
      .p0-decoration.-animal_privacy01 {
        right: 49.16667%; } }
  .p0-decoration.-animal_blog01 {
    position: relative;
    bottom: -20px;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);
    width: 183.48px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_blog01 {
        width: 183.48px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_blog01 {
        width: 233.52px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_blog01 {
        width: 275.22px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_blog01 {
        width: 300.24px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_blog01 {
        width: 375.3px; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal_blog01 {
        width: 417px; } }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_blog01 {
        position: absolute;
        bottom: -20px;
        left: auto;
        right: 56.66667%;
        transform: translateX(0);
        padding-top: 0; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_blog01 {
        bottom: -35px; } }
  .p0-decoration.-animal_blog02 {
    position: relative;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);
    width: 118.8px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_blog02 {
        width: 118.8px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_blog02 {
        width: 151.2px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_blog02 {
        width: 178.2px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_blog02 {
        width: 194.4px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_blog02 {
        width: 243px; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal_blog02 {
        width: 270px; } }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_blog02 {
        position: absolute;
        bottom: 20px;
        left: auto;
        right: 14.16667%;
        transform: translateX(0);
        padding-top: 0; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_blog02 {
        bottom: 40px; } }
  .p0-decoration.-animal_recruit01 {
    position: relative;
    bottom: -30px;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);
    width: 75.24px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_recruit01 {
        width: 75.24px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_recruit01 {
        width: 95.76px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_recruit01 {
        width: 112.86px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_recruit01 {
        width: 123.12px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_recruit01 {
        width: 153.9px; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal_recruit01 {
        width: 171px; } }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_recruit01 {
        position: absolute;
        bottom: 5px;
        left: auto;
        right: 63.33333%;
        transform: translateX(0);
        padding-top: 0; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_recruit01 {
        bottom: 5px; } }
  .p0-decoration.-animal_error01 {
    position: relative;
    bottom: -30px;
    left: calc(100% - 20px);
    transform: translate(-100%, 0);
    width: 152.24px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_error01 {
        width: 152.24px; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_error01 {
        width: 193.76px; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-animal_error01 {
        width: 228.36px; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_error01 {
        width: 249.12px; } }
    @media all and (min-width: 1000px) {
      .p0-decoration.-animal_error01 {
        width: 311.4px; } }
    @media all and (min-width: 1120px) {
      .p0-decoration.-animal_error01 {
        width: 346px; } }
    @media all and (min-width: 480px) {
      .p0-decoration.-animal_error01 {
        position: absolute;
        bottom: -10px;
        left: auto;
        right: 50vw;
        transform: translateX(0);
        padding-top: 0; } }
    @media all and (min-width: 600px) {
      .p0-decoration.-animal_error01 {
        right: 50vw; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-animal_error01 {
        bottom: -10px; } }
    @media all and (min-width: 1200px) {
      .p0-decoration.-animal_error01 {
        right: 610px; } }
  .p0-decoration.-animal_error02 {
    position: relative;
    width: 100%;
    box-sizing: border-box; }
    @media all and (min-width: 375px) {
      .p0-decoration.-animal_error02 {
        width: 311.96px; } }
  @media all and (min-width: 375px) and (min-width: 480px) {
    .p0-decoration.-animal_error02 {
      width: 311.96px; } }
  @media all and (min-width: 375px) and (min-width: 600px) {
    .p0-decoration.-animal_error02 {
      width: 397.04px; } }
  @media all and (min-width: 375px) and (min-width: 720px) {
    .p0-decoration.-animal_error02 {
      width: 467.94px; } }
  @media all and (min-width: 375px) and (min-width: 800px) {
    .p0-decoration.-animal_error02 {
      width: 510.48px; } }
  @media all and (min-width: 375px) and (min-width: 1000px) {
    .p0-decoration.-animal_error02 {
      width: 638.1px; } }
  @media all and (min-width: 375px) and (min-width: 1120px) {
    .p0-decoration.-animal_error02 {
      width: 709px; } }

.p0-marquee {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  top: 0;
  left: 50%;
  width: 100vw;
  height: auto; }
  .p0-marquee_txt {
    flex: 0 0 auto; }
    .p0-marquee_txt.-marquee01 {
      position: relative;
      animation: 24s marquee01 -12s linear infinite;
      white-space: nowrap; }
      @media all and (min-width: 800px) {
        .p0-marquee_txt.-marquee01:nth-child(odd) {
          animation-duration: 30s;
          animation-delay: -15s; } }
      @media all and (min-width: 1620px) {
        .p0-marquee_txt.-marquee01:nth-child(odd) {
          animation-duration: 36s;
          animation-delay: -18s; } }
      .p0-marquee_txt.-marquee01:nth-child(even) {
        animation: 24s marquee01Copy linear infinite; }
        @media all and (min-width: 800px) {
          .p0-marquee_txt.-marquee01:nth-child(even) {
            animation-duration: 30s; } }
        @media all and (min-width: 1620px) {
          .p0-marquee_txt.-marquee01:nth-child(even) {
            animation-duration: 36s; } }
    .p0-marquee_txt.-marquee02 {
      position: relative;
      animation: 48s marquee01 -24s linear infinite;
      white-space: nowrap; }
      @media all and (min-width: 800px) {
        .p0-marquee_txt.-marquee02:nth-child(odd) {
          animation-duration: 60s;
          animation-delay: -30s; } }
      @media all and (min-width: 1620px) {
        .p0-marquee_txt.-marquee02:nth-child(odd) {
          animation-duration: 72s;
          animation-delay: -36s; } }
      .p0-marquee_txt.-marquee02:nth-child(even) {
        animation: 48s marquee01Copy linear infinite; }
        @media all and (min-width: 800px) {
          .p0-marquee_txt.-marquee02:nth-child(even) {
            animation-duration: 60s; } }
        @media all and (min-width: 1620px) {
          .p0-marquee_txt.-marquee02:nth-child(even) {
            animation-duration: 72s; } }

.p0-sidescroll_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .p0-sidescroll_wrapper.-frontabout {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .p0-sidescroll_wrapper.-workstag {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*
      background-image: url("../images/front/works/works_bg2.jpg");
      background-position: center center;
      background-repeat: repeat-x;
      background-size: auto 100%;
*/ }

.p0-sidescroll_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  gap: 0 0.27em; }

.p0-sidescroll_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

/* カーブするテキストで使用するCSS */
.p0-textcurve {
  position: relative;
  top: -5vw;
  left: 50%;
  transform: translateX(-50%);
  width: 289.285714285714286vw;
  height: 35.714285714285714vw;
  margin-bottom: -10px;
  /*
  @include breakpoint-contentinnerhalf {
    width: 1782px;  // 3240px * 0.55 = 1782px
    height: 350px;  //220px / 400px = 0.55
  }
  @include breakpoint-pc {
    width: 2268px;  // 3240px * 0.7 = 2268px
    height: 350px;  //280px / 400px = 0.7
  }
  @include breakpoint-narrowinner {
    width: 2835px;  // 3240px * 0.875 = 2835px
    height: 350px;  //350px / 400px = 0.875
  }
*/ }
  @media all and (min-width: 1000px) {
    .p0-textcurve {
      top: 0;
      width: 3240px;
      height: 400px;
      margin-bottom: -20px; } }
  .p0-textcurve text {
    transform: translateY(5vw); }
    @media all and (min-width: 1000px) {
      .p0-textcurve text {
        transform: translateY(0); } }

/* トップページのみで使用するCSS */
.p0-front_about_box1 {
  box-sizing: border-box; }

.p0-front_about_box2 {
  box-sizing: border-box;
  padding-top: 55px; }
  @media all and (min-width: 600px) {
    .p0-front_about_box2 {
      padding-top: 75px; } }
  @media all and (min-width: 720px) {
    .p0-front_about_box2 {
      padding-top: 65px; } }
  @media all and (min-width: 800px) {
    .p0-front_about_box2 {
      padding-top: 120px; } }
  @media all and (min-width: 1000px) {
    .p0-front_about_box2 {
      padding-top: 180px; } }
  @media all and (min-width: 1620px) {
    .p0-front_about_box2 {
      padding-top: 200px; } }

.p0-front_about_box3 {
  position: relative;
  height: 100vh; }

.p0-front_about_lead {
  box-sizing: border-box;
  margin-bottom: 30px;
  padding-top: 40px; }
  @media all and (min-width: 600px) {
    .p0-front_about_lead {
      margin-bottom: 40px;
      padding-top: 60px; } }
  @media all and (min-width: 800px) {
    .p0-front_about_lead {
      margin-bottom: 70px;
      padding-top: 50px; } }
  @media all and (min-width: 1000px) {
    .p0-front_about_lead {
      margin-bottom: 70px;
      padding-top: 120px; } }
  @media all and (min-width: 1120px) {
    .p0-front_about_lead {
      margin-bottom: 110px; } }
  @media all and (min-width: 1620px) {
    .p0-front_about_lead {
      margin-bottom: 140px; } }

.p0-front_about_txt {
  margin-bottom: 20px; }
  @media all and (min-width: 800px) {
    .p0-front_about_txt {
      margin-bottom: 120px; } }
  @media all and (min-width: 1000px) {
    .p0-front_about_txt {
      margin-bottom: 110px; } }
  @media all and (min-width: 1120px) {
    .p0-front_about_txt {
      margin-bottom: 190px; } }

.p0-front_about_noisetext {
  position: relative;
  top: 10vw;
  width: 100%; }
  @media all and (min-width: 480px) {
    .p0-front_about_noisetext {
      top: 50px; } }
  @media all and (min-width: 600px) {
    .p0-front_about_noisetext {
      top: 30px; } }
  @media all and (min-width: 720px) {
    .p0-front_about_noisetext {
      top: 65px;
      width: calc(100% + 5vw);
      transform: translateX(-5vw); } }
  @media all and (min-width: 800px) {
    .p0-front_about_noisetext {
      top: 0;
      width: 100%;
      transform: translateX(0);
      margin-bottom: 50px; } }
  @media all and (min-width: 1000px) {
    .p0-front_about_noisetext {
      transform: translateX(0); } }
  @media all and (min-width: 1120px) {
    .p0-front_about_noisetext {
      transform: translateX(-20px); } }
  @media all and (min-width: 1620px) {
    .p0-front_about_noisetext {
      transform: translateX(-50px); } }
  .p0-front_about_noisetext.-sp {
    display: block; }
    @media all and (min-width: 720px) {
      .p0-front_about_noisetext.-sp {
        display: none; } }
  .p0-front_about_noisetext.-w720 {
    display: none; }
    @media all and (min-width: 720px) {
      .p0-front_about_noisetext.-w720 {
        display: block; } }
    @media all and (min-width: 800px) {
      .p0-front_about_noisetext.-w720 {
        display: none; } }
  .p0-front_about_noisetext.-pc {
    display: none; }
    @media all and (min-width: 800px) {
      .p0-front_about_noisetext.-pc {
        display: block; } }

@media all and (min-width: 800px) {
  .p0-front_about_firework {
    margin-bottom: -90px; } }

.p0-front_about_firework.-sp {
  display: block; }
  @media all and (min-width: 800px) {
    .p0-front_about_firework.-sp {
      display: none; } }

.p0-front_about_firework.-pc {
  display: none; }
  @media all and (min-width: 800px) {
    .p0-front_about_firework.-pc {
      display: block; } }

.p0-front_about_logo {
  position: relative;
  box-sizing: border-box; }
  @media all and (min-width: 800px) {
    .p0-front_about_logo {
      margin-bottom: 110px;
      padding-top: 10px; } }
  .p0-front_about_logo.-sp {
    display: block;
    position: absolute;
    top: 10px;
    right: 4.1vw; }
    @media all and (min-width: 480px) {
      .p0-front_about_logo.-sp {
        top: 5px;
        right: 4.1vw; } }
    @media all and (min-width: 600px) {
      .p0-front_about_logo.-sp {
        top: -10px;
        right: -2.5vw; } }
    @media all and (min-width: 720px) {
      .p0-front_about_logo.-sp {
        top: 5px; } }
    @media all and (min-width: 800px) {
      .p0-front_about_logo.-sp {
        display: none; } }
  .p0-front_about_logo.-pc {
    display: none; }
    @media all and (min-width: 800px) {
      .p0-front_about_logo.-pc {
        display: block; } }
    @media all and (min-width: 1000px) {
      .p0-front_about_logo.-pc {
        transform: translateX(2.5vw); } }
    @media all and (min-width: 1200px) {
      .p0-front_about_logo.-pc {
        transform: translateX(0); } }

.p0-front_about_img {
  position: relative;
  width: 100%; }
  .p0-front_about_img img {
    width: 100%;
    height: auto; }
  .p0-front_about_img.-i01 {
    width: 44.375vw;
    margin-bottom: 10.416vw;
    margin-left: auto;
    margin-right: -10.416vw; }
    @media all and (min-width: 720px) {
      .p0-front_about_img.-i01 {
        width: 75.8%;
        margin-bottom: 2.9vw;
        margin-left: auto;
        margin-right: -10%; } }
    @media all and (min-width: 1620px) {
      .p0-front_about_img.-i01 {
        margin-bottom: 50px; } }
  .p0-front_about_img.-i02 {
    width: 39.166vw;
    margin-bottom: 5vw;
    margin-left: auto;
    margin-right: 0; }
    @media all and (min-width: 720px) {
      .p0-front_about_img.-i02 {
        width: 63.2%;
        margin-bottom: 5vw;
        margin-left: 14.5%;
        margin-right: auto; } }
    @media all and (min-width: 800px) {
      .p0-front_about_img.-i02 {
        margin-bottom: 50px; } }
    @media all and (min-width: 1620px) {
      .p0-front_about_img.-i02 {
        margin-left: 14.5%;
        margin-right: auto;
        width: 63.2%; } }
  .p0-front_about_img.-i03 {
    width: 24.375vw;
    margin-left: -6.66vw;
    margin-right: auto;
    z-index: 2; }
    @media all and (min-width: 720px) {
      .p0-front_about_img.-i03 {
        width: 41.6%;
        margin-left: auto;
        margin-right: 8.3%; } }
    @media all and (min-width: 1620px) {
      .p0-front_about_img.-i03 {
        width: 41.6%;
        margin-left: auto;
        margin-right: 8.3%; } }
  .p0-front_about_img.-i04 {
    margin-top: -20.83vw;
    margin-left: auto;
    margin-right: 2.08vw;
    width: 26.875vw;
    margin-bottom: 10.5vw;
    z-index: 3; }
    @media all and (min-width: 720px) {
      .p0-front_about_img.-i04 {
        z-index: 1;
        width: 50%;
        margin-top: -16.8vw;
        margin-left: 11.1%;
        margin-right: auto;
        margin-bottom: 4vw; } }
    @media all and (min-width: 800px) {
      .p0-front_about_img.-i04 {
        margin-top: -16.8vw;
        margin-left: 11.1%;
        margin-right: auto;
        width: 50%;
        margin-bottom: 40px; } }
    @media all and (min-width: 1620px) {
      .p0-front_about_img.-i04 {
        margin-top: -273px; } }
  .p0-front_about_img.-m01 {
    left: 2.7%;
    width: 44.375vw;
    margin-bottom: 5.2vw; }
    @media all and (min-width: 720px) {
      .p0-front_about_img.-m01 {
        left: 2.7%;
        width: 100%;
        margin-bottom: 7.6vw; } }
    @media all and (min-width: 1620px) {
      .p0-front_about_img.-m01 {
        margin-bottom: 130px; } }
  .p0-front_about_img.-m02 {
    left: -1.3%;
    z-index: 3;
    width: 44.375vw;
    margin-bottom: 15vw; }
    @media all and (min-width: 720px) {
      .p0-front_about_img.-m02 {
        left: -1.3%;
        width: 100%;
        margin-bottom: 3.1vw; } }
    @media all and (min-width: 1620px) {
      .p0-front_about_img.-m02 {
        margin-bottom: 50px; } }
  .p0-front_about_img.-m03 {
    left: 1.3%;
    width: 44.375vw;
    margin-top: 6.25vw;
    margin-bottom: 8.33vw; }
    @media all and (min-width: 720px) {
      .p0-front_about_img.-m03 {
        left: 1.3%;
        width: 100%;
        margin-top: 100px;
        margin-bottom: 4.1vw; } }
    @media all and (min-width: 1620px) {
      .p0-front_about_img.-m03 {
        left: 1.3%;
        margin-top: 100px;
        margin-bottom: 70px; } }
  .p0-front_about_img.-m04 {
    right: 13.1%;
    width: 44.375vw;
    margin-bottom: 5vw; }
    @media all and (min-width: 720px) {
      .p0-front_about_img.-m04 {
        right: 13.1%;
        width: 100%;
        margin-bottom: 5vw; } }
    @media all and (min-width: 800px) {
      .p0-front_about_img.-m04 {
        margin-bottom: 50px; } }
    @media all and (min-width: 1620px) {
      .p0-front_about_img.-m04 {
        right: 13.1%;
        margin-bottom: 50px; } }

.p0-front_project {
  box-sizing: border-box;
  padding-bottom: 110px; }
  @media all and (min-width: 800px) {
    .p0-front_project {
      padding-bottom: 130px; } }
  @media all and (min-width: 1000px) {
    .p0-front_project {
      padding-bottom: 150px; } }
  @media all and (min-width: 1120px) {
    .p0-front_project {
      padding-bottom: 170px; } }

@media all and (min-width: 800px) {
  .p0-front_service_slide {
    width: 100%;
    height: 100%; } }

.p0-front_service_slide:hover .p0-front_service_slide_bg, .p0-front_service_slide:focus .p0-front_service_slide_bg {
  filter: alpha(opacity=60);
  opacity: 0.6; }

.p0-front_service_slide_inner {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: #999999;
  transition: transform 0.3s ease-in-out; }
  @media all and (min-width: 800px) {
    .p0-front_service_slide_inner {
      border-radius: 25px; } }
  @media all and (min-width: 1000px) {
    .p0-front_service_slide_inner {
      border-radius: 30px; } }
  @media all and (min-width: 1120px) {
    .p0-front_service_slide_inner {
      border-radius: 35px; } }
  @media all and (min-width: 1620px) {
    .p0-front_service_slide_inner {
      border-radius: 50px; } }

.p0-front_service_slide_imgsize {
  display: block; }
  @media all and (min-width: 800px) {
    .p0-front_service_slide_imgsize {
      display: none; } }

.p0-front_service_slide_bg {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.3s ease-in-out; }
  @media all and (min-width: 800px) {
    .p0-front_service_slide_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } }

@media all and (min-width: 800px) {
  .p0-front_service_slide.swiper-slide-prev .p0-front_service_slide_inner, .p0-front_service_slide.swiper-slide-next .p0-front_service_slide_inner {
    transform: translateX(-100px); } }

.p0-front_service_slug {
  position: absolute;
  right: 15px;
  bottom: 15px; }
  @media all and (min-width: 600px) {
    .p0-front_service_slug {
      right: 18px;
      bottom: 18px; } }
  @media all and (min-width: 800px) {
    .p0-front_service_slug {
      right: 20px;
      bottom: 20px; } }
  @media all and (min-width: 1000px) {
    .p0-front_service_slug {
      right: 25px;
      bottom: 25px; } }
  @media all and (min-width: 1120px) {
    .p0-front_service_slug {
      right: 30px;
      bottom: 30px; } }
  @media all and (min-width: 1620px) {
    .p0-front_service_slug {
      right: 40px;
      bottom: 40px; } }

.p0-front_works {
  width: 100%; }
  .p0-front_works_inner {
    height: 100%; }
  .p0-front_works_boxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    height: 100%; }
  .p0-front_works_box1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    width: 100%;
    background-color: #2b2b2b; }
    @media all and (min-width: 1000px) {
      .p0-front_works_box1 {
        width: 58%; } }
    .p0-front_works_box1:before, .p0-front_works_box1:after {
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      content: "";
      background-color: #2b2b2b; }
    .p0-front_works_box1:before {
      width: 100vw;
      left: 0;
      transform: translateX(-100%); }
    .p0-front_works_box1:after {
      width: 215px;
      right: 0;
      transform: translateX(100%); }
  .p0-front_works_box2 {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding: 0 0 0 0;
    box-sizing: border-box;
    background-color: #2b2b2b; }
    @media all and (min-width: 800px) {
      .p0-front_works_box2 {
        padding: 0 0 0 0; } }
    @media all and (min-width: 1000px) {
      .p0-front_works_box2 {
        width: 42%;
        padding: 110px 0 70px 0;
        background-color: transparent; } }
    @media all and (min-width: 1120px) {
      .p0-front_works_box2 {
        padding-left: 40px; } }
    .p0-front_works_box2:before, .p0-front_works_box2:after {
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      content: "";
      background-color: #2b2b2b; }
      @media all and (min-width: 1000px) {
        .p0-front_works_box2:before, .p0-front_works_box2:after {
          display: none; } }
    .p0-front_works_box2:before {
      width: 100vw;
      left: 0;
      transform: translateX(-100%); }
    .p0-front_works_box2:after {
      width: 215px;
      right: 0;
      transform: translateX(100%); }
  .p0-front_works_masktxt {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    padding: 70px 0 40px 0;
    box-sizing: border-box;
    background-image: url("../images/front/works/works_bg1.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media all and (min-width: 1000px) {
      .p0-front_works_masktxt {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 100%;
        margin-left: 0;
        padding: 120px 0 70px 0; } }
    .p0-front_works_masktxt img {
      width: 100%;
      max-width: 581px;
      height: auto; }
      @media all and (min-width: 800px) {
        .p0-front_works_masktxt img {
          width: 100%; } }
  .p0-front_works_allworks {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-bottom: 110px;
    box-sizing: border-box;
    background-color: #2b2b2b;
    text-align: center; }
    @media all and (min-width: 1000px) {
      .p0-front_works_allworks {
        padding-bottom: 140px;
        text-align: left; } }
    .p0-front_works_allworks.-ni {
      display: none; }
      @media all and (min-width: 1000px) {
        .p0-front_works_allworks.-ni {
          display: block; } }
    .p0-front_works_allworks.-sp {
      display: block; }
      @media all and (min-width: 1000px) {
        .p0-front_works_allworks.-sp {
          display: none; } }
  .p0-front_works_tags {
    /*
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-stretch;
*/
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto 45px;
    line-height: 1; }
    @media all and (min-width: 1000px) {
      .p0-front_works_tags {
        left: 0;
        transform: translateX(0);
        margin-bottom: 0; } }
  .p0-front_works_tagline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    line-height: 1; }
    .p0-front_works_tagline.-line01 {
      margin-bottom: 5px; }
      @media all and (min-width: 1120px) {
        .p0-front_works_tagline.-line01 {
          margin-bottom: 10px; } }
    .p0-front_works_tagline.-line02 {
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      margin-bottom: 5px; }
      @media all and (min-width: 800px) {
        .p0-front_works_tagline.-line02 {
          margin-bottom: 10px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line02 {
          margin-bottom: 5px; } }
      @media all and (min-width: 1120px) {
        .p0-front_works_tagline.-line02 {
          margin-bottom: 10px; } }
    .p0-front_works_tagline.-line03 {
      margin-bottom: 7px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line03 {
          margin-bottom: 0; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line03 {
          margin-bottom: 10px; } }
    .p0-front_works_tagline.-line04 {
      margin-bottom: 2px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line04 {
          margin-bottom: 0; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line04 {
          margin-bottom: 2px; } }
      @media all and (min-width: 1120px) {
        .p0-front_works_tagline.-line04 {
          margin-bottom: 4px; } }
    .p0-front_works_tagline.-line05 {
      margin-bottom: 0; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line05 {
          margin-bottom: 5px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line05 {
          margin-bottom: 0; } }
    .p0-front_works_tagline.-line06 {
      margin-bottom: 5px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line06 {
          margin-bottom: 0; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line06 {
          margin-bottom: 5px; } }
    .p0-front_works_tagline.-line07 {
      margin-bottom: 0; }
    .p0-front_works_tagline.-line08 {
      margin-bottom: 5px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line08 {
          margin-bottom: 0; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line08 {
          margin-bottom: 5px; } }
    .p0-front_works_tagline.-line09 {
      margin-bottom: 10px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line09 {
          margin-bottom: 0; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line09 {
          margin-bottom: 10px; } }
    .p0-front_works_tagline.-line10 {
      margin-bottom: 10px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line10 {
          margin-bottom: 0; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line10 {
          margin-bottom: 10px; } }
    .p0-front_works_tagline.-line11 {
      margin-bottom: 10px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line11 {
          margin-bottom: 15px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line11 {
          margin-bottom: 15px; } }
    .p0-front_works_tagline.-line12 {
      margin-bottom: 3px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line12 {
          margin-bottom: 0; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line12 {
          margin-bottom: 5px; } }
    .p0-front_works_tagline.-line13 {
      margin-bottom: 1px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line13 {
          -webkit-box-align: end;
          -ms-flex-align: end;
          align-items: flex-end;
          margin-bottom: 10px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line13 {
          -webkit-box-align: stretch;
          -ms-flex-align: stretch;
          align-items: stretch;
          margin-bottom: 0; } }
    .p0-front_works_tagline.-line14 {
      margin-bottom: 10px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line14 {
          margin-bottom: 0; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line14 {
          margin-bottom: 15px; } }
    .p0-front_works_tagline.-line15 {
      margin-bottom: 15px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line15 {
          margin-bottom: 3px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line15 {
          margin-bottom: 20px; } }
    .p0-front_works_tagline.-line16 {
      margin-bottom: 10px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line16 {
          margin-bottom: 0; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line16 {
          margin-bottom: 10px; } }
    .p0-front_works_tagline.-line17 {
      margin-bottom: 10px; }
      @media all and (min-width: 600px) {
        .p0-front_works_tagline.-line17 {
          margin-bottom: 0; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tagline.-line17 {
          margin-bottom: 15px; } }
    .p0-front_works_tagline.-line18 {
      margin-bottom: 0; }
  .p0-front_works_tag {
    position: relative;
    box-sizing: border-box;
    line-height: 1; }
    .p0-front_works_tag.-disppat1 {
      display: block; }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-disppat1 {
          display: none; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-disppat1 {
          display: block; } }
    .p0-front_works_tag.-disppat2 {
      display: none; }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-disppat2 {
          display: block; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-disppat2 {
          display: none; } }
    .p0-front_works_tag.-tag01 {
      margin-left: 4.166666666666667vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag01 {
          margin-left: 20px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag01 {
          margin-left: 0; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag01 {
          margin-left: 20px; } }
    .p0-front_works_tag.-tag02 {
      margin-left: 6.25vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag02 {
          margin-left: 30px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag02 {
          margin-left: 60px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag02 {
          margin-left: 35px; } }
    .p0-front_works_tag.-tag03 {
      margin-left: 10.416666666666667vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag03 {
          margin-left: 50px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag03 {
          margin-left: 60px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag03 {
          margin-left: 70px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag03 {
          margin-left: 65px; } }
    .p0-front_works_tag.-tag04 {
      margin-left: 20.833333333333333vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag04 {
          margin-left: 100px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag04 {
          margin-left: 50px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag04 {
          margin-left: 90px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag04 {
          margin-left: 120px; } }
    .p0-front_works_tag.-tag05 {
      margin-left: 33.333333333333333vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag05 {
          margin-left: 160px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag05 {
          margin-left: 80px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag05 {
          margin-left: 120px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag05 {
          margin-left: 180px; } }
    .p0-front_works_tag.-tag06 {
      margin-left: 0; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag06 {
          margin-left: 0; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag06 {
          margin-left: 15px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag06 {
          margin-left: 45px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag06 {
          margin-left: 5px; } }
    .p0-front_works_tag.-tag07 {
      margin-left: 43.75vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag07 {
          margin-left: 210px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag07 {
          margin-left: 120px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag07 {
          margin-left: 135px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag07 {
          margin-left: 240px; } }
    .p0-front_works_tag.-tag08 {
      margin-left: 6.25vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag08 {
          margin-left: 30px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag08 {
          transform: translateY(3px);
          margin-left: 75px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag08 {
          margin-left: 90px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag08 {
          transform: translateY(0);
          margin-left: 35px; } }
    .p0-front_works_tag.-tag09 {
      margin-left: 41.666666666666667vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag09 {
          margin-left: 200px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag09 {
          transform: translateY(-4px);
          margin-left: 25px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag09 {
          margin-left: 40px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag09 {
          transform: translateY(0);
          margin-left: 230px; } }
    .p0-front_works_tag.-tag10 {
      margin-left: 22.916666666666667vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag10 {
          margin-left: 110px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag10 {
          margin-left: 120px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag10 {
          margin-left: 170px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag10 {
          margin-left: 130px; } }
    .p0-front_works_tag.-tag11 {
      margin-left: 18.75vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag11 {
          margin-left: 90px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag11 {
          transform: translateY(4px);
          margin-left: 0; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag11 {
          margin-left: 10px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag11 {
          transform: translateY(0);
          margin-left: 105px; } }
    .p0-front_works_tag.-tag12 {
      margin-left: 9.375vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag12 {
          margin-left: 45px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag12 {
          margin-left: 65px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag12 {
          margin-left: 110px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag12 {
          margin-left: 55px; } }
    .p0-front_works_tag.-tag13 {
      margin-left: 35.416666666666667vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag13 {
          margin-left: 170px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag13 {
          transform: translateY(10px);
          margin-left: 140px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag13 {
          transform: translateY(0);
          margin-left: 200px; } }
    .p0-front_works_tag.-tag14 {
      margin-left: 0; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag14 {
          margin-left: 0; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag14 {
          margin-left: 120px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag14 {
          margin-left: 165px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag14 {
          margin-left: 0; } }
    .p0-front_works_tag.-tag15 {
      margin-left: 37.5vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag15 {
          margin-left: 180px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag15 {
          margin-left: 20px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag15 {
          margin-left: 210px; } }
    .p0-front_works_tag.-tag16 {
      margin-left: 9.375vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag16 {
          margin-left: 45px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag16 {
          margin-left: 0; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag16 {
          margin-left: 10px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag16 {
          margin-left: 50px; } }
    .p0-front_works_tag.-tag17 {
      margin-left: 22.916666666666667vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag17 {
          margin-left: 110px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag17 {
          margin-left: 160px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag17 {
          margin-left: 210px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag17 {
          margin-left: 130px; } }
    .p0-front_works_tag.-tag18 {
      margin-left: 16.666666666666667vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag18 {
          margin-left: 80px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag18 {
          margin-left: 25px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag18 {
          margin-left: 30px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag18 {
          margin-left: 100px; } }
    .p0-front_works_tag.-tag19 {
      margin-left: 5.208333333333333vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag19 {
          margin-left: 25px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag19 {
          margin-left: 50px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag19 {
          margin-left: 100px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag19 {
          margin-left: 30px; } }
    .p0-front_works_tag.-tag20 {
      margin-left: 6.25vw; }
      @media all and (min-width: 480px) {
        .p0-front_works_tag.-tag20 {
          margin-left: 30px; } }
      @media all and (min-width: 600px) {
        .p0-front_works_tag.-tag20 {
          margin-left: 50px; } }
      @media all and (min-width: 720px) {
        .p0-front_works_tag.-tag20 {
          margin-left: 55px; } }
      @media all and (min-width: 1000px) {
        .p0-front_works_tag.-tag20 {
          margin-left: 35px; } }
  .p0-front_works_btmimg {
    display: block;
    width: 100%;
    height: 188px;
    background-image: url("../images/front/works/works_bg3.jpg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover; }
    @media all and (min-width: 720px) {
      .p0-front_works_btmimg {
        height: 225px; } }
    @media all and (min-width: 800px) {
      .p0-front_works_btmimg {
        height: 250px; } }
    @media all and (min-width: 1000px) {
      .p0-front_works_btmimg {
        display: none; } }

.p1-logo {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  pointer-events: all; }
  .p1-logo.-gnav {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 90px; }
    @media all and (min-width: 1000px) {
      .p1-logo.-gnav {
        width: 110px; } }
    @media all and (min-width: 1120px) {
      .p1-logo.-gnav {
        margin-left: -15px; } }
    .p1-logo.-gnav .p1-logo_item {
      padding: 12px;
      background-color: black; }
      @media all and (min-width: 1000px) {
        .p1-logo.-gnav .p1-logo_item {
          padding: 15px; } }
  .p1-logo.-drawer {
    display: block;
    width: 152px;
    height: auto;
    margin-bottom: 25px; }
    @media all and (min-width: 800px) {
      .p1-logo.-drawer {
        margin-bottom: 50px; } }
    @media all and (min-width: 1000px) {
      .p1-logo.-drawer {
        width: 186px; } }
  .p1-logo_item {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box; }
  .p1-logo_img {
    display: block;
    width: 100%;
    height: auto; }

.p1-drawer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transform: translateY(-100vh);
  background-color: rgba(255, 255, 255, 0.94);
  pointer-events: all; }
  .p1-drawer.is-open {
    animation: 0.6s ease-out 0s 1 both drawerIn; }
  .p1-drawer.is-close {
    animation: 0.4s ease-in 0s 1 both drawerOut; }
  .p1-drawer-open {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 1200;
    width: 40px;
    height: 34px;
    box-sizing: border-box;
    cursor: pointer; }
    @media all and (min-width: 1000px) {
      .p1-drawer-open {
        width: 50px;
        height: 42px; } }
    .p1-drawer-open span, .p1-drawer-open span:before, .p1-drawer-open span:after {
      display: block;
      position: absolute;
      height: 6px;
      width: 100%;
      content: '';
      border-radius: 3px;
      background-color: #e9e9e9;
      transition: 0.5s; }
    .p1-drawer-open span {
      transition: 0.5s; }
      .p1-drawer-open span::before {
        bottom: 14px;
        transform-origin: top left; }
        @media all and (min-width: 1000px) {
          .p1-drawer-open span::before {
            bottom: 18px; } }
      .p1-drawer-open span::after {
        top: 14px;
        transform-origin: bottom left; }
        @media all and (min-width: 1000px) {
          .p1-drawer-open span::after {
            top: 18px; } }
    .p1-drawer-open.is-open span {
      background-color: rgba(233, 233, 233, 0);
      transition: 0.01s; }
    .p1-drawer-open.is-open span::before {
      transform: translate(8px, 3px) rotate(40deg); }
      @media all and (min-width: 1000px) {
        .p1-drawer-open.is-open span::before {
          transform: translate(8px, 3px) rotate(40deg); } }
    .p1-drawer-open.is-open span::after {
      transform: translate(8px, -1px) rotate(-40deg); }
      @media all and (min-width: 1000px) {
        .p1-drawer-open.is-open span::after {
          transform: translate(8px, -2px) rotate(-40deg); } }

.p1-gnav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 27px;
  right: 5%;
  z-index: 1001;
  mix-blend-mode: difference; }
  @media all and (min-width: 1620px) {
    .p1-gnav {
      right: 60px; } }
  .p1-gnav_btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: center;
    align-content: center; }
  .p1-gnav_btn.-contact {
    margin-right: 25px;
    transition: 0.4s; }
    @media all and (min-width: 800px) {
      .p1-gnav_btn.-contact {
        margin-right: 25px; } }
  .p1-gnav.is-open .p1-gnav_btn.-contact {
    opacity: 0;
    pointer-events: none; }

.p1-dnav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding-bottom: 40px;
  box-sizing: border-box; }
  @media all and (min-height: 890px) {
    .p1-dnav {
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      padding-bottom: 0;
      padding-top: 150px; } }
  .p1-dnav_content {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 305px;
    max-width: 90%;
    margin: 0 auto; }
  .p1-dnav_list {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    box-sizing: border-box; }
    .p1-dnav_list.-full {
      width: 100%; }
    .p1-dnav_list_col {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch; }
  .p1-dnav_item {
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p1-dnav_item {
        margin-bottom: 10px; } }
  .p1-dnav_link {
    color: #151515; }
  .p1-dnav_txt {
    display: block;
    line-height: 1;
    letter-spacing: 0.01em; }
    .p1-dnav_txt.-en {
      margin-bottom: 5px;
      font-family: 'Bebas Regular';
      font-weight: normal;
      font-style: normal;
      font-size: 4.5rem; }
      @media all and (min-width: 800px) {
        .p1-dnav_txt.-en {
          font-size: 4.5rem; } }
    .p1-dnav_txt.-jp {
      font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.3rem; }
      @media all and (min-width: 800px) {
        .p1-dnav_txt.-jp {
          font-size: 1.3rem; } }

.p2-copyright {
  font-family: din-1451-lt-pro-engschrift,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
  color: white;
  text-align: center;
  line-height: 1;
  letter-spacing: 0.01em; }
  @media all and (min-width: 1000px) {
    .p2-copyright {
      font-size: 1.4rem; } }
  @media all and (min-width: 1120px) {
    .p2-copyright {
      font-size: 1.7rem; } }

.p2-ftinfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  @media all and (min-width: 600px) {
    .p2-ftinfo {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start; } }
  .p2-ftinfo_item {
    box-sizing: border-box; }
    .p2-ftinfo_item.-item1 {
      transform: translateX(20px); }
      @media all and (min-width: 600px) {
        .p2-ftinfo_item.-item1 {
          transform: translateX(0);
          padding-right: 20px; } }
    .p2-ftinfo_item.-item2 {
      margin-top: -0.1em;
      text-align: center; }
      @media all and (min-width: 800px) {
        .p2-ftinfo_item.-item2 {
          margin-top: -0.1em; } }
      @media all and (min-width: 600px) {
        .p2-ftinfo_item.-item2 {
          text-align: left; } }
  .p2-ftinfo_img.-logo {
    width: 109px;
    margin-bottom: 15px; }
    @media all and (min-width: 600px) {
      .p2-ftinfo_img.-logo {
        margin-top: 3px;
        margin-bottom: 0; } }
    @media all and (min-width: 1000px) {
      .p2-ftinfo_img.-logo {
        width: 120px;
        margin-top: 1px; } }
  .p2-ftinfo_txt {
    font-family: din-1451-lt-pro-engschrift,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1.5;
    color: white;
    letter-spacing: 0.01em; }
    @media all and (min-width: 1000px) {
      .p2-ftinfo_txt {
        font-size: 1.9rem;
        line-height: 1.2; } }
    .p2-ftinfo_txt.-tel a {
      color: white; }

.p2-ftnav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  font-size: 1.1rem;
  color: white; }
  @media all and (min-width: 1000px) {
    .p2-ftnav {
      font-size: 1.3rem; } }
  .p2-ftnav_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }
    @media all and (min-width: 480px) {
      .p2-ftnav_inner {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; } }
  .p2-ftnav_item {
    margin: 0 2px; }
    @media all and (min-width: 600px) {
      .p2-ftnav_item {
        margin: 0 4px; } }
    .p2-ftnav_item a {
      padding: 5px 5px;
      box-sizing: border-box;
      color: white; }
      @media all and (min-width: 600px) {
        .p2-ftnav_item a {
          padding: 5px 10px; } }

.p2-ftsns_inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.p2-ftsns_item {
  width: 46px;
  height: 46px;
  margin: 0 10px;
  border-radius: 50%;
  box-sizing: border-box; }
  .p2-ftsns_item a {
    display: inline-block;
    width: 100%;
    height: 100%; }
  .p2-ftsns_item img {
    width: 100%;
    height: auto; }

.p2-ftsns_icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.p2-ftsns .icon-fill-bk {
  fill: black; }

.p2-ftsns .icon-fill-wh {
  fill: white; }

.p2-ftsns #icon-fb {
  width: 23px;
  height: 23px; }

.p2-ftsns #icon-ig {
  width: 21px;
  height: 21px; }

.p2-ftsns #icon-tw {
  width: 20px;
  height: 17px; }

.p3-headline {
  position: relative; }
  .p3-headline.-left {
    text-align: left; }
  .p3-headline.-center {
    text-align: center; }
  .p3-headline.-right {
    text-align: right; }
  .p3-headline.-justify {
    text-align: justify; }
  .p3-headline.-wh {
    color: white; }
  .p3-headline.-bk {
    color: black; }
  .p3-headline.-black01 {
    color: #151515; }
  .p3-headline.-black02 {
    color: #333333; }
  .p3-headline.-black03 {
    color: #555555; }
  .p3-headline.-gray01 {
    color: #2b2b2b; }
  .p3-headline.-gray02 {
    color: #999999; }
  .p3-headline.-gray03 {
    color: #c3c3c3; }
  .p3-headline.-gray04 {
    color: #dbdbdb; }
  .p3-headline.-blue01 {
    color: #004656; }
  .p3-headline.-blue02 {
    color: #1888a1; }
  .p3-headline.-blue03 {
    color: #3de7f7; }
  .p3-headline.-brown01 {
    color: #8b664e; }
  .p3-headline.-catchcopy01 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 12.5vw;
    line-height: 1.525;
    letter-spacing: 0.03em;
    text-align: center; }
    @media all and (min-width: 340px) {
      .p3-headline.-catchcopy01 {
        font-size: 13vw; } }
    @media all and (min-width: 480px) {
      .p3-headline.-catchcopy01 {
        font-size: 6.2rem; } }
    @media all and (min-width: 600px) {
      .p3-headline.-catchcopy01 {
        font-size: 6.787rem; } }
    @media all and (min-width: 720px) {
      .p3-headline.-catchcopy01 {
        text-align: left; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-catchcopy01 {
        font-size: 8rem; } }
  .p3-headline.-lowerkv01 {
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 6.25rem;
    line-height: 1;
    letter-spacing: 0.01em;
    text-align: center; }
    @media all and (min-width: 480px) {
      .p3-headline.-lowerkv01 {
        text-align: left; } }
    @media all and (min-width: 600px) {
      .p3-headline.-lowerkv01 {
        font-size: 7rem; } }
    @media all and (min-width: 720px) {
      .p3-headline.-lowerkv01 {
        font-size: 8rem; } }
    @media all and (min-width: 800px) {
      .p3-headline.-lowerkv01 {
        font-size: 9rem; } }
    @media all and (min-width: 1000px) {
      .p3-headline.-lowerkv01 {
        font-size: 10.25rem; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-lowerkv01 {
        font-size: 11.25rem; } }
  .p3-headline.-lowerkv02 {
    font-family: dnp-shuei-nmincho-std,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8rem;
    line-height: 1;
    text-align: center; }
    @media all and (min-width: 480px) {
      .p3-headline.-lowerkv02 {
        text-align: left; } }
    @media all and (min-width: 600px) {
      .p3-headline.-lowerkv02 {
        font-size: 2rem;
        text-align: left; } }
    @media all and (min-width: 800px) {
      .p3-headline.-lowerkv02 {
        font-size: 2.3rem; } }
    @media all and (min-width: 1000px) {
      .p3-headline.-lowerkv02 {
        font-size: 2.5rem; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-lowerkv02 {
        font-size: 2.6rem; } }
  .p3-headline.-ttl01 {
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 3.5rem;
    line-height: 1;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl01 {
        font-size: 5rem; } }
    .p3-headline.-ttl01.-ajusttop {
      line-height: 0.6; }
  .p3-headline.-ttl02 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2.8rem;
    line-height: 1.7;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl02 {
        font-size: 3.6vw; } }
    @media all and (min-width: 1000px) {
      .p3-headline.-ttl02 {
        font-size: 3.6rem;
        line-height: 2.25; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-ttl02 {
        font-size: 4rem; } }
    .p3-headline.-ttl02.-ajusttop {
      margin-top: -0.35em; }
      @media all and (min-width: 1000px) {
        .p3-headline.-ttl02.-ajusttop {
          margin-top: -0.625em; } }
  .p3-headline.-ttl03 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 4.9rem;
    line-height: 1.4;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl03 {
        font-size: 7rem; } }
  .p3-headline.-ttlset01.-main {
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 4rem;
    line-height: 1;
    letter-spacing: 0.01em; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttlset01.-main {
        font-size: 4.4rem; } }
    @media all and (min-width: 1000px) {
      .p3-headline.-ttlset01.-main {
        font-size: 5.4rem; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-ttlset01.-main {
        font-size: 6rem; } }
    .p3-headline.-ttlset01.-main.-separator:after {
      display: block;
      position: relative;
      content: "";
      width: 120px;
      height: 2px;
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
      background-color: #1888a1; }
      @media all and (min-width: 800px) {
        .p3-headline.-ttlset01.-main.-separator:after {
          margin-top: 30px; } }
  .p3-headline.-ttlset01.-sub {
    font-family: dnp-shuei-nmincho-std,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8rem;
    line-height: 1.8;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttlset01.-sub {
        line-height: 1.8; } }
    @media all and (min-width: 800px) {
      .p3-headline.-ttlset01.-sub {
        font-size: 1.8rem; } }
    @media all and (min-width: 1000px) {
      .p3-headline.-ttlset01.-sub {
        font-size: 2.3rem; } }
    @media all and (min-width: 1120px) {
      .p3-headline.-ttlset01.-sub {
        font-size: 2.6rem; } }
  .p3-headline.-ttlset02.-main {
    font-family: 'Linux Libertine Display Capitals';
    font-weight: normal;
    font-style: normal;
    font-size: 4.9rem;
    line-height: 1;
    letter-spacing: 0.01em; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttlset02.-main {
        font-size: 7rem; } }
    .p3-headline.-ttlset02.-main.-ajusttop {
      margin-top: -0.15em; }
  .p3-headline.-ttlset02.-sub {
    font-family: dnp-shuei-nmincho-std,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 1;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttlset02.-sub {
        font-size: 2rem; } }
  .p3-headline.-ttlset03.-main {
    font-family: 'Linux Libertine Display Capitals';
    font-weight: normal;
    font-style: normal;
    font-size: 3.22rem;
    line-height: 1;
    letter-spacing: 0.01em; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttlset03.-main {
        font-size: 4.6rem; } }
  .p3-headline.-ttlset03.-sub {
    font-family: dnp-shuei-nmincho-std,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.44rem;
    line-height: 1;
    letter-spacing: 0; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttlset03.-sub {
        font-size: 1.8rem; } }
  .p3-headline.-ttlset04.-main {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.96rem;
    line-height: 2;
    letter-spacing: 0.07em; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttlset04.-main {
        font-size: 2.8rem; } }
    @media all and (min-width: 800px) {
      .p3-headline.-ttlset04.-main {
        line-height: 2; } }
  .p3-headline.-ttlset04.-sub {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1.52rem;
    line-height: 2.3;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttlset04.-sub {
        font-size: 1.9rem; } }
    @media all and (min-width: 800px) {
      .p3-headline.-ttlset04.-sub {
        line-height: 2.3; } }
  .p3-headline.-twolinettl01 > div {
    position: relative; }
    .p3-headline.-twolinettl01 > div > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
  .p3-headline.-twolinettl01 > div:nth-of-type(1) > div {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 3.5rem;
    line-height: 1;
    letter-spacing: 0.01em; }
    @media all and (min-width: 600px) {
      .p3-headline.-twolinettl01 > div:nth-of-type(1) > div {
        font-size: 4rem; } }
    @media all and (min-width: 1000px) {
      .p3-headline.-twolinettl01 > div:nth-of-type(1) > div {
        font-size: 5rem; } }
    .p3-headline.-twolinettl01 > div:nth-of-type(1) > div:before {
      display: inline-block;
      position: relative;
      transform: translateY(1px);
      content: "";
      width: 8px;
      height: 12px;
      margin-right: 5px;
      background-image: url("../images/common/icon/right_dottri_bk.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }
      @media all and (min-width: 600px) {
        .p3-headline.-twolinettl01 > div:nth-of-type(1) > div:before {
          width: 9px;
          height: 15px;
          margin-right: 11px; } }
      @media all and (min-width: 800px) {
        .p3-headline.-twolinettl01 > div:nth-of-type(1) > div:before {
          width: 11px;
          height: 18px;
          margin-right: 9px; } }
  .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1) {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    position: relative;
    width: 100vw;
    margin-top: -0.3em;
    margin-left: 1.69em;
    font-family: 'Linux Libertine Display Capitals';
    font-weight: normal;
    font-style: normal;
    font-size: 3.3rem;
    line-height: 1;
    letter-spacing: 0.01em; }
    @media all and (min-width: 600px) {
      .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1) {
        font-size: 3.8rem; } }
    @media all and (min-width: 1000px) {
      .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1) {
        font-size: 4.6rem; } }
    .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1):after {
      display: block;
      position: relative;
      content: "";
      width: 100vw;
      height: 1px;
      margin-left: 20px;
      background-color: #151515; }
    .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-small {
      font-size: 2.97rem; }
      @media all and (min-width: 480px) {
        .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-small {
          font-size: 3.3rem; } }
      @media all and (min-width: 600px) {
        .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-small {
          font-size: 3.8rem; } }
      @media all and (min-width: 1000px) {
        .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-small {
          font-size: 4.6rem; } }
  .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2) {
    display: inline-block;
    position: relative;
    left: 13px;
    top: 0;
    font-family: dnp-shuei-nmincho-std,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.14rem;
    line-height: 1;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2) {
        line-height: 1; } }
    @media all and (min-width: 480px) {
      .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2) {
        position: absolute;
        left: auto;
        right: 0; } }
    @media all and (min-width: 600px) {
      .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2) {
        font-size: 1.28rem; } }
    @media all and (min-width: 1000px) {
      .p3-headline.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2) {
        font-size: 1.6rem; } }
  .p3-headline.-twolinettl02 div:nth-of-type(1) {
    margin-bottom: 10px;
    font-family: 'Linux Libertine Display Capitals';
    font-weight: normal;
    font-style: normal;
    font-size: 3.5rem;
    line-height: 1;
    letter-spacing: 0.01em; }
    @media all and (min-width: 800px) {
      .p3-headline.-twolinettl02 div:nth-of-type(1) {
        font-size: 5rem; } }
  .p3-headline.-twolinettl02 div:nth-of-type(2) {
    font-family: dnp-shuei-nmincho-std,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 1;
    letter-spacing: 0.07em; }
    @media all and (min-width: 800px) {
      .p3-headline.-twolinettl02 div:nth-of-type(2) {
        font-size: 2rem; } }
  .p3-headline.-tagname01 {
    font-family: dnp-shuei-nmincho-std,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8rem;
    line-height: 1;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-headline.-tagname01 {
        font-size: 2.4rem; } }
  .p3-headline.-workssingle01, .p3-headline.-topicssingle01, .p3-headline.-blogsingle01 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    position: relative;
    padding-bottom: 15px;
    box-sizing: border-box; }
    .p3-headline.-workssingle01:after, .p3-headline.-topicssingle01:after, .p3-headline.-blogsingle01:after {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 1px;
      content: "";
      background-color: #151515; }
    .p3-headline.-workssingle01 div:nth-of-type(1), .p3-headline.-topicssingle01 div:nth-of-type(1), .p3-headline.-blogsingle01 div:nth-of-type(1) {
      margin-right: 6px;
      font-family: 'Bebas Regular';
      font-weight: normal;
      font-style: normal;
      font-size: 2.8rem;
      line-height: 1;
      letter-spacing: 0.01em; }
      @media all and (min-width: 800px) {
        .p3-headline.-workssingle01 div:nth-of-type(1), .p3-headline.-topicssingle01 div:nth-of-type(1), .p3-headline.-blogsingle01 div:nth-of-type(1) {
          font-size: 4rem; } }
    .p3-headline.-workssingle01 div:nth-of-type(2), .p3-headline.-topicssingle01 div:nth-of-type(2), .p3-headline.-blogsingle01 div:nth-of-type(2) {
      font-family: dnp-shuei-nmincho-std,sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 1.2rem;
      line-height: 1;
      letter-spacing: 0.01em; }
      @media all and (min-width: 800px) {
        .p3-headline.-workssingle01 div:nth-of-type(2), .p3-headline.-topicssingle01 div:nth-of-type(2), .p3-headline.-blogsingle01 div:nth-of-type(2) {
          font-size: 1.4rem; } }
  .p3-headline.-topicssingle01 div:nth-of-type(1), .p3-headline.-blogsingle01 div:nth-of-type(1) {
    margin-right: 16px; }

.p3-box {
  position: relative; }
  .p3-box.-flexbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
  .p3-box.-sidescroll {
    position: relative;
    height: 100vh; }
    .p3-box.-sidescroll:before {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 300vw;
      content: "";
      background-color: #2b2b2b; }
  .p3-box.-company {
    width: 100%;
    max-width: 800px;
    margin: 0 auto; }
    .p3-box.-company table {
      display: block;
      width: 100%; }
      @media all and (min-width: 480px) {
        .p3-box.-company table {
          display: table; } }
      .p3-box.-company table tbody {
        display: block; }
        @media all and (min-width: 480px) {
          .p3-box.-company table tbody {
            display: table-row; } }
      .p3-box.-company table tr {
        display: block; }
        @media all and (min-width: 480px) {
          .p3-box.-company table tr {
            display: table-row-group; } }
      .p3-box.-company table th, .p3-box.-company table td {
        display: block;
        padding: 10px 0 20px;
        box-sizing: border-box; }
        @media all and (min-width: 480px) {
          .p3-box.-company table th, .p3-box.-company table td {
            display: table-cell;
            padding: 10px 0; } }
        @media all and (min-width: 800px) {
          .p3-box.-company table th, .p3-box.-company table td {
            padding: 15px 10px; } }
      .p3-box.-company table th {
        width: 90px;
        padding-bottom: 0;
        text-align: left; }
        @media all and (min-width: 480px) {
          .p3-box.-company table th {
            padding-bottom: 10px; } }
        @media all and (min-width: 600px) {
          .p3-box.-company table th {
            width: 110px; } }
        @media all and (min-width: 800px) {
          .p3-box.-company table th {
            width: 130px; } }
        @media all and (min-width: 1000px) {
          .p3-box.-company table th {
            width: 150px; } }
  .p3-box.-member {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative; }
    .p3-box.-member .p3-box_img {
      position: relative;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
      @media all and (min-width: 480px) {
        .p3-box.-member .p3-box_img {
          width: 52.77778%;
          margin-left: 0; } }
      @media all and (min-width: 1000px) {
        .p3-box.-member .p3-box_img {
          width: 470px; } }
      .p3-box.-member .p3-box_img img {
        width: 228px;
        height: auto; }
        @media all and (min-width: 480px) {
          .p3-box.-member .p3-box_img img {
            width: 100%; } }
      .p3-box.-member .p3-box_img_name {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-67.5%, 0);
        font-family: 'Bebas Regular';
        font-weight: normal;
        font-style: normal;
        font-size: 4rem;
        color: rgba(61, 231, 247, 0.7);
        line-height: 0.87;
        text-align: left;
        writing-mode: vertical-rl;
        text-align: left;
        text-orientation: upright;
        text-orientation: sideways; }
        .p3-box.-member .p3-box_img_name .combine {
          text-combine-upright: all; }
        @media all and (min-width: 480px) {
          .p3-box.-member .p3-box_img_name {
            top: auto;
            bottom: 0;
            transform: translate(-15px, 22.5%);
            font-size: 4.375rem;
            text-align: center;
            writing-mode: horizontal-tb;
            text-align: inherit;
            text-orientation: inherit; }
            .p3-box.-member .p3-box_img_name .combine {
              text-combine-upright: none; } }
        @media all and (min-width: 600px) {
          .p3-box.-member .p3-box_img_name {
            transform: translate(-18px, 22.5%);
            font-size: 5.625rem; } }
        @media all and (min-width: 720px) {
          .p3-box.-member .p3-box_img_name {
            transform: translate(-20px, 22.5%);
            font-size: 6.625rem; } }
        @media all and (min-width: 800px) {
          .p3-box.-member .p3-box_img_name {
            transform: translate(-25px, 22.5%);
            font-size: 7.5rem; } }
        @media all and (min-width: 1000px) {
          .p3-box.-member .p3-box_img_name {
            transform: translate(-30px, 70%);
            font-size: 10rem; } }
        @media all and (min-width: 1120px) {
          .p3-box.-member .p3-box_img_name {
            transform: translate(-40px, 72.5%); } }
        @media all and (min-width: 1200px) {
          .p3-box.-member .p3-box_img_name {
            transform: translate(-30px, 75%); } }
        .p3-box.-member .p3-box_img_name span {
          display: inline-block;
          margin-bottom: 5px; }
          @media all and (min-width: 480px) {
            .p3-box.-member .p3-box_img_name span {
              display: block;
              margin-bottom: 0; } }
          .p3-box.-member .p3-box_img_name span:last-of-type {
            margin-bottom: 0; }
    .p3-box.-member .p3-box_info {
      position: relative;
      width: 100%;
      padding-top: 18px;
      box-sizing: border-box;
      text-align: center; }
      @media all and (min-width: 480px) {
        .p3-box.-member .p3-box_info {
          width: 41.66667%;
          text-align: left; } }
      @media all and (min-width: 1000px) {
        .p3-box.-member .p3-box_info {
          width: calc( 100% - 470px - 60px); } }
      .p3-box.-member .p3-box_info_name.-en {
        margin-bottom: 5px; }
        @media all and (min-width: 720px) {
          .p3-box.-member .p3-box_info_name.-en {
            margin-bottom: 10px; } }
        @media all and (min-width: 1000px) {
          .p3-box.-member .p3-box_info_name.-en {
            margin-bottom: 20px; } }
      .p3-box.-member .p3-box_info_name.-jp {
        margin-bottom: 5px; }
        @media all and (min-width: 720px) {
          .p3-box.-member .p3-box_info_name.-jp {
            margin-bottom: 10px; } }
        @media all and (min-width: 1000px) {
          .p3-box.-member .p3-box_info_name.-jp {
            margin-bottom: 15px; } }
        @media all and (min-width: 1120px) {
          .p3-box.-member .p3-box_info_name.-jp {
            margin-bottom: 20px; } }
      .p3-box.-member .p3-box_info_job {
        margin-bottom: 10px; }
        @media all and (min-width: 600px) {
          .p3-box.-member .p3-box_info_job {
            margin-bottom: 25px; } }
        @media all and (min-width: 800px) {
          .p3-box.-member .p3-box_info_job {
            margin-bottom: 15px; } }
        @media all and (min-width: 1000px) {
          .p3-box.-member .p3-box_info_job {
            margin-bottom: 25px; } }
      .p3-box.-member .p3-box_info_sns {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 13px 13px; }
        @media all and (min-width: 480px) {
          .p3-box.-member .p3-box_info_sns {
            -webkit-justify-content: flex-start;
            justify-content: flex-start; } }
        .p3-box.-member .p3-box_info_sns_item {
          width: 19px;
          height: auto;
          margin: 0;
          box-sizing: border-box; }
          .p3-box.-member .p3-box_info_sns_item a {
            display: inline-block;
            width: 100%;
            height: 100%; }
          .p3-box.-member .p3-box_info_sns_item img {
            width: 100%;
            height: auto; }
        .p3-box.-member .p3-box_info_sns_icon {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100%;
          height: 100%; }
        .p3-box.-member .p3-box_info_sns .icon-fill-bk {
          fill: black; }
        .p3-box.-member .p3-box_info_sns .icon-fill-wh {
          fill: white; }
        .p3-box.-member .p3-box_info_sns #icon-fb {
          width: 18px;
          height: 18px; }
        .p3-box.-member .p3-box_info_sns #icon-ig {
          width: 19px;
          height: 19px; }
        .p3-box.-member .p3-box_info_sns #icon-tw {
          width: 20px;
          height: 17px; }
    .p3-box.-member .p3-box_txt {
      width: 100%;
      margin-top: 20px;
      box-sizing: border-box; }
      @media all and (min-width: 600px) {
        .p3-box.-member .p3-box_txt {
          margin-top: 30px; } }
      @media all and (min-width: 720px) {
        .p3-box.-member .p3-box_txt {
          padding-left: 10%; } }
      @media all and (min-width: 800px) {
        .p3-box.-member .p3-box_txt {
          padding-left: 25%; } }
      @media all and (min-width: 1000px) {
        .p3-box.-member .p3-box_txt {
          margin-top: 50px;
          padding-left: 30%; } }
      @media all and (min-width: 1120px) {
        .p3-box.-member .p3-box_txt {
          padding-left: 32%; } }
      @media all and (min-width: 1620px) {
        .p3-box.-member .p3-box_txt {
          padding-left: 35%; } }
  .p3-box.-workstxtimgs {
    margin-bottom: 50px; }
    @media all and (min-width: 800px) {
      .p3-box.-workstxtimgs {
        margin-bottom: 100px; } }

.p3-text {
  position: relative; }
  .p3-text.-shadow.-bk01 {
    -moz-text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.23), 0px 0px 5px rgba(0, 0, 0, 0.5);
    -webkit-text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.23), 0px 0px 5px rgba(0, 0, 0, 0.5);
    -ms-text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.23), 0px 0px 5px rgba(0, 0, 0, 0.5);
    text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.23), 0px 0px 5px rgba(0, 0, 0, 0.5); }
  .p3-text.-shadow.-bk02 {
    -moz-text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18), 0px 0px 14px rgba(0, 0, 0, 0.43);
    -webkit-text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23), 0px 0px 14px rgba(0, 0, 0, 0.43);
    -ms-text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23), 0px 0px 14px rgba(0, 0, 0, 0.43);
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23), 0px 0px 14px rgba(0, 0, 0, 0.43); }
  .p3-text.-shadow.-wh {
    -moz-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px white;
    -webkit-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px white;
    -ms-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px white;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px white; }
  .p3-text.-left {
    text-align: left; }
  .p3-text.-center {
    text-align: center; }
  .p3-text.-right {
    text-align: right; }
  .p3-text.-justify {
    text-align: justify; }
  .p3-text.-pcj_spc {
    text-align: center; }
    @media all and (min-width: 800px) {
      .p3-text.-pcj_spc {
        text-align: justify; } }
  .p3-text.-pcc_w720j {
    text-align: justify; }
    @media all and (min-width: 720px) {
      .p3-text.-pcc_w720j {
        text-align: center; } }
  .p3-text.-transparent {
    color: transparent; }
  .p3-text.-wh {
    color: white; }
  .p3-text.-bk {
    color: black; }
  .p3-text.-black01 {
    color: #151515; }
  .p3-text.-black02 {
    color: #333333; }
  .p3-text.-black03 {
    color: #555555; }
  .p3-text.-gray01 {
    color: #2b2b2b; }
  .p3-text.-gray02 {
    color: #999999; }
  .p3-text.-gray03 {
    color: #c3c3c3; }
  .p3-text.-gray04 {
    color: #dbdbdb; }
  .p3-text.-blue01 {
    color: #004656; }
  .p3-text.-blue02 {
    color: #1888a1; }
  .p3-text.-blue03 {
    color: #3de7f7; }
  .p3-text.-brown01 {
    color: #8b664e; }
  .p3-text.-italic {
    font-style: italic !important; }
  .p3-text.-decounderline {
    text-decoration: underline; }
  .p3-text.-lead01 {
    position: relative;
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 5.8vw;
    line-height: 1.7;
    letter-spacing: 0.05em; }
    @media all and (min-width: 480px) {
      .p3-text.-lead01 {
        font-size: 2.8rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-lead01 {
        font-size: 3rem;
        line-height: 2.4; } }
    @media all and (min-width: 1000px) {
      .p3-text.-lead01 {
        font-size: 3.6rem;
        line-height: 2.2; } }
    @media all and (min-width: 1120px) {
      .p3-text.-lead01 {
        font-size: 4rem;
        line-height: 2.1; } }
    @media all and (min-width: 1620px) {
      .p3-text.-lead01 {
        font-size: 4.4rem;
        line-height: 2; } }
  .p3-text.-lead02 {
    position: relative;
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2.8rem;
    line-height: 1.7;
    letter-spacing: 0.05em; }
    @media all and (min-width: 480px) {
      .p3-text.-lead02 {
        font-size: 2.8rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-lead02 {
        font-size: 2.8rem;
        line-height: 2; } }
    @media all and (min-width: 1000px) {
      .p3-text.-lead02 {
        font-size: 3.6rem;
        line-height: 2.2; } }
    @media all and (min-width: 1120px) {
      .p3-text.-lead02 {
        font-size: 4rem;
        line-height: 2; } }
    @media all and (min-width: 1620px) {
      .p3-text.-lead02 {
        font-size: 4.4rem;
        line-height: 1.8; } }
  .p3-text.-lead03 {
    position: relative;
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.8rem;
    line-height: 1.8;
    letter-spacing: 0.01em; }
    @media all and (min-width: 800px) {
      .p3-text.-lead03 {
        line-height: 1.8; } }
    @media all and (min-width: 800px) {
      .p3-text.-lead03 {
        font-size: 1.8rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-lead03 {
        font-size: 2rem; } }
  .p3-text.-lead04 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2.8rem;
    line-height: 1.7;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-lead04 {
        font-size: 3.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-lead04 {
        line-height: 2; } }
    .p3-text.-lead04.-ajusttop {
      margin-top: -0.35em; }
      @media all and (min-width: 800px) {
        .p3-text.-lead04.-ajusttop {
          margin-top: -0.5em; } }
  .p3-text.-lead05 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2.8rem;
    line-height: 1.6;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-lead05 {
        line-height: 1.6; } }
    @media all and (min-width: 1000px) {
      .p3-text.-lead05 {
        font-size: 3.6rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-lead05 {
        font-size: 4.2rem; } }
    .p3-text.-lead05.-ajusttop {
      margin-top: -0.3em; }
      @media all and (min-width: 800px) {
        .p3-text.-lead05.-ajusttop {
          margin-top: -0.3em; } }
    .p3-text.-lead05.-w720left {
      text-align: center; }
      @media all and (min-width: 720px) {
        .p3-text.-lead05.-w720left {
          text-align: left; } }
  .p3-text.-t01 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.1;
    letter-spacing: 0.02em; }
    @media all and (min-width: 720px) {
      .p3-text.-t01 {
        line-height: 2.3; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t01 {
        line-height: 2.8; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t01 {
        font-size: 1.6rem;
        line-height: 2.75; } }
    @media all and (min-width: 1620px) {
      .p3-text.-t01 {
        font-size: 1.8rem;
        line-height: 2.4; } }
  .p3-text.-t02 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2.3;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-t02 {
        font-size: 1.3rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t02 {
        line-height: 2.3; } }
  .p3-text.-t03 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.3;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-text.-t03 {
        line-height: 2.3; } }
    @media all and (min-width: 600px) {
      .p3-text.-t03 {
        font-size: 1.4rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t03 {
        font-size: 1.5rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t03 {
        font-size: 1.6rem; } }
  .p3-text.-t04 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.1;
    letter-spacing: 0.02em; }
    @media all and (min-width: 720px) {
      .p3-text.-t04 {
        line-height: 2.3; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t04 {
        line-height: 2.8; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t04 {
        font-size: 1.6rem;
        line-height: 2.75; } }
  .p3-text.-t05 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2.3;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-text.-t05 {
        line-height: 2.3; } }
    @media all and (min-width: 720px) {
      .p3-text.-t05 {
        font-size: 1.5rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t05 {
        font-size: 1.4rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t05 {
        font-size: 1.5rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t05 {
        font-size: 1.6rem; } }
    .p3-text.-t05.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t05.-lh100 {
          line-height: 1; } }
  .p3-text.-t06 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1.44rem;
    line-height: 2.1;
    letter-spacing: 0; }
    @media all and (min-width: 800px) {
      .p3-text.-t06 {
        font-size: 1.8rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t06 {
        line-height: 2.1; } }
    .p3-text.-t06.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t06.-lh100 {
          line-height: 1; } }
    .p3-text.-t06.-lh110 {
      line-height: 1.1; }
      @media all and (min-width: 800px) {
        .p3-text.-t06.-lh110 {
          line-height: 1.1; } }
    .p3-text.-t06.-lh120 {
      line-height: 1.2; }
      @media all and (min-width: 800px) {
        .p3-text.-t06.-lh120 {
          line-height: 1.2; } }
  .p3-text.-t07 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.6;
    letter-spacing: 0; }
    @media all and (min-width: 720px) {
      .p3-text.-t07 {
        font-size: 1.6rem;
        line-height: 1.625; } }
    @media all and (min-width: 800px) {
      .p3-text.-t07 {
        font-size: 1.5rem;
        line-height: 1.6; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t07 {
        font-size: 1.6rem;
        line-height: 1.625; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t07 {
        font-size: 1.8rem;
        line-height: 1.65; } }
    @media all and (min-width: 1620px) {
      .p3-text.-t07 {
        font-size: 2.2rem;
        line-height: 1.75; } }
  .p3-text.-t08 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.625;
    letter-spacing: 0; }
    @media all and (min-width: 800px) {
      .p3-text.-t08 {
        line-height: 1.625; } }
    @media all and (min-width: 720px) {
      .p3-text.-t08 {
        font-size: 1.8rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t08 {
        font-size: 1.6rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t08 {
        font-size: 1.8rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t08 {
        font-size: 2rem; } }
    @media all and (min-width: 1620px) {
      .p3-text.-t08 {
        font-size: 2.4rem; } }
  .p3-text.-t09 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.8;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-text.-t09 {
        line-height: 1.8; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t09 {
        font-size: 1.1rem; } }
  .p3-text.-t10 {
    font-family: 'Bebas Neue Regular';
    font-weight: normal;
    font-style: normal;
    font-family: 'Bebas Neue Regular';
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-text.-t10 {
        line-height: 1; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t10 {
        font-size: 1.7rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t10 {
        font-size: 2rem; } }
    @media all and (min-width: 1620px) {
      .p3-text.-t10 {
        font-size: 2.4rem; } }
  .p3-text.-t11 {
    font-family: 'RockvilleSolidRegular';
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 1;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-text.-t11 {
        line-height: 1; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t11 {
        font-size: 1.7rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t11 {
        font-size: 2rem; } }
    @media all and (min-width: 1620px) {
      .p3-text.-t11 {
        font-size: 2.4rem; } }
  .p3-text.-t12 {
    font-family: din-1451-lt-pro-engschrift,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 0; }
    @media all and (min-width: 800px) {
      .p3-text.-t12 {
        line-height: 1; } }
    @media all and (min-width: 480px) {
      .p3-text.-t12 {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t12 {
        font-size: 1.7rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t12 {
        font-size: 2.2rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t12 {
        font-size: 2.4rem; } }
    @media all and (min-width: 1620px) {
      .p3-text.-t12 {
        font-size: 3.6rem; } }
  .p3-text.-t13 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2.6rem;
    line-height: 1.3;
    letter-spacing: 0; }
    @media all and (min-width: 800px) {
      .p3-text.-t13 {
        line-height: 1.3; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t13 {
        font-size: 3.2rem; } }
  .p3-text.-t14 {
    font-family: din-1451-lt-pro-engschrift,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 7vw;
    line-height: 1;
    letter-spacing: 0; }
    @media all and (min-width: 800px) {
      .p3-text.-t14 {
        line-height: 1; } }
    @media all and (min-width: 480px) {
      .p3-text.-t14 {
        font-size: 3.4rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t14 {
        font-size: 4rem; } }
  .p3-text.-t15 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 3.5vw;
    line-height: 1;
    letter-spacing: 0; }
    @media all and (min-width: 800px) {
      .p3-text.-t15 {
        line-height: 1; } }
    @media all and (min-width: 480px) {
      .p3-text.-t15 {
        font-size: 1.7rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t15 {
        font-size: 2rem; } }
  .p3-text.-t16 {
    font-family: 'Linux Libertine Display Capitals';
    font-weight: normal;
    font-style: normal;
    font-size: 1.12rem;
    line-height: 1.4; }
    @media all and (min-width: 800px) {
      .p3-text.-t16 {
        font-size: 1.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t16 {
        line-height: 1.4; } }
  .p3-text.-t17 {
    font-family: dnp-shuei-nmincho-std,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.28rem;
    line-height: 2.25;
    letter-spacing: 0; }
    @media all and (min-width: 800px) {
      .p3-text.-t17 {
        font-size: 1.6rem; } }
  .p3-text.-t18 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 2.88rem;
    line-height: 1.4;
    letter-spacing: 0.01em; }
    @media all and (min-width: 800px) {
      .p3-text.-t18 {
        font-size: 3.6rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t18 {
        line-height: 1.8; } }
  .p3-text.-t19 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.2;
    letter-spacing: 0.01em; }
    @media all and (min-width: 800px) {
      .p3-text.-t19 {
        font-size: 2rem; } }
    .p3-text.-t19.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t19.-lh100 {
          line-height: 1; } }
  .p3-text.-t20 {
    font-family: 'Linux Libertine Display Capitals';
    font-weight: normal;
    font-style: normal;
    font-size: 1.36rem;
    line-height: 1.4; }
    @media all and (min-width: 800px) {
      .p3-text.-t20 {
        font-size: 1.7rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t20 {
        line-height: 1.4; } }
  .p3-text.-t21 {
    font-family: 'Linux Libertine Display Capitals';
    font-weight: normal;
    font-style: normal;
    font-size: 1.28rem;
    line-height: 2; }
    @media all and (min-width: 800px) {
      .p3-text.-t21 {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t21 {
        line-height: 2; } }
    .p3-text.-t21.-ls005 {
      letter-spacing: 0.05em; }
  .p3-text.-t22 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.8; }
    @media all and (min-width: 800px) {
      .p3-text.-t22 {
        font-size: 1.1rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t22 {
        line-height: 1.8; } }
  .p3-text.-t23 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.8;
    letter-spacing: 0; }
    @media all and (min-width: 800px) {
      .p3-text.-t23 {
        font-size: 1.2rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t23 {
        line-height: 1.8; } }
  .p3-text.-t24 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.12rem;
    line-height: 1.8; }
    @media all and (min-width: 800px) {
      .p3-text.-t24 {
        font-size: 1.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t24 {
        line-height: 1.8; } }
    .p3-text.-t24.-lh250 {
      line-height: 2.5; }
      @media all and (min-width: 800px) {
        .p3-text.-t24.-lh250 {
          line-height: 2.5; } }
      .p3-text.-t24.-lh250.-ajusttop {
        margin-top: -0.75em; }
        @media all and (min-width: 800px) {
          .p3-text.-t24.-lh250.-ajusttop {
            margin-top: -0.75em; } }
    .p3-text.-t24.-ls005 {
      letter-spacing: 0.05em; }
  .p3-text.-t25 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.8; }
    @media all and (min-width: 800px) {
      .p3-text.-t25 {
        font-size: 1.1rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t25 {
        line-height: 1.8; } }
    .p3-text.-t25.-ls005 {
      letter-spacing: 0.05em; }
  .p3-text.-t26 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.44rem;
    line-height: 1.4; }
    @media all and (min-width: 800px) {
      .p3-text.-t26 {
        font-size: 1.8rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t26 {
        line-height: 1.4; } }
    .p3-text.-t26.-ls005 {
      letter-spacing: 0.05em; }
  .p3-text.-t27 {
    font-family: din-1451-lt-pro-engschrift,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.28rem;
    line-height: 1;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-t27 {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t27 {
        line-height: 1; } }
  .p3-text.-t28 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.9;
    letter-spacing: 0.05em; }
    @media all and (min-width: 600px) {
      .p3-text.-t28 {
        font-size: 1.4rem;
        line-height: 1.8; } }
    @media all and (min-width: 720px) {
      .p3-text.-t28 {
        font-size: 1.3rem;
        line-height: 1.9; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t28 {
        font-size: 1.3rem;
        line-height: 2.15; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t28 {
        font-size: 1.4rem;
        line-height: 2.1; } }
  .p3-text.-t29 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.52rem;
    line-height: 2;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-t29 {
        font-size: 1.9rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t29 {
        line-height: 2; } }
  .p3-text.-t30 {
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 4rem;
    line-height: 1;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-t30 {
        font-size: 5rem; } }
  .p3-text.-t31 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1.28rem;
    line-height: 2.1;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-t31 {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t31 {
        line-height: 2.75; } }
  .p3-text.-t32 {
    font-family: din-1451-lt-pro-engschrift,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.4rem;
    line-height: 1.4;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-t32 {
        font-size: 3rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t32 {
        line-height: 1.5; } }
  .p3-text.-t33 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.5;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-text.-t33 {
        font-size: 1.5rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t33 {
        line-height: 1.5; } }
  .p3-text.-t34 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.1;
    letter-spacing: 0.02em; }
    @media all and (min-width: 720px) {
      .p3-text.-t34 {
        font-size: 1.3rem;
        line-height: 1.9; } }
    @media all and (min-width: 800px) {
      .p3-text.-t34 {
        font-size: 1.4rem;
        line-height: 2; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t34 {
        line-height: 2.55; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t34 {
        font-size: 1.6rem;
        line-height: 2.75;
        letter-spacing: 0.05em; } }
  .p3-text.-t35 {
    font-family: 'Bebas Neue Regular';
    font-weight: normal;
    font-style: normal;
    font-family: 'Bebas Neue Book';
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.05em; }
    @media all and (min-width: 600px) {
      .p3-text.-t35 {
        font-size: 1.8rem; } }
    @media all and (min-width: 720px) {
      .p3-text.-t35 {
        font-size: 2rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t35 {
        font-size: 2.4rem; } }
  .p3-text.-t36 {
    font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2.4rem;
    line-height: 1;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-t36 {
        line-height: 1; } }
    @media all and (min-width: 600px) {
      .p3-text.-t36 {
        font-size: 2.4rem; } }
    @media all and (min-width: 600px) {
      .p3-text.-t36 {
        font-size: 2.8rem; } }
    @media all and (min-width: 720px) {
      .p3-text.-t36 {
        font-size: 3.2rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-t36 {
        font-size: 3.6rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-t36 {
        font-size: 4rem; } }
  .p3-text.-t37 {
    font-family: 'Linux Libertine Display Capitals', 'Shippori Mincho', serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.36rem;
    line-height: 1.4; }
    @media all and (min-width: 800px) {
      .p3-text.-t37 {
        font-size: 1.7rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t37 {
        line-height: 1.4; } }
  .p3-text.-mask01 {
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 12.5vw;
    line-height: 1;
    letter-spacing: 0.01em;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text; }
    @media all and (min-width: 375px) {
      .p3-text.-mask01 {
        font-size: 13vw; } }
    @media all and (min-width: 480px) {
      .p3-text.-mask01 {
        font-size: 6.375rem; } }
    @media all and (min-width: 600px) {
      .p3-text.-mask01 {
        font-size: 8rem; } }
    @media all and (min-width: 720px) {
      .p3-text.-mask01 {
        font-size: 8.625rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-mask01 {
        font-size: 11.625rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-mask01 {
        font-size: 14.25rem; } }
    .p3-text.-mask01.-bgimg01 {
      background-image: url("../images/front/works/works_bg2.jpg");
      background-position: center center;
      background-repeat: repeat-x;
      background-size: auto 100vh; }
  .p3-text.-noise01 {
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 7.8125vw;
    line-height: 1.1;
    letter-spacing: 0.01em; }
    @media all and (min-width: 480px) {
      .p3-text.-noise01 {
        font-size: 3.625rem; } }
    @media all and (min-width: 720px) {
      .p3-text.-noise01 {
        font-size: 3.625rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-noise01 {
        font-size: 4.625rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-noise01 {
        font-size: 5.25rem; } }
    @media all and (min-width: 1620px) {
      .p3-text.-noise01 {
        font-size: 6.25rem; } }
    .p3-text.-noise01:before, .p3-text.-noise01:after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      color: black;
      background: #f8f8f8;
      overflow: hidden;
      clip: rect(0, 900px, 0, 0); }
    .p3-text.-noise01:before {
      left: -2px;
      text-shadow: 1px 0 #0042ff;
      animation: textNoise2-line2 3s infinite linear alternate-reverse; }
      @media all and (min-width: 720px) {
        .p3-text.-noise01:before {
          animation: textNoise2-line1 3s infinite linear alternate-reverse; } }
    .p3-text.-noise01:after {
      left: 2px;
      text-shadow: -1px 0 #ff0000;
      animation: textNoise1-line2 2s infinite linear alternate-reverse; }
      @media all and (min-width: 720px) {
        .p3-text.-noise01:after {
          animation: textNoise1-line1 2s infinite linear alternate-reverse; } }
  .p3-text.-marquee01, .p3-text.-sidescroll01 {
    position: relative;
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 9.384rem;
    line-height: 1;
    letter-spacing: 0.01em; }
    @media all and (min-width: 800px) {
      .p3-text.-marquee01, .p3-text.-sidescroll01 {
        font-size: 10.425rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-marquee01, .p3-text.-sidescroll01 {
        font-size: 13.056rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-marquee01, .p3-text.-sidescroll01 {
        font-size: 15rem; } }
  .p3-text.-wave01 {
    font-family: 'Bebas Neue Regular';
    font-weight: normal;
    font-style: normal;
    font-family: 'Bebas Neue Light';
    font-weight: 300;
    font-size: 18vw;
    line-height: 1;
    letter-spacing: 0.03em;
    text-align: center; }
    @media all and (min-width: 480px) {
      .p3-text.-wave01 {
        font-size: 8.2rem; } }
    @media all and (min-width: 600px) {
      .p3-text.-wave01 {
        font-size: 6.8rem; } }
    @media all and (min-width: 720px) {
      .p3-text.-wave01 {
        font-size: 5.8rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-wave01 {
        font-size: 5.3rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-wave01 {
        font-size: 5rem; } }
    .p3-text.-wave01.-gray03 {
      fill: #c3c3c3; }
    .p3-text.-wave01 .-shorten {
      letter-spacing: -0.1em; }
  .p3-text.-dt01 {
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 1.3rem;
    line-height: 1;
    letter-spacing: 0.03em; }
    @media all and (min-width: 600px) {
      .p3-text.-dt01 {
        font-size: 1.4rem; } }
    @media all and (min-width: 1000px) {
      .p3-text.-dt01 {
        font-size: 1.5rem; } }
    @media all and (min-width: 1120px) {
      .p3-text.-dt01 {
        font-size: 1.6rem; } }
    .p3-text.-dt01.-lh185 {
      line-height: 1.85; }
      @media all and (min-width: 800px) {
        .p3-text.-dt01.-lh185 {
          line-height: 1.85; } }
  .p3-text.-dt02 {
    font-family: 'Bebas Regular';
    font-weight: normal;
    font-style: normal;
    font-size: 1.75rem;
    line-height: 1;
    letter-spacing: 0.03em; }
    @media all and (min-width: 800px) {
      .p3-text.-dt02 {
        font-size: 1.75rem; } }
  .p3-text.-list01 {
    margin-left: 20px;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.875;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-list01 {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-list01 {
        line-height: 1.875; } }
    .p3-text.-list01 > li {
      margin-bottom: 15px; }
      @media all and (min-width: 800px) {
        .p3-text.-list01 > li {
          margin-bottom: 30px; } }
      .p3-text.-list01 > li:last-of-type {
        margin-bottom: 0; }
    .p3-text.-list01 div {
      padding-left: 10px;
      box-sizing: border-box; }
  .p3-text.-list02 {
    margin-left: 18px;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2.3;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-list02 {
        font-size: 1.3rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-list02 {
        line-height: 2.3; } }
    .p3-text.-list02 > li {
      margin-bottom: 10px; }
      @media all and (min-width: 800px) {
        .p3-text.-list02 > li {
          margin-bottom: 20px; } }
      .p3-text.-list02 > li:last-of-type {
        margin-bottom: 0; }
  .p3-text.-list03 {
    margin-left: 0;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.1;
    letter-spacing: 0.05em; }
    @media all and (min-width: 800px) {
      .p3-text.-list03 {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-list03 {
        line-height: 1.1; } }
    .p3-text.-list03 > li {
      margin-bottom: 10px; }
      .p3-text.-list03 > li:last-of-type {
        margin-bottom: 0; }
  .p3-text.-line1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; }
  .p3-text.-line2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; }
  .p3-text.-line3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; }
  .p3-text.-line4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; }
  .p3-text.-line5 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5; }
  .p3-text.-line6 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6; }
  .p3-text.-line7 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7; }
  .p3-text.-line8 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8; }
  .p3-text.-line9 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9; }
  .p3-text.-line10 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10; }
  .p3-text.-mask {
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text; }
  .p3-text_punctuation.-a1min.-l {
    margin-left: -0.2em; }
  .p3-text_punctuation.-a1min.-r {
    letter-spacing: -0.55em; }
  .p3-text_punctuation.-a1min.-rh {
    letter-spacing: -0.275em; }
  .p3-text_punctuation.-a1min.-x {
    margin-left: -0.2em;
    letter-spacing: -0.55em; }
  .p3-text.-link01:after {
    display: inline-block;
    content: "";
    width: 12px;
    height: 12px;
    margin-left: 6px;
    background-image: url("../images/common/icon/exsite_bk.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }

.p3-img.-i01 {
  width: 100%; }
  .p3-img.-i01 img {
    width: 100%;
    height: auto; }

.p3-img.-igit01 {
  position: absolute;
  bottom: 4.166666666666667vw;
  left: 50%;
  transform: translate(-50%, 5vw);
  width: 21.666666666666667vw;
  height: auto; }
  @media all and (min-width: 1000px) {
    .p3-img.-igit01 {
      bottom: 80px;
      transform: translate(-50%, 0);
      width: 199px; } }

.p3-img.-spacer01, .p3-img.-spacer02 {
  position: relative;
  width: 100%;
  height: 30.83333vw;
  min-height: 185px;
  max-height: 500px;
  background-position: center center;
  background-repeat: repeat-x;
  background-size: auto 100%; }
  @media all and (min-width: 1000px) {
    .p3-img.-spacer01, .p3-img.-spacer02 {
      height: 346px; } }
  @media all and (min-width: 1620px) {
    .p3-img.-spacer01, .p3-img.-spacer02 {
      height: 500px; } }

.p3-img.-spacer01 {
  background-image: url("../images/front/spacer01.jpg"); }

.p3-img.-spacer02 {
  background-image: url("../images/about/spacer02.jpg"); }

.p3-img.-workstagname {
  pointer-events: none; }
  .p3-img.-workstagname.-pickup {
    width: auto;
    height: 108px; }
    @media all and (min-width: 600px) {
      .p3-img.-workstagname.-pickup {
        height: 108px; } }
    @media all and (min-width: 600px) {
      .p3-img.-workstagname.-pickup {
        height: 128px; } }
    @media all and (min-width: 720px) {
      .p3-img.-workstagname.-pickup {
        height: 75px; } }
    @media all and (min-width: 800px) {
      .p3-img.-workstagname.-pickup {
        height: 80px; } }
    @media all and (min-width: 1000px) {
      .p3-img.-workstagname.-pickup {
        height: 106px; } }
    @media all and (min-width: 1120px) {
      .p3-img.-workstagname.-pickup {
        height: 128px; } }
    @media all and (min-width: 1320px) {
      .p3-img.-workstagname.-pickup {
        height: 128px; } }
  .p3-img.-workstagname.-single {
    display: none;
    position: relative;
    height: calc(135px * 0.75 * 1.25);
    transform: rotate(-11.15deg);
    margin-top: auto;
    margin-bottom: 30px; }
    @media all and (min-width: 800px) {
      .p3-img.-workstagname.-single {
        display: block;
        height: 7.2vw; } }
    @media all and (min-width: 1000px) {
      .p3-img.-workstagname.-single {
        height: 6.7vw;
        margin-bottom: 5vw; } }
    @media all and (min-width: 1120px) {
      .p3-img.-workstagname.-single {
        height: 7.7vw;
        margin-bottom: 7vw; } }
    @media all and (min-width: 1200px) {
      .p3-img.-workstagname.-single {
        margin-bottom: 9vw; } }
    @media all and (min-width: 1320px) {
      .p3-img.-workstagname.-single {
        height: 102px;
        margin-bottom: 80px; } }
    .p3-img.-workstagname.-single img {
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      height: 100%; }

.p3-img.-worksmainimg, .p3-img.-worksmainvideo {
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  @media all and (min-width: 800px) {
    .p3-img.-worksmainimg, .p3-img.-worksmainvideo {
      width: calc( 100vw - ( ( 100vw - (100% + 260px) ) / 2 ) - 260px); } }
  @media all and (min-width: 1120px) {
    .p3-img.-worksmainimg, .p3-img.-worksmainvideo {
      width: calc( 100vw - ( ( 100vw - (100% + 310px) ) / 2 ) - 310px); } }
  @media all and (min-width: 1620px) {
    .p3-img.-worksmainimg, .p3-img.-worksmainvideo {
      width: 1000px; } }

.p3-img.-worksmainvideo {
  position: relative;
  width: 100%; }
  .p3-img.-worksmainvideo video {
    position: relative;
    width: 100%;
    height: auto; }

.p3-img.-workscomment {
  width: 100%; }
  .p3-img.-workscomment img {
    width: 100%;
    height: auto; }

.p3-img.-serviceimg01, .p3-img.-serviceimg02 {
  box-sizing: border-box;
  text-align: center; }
  @media all and (min-width: 800px) {
    .p3-img.-serviceimg01, .p3-img.-serviceimg02 {
      text-align: left; } }
  .p3-img.-serviceimg01 img, .p3-img.-serviceimg02 img {
    width: 100%; }

.p3-img.-serviceimg01 img {
  max-width: 300px; }
  @media all and (min-width: 800px) {
    .p3-img.-serviceimg01 img {
      width: 25vw;
      padding-bottom: 0;
      padding-left: 3.3vw; } }
  @media all and (min-width: 1320px) {
    .p3-img.-serviceimg01 img {
      padding-left: 40px; } }

@media all and (min-width: 800px) {
  .p3-img.-serviceimg02 {
    text-align: right; } }

.p3-img.-serviceimg02 img {
  max-width: 380px; }
  @media all and (min-width: 800px) {
    .p3-img.-serviceimg02 img {
      width: 31.6vw;
      margin-left: auto;
      margin-right: 0;
      padding-top: 25px;
      padding-bottom: 0;
      padding-right: 3.3vw; } }
  @media all and (min-width: 1620px) {
    .p3-img.-serviceimg02 img {
      padding-right: 40px; } }

.p3-img.-aboutspirits {
  text-align: center; }
  .p3-img.-aboutspirits img {
    width: 100%;
    max-width: 304px;
    height: auto; }
    @media all and (min-width: 720px) {
      .p3-img.-aboutspirits img {
        max-width: none; } }

.p3-img.-aboutspiritsbtm {
  text-align: center; }
  .p3-img.-aboutspiritsbtm img {
    width: 100%;
    height: auto; }

.p3-img.-aboutservice img {
  width: 100%;
  height: auto;
  box-sizing: border-box; }
  @media all and (min-width: 720px) {
    .p3-img.-aboutservice img {
      width: 84.7%;
      padding-left: 10px; } }

.p4-posthead {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .p4-posthead_author {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .p4-posthead_author_img {
      width: 36px;
      height: 36px;
      overflow: hidden;
      margin-right: 16px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      box-sizing: border-box; }
      @media all and (min-width: 720px) {
        .p4-posthead_author_img {
          width: 48px;
          height: 48px; } }
      @media all and (min-width: 800px) {
        .p4-posthead_author_img {
          width: 48px;
          height: 48px;
          margin-right: 20px; } }
      @media all and (min-width: 1000px) {
        .p4-posthead_author_img {
          width: 48px;
          height: 48px; } }
      @media all and (min-width: 1120px) {
        .p4-posthead_author_img {
          width: 60px;
          height: 60px; } }
      .p4-posthead_author_img img {
        width: 100%;
        height: auto;
        opacity: 0; }

.p4-postarea p:first-of-type img {
  margin: 0; }
  @media all and (min-width: 800px) {
    .p4-postarea p:first-of-type img {
      margin: 0; } }

.p4-postarea img {
  width: 100%;
  height: inherit;
  margin: 0; }

.p4-postarea a {
  text-decoration: underline; }

.p4-pager {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  padding: 50px 0;
  box-sizing: border-box;
  font-family: 'Bebas Regular';
  font-weight: normal;
  font-style: normal;
  font-size: 1.625rem;
  line-height: 1;
  letter-spacing: 0.03em;
  color: #1888a1;
  text-align: center;
  line-height: 1;
  /* pager非表示時(1ページのみの場合) */ }
  @media all and (min-width: 800px) {
    .p4-pager {
      font-size: 1.875rem; } }
  @media all and (min-width: 800px) {
    .p4-pager {
      padding: 100px 0; } }
  .p4-pager.-topics {
    padding: 35px 0 0; }
    @media all and (min-width: 800px) {
      .p4-pager.-topics {
        padding: 70px 0 0; } }
  .p4-pager .page-numbers {
    display: none;
    width: auto;
    margin: 0 9px;
    padding: 0;
    box-sizing: border-box;
    color: color-txt08(1);
    /*
    &.first {
      background-image: url("../images/common/icon_tri_gr.png");
      transform: rotate(180deg); //lastと同じ画像を左右反転して使いたい場合は、この行を有効にする
    }
    &.last {
      background-image: url("../images/common/icon_tri_gr.png");
    }
*/ }
    .p4-pager .page-numbers.current {
      display: block; }
    .p4-pager .page-numbers.dots {
      display: none; }
    .p4-pager .page-numbers.prev, .p4-pager .page-numbers.next {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: #1888a1; }
      @media all and (min-width: 800px) {
        .p4-pager .page-numbers.prev, .p4-pager .page-numbers.next {
          width: 30px;
          height: 30px; } }
    .p4-pager .page-numbers.-dummy {
      display: block;
      width: 24px;
      height: 24px; }
      @media all and (min-width: 800px) {
        .p4-pager .page-numbers.-dummy {
          width: 30px;
          height: 30px; } }
    .p4-pager .page-numbers .pagehead {
      display: block;
      margin-bottom: 5px; }
      @media all and (min-width: 800px) {
        .p4-pager .page-numbers .pagehead {
          margin-bottom: 10px; } }
    .p4-pager .page-numbers .pagebody {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
    .p4-pager .page-numbers .slash {
      display: inline-block;
      padding: 0 5px;
      box-sizing: border-box;
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500;
      font-size: 1.3rem; }
      @media all and (min-width: 800px) {
        .p4-pager .page-numbers .slash {
          font-size: 1.5rem; } }
  .p4-pager-nodisp {
    height: 15px; }
    @media all and (min-width: 800px) {
      .p4-pager-nodisp {
        height: 30px; } }

.p5-form {
  width: 100%; }
  .p5-form_ttl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 0 10px;
    box-sizing: border-box; }
    .p5-form_ttl.-center {
      -webkit-justify-content: center;
      justify-content: center; }
    .p5-form_ttl.-required:after {
      display: block;
      position: relative;
      content: "";
      width: 60px;
      height: 16px;
      margin-left: 8px;
      line-height: 1;
      background-image: url("../images/contact/required.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain; }
    .p5-form_ttl.-checkboxttl {
      margin-bottom: 15px; }
      @media all and (min-width: 800px) {
        .p5-form_ttl.-checkboxttl {
          margin-bottom: 30px; } }
  .p5-form_inputbox {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700;
    font-size: 1.44rem;
    line-height: 2.1;
    letter-spacing: 0.05em;
    color: #151515; }
    @media all and (min-width: 800px) {
      .p5-form_inputbox {
        font-size: 1.8rem; } }
    @media all and (min-width: 800px) {
      .p5-form_inputbox {
        line-height: 2.4; } }
  .p5-form_checkboxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 0 10px;
    box-sizing: border-box;
    gap: 20px 30px; }
    @media all and (min-width: 800px) {
      .p5-form_checkboxes {
        gap: 20px 40px; } }
    @media all and (min-width: 1120px) {
      .p5-form_checkboxes {
        gap: 20px 60px; } }
    .p5-form_checkboxes.-center {
      -webkit-justify-content: center;
      justify-content: center; }
    .p5-form_checkboxes label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2.1;
      letter-spacing: 0.02em;
      color: #151515; }
      @media all and (min-width: 720px) {
        .p5-form_checkboxes label {
          line-height: 2.3; } }
      @media all and (min-width: 1000px) {
        .p5-form_checkboxes label {
          line-height: 2.8; } }
      @media all and (min-width: 1120px) {
        .p5-form_checkboxes label {
          font-size: 1.6rem;
          line-height: 2.75; } }
    .p5-form_checkboxes .error {
      margin-top: -25px;
      padding-left: 0 !important;
      padding-right: 0 !important; }
  .p5-form_input {
    padding: 2px 10px; }
    @media all and (min-width: 800px) {
      .p5-form_input {
        padding: 3px 10px; } }
    .p5-form_input:focus {
      background-color: rgba(219, 219, 219, 0.3); }
    .p5-form_input.-txt {
      width: 100%;
      border-bottom: 2px solid #2b2b2b; }
    .p5-form_input.-txtarea {
      width: 100%;
      height: 320px;
      border: 1px solid #999999;
      background-color: white; }
    .p5-form_input.-check {
      width: 20px;
      height: 20px;
      margin-right: 16px; }
  .p5-form .-link {
    margin-bottom: 15px; }
    .p5-form .-link a {
      width: 100%;
      max-width: 270px;
      display: block;
      line-height: 1;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      padding: 8px 5%;
      color: white;
      background: color-base09(1);
      border-radius: 100px;
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
      @media all and (min-width: 800px) {
        .p5-form .-link a {
          width: 310px;
          max-width: 310px;
          padding: 10px 20px;
          font-size: 1.7rem; } }
  .p5-form_btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
  .p5-form_btn.-back:hover {
    filter: alpha(opacity=70);
    opacity: 0.7; }
  .p5-form_contactinfo {
    padding: 20px 30px 22px;
    box-sizing: border-box;
    text-align: center;
    background-color: white; }
    @media all and (min-width: 800px) {
      .p5-form_contactinfo {
        padding: 40px 60px 45px; } }

.l-wrap .mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0 !important; }

.l-wrap .mw_wp_form .mwform-checkbox-field {
  line-height: 1; }

.l-wrap .mw_wp_form .errorbox input {
  border-bottom-color: red; }

.l-wrap .mw_wp_form .errorbox textarea {
  border-color: red; }

.l-wrap .mw_wp_form .error {
  display: block;
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 700;
  font-size: 1.12rem;
  line-height: 2;
  letter-spacing: 0.05em;
  color: red; }
  @media all and (min-width: 800px) {
    .l-wrap .mw_wp_form .error {
      font-size: 1.4rem; } }
  @media all and (min-width: 800px) {
    .l-wrap .mw_wp_form .error {
      line-height: 2; } }
  @media all and (min-width: 800px) {
    .l-wrap .mw_wp_form .error {
      padding: 15px 10px; } }

.l-wrap .mw_wp_form.mw_wp_form_input .p5-form_display.-inputpage {
  display: block; }

.l-wrap .mw_wp_form.mw_wp_form_input .p5-form_display.-confirmpage, .l-wrap .mw_wp_form.mw_wp_form_input .p5-form_display.-completepage {
  display: none; }

.l-wrap .mw_wp_form.mw_wp_form_input .p5-form_box.-privacy .error {
  text-align: center; }

.l-wrap .mw_wp_form.mw_wp_form_input .p5-form_btn.-back {
  display: none; }

.l-wrap .mw_wp_form.mw_wp_form_input .p5-form_thx {
  display: none; }

.l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_display.-confirmpage {
  display: block; }

.l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_display.-inputpage, .l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_display.-completepage {
  display: none; }

.l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_ttl.-required:after {
  display: none; }

.l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_ttl.-checkboxttl {
  margin-bottom: 0; }
  @media all and (min-width: 800px) {
    .l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_ttl.-checkboxttl {
      margin-bottom: 0; } }

.l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_inputbox {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #c3c3c3; }

.l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_btn.-confirm, .l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_btn.-top {
  display: none; }

.l-wrap .mw_wp_form.mw_wp_form_confirm .error {
  display: none; }

.l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_box.-privacy {
  display: none; }

.l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_box.-last .p5-form_inputbox {
  border-bottom-style: none; }

.l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_box.-btns {
  padding-top: 10px;
  box-sizing: border-box; }
  @media all and (min-width: 800px) {
    .l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_box.-btns {
      padding-top: 20px; } }
  .l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_box.-btns .p5-form_btn.-submit {
    margin-bottom: 15px; }
    @media all and (min-width: 800px) {
      .l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_box.-btns .p5-form_btn.-submit {
        margin-bottom: 30px; } }

.l-wrap .mw_wp_form.mw_wp_form_confirm .p5-form_thx {
  display: none; }

.l-wrap .mw_wp_form .mw_wp_form_complete .p5-form_display.-completepage {
  display: block; }

.l-wrap .mw_wp_form .mw_wp_form_complete .p5-form_display.-inputpage, .l-wrap .mw_wp_form .mw_wp_form_complete .p5-form_display.-confirmpage {
  display: none; }

.l-wrap .mw_wp_form .mw_wp_form_complete .p5-form_btn.-back, .l-wrap .mw_wp_form .mw_wp_form_complete .p5-form_btn.-confirm {
  display: none; }

.l-wrap .mw_wp_form .mw_wp_form_complete ~ .p5-form_intro {
  display: none; }

.u-pos_absolute {
  position: absolute; }
  .u-pos_absolute.-w100per {
    width: 100%; }

.u-pos_relative {
  position: relative; }
  .u-pos_relative.-w100per {
    width: 100%; }

.u-pos_fixed {
  position: fixed; }

.u-pos_center_h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center; }

.u-pos_center_v {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center; }

.u-pos_center_hv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center; }

.u-pos_ajusthalf_plus {
  transform: translateY(50%); }

.u-pos_ajusthalf_minus {
  transform: translateY(-50%); }

.u-m0 {
  margin: 0px !important; }

.u-mtb0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.u-mlr0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.u-mt0 {
  margin-top: 0px !important; }

.u-mb0 {
  margin-bottom: 0px !important; }

.u-ml0 {
  margin-left: 0px !important; }

.u-mr0 {
  margin-right: 0px !important; }

.u-p0 {
  padding: 0px !important; }

.u-pt0 {
  padding-top: 0px !important; }

.u-pb0 {
  padding-bottom: 0px !important; }

.u-pl0 {
  padding-left: 0px !important; }

.u-pr0 {
  padding-right: 0px !important; }

.u-m5 {
  margin: 5px !important; }

.u-mtb5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.u-mlr5 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.u-mt5 {
  margin-top: 5px !important; }

.u-mb5 {
  margin-bottom: 5px !important; }

.u-ml5 {
  margin-left: 5px !important; }

.u-mr5 {
  margin-right: 5px !important; }

.u-p5 {
  padding: 5px !important; }

.u-pt5 {
  padding-top: 5px !important; }

.u-pb5 {
  padding-bottom: 5px !important; }

.u-pl5 {
  padding-left: 5px !important; }

.u-pr5 {
  padding-right: 5px !important; }

.u-m10 {
  margin: 10px !important; }

.u-mtb10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.u-mlr10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.u-mt10 {
  margin-top: 10px !important; }

.u-mb10 {
  margin-bottom: 10px !important; }

.u-ml10 {
  margin-left: 10px !important; }

.u-mr10 {
  margin-right: 10px !important; }

.u-p10 {
  padding: 10px !important; }

.u-pt10 {
  padding-top: 10px !important; }

.u-pb10 {
  padding-bottom: 10px !important; }

.u-pl10 {
  padding-left: 10px !important; }

.u-pr10 {
  padding-right: 10px !important; }

.u-m15 {
  margin: 15px !important; }

.u-mtb15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.u-mlr15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.u-mt15 {
  margin-top: 15px !important; }

.u-mb15 {
  margin-bottom: 15px !important; }

.u-ml15 {
  margin-left: 15px !important; }

.u-mr15 {
  margin-right: 15px !important; }

.u-p15 {
  padding: 15px !important; }

.u-pt15 {
  padding-top: 15px !important; }

.u-pb15 {
  padding-bottom: 15px !important; }

.u-pl15 {
  padding-left: 15px !important; }

.u-pr15 {
  padding-right: 15px !important; }

.u-m20 {
  margin: 20px !important; }

.u-mtb20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.u-mlr20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.u-mt20 {
  margin-top: 20px !important; }

.u-mb20 {
  margin-bottom: 20px !important; }

.u-ml20 {
  margin-left: 20px !important; }

.u-mr20 {
  margin-right: 20px !important; }

.u-p20 {
  padding: 20px !important; }

.u-pt20 {
  padding-top: 20px !important; }

.u-pb20 {
  padding-bottom: 20px !important; }

.u-pl20 {
  padding-left: 20px !important; }

.u-pr20 {
  padding-right: 20px !important; }

.u-m25 {
  margin: 25px !important; }

.u-mtb25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.u-mlr25 {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.u-mt25 {
  margin-top: 25px !important; }

.u-mb25 {
  margin-bottom: 25px !important; }

.u-ml25 {
  margin-left: 25px !important; }

.u-mr25 {
  margin-right: 25px !important; }

.u-p25 {
  padding: 25px !important; }

.u-pt25 {
  padding-top: 25px !important; }

.u-pb25 {
  padding-bottom: 25px !important; }

.u-pl25 {
  padding-left: 25px !important; }

.u-pr25 {
  padding-right: 25px !important; }

.u-m30 {
  margin: 30px !important; }

.u-mtb30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.u-mlr30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.u-mt30 {
  margin-top: 30px !important; }

.u-mb30 {
  margin-bottom: 30px !important; }

.u-ml30 {
  margin-left: 30px !important; }

.u-mr30 {
  margin-right: 30px !important; }

.u-p30 {
  padding: 30px !important; }

.u-pt30 {
  padding-top: 30px !important; }

.u-pb30 {
  padding-bottom: 30px !important; }

.u-pl30 {
  padding-left: 30px !important; }

.u-pr30 {
  padding-right: 30px !important; }

.u-m35 {
  margin: 35px !important; }

.u-mtb35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important; }

.u-mlr35 {
  margin-left: 35px !important;
  margin-right: 35px !important; }

.u-mt35 {
  margin-top: 35px !important; }

.u-mb35 {
  margin-bottom: 35px !important; }

.u-ml35 {
  margin-left: 35px !important; }

.u-mr35 {
  margin-right: 35px !important; }

.u-p35 {
  padding: 35px !important; }

.u-pt35 {
  padding-top: 35px !important; }

.u-pb35 {
  padding-bottom: 35px !important; }

.u-pl35 {
  padding-left: 35px !important; }

.u-pr35 {
  padding-right: 35px !important; }

.u-m40 {
  margin: 40px !important; }

.u-mtb40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.u-mlr40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.u-mt40 {
  margin-top: 40px !important; }

.u-mb40 {
  margin-bottom: 40px !important; }

.u-ml40 {
  margin-left: 40px !important; }

.u-mr40 {
  margin-right: 40px !important; }

.u-p40 {
  padding: 40px !important; }

.u-pt40 {
  padding-top: 40px !important; }

.u-pb40 {
  padding-bottom: 40px !important; }

.u-pl40 {
  padding-left: 40px !important; }

.u-pr40 {
  padding-right: 40px !important; }

.u-m45 {
  margin: 45px !important; }

.u-mtb45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important; }

.u-mlr45 {
  margin-left: 45px !important;
  margin-right: 45px !important; }

.u-mt45 {
  margin-top: 45px !important; }

.u-mb45 {
  margin-bottom: 45px !important; }

.u-ml45 {
  margin-left: 45px !important; }

.u-mr45 {
  margin-right: 45px !important; }

.u-p45 {
  padding: 45px !important; }

.u-pt45 {
  padding-top: 45px !important; }

.u-pb45 {
  padding-bottom: 45px !important; }

.u-pl45 {
  padding-left: 45px !important; }

.u-pr45 {
  padding-right: 45px !important; }

.u-m50 {
  margin: 50px !important; }

.u-mtb50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.u-mlr50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.u-mt50 {
  margin-top: 50px !important; }

.u-mb50 {
  margin-bottom: 50px !important; }

.u-ml50 {
  margin-left: 50px !important; }

.u-mr50 {
  margin-right: 50px !important; }

.u-p50 {
  padding: 50px !important; }

.u-pt50 {
  padding-top: 50px !important; }

.u-pb50 {
  padding-bottom: 50px !important; }

.u-pl50 {
  padding-left: 50px !important; }

.u-pr50 {
  padding-right: 50px !important; }

.u-m55 {
  margin: 55px !important; }

.u-mtb55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important; }

.u-mlr55 {
  margin-left: 55px !important;
  margin-right: 55px !important; }

.u-mt55 {
  margin-top: 55px !important; }

.u-mb55 {
  margin-bottom: 55px !important; }

.u-ml55 {
  margin-left: 55px !important; }

.u-mr55 {
  margin-right: 55px !important; }

.u-p55 {
  padding: 55px !important; }

.u-pt55 {
  padding-top: 55px !important; }

.u-pb55 {
  padding-bottom: 55px !important; }

.u-pl55 {
  padding-left: 55px !important; }

.u-pr55 {
  padding-right: 55px !important; }

.u-m60 {
  margin: 60px !important; }

.u-mtb60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.u-mlr60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.u-mt60 {
  margin-top: 60px !important; }

.u-mb60 {
  margin-bottom: 60px !important; }

.u-ml60 {
  margin-left: 60px !important; }

.u-mr60 {
  margin-right: 60px !important; }

.u-p60 {
  padding: 60px !important; }

.u-pt60 {
  padding-top: 60px !important; }

.u-pb60 {
  padding-bottom: 60px !important; }

.u-pl60 {
  padding-left: 60px !important; }

.u-pr60 {
  padding-right: 60px !important; }

.u-m65 {
  margin: 65px !important; }

.u-mtb65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important; }

.u-mlr65 {
  margin-left: 65px !important;
  margin-right: 65px !important; }

.u-mt65 {
  margin-top: 65px !important; }

.u-mb65 {
  margin-bottom: 65px !important; }

.u-ml65 {
  margin-left: 65px !important; }

.u-mr65 {
  margin-right: 65px !important; }

.u-p65 {
  padding: 65px !important; }

.u-pt65 {
  padding-top: 65px !important; }

.u-pb65 {
  padding-bottom: 65px !important; }

.u-pl65 {
  padding-left: 65px !important; }

.u-pr65 {
  padding-right: 65px !important; }

.u-m70 {
  margin: 70px !important; }

.u-mtb70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.u-mlr70 {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.u-mt70 {
  margin-top: 70px !important; }

.u-mb70 {
  margin-bottom: 70px !important; }

.u-ml70 {
  margin-left: 70px !important; }

.u-mr70 {
  margin-right: 70px !important; }

.u-p70 {
  padding: 70px !important; }

.u-pt70 {
  padding-top: 70px !important; }

.u-pb70 {
  padding-bottom: 70px !important; }

.u-pl70 {
  padding-left: 70px !important; }

.u-pr70 {
  padding-right: 70px !important; }

.u-m75 {
  margin: 75px !important; }

.u-mtb75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important; }

.u-mlr75 {
  margin-left: 75px !important;
  margin-right: 75px !important; }

.u-mt75 {
  margin-top: 75px !important; }

.u-mb75 {
  margin-bottom: 75px !important; }

.u-ml75 {
  margin-left: 75px !important; }

.u-mr75 {
  margin-right: 75px !important; }

.u-p75 {
  padding: 75px !important; }

.u-pt75 {
  padding-top: 75px !important; }

.u-pb75 {
  padding-bottom: 75px !important; }

.u-pl75 {
  padding-left: 75px !important; }

.u-pr75 {
  padding-right: 75px !important; }

.u-m80 {
  margin: 80px !important; }

.u-mtb80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.u-mlr80 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.u-mt80 {
  margin-top: 80px !important; }

.u-mb80 {
  margin-bottom: 80px !important; }

.u-ml80 {
  margin-left: 80px !important; }

.u-mr80 {
  margin-right: 80px !important; }

.u-p80 {
  padding: 80px !important; }

.u-pt80 {
  padding-top: 80px !important; }

.u-pb80 {
  padding-bottom: 80px !important; }

.u-pl80 {
  padding-left: 80px !important; }

.u-pr80 {
  padding-right: 80px !important; }

.u-m85 {
  margin: 85px !important; }

.u-mtb85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important; }

.u-mlr85 {
  margin-left: 85px !important;
  margin-right: 85px !important; }

.u-mt85 {
  margin-top: 85px !important; }

.u-mb85 {
  margin-bottom: 85px !important; }

.u-ml85 {
  margin-left: 85px !important; }

.u-mr85 {
  margin-right: 85px !important; }

.u-p85 {
  padding: 85px !important; }

.u-pt85 {
  padding-top: 85px !important; }

.u-pb85 {
  padding-bottom: 85px !important; }

.u-pl85 {
  padding-left: 85px !important; }

.u-pr85 {
  padding-right: 85px !important; }

.u-m90 {
  margin: 90px !important; }

.u-mtb90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important; }

.u-mlr90 {
  margin-left: 90px !important;
  margin-right: 90px !important; }

.u-mt90 {
  margin-top: 90px !important; }

.u-mb90 {
  margin-bottom: 90px !important; }

.u-ml90 {
  margin-left: 90px !important; }

.u-mr90 {
  margin-right: 90px !important; }

.u-p90 {
  padding: 90px !important; }

.u-pt90 {
  padding-top: 90px !important; }

.u-pb90 {
  padding-bottom: 90px !important; }

.u-pl90 {
  padding-left: 90px !important; }

.u-pr90 {
  padding-right: 90px !important; }

.u-m95 {
  margin: 95px !important; }

.u-mtb95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important; }

.u-mlr95 {
  margin-left: 95px !important;
  margin-right: 95px !important; }

.u-mt95 {
  margin-top: 95px !important; }

.u-mb95 {
  margin-bottom: 95px !important; }

.u-ml95 {
  margin-left: 95px !important; }

.u-mr95 {
  margin-right: 95px !important; }

.u-p95 {
  padding: 95px !important; }

.u-pt95 {
  padding-top: 95px !important; }

.u-pb95 {
  padding-bottom: 95px !important; }

.u-pl95 {
  padding-left: 95px !important; }

.u-pr95 {
  padding-right: 95px !important; }

.u-m100 {
  margin: 100px !important; }

.u-mtb100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important; }

.u-mlr100 {
  margin-left: 100px !important;
  margin-right: 100px !important; }

.u-mt100 {
  margin-top: 100px !important; }

.u-mb100 {
  margin-bottom: 100px !important; }

.u-ml100 {
  margin-left: 100px !important; }

.u-mr100 {
  margin-right: 100px !important; }

.u-p100 {
  padding: 100px !important; }

.u-pt100 {
  padding-top: 100px !important; }

.u-pb100 {
  padding-bottom: 100px !important; }

.u-pl100 {
  padding-left: 100px !important; }

.u-pr100 {
  padding-right: 100px !important; }

.u-m105 {
  margin: 105px !important; }

.u-mtb105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important; }

.u-mlr105 {
  margin-left: 105px !important;
  margin-right: 105px !important; }

.u-mt105 {
  margin-top: 105px !important; }

.u-mb105 {
  margin-bottom: 105px !important; }

.u-ml105 {
  margin-left: 105px !important; }

.u-mr105 {
  margin-right: 105px !important; }

.u-p105 {
  padding: 105px !important; }

.u-pt105 {
  padding-top: 105px !important; }

.u-pb105 {
  padding-bottom: 105px !important; }

.u-pl105 {
  padding-left: 105px !important; }

.u-pr105 {
  padding-right: 105px !important; }

.u-m110 {
  margin: 110px !important; }

.u-mtb110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important; }

.u-mlr110 {
  margin-left: 110px !important;
  margin-right: 110px !important; }

.u-mt110 {
  margin-top: 110px !important; }

.u-mb110 {
  margin-bottom: 110px !important; }

.u-ml110 {
  margin-left: 110px !important; }

.u-mr110 {
  margin-right: 110px !important; }

.u-p110 {
  padding: 110px !important; }

.u-pt110 {
  padding-top: 110px !important; }

.u-pb110 {
  padding-bottom: 110px !important; }

.u-pl110 {
  padding-left: 110px !important; }

.u-pr110 {
  padding-right: 110px !important; }

.u-m115 {
  margin: 115px !important; }

.u-mtb115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important; }

.u-mlr115 {
  margin-left: 115px !important;
  margin-right: 115px !important; }

.u-mt115 {
  margin-top: 115px !important; }

.u-mb115 {
  margin-bottom: 115px !important; }

.u-ml115 {
  margin-left: 115px !important; }

.u-mr115 {
  margin-right: 115px !important; }

.u-p115 {
  padding: 115px !important; }

.u-pt115 {
  padding-top: 115px !important; }

.u-pb115 {
  padding-bottom: 115px !important; }

.u-pl115 {
  padding-left: 115px !important; }

.u-pr115 {
  padding-right: 115px !important; }

.u-m120 {
  margin: 120px !important; }

.u-mtb120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important; }

.u-mlr120 {
  margin-left: 120px !important;
  margin-right: 120px !important; }

.u-mt120 {
  margin-top: 120px !important; }

.u-mb120 {
  margin-bottom: 120px !important; }

.u-ml120 {
  margin-left: 120px !important; }

.u-mr120 {
  margin-right: 120px !important; }

.u-p120 {
  padding: 120px !important; }

.u-pt120 {
  padding-top: 120px !important; }

.u-pb120 {
  padding-bottom: 120px !important; }

.u-pl120 {
  padding-left: 120px !important; }

.u-pr120 {
  padding-right: 120px !important; }

.u-m125 {
  margin: 125px !important; }

.u-mtb125 {
  margin-top: 125px !important;
  margin-bottom: 125px !important; }

.u-mlr125 {
  margin-left: 125px !important;
  margin-right: 125px !important; }

.u-mt125 {
  margin-top: 125px !important; }

.u-mb125 {
  margin-bottom: 125px !important; }

.u-ml125 {
  margin-left: 125px !important; }

.u-mr125 {
  margin-right: 125px !important; }

.u-p125 {
  padding: 125px !important; }

.u-pt125 {
  padding-top: 125px !important; }

.u-pb125 {
  padding-bottom: 125px !important; }

.u-pl125 {
  padding-left: 125px !important; }

.u-pr125 {
  padding-right: 125px !important; }

.u-m130 {
  margin: 130px !important; }

.u-mtb130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important; }

.u-mlr130 {
  margin-left: 130px !important;
  margin-right: 130px !important; }

.u-mt130 {
  margin-top: 130px !important; }

.u-mb130 {
  margin-bottom: 130px !important; }

.u-ml130 {
  margin-left: 130px !important; }

.u-mr130 {
  margin-right: 130px !important; }

.u-p130 {
  padding: 130px !important; }

.u-pt130 {
  padding-top: 130px !important; }

.u-pb130 {
  padding-bottom: 130px !important; }

.u-pl130 {
  padding-left: 130px !important; }

.u-pr130 {
  padding-right: 130px !important; }

.u-m135 {
  margin: 135px !important; }

.u-mtb135 {
  margin-top: 135px !important;
  margin-bottom: 135px !important; }

.u-mlr135 {
  margin-left: 135px !important;
  margin-right: 135px !important; }

.u-mt135 {
  margin-top: 135px !important; }

.u-mb135 {
  margin-bottom: 135px !important; }

.u-ml135 {
  margin-left: 135px !important; }

.u-mr135 {
  margin-right: 135px !important; }

.u-p135 {
  padding: 135px !important; }

.u-pt135 {
  padding-top: 135px !important; }

.u-pb135 {
  padding-bottom: 135px !important; }

.u-pl135 {
  padding-left: 135px !important; }

.u-pr135 {
  padding-right: 135px !important; }

.u-m140 {
  margin: 140px !important; }

.u-mtb140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important; }

.u-mlr140 {
  margin-left: 140px !important;
  margin-right: 140px !important; }

.u-mt140 {
  margin-top: 140px !important; }

.u-mb140 {
  margin-bottom: 140px !important; }

.u-ml140 {
  margin-left: 140px !important; }

.u-mr140 {
  margin-right: 140px !important; }

.u-p140 {
  padding: 140px !important; }

.u-pt140 {
  padding-top: 140px !important; }

.u-pb140 {
  padding-bottom: 140px !important; }

.u-pl140 {
  padding-left: 140px !important; }

.u-pr140 {
  padding-right: 140px !important; }

.u-m145 {
  margin: 145px !important; }

.u-mtb145 {
  margin-top: 145px !important;
  margin-bottom: 145px !important; }

.u-mlr145 {
  margin-left: 145px !important;
  margin-right: 145px !important; }

.u-mt145 {
  margin-top: 145px !important; }

.u-mb145 {
  margin-bottom: 145px !important; }

.u-ml145 {
  margin-left: 145px !important; }

.u-mr145 {
  margin-right: 145px !important; }

.u-p145 {
  padding: 145px !important; }

.u-pt145 {
  padding-top: 145px !important; }

.u-pb145 {
  padding-bottom: 145px !important; }

.u-pl145 {
  padding-left: 145px !important; }

.u-pr145 {
  padding-right: 145px !important; }

.u-m150 {
  margin: 150px !important; }

.u-mtb150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important; }

.u-mlr150 {
  margin-left: 150px !important;
  margin-right: 150px !important; }

.u-mt150 {
  margin-top: 150px !important; }

.u-mb150 {
  margin-bottom: 150px !important; }

.u-ml150 {
  margin-left: 150px !important; }

.u-mr150 {
  margin-right: 150px !important; }

.u-p150 {
  padding: 150px !important; }

.u-pt150 {
  padding-top: 150px !important; }

.u-pb150 {
  padding-bottom: 150px !important; }

.u-pl150 {
  padding-left: 150px !important; }

.u-pr150 {
  padding-right: 150px !important; }

.u-m155 {
  margin: 155px !important; }

.u-mtb155 {
  margin-top: 155px !important;
  margin-bottom: 155px !important; }

.u-mlr155 {
  margin-left: 155px !important;
  margin-right: 155px !important; }

.u-mt155 {
  margin-top: 155px !important; }

.u-mb155 {
  margin-bottom: 155px !important; }

.u-ml155 {
  margin-left: 155px !important; }

.u-mr155 {
  margin-right: 155px !important; }

.u-p155 {
  padding: 155px !important; }

.u-pt155 {
  padding-top: 155px !important; }

.u-pb155 {
  padding-bottom: 155px !important; }

.u-pl155 {
  padding-left: 155px !important; }

.u-pr155 {
  padding-right: 155px !important; }

.u-m160 {
  margin: 160px !important; }

.u-mtb160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important; }

.u-mlr160 {
  margin-left: 160px !important;
  margin-right: 160px !important; }

.u-mt160 {
  margin-top: 160px !important; }

.u-mb160 {
  margin-bottom: 160px !important; }

.u-ml160 {
  margin-left: 160px !important; }

.u-mr160 {
  margin-right: 160px !important; }

.u-p160 {
  padding: 160px !important; }

.u-pt160 {
  padding-top: 160px !important; }

.u-pb160 {
  padding-bottom: 160px !important; }

.u-pl160 {
  padding-left: 160px !important; }

.u-pr160 {
  padding-right: 160px !important; }

.u-m165 {
  margin: 165px !important; }

.u-mtb165 {
  margin-top: 165px !important;
  margin-bottom: 165px !important; }

.u-mlr165 {
  margin-left: 165px !important;
  margin-right: 165px !important; }

.u-mt165 {
  margin-top: 165px !important; }

.u-mb165 {
  margin-bottom: 165px !important; }

.u-ml165 {
  margin-left: 165px !important; }

.u-mr165 {
  margin-right: 165px !important; }

.u-p165 {
  padding: 165px !important; }

.u-pt165 {
  padding-top: 165px !important; }

.u-pb165 {
  padding-bottom: 165px !important; }

.u-pl165 {
  padding-left: 165px !important; }

.u-pr165 {
  padding-right: 165px !important; }

.u-m170 {
  margin: 170px !important; }

.u-mtb170 {
  margin-top: 170px !important;
  margin-bottom: 170px !important; }

.u-mlr170 {
  margin-left: 170px !important;
  margin-right: 170px !important; }

.u-mt170 {
  margin-top: 170px !important; }

.u-mb170 {
  margin-bottom: 170px !important; }

.u-ml170 {
  margin-left: 170px !important; }

.u-mr170 {
  margin-right: 170px !important; }

.u-p170 {
  padding: 170px !important; }

.u-pt170 {
  padding-top: 170px !important; }

.u-pb170 {
  padding-bottom: 170px !important; }

.u-pl170 {
  padding-left: 170px !important; }

.u-pr170 {
  padding-right: 170px !important; }

.u-m175 {
  margin: 175px !important; }

.u-mtb175 {
  margin-top: 175px !important;
  margin-bottom: 175px !important; }

.u-mlr175 {
  margin-left: 175px !important;
  margin-right: 175px !important; }

.u-mt175 {
  margin-top: 175px !important; }

.u-mb175 {
  margin-bottom: 175px !important; }

.u-ml175 {
  margin-left: 175px !important; }

.u-mr175 {
  margin-right: 175px !important; }

.u-p175 {
  padding: 175px !important; }

.u-pt175 {
  padding-top: 175px !important; }

.u-pb175 {
  padding-bottom: 175px !important; }

.u-pl175 {
  padding-left: 175px !important; }

.u-pr175 {
  padding-right: 175px !important; }

.u-m180 {
  margin: 180px !important; }

.u-mtb180 {
  margin-top: 180px !important;
  margin-bottom: 180px !important; }

.u-mlr180 {
  margin-left: 180px !important;
  margin-right: 180px !important; }

.u-mt180 {
  margin-top: 180px !important; }

.u-mb180 {
  margin-bottom: 180px !important; }

.u-ml180 {
  margin-left: 180px !important; }

.u-mr180 {
  margin-right: 180px !important; }

.u-p180 {
  padding: 180px !important; }

.u-pt180 {
  padding-top: 180px !important; }

.u-pb180 {
  padding-bottom: 180px !important; }

.u-pl180 {
  padding-left: 180px !important; }

.u-pr180 {
  padding-right: 180px !important; }

.u-m185 {
  margin: 185px !important; }

.u-mtb185 {
  margin-top: 185px !important;
  margin-bottom: 185px !important; }

.u-mlr185 {
  margin-left: 185px !important;
  margin-right: 185px !important; }

.u-mt185 {
  margin-top: 185px !important; }

.u-mb185 {
  margin-bottom: 185px !important; }

.u-ml185 {
  margin-left: 185px !important; }

.u-mr185 {
  margin-right: 185px !important; }

.u-p185 {
  padding: 185px !important; }

.u-pt185 {
  padding-top: 185px !important; }

.u-pb185 {
  padding-bottom: 185px !important; }

.u-pl185 {
  padding-left: 185px !important; }

.u-pr185 {
  padding-right: 185px !important; }

.u-m190 {
  margin: 190px !important; }

.u-mtb190 {
  margin-top: 190px !important;
  margin-bottom: 190px !important; }

.u-mlr190 {
  margin-left: 190px !important;
  margin-right: 190px !important; }

.u-mt190 {
  margin-top: 190px !important; }

.u-mb190 {
  margin-bottom: 190px !important; }

.u-ml190 {
  margin-left: 190px !important; }

.u-mr190 {
  margin-right: 190px !important; }

.u-p190 {
  padding: 190px !important; }

.u-pt190 {
  padding-top: 190px !important; }

.u-pb190 {
  padding-bottom: 190px !important; }

.u-pl190 {
  padding-left: 190px !important; }

.u-pr190 {
  padding-right: 190px !important; }

.u-m195 {
  margin: 195px !important; }

.u-mtb195 {
  margin-top: 195px !important;
  margin-bottom: 195px !important; }

.u-mlr195 {
  margin-left: 195px !important;
  margin-right: 195px !important; }

.u-mt195 {
  margin-top: 195px !important; }

.u-mb195 {
  margin-bottom: 195px !important; }

.u-ml195 {
  margin-left: 195px !important; }

.u-mr195 {
  margin-right: 195px !important; }

.u-p195 {
  padding: 195px !important; }

.u-pt195 {
  padding-top: 195px !important; }

.u-pb195 {
  padding-bottom: 195px !important; }

.u-pl195 {
  padding-left: 195px !important; }

.u-pr195 {
  padding-right: 195px !important; }

.u-m200 {
  margin: 200px !important; }

.u-mtb200 {
  margin-top: 200px !important;
  margin-bottom: 200px !important; }

.u-mlr200 {
  margin-left: 200px !important;
  margin-right: 200px !important; }

.u-mt200 {
  margin-top: 200px !important; }

.u-mb200 {
  margin-bottom: 200px !important; }

.u-ml200 {
  margin-left: 200px !important; }

.u-mr200 {
  margin-right: 200px !important; }

.u-p200 {
  padding: 200px !important; }

.u-pt200 {
  padding-top: 200px !important; }

.u-pb200 {
  padding-bottom: 200px !important; }

.u-pl200 {
  padding-left: 200px !important; }

.u-pr200 {
  padding-right: 200px !important; }

.u-m205 {
  margin: 205px !important; }

.u-mtb205 {
  margin-top: 205px !important;
  margin-bottom: 205px !important; }

.u-mlr205 {
  margin-left: 205px !important;
  margin-right: 205px !important; }

.u-mt205 {
  margin-top: 205px !important; }

.u-mb205 {
  margin-bottom: 205px !important; }

.u-ml205 {
  margin-left: 205px !important; }

.u-mr205 {
  margin-right: 205px !important; }

.u-p205 {
  padding: 205px !important; }

.u-pt205 {
  padding-top: 205px !important; }

.u-pb205 {
  padding-bottom: 205px !important; }

.u-pl205 {
  padding-left: 205px !important; }

.u-pr205 {
  padding-right: 205px !important; }

.u-m210 {
  margin: 210px !important; }

.u-mtb210 {
  margin-top: 210px !important;
  margin-bottom: 210px !important; }

.u-mlr210 {
  margin-left: 210px !important;
  margin-right: 210px !important; }

.u-mt210 {
  margin-top: 210px !important; }

.u-mb210 {
  margin-bottom: 210px !important; }

.u-ml210 {
  margin-left: 210px !important; }

.u-mr210 {
  margin-right: 210px !important; }

.u-p210 {
  padding: 210px !important; }

.u-pt210 {
  padding-top: 210px !important; }

.u-pb210 {
  padding-bottom: 210px !important; }

.u-pl210 {
  padding-left: 210px !important; }

.u-pr210 {
  padding-right: 210px !important; }

.u-m215 {
  margin: 215px !important; }

.u-mtb215 {
  margin-top: 215px !important;
  margin-bottom: 215px !important; }

.u-mlr215 {
  margin-left: 215px !important;
  margin-right: 215px !important; }

.u-mt215 {
  margin-top: 215px !important; }

.u-mb215 {
  margin-bottom: 215px !important; }

.u-ml215 {
  margin-left: 215px !important; }

.u-mr215 {
  margin-right: 215px !important; }

.u-p215 {
  padding: 215px !important; }

.u-pt215 {
  padding-top: 215px !important; }

.u-pb215 {
  padding-bottom: 215px !important; }

.u-pl215 {
  padding-left: 215px !important; }

.u-pr215 {
  padding-right: 215px !important; }

.u-m220 {
  margin: 220px !important; }

.u-mtb220 {
  margin-top: 220px !important;
  margin-bottom: 220px !important; }

.u-mlr220 {
  margin-left: 220px !important;
  margin-right: 220px !important; }

.u-mt220 {
  margin-top: 220px !important; }

.u-mb220 {
  margin-bottom: 220px !important; }

.u-ml220 {
  margin-left: 220px !important; }

.u-mr220 {
  margin-right: 220px !important; }

.u-p220 {
  padding: 220px !important; }

.u-pt220 {
  padding-top: 220px !important; }

.u-pb220 {
  padding-bottom: 220px !important; }

.u-pl220 {
  padding-left: 220px !important; }

.u-pr220 {
  padding-right: 220px !important; }

.u-m225 {
  margin: 225px !important; }

.u-mtb225 {
  margin-top: 225px !important;
  margin-bottom: 225px !important; }

.u-mlr225 {
  margin-left: 225px !important;
  margin-right: 225px !important; }

.u-mt225 {
  margin-top: 225px !important; }

.u-mb225 {
  margin-bottom: 225px !important; }

.u-ml225 {
  margin-left: 225px !important; }

.u-mr225 {
  margin-right: 225px !important; }

.u-p225 {
  padding: 225px !important; }

.u-pt225 {
  padding-top: 225px !important; }

.u-pb225 {
  padding-bottom: 225px !important; }

.u-pl225 {
  padding-left: 225px !important; }

.u-pr225 {
  padding-right: 225px !important; }

.u-m230 {
  margin: 230px !important; }

.u-mtb230 {
  margin-top: 230px !important;
  margin-bottom: 230px !important; }

.u-mlr230 {
  margin-left: 230px !important;
  margin-right: 230px !important; }

.u-mt230 {
  margin-top: 230px !important; }

.u-mb230 {
  margin-bottom: 230px !important; }

.u-ml230 {
  margin-left: 230px !important; }

.u-mr230 {
  margin-right: 230px !important; }

.u-p230 {
  padding: 230px !important; }

.u-pt230 {
  padding-top: 230px !important; }

.u-pb230 {
  padding-bottom: 230px !important; }

.u-pl230 {
  padding-left: 230px !important; }

.u-pr230 {
  padding-right: 230px !important; }

.u-m235 {
  margin: 235px !important; }

.u-mtb235 {
  margin-top: 235px !important;
  margin-bottom: 235px !important; }

.u-mlr235 {
  margin-left: 235px !important;
  margin-right: 235px !important; }

.u-mt235 {
  margin-top: 235px !important; }

.u-mb235 {
  margin-bottom: 235px !important; }

.u-ml235 {
  margin-left: 235px !important; }

.u-mr235 {
  margin-right: 235px !important; }

.u-p235 {
  padding: 235px !important; }

.u-pt235 {
  padding-top: 235px !important; }

.u-pb235 {
  padding-bottom: 235px !important; }

.u-pl235 {
  padding-left: 235px !important; }

.u-pr235 {
  padding-right: 235px !important; }

.u-m240 {
  margin: 240px !important; }

.u-mtb240 {
  margin-top: 240px !important;
  margin-bottom: 240px !important; }

.u-mlr240 {
  margin-left: 240px !important;
  margin-right: 240px !important; }

.u-mt240 {
  margin-top: 240px !important; }

.u-mb240 {
  margin-bottom: 240px !important; }

.u-ml240 {
  margin-left: 240px !important; }

.u-mr240 {
  margin-right: 240px !important; }

.u-p240 {
  padding: 240px !important; }

.u-pt240 {
  padding-top: 240px !important; }

.u-pb240 {
  padding-bottom: 240px !important; }

.u-pl240 {
  padding-left: 240px !important; }

.u-pr240 {
  padding-right: 240px !important; }

.u-m-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.u-m-pcAuto-spLeft {
  margin-left: 0 !important;
  margin-right: auto !important; }
  @media all and (min-width: 800px) {
    .u-m-pcAuto-spLeft {
      margin-left: auto !important;
      margin-right: auto !important; } }

.u-m-pcAuto-spRight {
  margin-left: auto !important;
  margin-right: 0 !important; }
  @media all and (min-width: 800px) {
    .u-m-pcAuto-spRight {
      margin-left: auto !important;
      margin-right: auto !important; } }

.u-mr-01em {
  margin-right: calc(0.1em); }

.u-mb5_half {
  margin-bottom: 2.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb5_half {
      margin-bottom: 5px !important; } }

.u-mb10_half {
  margin-bottom: 5px !important; }
  @media all and (min-width: 800px) {
    .u-mb10_half {
      margin-bottom: 10px !important; } }

.u-mb15_half {
  margin-bottom: 7.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb15_half {
      margin-bottom: 15px !important; } }

.u-mb20_half {
  margin-bottom: 10px !important; }
  @media all and (min-width: 800px) {
    .u-mb20_half {
      margin-bottom: 20px !important; } }

.u-mb25_half {
  margin-bottom: 12.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb25_half {
      margin-bottom: 25px !important; } }

.u-mb30_half {
  margin-bottom: 15px !important; }
  @media all and (min-width: 800px) {
    .u-mb30_half {
      margin-bottom: 30px !important; } }

.u-mb35_half {
  margin-bottom: 17.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb35_half {
      margin-bottom: 35px !important; } }

.u-mb40_half {
  margin-bottom: 20px !important; }
  @media all and (min-width: 800px) {
    .u-mb40_half {
      margin-bottom: 40px !important; } }

.u-mb45_half {
  margin-bottom: 22.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb45_half {
      margin-bottom: 45px !important; } }

.u-mb50_half {
  margin-bottom: 25px !important; }
  @media all and (min-width: 800px) {
    .u-mb50_half {
      margin-bottom: 50px !important; } }

.u-mb55_half {
  margin-bottom: 27.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb55_half {
      margin-bottom: 55px !important; } }

.u-mb60_half {
  margin-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .u-mb60_half {
      margin-bottom: 60px !important; } }

.u-mb65_half {
  margin-bottom: 32.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb65_half {
      margin-bottom: 65px !important; } }

.u-mb70_half {
  margin-bottom: 35px !important; }
  @media all and (min-width: 800px) {
    .u-mb70_half {
      margin-bottom: 70px !important; } }

.u-mb75_half {
  margin-bottom: 37.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb75_half {
      margin-bottom: 75px !important; } }

.u-mb80_half {
  margin-bottom: 40px !important; }
  @media all and (min-width: 800px) {
    .u-mb80_half {
      margin-bottom: 80px !important; } }

.u-mb85_half {
  margin-bottom: 42.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb85_half {
      margin-bottom: 85px !important; } }

.u-mb90_half {
  margin-bottom: 45px !important; }
  @media all and (min-width: 800px) {
    .u-mb90_half {
      margin-bottom: 90px !important; } }

.u-mb95_half {
  margin-bottom: 47.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb95_half {
      margin-bottom: 95px !important; } }

.u-mb100_half {
  margin-bottom: 50px !important; }
  @media all and (min-width: 800px) {
    .u-mb100_half {
      margin-bottom: 100px !important; } }

.u-mb105_half {
  margin-bottom: 52.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb105_half {
      margin-bottom: 105px !important; } }

.u-mb110_half {
  margin-bottom: 55px !important; }
  @media all and (min-width: 800px) {
    .u-mb110_half {
      margin-bottom: 110px !important; } }

.u-mb115_half {
  margin-bottom: 57.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb115_half {
      margin-bottom: 115px !important; } }

.u-mb120_half {
  margin-bottom: 60px !important; }
  @media all and (min-width: 800px) {
    .u-mb120_half {
      margin-bottom: 120px !important; } }

.u-mb125_half {
  margin-bottom: 62.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb125_half {
      margin-bottom: 125px !important; } }

.u-mb130_half {
  margin-bottom: 65px !important; }
  @media all and (min-width: 800px) {
    .u-mb130_half {
      margin-bottom: 130px !important; } }

.u-mb135_half {
  margin-bottom: 67.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb135_half {
      margin-bottom: 135px !important; } }

.u-mb140_half {
  margin-bottom: 70px !important; }
  @media all and (min-width: 800px) {
    .u-mb140_half {
      margin-bottom: 140px !important; } }

.u-mb145_half {
  margin-bottom: 72.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb145_half {
      margin-bottom: 145px !important; } }

.u-mb150_half {
  margin-bottom: 75px !important; }
  @media all and (min-width: 800px) {
    .u-mb150_half {
      margin-bottom: 150px !important; } }

.u-mb155_half {
  margin-bottom: 77.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb155_half {
      margin-bottom: 155px !important; } }

.u-mb160_half {
  margin-bottom: 80px !important; }
  @media all and (min-width: 800px) {
    .u-mb160_half {
      margin-bottom: 160px !important; } }

.u-mb165_half {
  margin-bottom: 82.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb165_half {
      margin-bottom: 165px !important; } }

.u-mb170_half {
  margin-bottom: 85px !important; }
  @media all and (min-width: 800px) {
    .u-mb170_half {
      margin-bottom: 170px !important; } }

.u-mb175_half {
  margin-bottom: 87.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb175_half {
      margin-bottom: 175px !important; } }

.u-mb180_half {
  margin-bottom: 90px !important; }
  @media all and (min-width: 800px) {
    .u-mb180_half {
      margin-bottom: 180px !important; } }

.u-mb185_half {
  margin-bottom: 92.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb185_half {
      margin-bottom: 185px !important; } }

.u-mb190_half {
  margin-bottom: 95px !important; }
  @media all and (min-width: 800px) {
    .u-mb190_half {
      margin-bottom: 190px !important; } }

.u-mb195_half {
  margin-bottom: 97.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb195_half {
      margin-bottom: 195px !important; } }

.u-mb200_half {
  margin-bottom: 100px !important; }
  @media all and (min-width: 800px) {
    .u-mb200_half {
      margin-bottom: 200px !important; } }

.u-mb205_half {
  margin-bottom: 102.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb205_half {
      margin-bottom: 205px !important; } }

.u-mb210_half {
  margin-bottom: 105px !important; }
  @media all and (min-width: 800px) {
    .u-mb210_half {
      margin-bottom: 210px !important; } }

.u-mb215_half {
  margin-bottom: 107.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb215_half {
      margin-bottom: 215px !important; } }

.u-mb220_half {
  margin-bottom: 110px !important; }
  @media all and (min-width: 800px) {
    .u-mb220_half {
      margin-bottom: 220px !important; } }

.u-mb225_half {
  margin-bottom: 112.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb225_half {
      margin-bottom: 225px !important; } }

.u-mb230_half {
  margin-bottom: 115px !important; }
  @media all and (min-width: 800px) {
    .u-mb230_half {
      margin-bottom: 230px !important; } }

.u-mb235_half {
  margin-bottom: 117.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb235_half {
      margin-bottom: 235px !important; } }

.u-mb240_half {
  margin-bottom: 120px !important; }
  @media all and (min-width: 800px) {
    .u-mb240_half {
      margin-bottom: 240px !important; } }

.u-mb15_23rd {
  margin-bottom: 10px !important; }
  @media all and (min-width: 800px) {
    .u-mb15_23rd {
      margin-bottom: 15px !important; } }

.u-mb30_23rd {
  margin-bottom: 20px !important; }
  @media all and (min-width: 800px) {
    .u-mb30_23rd {
      margin-bottom: 30px !important; } }

.u-mb45_23rd {
  margin-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .u-mb45_23rd {
      margin-bottom: 45px !important; } }

.u-mb60_23rd {
  margin-bottom: 40px !important; }
  @media all and (min-width: 800px) {
    .u-mb60_23rd {
      margin-bottom: 60px !important; } }

.u-mb75_23rd {
  margin-bottom: 50px !important; }
  @media all and (min-width: 800px) {
    .u-mb75_23rd {
      margin-bottom: 75px !important; } }

.u-mb90_23rd {
  margin-bottom: 60px !important; }
  @media all and (min-width: 800px) {
    .u-mb90_23rd {
      margin-bottom: 90px !important; } }

.u-mb105_23rd {
  margin-bottom: 70px !important; }
  @media all and (min-width: 800px) {
    .u-mb105_23rd {
      margin-bottom: 105px !important; } }

.u-mb120_23rd {
  margin-bottom: 80px !important; }
  @media all and (min-width: 800px) {
    .u-mb120_23rd {
      margin-bottom: 120px !important; } }

.u-mb135_23rd {
  margin-bottom: 90px !important; }
  @media all and (min-width: 800px) {
    .u-mb135_23rd {
      margin-bottom: 135px !important; } }

.u-mb150_23rd {
  margin-bottom: 100px !important; }
  @media all and (min-width: 800px) {
    .u-mb150_23rd {
      margin-bottom: 150px !important; } }

.u-mb165_23rd {
  margin-bottom: 110px !important; }
  @media all and (min-width: 800px) {
    .u-mb165_23rd {
      margin-bottom: 165px !important; } }

.u-mb180_23rd {
  margin-bottom: 120px !important; }
  @media all and (min-width: 800px) {
    .u-mb180_23rd {
      margin-bottom: 180px !important; } }

.u-mb195_23rd {
  margin-bottom: 130px !important; }
  @media all and (min-width: 800px) {
    .u-mb195_23rd {
      margin-bottom: 195px !important; } }

.u-mb210_23rd {
  margin-bottom: 140px !important; }
  @media all and (min-width: 800px) {
    .u-mb210_23rd {
      margin-bottom: 210px !important; } }

.u-mb225_23rd {
  margin-bottom: 150px !important; }
  @media all and (min-width: 800px) {
    .u-mb225_23rd {
      margin-bottom: 225px !important; } }

.u-mb240_23rd {
  margin-bottom: 160px !important; }
  @media all and (min-width: 800px) {
    .u-mb240_23rd {
      margin-bottom: 240px !important; } }

.u-mb255_23rd {
  margin-bottom: 170px !important; }
  @media all and (min-width: 800px) {
    .u-mb255_23rd {
      margin-bottom: 255px !important; } }

.u-mb270_23rd {
  margin-bottom: 180px !important; }
  @media all and (min-width: 800px) {
    .u-mb270_23rd {
      margin-bottom: 270px !important; } }

.u-mb10_level {
  margin-bottom: 3.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb10_level {
      margin-bottom: 5px !important; } }
  @media all and (min-width: 800px) {
    .u-mb10_level {
      margin-bottom: 10px !important; } }

.u-mb20_level {
  margin-bottom: 6.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb20_level {
      margin-bottom: 10px !important; } }
  @media all and (min-width: 800px) {
    .u-mb20_level {
      margin-bottom: 20px !important; } }

.u-mb30_level {
  margin-bottom: 10px !important; }
  @media all and (min-width: 500px) {
    .u-mb30_level {
      margin-bottom: 15px !important; } }
  @media all and (min-width: 800px) {
    .u-mb30_level {
      margin-bottom: 30px !important; } }

.u-mb40_level {
  margin-bottom: 13.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb40_level {
      margin-bottom: 20px !important; } }
  @media all and (min-width: 800px) {
    .u-mb40_level {
      margin-bottom: 40px !important; } }

.u-mb50_level {
  margin-bottom: 16.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb50_level {
      margin-bottom: 25px !important; } }
  @media all and (min-width: 800px) {
    .u-mb50_level {
      margin-bottom: 50px !important; } }

.u-mb60_level {
  margin-bottom: 20px !important; }
  @media all and (min-width: 500px) {
    .u-mb60_level {
      margin-bottom: 30px !important; } }
  @media all and (min-width: 800px) {
    .u-mb60_level {
      margin-bottom: 60px !important; } }

.u-mb70_level {
  margin-bottom: 23.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb70_level {
      margin-bottom: 35px !important; } }
  @media all and (min-width: 800px) {
    .u-mb70_level {
      margin-bottom: 70px !important; } }

.u-mb80_level {
  margin-bottom: 26.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb80_level {
      margin-bottom: 40px !important; } }
  @media all and (min-width: 800px) {
    .u-mb80_level {
      margin-bottom: 80px !important; } }

.u-mb90_level {
  margin-bottom: 30px !important; }
  @media all and (min-width: 500px) {
    .u-mb90_level {
      margin-bottom: 45px !important; } }
  @media all and (min-width: 800px) {
    .u-mb90_level {
      margin-bottom: 90px !important; } }

.u-mb100_level {
  margin-bottom: 33.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb100_level {
      margin-bottom: 50px !important; } }
  @media all and (min-width: 800px) {
    .u-mb100_level {
      margin-bottom: 100px !important; } }

.u-mb110_level {
  margin-bottom: 36.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb110_level {
      margin-bottom: 55px !important; } }
  @media all and (min-width: 800px) {
    .u-mb110_level {
      margin-bottom: 110px !important; } }

.u-mb120_level {
  margin-bottom: 40px !important; }
  @media all and (min-width: 500px) {
    .u-mb120_level {
      margin-bottom: 60px !important; } }
  @media all and (min-width: 800px) {
    .u-mb120_level {
      margin-bottom: 120px !important; } }

.u-mb130_level {
  margin-bottom: 43.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb130_level {
      margin-bottom: 65px !important; } }
  @media all and (min-width: 800px) {
    .u-mb130_level {
      margin-bottom: 130px !important; } }

.u-mb140_level {
  margin-bottom: 46.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb140_level {
      margin-bottom: 70px !important; } }
  @media all and (min-width: 800px) {
    .u-mb140_level {
      margin-bottom: 140px !important; } }

.u-mb150_level {
  margin-bottom: 50px !important; }
  @media all and (min-width: 500px) {
    .u-mb150_level {
      margin-bottom: 75px !important; } }
  @media all and (min-width: 800px) {
    .u-mb150_level {
      margin-bottom: 150px !important; } }

.u-mb160_level {
  margin-bottom: 53.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb160_level {
      margin-bottom: 80px !important; } }
  @media all and (min-width: 800px) {
    .u-mb160_level {
      margin-bottom: 160px !important; } }

.u-mb170_level {
  margin-bottom: 56.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb170_level {
      margin-bottom: 85px !important; } }
  @media all and (min-width: 800px) {
    .u-mb170_level {
      margin-bottom: 170px !important; } }

.u-mb180_level {
  margin-bottom: 60px !important; }
  @media all and (min-width: 500px) {
    .u-mb180_level {
      margin-bottom: 90px !important; } }
  @media all and (min-width: 800px) {
    .u-mb180_level {
      margin-bottom: 180px !important; } }

.u-mb-minus5_half {
  margin-bottom: -2.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus5_half {
      margin-bottom: -5px !important; } }

.u-mb-minus10_half {
  margin-bottom: -5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus10_half {
      margin-bottom: -10px !important; } }

.u-mb-minus15_half {
  margin-bottom: -7.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus15_half {
      margin-bottom: -15px !important; } }

.u-mb-minus20_half {
  margin-bottom: -10px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus20_half {
      margin-bottom: -20px !important; } }

.u-mb-minus25_half {
  margin-bottom: -12.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus25_half {
      margin-bottom: -25px !important; } }

.u-mb-minus30_half {
  margin-bottom: -15px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus30_half {
      margin-bottom: -30px !important; } }

.u-mb-minus35_half {
  margin-bottom: -17.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus35_half {
      margin-bottom: -35px !important; } }

.u-mb-minus40_half {
  margin-bottom: -20px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus40_half {
      margin-bottom: -40px !important; } }

.u-mb-minus45_half {
  margin-bottom: -22.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus45_half {
      margin-bottom: -45px !important; } }

.u-mb-minus50_half {
  margin-bottom: -25px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus50_half {
      margin-bottom: -50px !important; } }

.u-mb-minus55_half {
  margin-bottom: -27.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus55_half {
      margin-bottom: -55px !important; } }

.u-mb-minus60_half {
  margin-bottom: -30px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus60_half {
      margin-bottom: -60px !important; } }

.u-mb-minus65_half {
  margin-bottom: -32.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus65_half {
      margin-bottom: -65px !important; } }

.u-mb-minus70_half {
  margin-bottom: -35px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus70_half {
      margin-bottom: -70px !important; } }

.u-mb-minus75_half {
  margin-bottom: -37.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus75_half {
      margin-bottom: -75px !important; } }

.u-mb-minus80_half {
  margin-bottom: -40px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus80_half {
      margin-bottom: -80px !important; } }

.u-mb-minus85_half {
  margin-bottom: -42.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus85_half {
      margin-bottom: -85px !important; } }

.u-mb-minus90_half {
  margin-bottom: -45px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus90_half {
      margin-bottom: -90px !important; } }

.u-mb-minus95_half {
  margin-bottom: -47.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus95_half {
      margin-bottom: -95px !important; } }

.u-mb-minus100_half {
  margin-bottom: -50px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus100_half {
      margin-bottom: -100px !important; } }

.u-mb-minus105_half {
  margin-bottom: -52.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus105_half {
      margin-bottom: -105px !important; } }

.u-mb-minus110_half {
  margin-bottom: -55px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus110_half {
      margin-bottom: -110px !important; } }

.u-mb-minus115_half {
  margin-bottom: -57.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus115_half {
      margin-bottom: -115px !important; } }

.u-mb-minus120_half {
  margin-bottom: -60px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus120_half {
      margin-bottom: -120px !important; } }

.u-mb-minus125_half {
  margin-bottom: -62.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus125_half {
      margin-bottom: -125px !important; } }

.u-mb-minus130_half {
  margin-bottom: -65px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus130_half {
      margin-bottom: -130px !important; } }

.u-mb-minus135_half {
  margin-bottom: -67.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus135_half {
      margin-bottom: -135px !important; } }

.u-mb-minus140_half {
  margin-bottom: -70px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus140_half {
      margin-bottom: -140px !important; } }

.u-mb-minus145_half {
  margin-bottom: -72.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus145_half {
      margin-bottom: -145px !important; } }

.u-mb-minus150_half {
  margin-bottom: -75px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus150_half {
      margin-bottom: -150px !important; } }

.u-mb-minus155_half {
  margin-bottom: -77.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus155_half {
      margin-bottom: -155px !important; } }

.u-mb-minus160_half {
  margin-bottom: -80px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus160_half {
      margin-bottom: -160px !important; } }

.u-mb-minus165_half {
  margin-bottom: -82.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus165_half {
      margin-bottom: -165px !important; } }

.u-mb-minus170_half {
  margin-bottom: -85px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus170_half {
      margin-bottom: -170px !important; } }

.u-mb-minus175_half {
  margin-bottom: -87.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus175_half {
      margin-bottom: -175px !important; } }

.u-mb-minus180_half {
  margin-bottom: -90px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus180_half {
      margin-bottom: -180px !important; } }

.u-mb-minus185_half {
  margin-bottom: -92.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus185_half {
      margin-bottom: -185px !important; } }

.u-mb-minus190_half {
  margin-bottom: -95px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus190_half {
      margin-bottom: -190px !important; } }

.u-mb-minus195_half {
  margin-bottom: -97.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus195_half {
      margin-bottom: -195px !important; } }

.u-mb-minus200_half {
  margin-bottom: -100px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus200_half {
      margin-bottom: -200px !important; } }

.u-mb-minus205_half {
  margin-bottom: -102.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus205_half {
      margin-bottom: -205px !important; } }

.u-mb-minus210_half {
  margin-bottom: -105px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus210_half {
      margin-bottom: -210px !important; } }

.u-mb-minus215_half {
  margin-bottom: -107.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus215_half {
      margin-bottom: -215px !important; } }

.u-mb-minus220_half {
  margin-bottom: -110px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus220_half {
      margin-bottom: -220px !important; } }

.u-mb-minus225_half {
  margin-bottom: -112.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus225_half {
      margin-bottom: -225px !important; } }

.u-mb-minus230_half {
  margin-bottom: -115px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus230_half {
      margin-bottom: -230px !important; } }

.u-mb-minus235_half {
  margin-bottom: -117.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus235_half {
      margin-bottom: -235px !important; } }

.u-mb-minus240_half {
  margin-bottom: -120px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus240_half {
      margin-bottom: -240px !important; } }

.u-pb5_half {
  padding-bottom: 2.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb5_half {
      padding-bottom: 5px !important; } }

.u-pb10_half {
  padding-bottom: 5px !important; }
  @media all and (min-width: 800px) {
    .u-pb10_half {
      padding-bottom: 10px !important; } }

.u-pb15_half {
  padding-bottom: 7.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb15_half {
      padding-bottom: 15px !important; } }

.u-pb20_half {
  padding-bottom: 10px !important; }
  @media all and (min-width: 800px) {
    .u-pb20_half {
      padding-bottom: 20px !important; } }

.u-pb25_half {
  padding-bottom: 12.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb25_half {
      padding-bottom: 25px !important; } }

.u-pb30_half {
  padding-bottom: 15px !important; }
  @media all and (min-width: 800px) {
    .u-pb30_half {
      padding-bottom: 30px !important; } }

.u-pb35_half {
  padding-bottom: 17.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb35_half {
      padding-bottom: 35px !important; } }

.u-pb40_half {
  padding-bottom: 20px !important; }
  @media all and (min-width: 800px) {
    .u-pb40_half {
      padding-bottom: 40px !important; } }

.u-pb45_half {
  padding-bottom: 22.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb45_half {
      padding-bottom: 45px !important; } }

.u-pb50_half {
  padding-bottom: 25px !important; }
  @media all and (min-width: 800px) {
    .u-pb50_half {
      padding-bottom: 50px !important; } }

.u-pb55_half {
  padding-bottom: 27.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb55_half {
      padding-bottom: 55px !important; } }

.u-pb60_half {
  padding-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .u-pb60_half {
      padding-bottom: 60px !important; } }

.u-pb65_half {
  padding-bottom: 32.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb65_half {
      padding-bottom: 65px !important; } }

.u-pb70_half {
  padding-bottom: 35px !important; }
  @media all and (min-width: 800px) {
    .u-pb70_half {
      padding-bottom: 70px !important; } }

.u-pb75_half {
  padding-bottom: 37.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb75_half {
      padding-bottom: 75px !important; } }

.u-pb80_half {
  padding-bottom: 40px !important; }
  @media all and (min-width: 800px) {
    .u-pb80_half {
      padding-bottom: 80px !important; } }

.u-pb85_half {
  padding-bottom: 42.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb85_half {
      padding-bottom: 85px !important; } }

.u-pb90_half {
  padding-bottom: 45px !important; }
  @media all and (min-width: 800px) {
    .u-pb90_half {
      padding-bottom: 90px !important; } }

.u-pb95_half {
  padding-bottom: 47.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb95_half {
      padding-bottom: 95px !important; } }

.u-pb100_half {
  padding-bottom: 50px !important; }
  @media all and (min-width: 800px) {
    .u-pb100_half {
      padding-bottom: 100px !important; } }

.u-pb105_half {
  padding-bottom: 52.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb105_half {
      padding-bottom: 105px !important; } }

.u-pb110_half {
  padding-bottom: 55px !important; }
  @media all and (min-width: 800px) {
    .u-pb110_half {
      padding-bottom: 110px !important; } }

.u-pb115_half {
  padding-bottom: 57.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb115_half {
      padding-bottom: 115px !important; } }

.u-pb120_half {
  padding-bottom: 60px !important; }
  @media all and (min-width: 800px) {
    .u-pb120_half {
      padding-bottom: 120px !important; } }

.u-pb125_half {
  padding-bottom: 62.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb125_half {
      padding-bottom: 125px !important; } }

.u-pb130_half {
  padding-bottom: 65px !important; }
  @media all and (min-width: 800px) {
    .u-pb130_half {
      padding-bottom: 130px !important; } }

.u-pb135_half {
  padding-bottom: 67.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb135_half {
      padding-bottom: 135px !important; } }

.u-pb140_half {
  padding-bottom: 70px !important; }
  @media all and (min-width: 800px) {
    .u-pb140_half {
      padding-bottom: 140px !important; } }

.u-pb145_half {
  padding-bottom: 72.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb145_half {
      padding-bottom: 145px !important; } }

.u-pb150_half {
  padding-bottom: 75px !important; }
  @media all and (min-width: 800px) {
    .u-pb150_half {
      padding-bottom: 150px !important; } }

.u-pb155_half {
  padding-bottom: 77.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb155_half {
      padding-bottom: 155px !important; } }

.u-pb160_half {
  padding-bottom: 80px !important; }
  @media all and (min-width: 800px) {
    .u-pb160_half {
      padding-bottom: 160px !important; } }

.u-pb165_half {
  padding-bottom: 82.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb165_half {
      padding-bottom: 165px !important; } }

.u-pb170_half {
  padding-bottom: 85px !important; }
  @media all and (min-width: 800px) {
    .u-pb170_half {
      padding-bottom: 170px !important; } }

.u-pb175_half {
  padding-bottom: 87.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb175_half {
      padding-bottom: 175px !important; } }

.u-pb180_half {
  padding-bottom: 90px !important; }
  @media all and (min-width: 800px) {
    .u-pb180_half {
      padding-bottom: 180px !important; } }

.u-pb185_half {
  padding-bottom: 92.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb185_half {
      padding-bottom: 185px !important; } }

.u-pb190_half {
  padding-bottom: 95px !important; }
  @media all and (min-width: 800px) {
    .u-pb190_half {
      padding-bottom: 190px !important; } }

.u-pb195_half {
  padding-bottom: 97.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb195_half {
      padding-bottom: 195px !important; } }

.u-pb200_half {
  padding-bottom: 100px !important; }
  @media all and (min-width: 800px) {
    .u-pb200_half {
      padding-bottom: 200px !important; } }

.u-pb205_half {
  padding-bottom: 102.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb205_half {
      padding-bottom: 205px !important; } }

.u-pb210_half {
  padding-bottom: 105px !important; }
  @media all and (min-width: 800px) {
    .u-pb210_half {
      padding-bottom: 210px !important; } }

.u-pb215_half {
  padding-bottom: 107.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb215_half {
      padding-bottom: 215px !important; } }

.u-pb220_half {
  padding-bottom: 110px !important; }
  @media all and (min-width: 800px) {
    .u-pb220_half {
      padding-bottom: 220px !important; } }

.u-pb225_half {
  padding-bottom: 112.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb225_half {
      padding-bottom: 225px !important; } }

.u-pb230_half {
  padding-bottom: 115px !important; }
  @media all and (min-width: 800px) {
    .u-pb230_half {
      padding-bottom: 230px !important; } }

.u-pb235_half {
  padding-bottom: 117.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb235_half {
      padding-bottom: 235px !important; } }

.u-pb240_half {
  padding-bottom: 120px !important; }
  @media all and (min-width: 800px) {
    .u-pb240_half {
      padding-bottom: 240px !important; } }

.u-mb25_10 {
  margin-bottom: 10px !important; }
  @media all and (min-width: 800px) {
    .u-mb25_10 {
      margin-bottom: 25px !important; } }

.u-mb25_15 {
  margin-bottom: 15px !important; }
  @media all and (min-width: 800px) {
    .u-mb25_15 {
      margin-bottom: 25px !important; } }

.u-mb0_25 {
  box-sizing: border-box;
  margin-bottom: 25px; }
  @media all and (min-width: 800px) {
    .u-mb0_25 {
      margin-bottom: 0; } }

.u-mb0_50 {
  box-sizing: border-box;
  margin-bottom: 50px; }
  @media all and (min-width: 800px) {
    .u-mb0_50 {
      margin-bottom: 0; } }

.u-mb40_15 {
  box-sizing: border-box;
  margin-bottom: 15px; }
  @media all and (min-width: 800px) {
    .u-mb40_15 {
      margin-bottom: 40px; } }

.u-mb20n_5 {
  box-sizing: border-box;
  margin-bottom: 5px; }
  @media all and (min-width: 1120px) {
    .u-mb20n_5 {
      margin-bottom: 20px; } }

.u-mb20n_13 {
  box-sizing: border-box;
  margin-bottom: 13px; }
  @media all and (min-width: 1120px) {
    .u-mb20n_13 {
      margin-bottom: 20px; } }

.u-mb65ni_30 {
  box-sizing: border-box;
  margin-bottom: 30px; }
  @media all and (min-width: 1000px) {
    .u-mb65ni_30 {
      margin-bottom: 65px; } }

.u-pt120_60 {
  box-sizing: border-box;
  padding-top: 60px; }
  @media all and (min-width: 800px) {
    .u-pt120_60 {
      padding-top: 120px; } }

.u-pt180_90 {
  box-sizing: border-box;
  padding-top: 90px; }
  @media all and (min-width: 800px) {
    .u-pt180_90 {
      padding-top: 180px; } }

.u-pb190_200 {
  box-sizing: border-box;
  padding-bottom: 200px; }
  @media all and (min-width: 800px) {
    .u-pb190_200 {
      padding-bottom: 190px; } }

.u-pb120ni_90pc_70 {
  box-sizing: border-box;
  padding-bottom: 70px; }
  @media all and (min-width: 800px) {
    .u-pb120ni_90pc_70 {
      padding-bottom: 90px; } }
  @media all and (min-width: 1000px) {
    .u-pb120ni_90pc_70 {
      padding-bottom: 120px; } }
  @media all and (min-width: 1000px) {
    .u-pb120ni_90pc_70.-rightver {
      padding-bottom: 150px; } }

.u-pl10_0 {
  padding-left: 0;
  box-sizing: border-box; }
  @media all and (min-width: 800px) {
    .u-pl10_0 {
      padding-left: 10px; } }

.u-mb_minus10_half {
  margin-bottom: -5px; }
  @media all and (min-width: 800px) {
    .u-mb_minus10_half {
      margin-bottom: -10px; } }

.u-mb_minus80_half {
  margin-bottom: -40px; }
  @media all and (min-width: 800px) {
    .u-mb_minus80_half {
      margin-bottom: -80px; } }

.u-mb_hlttl01_type01 {
  box-sizing: border-box;
  margin-bottom: 20px; }
  @media all and (min-width: 800px) {
    .u-mb_hlttl01_type01 {
      margin-bottom: 35px; } }
  @media all and (min-width: 1000px) {
    .u-mb_hlttl01_type01 {
      margin-bottom: 45px; } }
  @media all and (min-width: 1120px) {
    .u-mb_hlttl01_type01 {
      margin-bottom: 85px; } }

.u-mb_hllowerkv01 {
  box-sizing: border-box;
  margin-bottom: 15px; }
  @media all and (min-width: 800px) {
    .u-mb_hllowerkv01 {
      margin-bottom: 35px; } }

.u-mb_tlead02_type01 {
  box-sizing: border-box;
  margin-bottom: 10px; }
  @media all and (min-width: 800px) {
    .u-mb_tlead02_type01 {
      margin-bottom: 30px; } }
  @media all and (min-width: 1000px) {
    .u-mb_tlead02_type01 {
      margin-bottom: 40px; } }
  @media all and (min-width: 1120px) {
    .u-mb_tlead02_type01 {
      margin-bottom: 50px; } }

.u-mb_frontaboutus {
  margin-bottom: 30px; }
  @media all and (min-width: 480px) {
    .u-mb_frontaboutus {
      margin-bottom: 0; } }

.u-pt_frontservice {
  box-sizing: border-box;
  padding-top: 70px; }
  @media all and (min-width: 1000px) {
    .u-pt_frontservice {
      padding-top: 90px; } }
  @media all and (min-width: 1120px) {
    .u-pt_frontservice {
      padding-top: 130px; } }

.u-pb_frontservice {
  box-sizing: border-box;
  padding-bottom: 80px; }
  @media all and (min-width: 800px) {
    .u-pb_frontservice {
      padding-bottom: 120px; } }
  @media all and (min-width: 1000px) {
    .u-pb_frontservice {
      padding-bottom: 140px; } }
  @media all and (min-width: 1120px) {
    .u-pb_frontservice {
      padding-bottom: 190px; } }

.u-mb_aslead {
  box-sizing: border-box;
  margin-bottom: 20px; }
  @media all and (min-width: 720px) {
    .u-mb_aslead {
      margin-bottom: 20px; } }
  @media all and (min-width: 1000px) {
    .u-mb_aslead {
      margin-bottom: 25px; } }
  @media all and (min-width: 1120px) {
    .u-mb_aslead {
      margin-bottom: 30px; } }

.u-mb_aslead {
  box-sizing: border-box;
  margin-bottom: 20px; }
  @media all and (min-width: 720px) {
    .u-mb_aslead {
      margin-bottom: 20px; } }
  @media all and (min-width: 1000px) {
    .u-mb_aslead {
      margin-bottom: 25px; } }
  @media all and (min-width: 1120px) {
    .u-mb_aslead {
      margin-bottom: 30px; } }

.u-mb_memberlast {
  margin-bottom: 70px; }
  @media all and (min-width: 480px) {
    .u-mb_memberlast {
      margin-bottom: 90px; } }
  @media all and (min-width: 600px) {
    .u-mb_memberlast {
      margin-bottom: 130px; } }
  @media all and (min-width: 800px) {
    .u-mb_memberlast {
      margin-bottom: 190px; } }
  @media all and (min-width: 1000px) {
    .u-mb_memberlast {
      margin-bottom: 190px; } }

.u-mb_gitlead {
  margin-bottom: 0; }
  @media all and (min-width: 600px) {
    .u-mb_gitlead {
      margin-bottom: 5px; } }
  @media all and (min-width: 800px) {
    .u-mb_gitlead {
      margin-bottom: 10px; } }
  @media all and (min-width: 1000px) {
    .u-mb_gitlead {
      margin-bottom: 20px; } }

.u-mb_gitttl {
  margin-bottom: 65px; }
  @media all and (min-width: 1000px) {
    .u-mb_gitttl {
      margin-bottom: 75px; } }

.u-pb_loadresult {
  padding-bottom: 40px; }
  @media all and (min-width: 800px) {
    .u-pb_loadresult {
      padding-bottom: 80px; } }
  .u-pb_loadresult.js-displayall {
    padding-bottom: 15px; }
    @media all and (min-width: 800px) {
      .u-pb_loadresult.js-displayall {
        padding-bottom: 30px; } }

.u-worksttlset04_main {
  margin-top: 10px;
  margin-bottom: 15px; }
  @media all and (min-width: 800px) {
    .u-worksttlset04_main {
      margin-top: 20px;
      margin-bottom: 30px; } }

.u-worksttlset04_sub {
  margin-bottom: 5px; }
  @media all and (min-width: 800px) {
    .u-worksttlset04_sub {
      margin-bottom: 10px; } }

.js-fadeinitem {
  opacity: 0; }

.js-fadein {
  animation: 0.5s ease-in-out 0s 1 both fadeIn; }

.js-fadeinupitem {
  opacity: 0;
  transform: translateY(10px); }

.js-fadeinup {
  animation: 0.5s ease-in-out 0s 1 both fadeInUp; }

.js-slideupitem {
  transform: translateY(20px); }

.js-slideup {
  animation: 0.5s ease-in-out 0s 1 both slideUp; }

.js-blurwhitem {
  color: white; }

.js-blurWh {
  color: white;
  animation: 10s ease-out 0s 1 both blurWh; }

.js-charupitem {
  position: relative; }
  .js-charupitem .js-charup_container {
    overflow: hidden; }
    .js-charupitem .js-charup_container span {
      display: inline-block;
      transform: translate3d(0, 2em, 0); }
  .js-charupitem.-twolinettl01 div:nth-of-type(1):before {
    opacity: 0; }
  .js-charupitem.-twolinettl01 div:nth-of-type(2):after {
    opacity: 0; }

.js-charup {
  position: relative;
  z-index: 3; }
  .js-charup .js-charup_container span {
    animation: oneCharUp 850ms 1 ease both;
    will-change: animation; }
    .js-charup .js-charup_container span:nth-child(1) {
      animation-delay: 100ms; }
    .js-charup .js-charup_container span:nth-child(2) {
      animation-delay: 168ms; }
    .js-charup .js-charup_container span:nth-child(3) {
      animation-delay: 236ms; }
    .js-charup .js-charup_container span:nth-child(4) {
      animation-delay: 304ms; }
    .js-charup .js-charup_container span:nth-child(5) {
      animation-delay: 372ms; }
    .js-charup .js-charup_container span:nth-child(6) {
      animation-delay: 440ms; }
    .js-charup .js-charup_container span:nth-child(7) {
      animation-delay: 508ms; }
    .js-charup .js-charup_container span:nth-child(8) {
      animation-delay: 576ms; }
    .js-charup .js-charup_container span:nth-child(9) {
      animation-delay: 644ms; }
    .js-charup .js-charup_container span:nth-child(10) {
      animation-delay: 712ms; }
    .js-charup .js-charup_container span:nth-child(11) {
      animation-delay: 780ms; }
    .js-charup .js-charup_container span:nth-child(12) {
      animation-delay: 848ms; }
    .js-charup .js-charup_container span:nth-child(13) {
      animation-delay: 916ms; }
    .js-charup .js-charup_container span:nth-child(14) {
      animation-delay: 984ms; }
    .js-charup .js-charup_container span:nth-child(15) {
      animation-delay: 1052ms; }
    .js-charup .js-charup_container span:nth-child(16) {
      animation-delay: 1120ms; }
    .js-charup .js-charup_container span:nth-child(17) {
      animation-delay: 1188ms; }
    .js-charup .js-charup_container span:nth-child(18) {
      animation-delay: 1256ms; }
    .js-charup .js-charup_container span:nth-child(19) {
      animation-delay: 1324ms; }
    .js-charup .js-charup_container span:nth-child(20) {
      animation-delay: 1392ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(1) {
    animation-delay: 100ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(2) {
    animation-delay: 168ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(3) {
    animation-delay: 236ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(4) {
    animation-delay: 304ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(5) {
    animation-delay: 372ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(6) {
    animation-delay: 440ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(7) {
    animation-delay: 508ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(8) {
    animation-delay: 576ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(9) {
    animation-delay: 644ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(10) {
    animation-delay: 712ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(11) {
    animation-delay: 780ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(12) {
    animation-delay: 848ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(13) {
    animation-delay: 916ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(14) {
    animation-delay: 984ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(15) {
    animation-delay: 1052ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(16) {
    animation-delay: 1120ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(17) {
    animation-delay: 1188ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(18) {
    animation-delay: 1256ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(19) {
    animation-delay: 1324ms; }
  .js-charup .js-charup_container.-delay0 span:nth-child(20) {
    animation-delay: 1392ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(1) {
    animation-delay: 185ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(2) {
    animation-delay: 253ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(3) {
    animation-delay: 321ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(4) {
    animation-delay: 389ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(5) {
    animation-delay: 457ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(6) {
    animation-delay: 525ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(7) {
    animation-delay: 593ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(8) {
    animation-delay: 661ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(9) {
    animation-delay: 729ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(10) {
    animation-delay: 797ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(11) {
    animation-delay: 865ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(12) {
    animation-delay: 933ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(13) {
    animation-delay: 1001ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(14) {
    animation-delay: 1069ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(15) {
    animation-delay: 1137ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(16) {
    animation-delay: 1205ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(17) {
    animation-delay: 1273ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(18) {
    animation-delay: 1341ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(19) {
    animation-delay: 1409ms; }
  .js-charup .js-charup_container.-delay1 span:nth-child(20) {
    animation-delay: 1477ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(1) {
    animation-delay: 270ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(2) {
    animation-delay: 338ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(3) {
    animation-delay: 406ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(4) {
    animation-delay: 474ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(5) {
    animation-delay: 542ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(6) {
    animation-delay: 610ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(7) {
    animation-delay: 678ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(8) {
    animation-delay: 746ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(9) {
    animation-delay: 814ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(10) {
    animation-delay: 882ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(11) {
    animation-delay: 950ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(12) {
    animation-delay: 1018ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(13) {
    animation-delay: 1086ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(14) {
    animation-delay: 1154ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(15) {
    animation-delay: 1222ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(16) {
    animation-delay: 1290ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(17) {
    animation-delay: 1358ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(18) {
    animation-delay: 1426ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(19) {
    animation-delay: 1494ms; }
  .js-charup .js-charup_container.-delay2 span:nth-child(20) {
    animation-delay: 1562ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(1) {
    animation-delay: 355ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(2) {
    animation-delay: 423ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(3) {
    animation-delay: 491ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(4) {
    animation-delay: 559ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(5) {
    animation-delay: 627ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(6) {
    animation-delay: 695ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(7) {
    animation-delay: 763ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(8) {
    animation-delay: 831ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(9) {
    animation-delay: 899ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(10) {
    animation-delay: 967ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(11) {
    animation-delay: 1035ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(12) {
    animation-delay: 1103ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(13) {
    animation-delay: 1171ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(14) {
    animation-delay: 1239ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(15) {
    animation-delay: 1307ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(16) {
    animation-delay: 1375ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(17) {
    animation-delay: 1443ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(18) {
    animation-delay: 1511ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(19) {
    animation-delay: 1579ms; }
  .js-charup .js-charup_container.-delay3 span:nth-child(20) {
    animation-delay: 1647ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(1) {
    animation-delay: 440ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(2) {
    animation-delay: 508ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(3) {
    animation-delay: 576ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(4) {
    animation-delay: 644ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(5) {
    animation-delay: 712ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(6) {
    animation-delay: 780ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(7) {
    animation-delay: 848ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(8) {
    animation-delay: 916ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(9) {
    animation-delay: 984ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(10) {
    animation-delay: 1052ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(11) {
    animation-delay: 1120ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(12) {
    animation-delay: 1188ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(13) {
    animation-delay: 1256ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(14) {
    animation-delay: 1324ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(15) {
    animation-delay: 1392ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(16) {
    animation-delay: 1460ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(17) {
    animation-delay: 1528ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(18) {
    animation-delay: 1596ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(19) {
    animation-delay: 1664ms; }
  .js-charup .js-charup_container.-delay4 span:nth-child(20) {
    animation-delay: 1732ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(1) {
    animation-delay: 525ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(2) {
    animation-delay: 593ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(3) {
    animation-delay: 661ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(4) {
    animation-delay: 729ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(5) {
    animation-delay: 797ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(6) {
    animation-delay: 865ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(7) {
    animation-delay: 933ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(8) {
    animation-delay: 1001ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(9) {
    animation-delay: 1069ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(10) {
    animation-delay: 1137ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(11) {
    animation-delay: 1205ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(12) {
    animation-delay: 1273ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(13) {
    animation-delay: 1341ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(14) {
    animation-delay: 1409ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(15) {
    animation-delay: 1477ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(16) {
    animation-delay: 1545ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(17) {
    animation-delay: 1613ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(18) {
    animation-delay: 1681ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(19) {
    animation-delay: 1749ms; }
  .js-charup .js-charup_container.-delay5 span:nth-child(20) {
    animation-delay: 1817ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(1) {
    animation-delay: 610ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(2) {
    animation-delay: 678ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(3) {
    animation-delay: 746ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(4) {
    animation-delay: 814ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(5) {
    animation-delay: 882ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(6) {
    animation-delay: 950ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(7) {
    animation-delay: 1018ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(8) {
    animation-delay: 1086ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(9) {
    animation-delay: 1154ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(10) {
    animation-delay: 1222ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(11) {
    animation-delay: 1290ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(12) {
    animation-delay: 1358ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(13) {
    animation-delay: 1426ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(14) {
    animation-delay: 1494ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(15) {
    animation-delay: 1562ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(16) {
    animation-delay: 1630ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(17) {
    animation-delay: 1698ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(18) {
    animation-delay: 1766ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(19) {
    animation-delay: 1834ms; }
  .js-charup .js-charup_container.-delay6 span:nth-child(20) {
    animation-delay: 1902ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(1) {
    animation-delay: 695ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(2) {
    animation-delay: 763ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(3) {
    animation-delay: 831ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(4) {
    animation-delay: 899ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(5) {
    animation-delay: 967ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(6) {
    animation-delay: 1035ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(7) {
    animation-delay: 1103ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(8) {
    animation-delay: 1171ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(9) {
    animation-delay: 1239ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(10) {
    animation-delay: 1307ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(11) {
    animation-delay: 1375ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(12) {
    animation-delay: 1443ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(13) {
    animation-delay: 1511ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(14) {
    animation-delay: 1579ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(15) {
    animation-delay: 1647ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(16) {
    animation-delay: 1715ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(17) {
    animation-delay: 1783ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(18) {
    animation-delay: 1851ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(19) {
    animation-delay: 1919ms; }
  .js-charup .js-charup_container.-delay7 span:nth-child(20) {
    animation-delay: 1987ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(1) {
    animation-delay: 780ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(2) {
    animation-delay: 848ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(3) {
    animation-delay: 916ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(4) {
    animation-delay: 984ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(5) {
    animation-delay: 1052ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(6) {
    animation-delay: 1120ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(7) {
    animation-delay: 1188ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(8) {
    animation-delay: 1256ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(9) {
    animation-delay: 1324ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(10) {
    animation-delay: 1392ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(11) {
    animation-delay: 1460ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(12) {
    animation-delay: 1528ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(13) {
    animation-delay: 1596ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(14) {
    animation-delay: 1664ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(15) {
    animation-delay: 1732ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(16) {
    animation-delay: 1800ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(17) {
    animation-delay: 1868ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(18) {
    animation-delay: 1936ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(19) {
    animation-delay: 2004ms; }
  .js-charup .js-charup_container.-delay8 span:nth-child(20) {
    animation-delay: 2072ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(1) {
    animation-delay: 865ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(2) {
    animation-delay: 933ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(3) {
    animation-delay: 1001ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(4) {
    animation-delay: 1069ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(5) {
    animation-delay: 1137ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(6) {
    animation-delay: 1205ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(7) {
    animation-delay: 1273ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(8) {
    animation-delay: 1341ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(9) {
    animation-delay: 1409ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(10) {
    animation-delay: 1477ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(11) {
    animation-delay: 1545ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(12) {
    animation-delay: 1613ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(13) {
    animation-delay: 1681ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(14) {
    animation-delay: 1749ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(15) {
    animation-delay: 1817ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(16) {
    animation-delay: 1885ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(17) {
    animation-delay: 1953ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(18) {
    animation-delay: 2021ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(19) {
    animation-delay: 2089ms; }
  .js-charup .js-charup_container.-delay9 span:nth-child(20) {
    animation-delay: 2157ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(1) {
    animation-delay: 950ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(2) {
    animation-delay: 1018ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(3) {
    animation-delay: 1086ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(4) {
    animation-delay: 1154ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(5) {
    animation-delay: 1222ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(6) {
    animation-delay: 1290ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(7) {
    animation-delay: 1358ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(8) {
    animation-delay: 1426ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(9) {
    animation-delay: 1494ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(10) {
    animation-delay: 1562ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(11) {
    animation-delay: 1630ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(12) {
    animation-delay: 1698ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(13) {
    animation-delay: 1766ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(14) {
    animation-delay: 1834ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(15) {
    animation-delay: 1902ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(16) {
    animation-delay: 1970ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(17) {
    animation-delay: 2038ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(18) {
    animation-delay: 2106ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(19) {
    animation-delay: 2174ms; }
  .js-charup .js-charup_container.-delay10 span:nth-child(20) {
    animation-delay: 2242ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(1) {
    animation-delay: 1035ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(2) {
    animation-delay: 1103ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(3) {
    animation-delay: 1171ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(4) {
    animation-delay: 1239ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(5) {
    animation-delay: 1307ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(6) {
    animation-delay: 1375ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(7) {
    animation-delay: 1443ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(8) {
    animation-delay: 1511ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(9) {
    animation-delay: 1579ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(10) {
    animation-delay: 1647ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(11) {
    animation-delay: 1715ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(12) {
    animation-delay: 1783ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(13) {
    animation-delay: 1851ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(14) {
    animation-delay: 1919ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(15) {
    animation-delay: 1987ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(16) {
    animation-delay: 2055ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(17) {
    animation-delay: 2123ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(18) {
    animation-delay: 2191ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(19) {
    animation-delay: 2259ms; }
  .js-charup .js-charup_container.-delay11 span:nth-child(20) {
    animation-delay: 2327ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(1) {
    animation-delay: 1120ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(2) {
    animation-delay: 1188ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(3) {
    animation-delay: 1256ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(4) {
    animation-delay: 1324ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(5) {
    animation-delay: 1392ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(6) {
    animation-delay: 1460ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(7) {
    animation-delay: 1528ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(8) {
    animation-delay: 1596ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(9) {
    animation-delay: 1664ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(10) {
    animation-delay: 1732ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(11) {
    animation-delay: 1800ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(12) {
    animation-delay: 1868ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(13) {
    animation-delay: 1936ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(14) {
    animation-delay: 2004ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(15) {
    animation-delay: 2072ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(16) {
    animation-delay: 2140ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(17) {
    animation-delay: 2208ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(18) {
    animation-delay: 2276ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(19) {
    animation-delay: 2344ms; }
  .js-charup .js-charup_container.-delay12 span:nth-child(20) {
    animation-delay: 2412ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(1) {
    animation-delay: 1205ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(2) {
    animation-delay: 1273ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(3) {
    animation-delay: 1341ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(4) {
    animation-delay: 1409ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(5) {
    animation-delay: 1477ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(6) {
    animation-delay: 1545ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(7) {
    animation-delay: 1613ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(8) {
    animation-delay: 1681ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(9) {
    animation-delay: 1749ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(10) {
    animation-delay: 1817ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(11) {
    animation-delay: 1885ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(12) {
    animation-delay: 1953ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(13) {
    animation-delay: 2021ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(14) {
    animation-delay: 2089ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(15) {
    animation-delay: 2157ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(16) {
    animation-delay: 2225ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(17) {
    animation-delay: 2293ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(18) {
    animation-delay: 2361ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(19) {
    animation-delay: 2429ms; }
  .js-charup .js-charup_container.-delay13 span:nth-child(20) {
    animation-delay: 2497ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(1) {
    animation-delay: 1290ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(2) {
    animation-delay: 1358ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(3) {
    animation-delay: 1426ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(4) {
    animation-delay: 1494ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(5) {
    animation-delay: 1562ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(6) {
    animation-delay: 1630ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(7) {
    animation-delay: 1698ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(8) {
    animation-delay: 1766ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(9) {
    animation-delay: 1834ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(10) {
    animation-delay: 1902ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(11) {
    animation-delay: 1970ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(12) {
    animation-delay: 2038ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(13) {
    animation-delay: 2106ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(14) {
    animation-delay: 2174ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(15) {
    animation-delay: 2242ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(16) {
    animation-delay: 2310ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(17) {
    animation-delay: 2378ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(18) {
    animation-delay: 2446ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(19) {
    animation-delay: 2514ms; }
  .js-charup .js-charup_container.-delay14 span:nth-child(20) {
    animation-delay: 2582ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(1) {
    animation-delay: 1375ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(2) {
    animation-delay: 1443ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(3) {
    animation-delay: 1511ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(4) {
    animation-delay: 1579ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(5) {
    animation-delay: 1647ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(6) {
    animation-delay: 1715ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(7) {
    animation-delay: 1783ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(8) {
    animation-delay: 1851ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(9) {
    animation-delay: 1919ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(10) {
    animation-delay: 1987ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(11) {
    animation-delay: 2055ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(12) {
    animation-delay: 2123ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(13) {
    animation-delay: 2191ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(14) {
    animation-delay: 2259ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(15) {
    animation-delay: 2327ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(16) {
    animation-delay: 2395ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(17) {
    animation-delay: 2463ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(18) {
    animation-delay: 2531ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(19) {
    animation-delay: 2599ms; }
  .js-charup .js-charup_container.-delay15 span:nth-child(20) {
    animation-delay: 2667ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(1) {
    animation-delay: 1460ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(2) {
    animation-delay: 1528ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(3) {
    animation-delay: 1596ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(4) {
    animation-delay: 1664ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(5) {
    animation-delay: 1732ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(6) {
    animation-delay: 1800ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(7) {
    animation-delay: 1868ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(8) {
    animation-delay: 1936ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(9) {
    animation-delay: 2004ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(10) {
    animation-delay: 2072ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(11) {
    animation-delay: 2140ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(12) {
    animation-delay: 2208ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(13) {
    animation-delay: 2276ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(14) {
    animation-delay: 2344ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(15) {
    animation-delay: 2412ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(16) {
    animation-delay: 2480ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(17) {
    animation-delay: 2548ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(18) {
    animation-delay: 2616ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(19) {
    animation-delay: 2684ms; }
  .js-charup .js-charup_container.-delay16 span:nth-child(20) {
    animation-delay: 2752ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(1) {
    animation-delay: 1545ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(2) {
    animation-delay: 1613ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(3) {
    animation-delay: 1681ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(4) {
    animation-delay: 1749ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(5) {
    animation-delay: 1817ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(6) {
    animation-delay: 1885ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(7) {
    animation-delay: 1953ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(8) {
    animation-delay: 2021ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(9) {
    animation-delay: 2089ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(10) {
    animation-delay: 2157ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(11) {
    animation-delay: 2225ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(12) {
    animation-delay: 2293ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(13) {
    animation-delay: 2361ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(14) {
    animation-delay: 2429ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(15) {
    animation-delay: 2497ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(16) {
    animation-delay: 2565ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(17) {
    animation-delay: 2633ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(18) {
    animation-delay: 2701ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(19) {
    animation-delay: 2769ms; }
  .js-charup .js-charup_container.-delay17 span:nth-child(20) {
    animation-delay: 2837ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(1) {
    animation-delay: 1630ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(2) {
    animation-delay: 1698ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(3) {
    animation-delay: 1766ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(4) {
    animation-delay: 1834ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(5) {
    animation-delay: 1902ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(6) {
    animation-delay: 1970ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(7) {
    animation-delay: 2038ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(8) {
    animation-delay: 2106ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(9) {
    animation-delay: 2174ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(10) {
    animation-delay: 2242ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(11) {
    animation-delay: 2310ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(12) {
    animation-delay: 2378ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(13) {
    animation-delay: 2446ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(14) {
    animation-delay: 2514ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(15) {
    animation-delay: 2582ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(16) {
    animation-delay: 2650ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(17) {
    animation-delay: 2718ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(18) {
    animation-delay: 2786ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(19) {
    animation-delay: 2854ms; }
  .js-charup .js-charup_container.-delay18 span:nth-child(20) {
    animation-delay: 2922ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(1) {
    animation-delay: 1715ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(2) {
    animation-delay: 1783ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(3) {
    animation-delay: 1851ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(4) {
    animation-delay: 1919ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(5) {
    animation-delay: 1987ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(6) {
    animation-delay: 2055ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(7) {
    animation-delay: 2123ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(8) {
    animation-delay: 2191ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(9) {
    animation-delay: 2259ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(10) {
    animation-delay: 2327ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(11) {
    animation-delay: 2395ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(12) {
    animation-delay: 2463ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(13) {
    animation-delay: 2531ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(14) {
    animation-delay: 2599ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(15) {
    animation-delay: 2667ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(16) {
    animation-delay: 2735ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(17) {
    animation-delay: 2803ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(18) {
    animation-delay: 2871ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(19) {
    animation-delay: 2939ms; }
  .js-charup .js-charup_container.-delay19 span:nth-child(20) {
    animation-delay: 3007ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(1) {
    animation-delay: 1800ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(2) {
    animation-delay: 1868ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(3) {
    animation-delay: 1936ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(4) {
    animation-delay: 2004ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(5) {
    animation-delay: 2072ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(6) {
    animation-delay: 2140ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(7) {
    animation-delay: 2208ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(8) {
    animation-delay: 2276ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(9) {
    animation-delay: 2344ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(10) {
    animation-delay: 2412ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(11) {
    animation-delay: 2480ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(12) {
    animation-delay: 2548ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(13) {
    animation-delay: 2616ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(14) {
    animation-delay: 2684ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(15) {
    animation-delay: 2752ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(16) {
    animation-delay: 2820ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(17) {
    animation-delay: 2888ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(18) {
    animation-delay: 2956ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(19) {
    animation-delay: 3024ms; }
  .js-charup .js-charup_container.-delay20 span:nth-child(20) {
    animation-delay: 3092ms; }
  .js-charup.-twolinettl01 > div:nth-of-type(1) > div:before {
    animation: 0.3s ease-in-out 100ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish1:after {
    animation: 850ms ease-in-out 185ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish2:after {
    animation: 850ms ease-in-out 270ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish3:after {
    animation: 850ms ease-in-out 355ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish4:after {
    animation: 850ms ease-in-out 440ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish5:after {
    animation: 850ms ease-in-out 525ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish6:after {
    animation: 850ms ease-in-out 610ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish7:after {
    animation: 850ms ease-in-out 695ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish8:after {
    animation: 850ms ease-in-out 780ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish9:after {
    animation: 850ms ease-in-out 865ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish10:after {
    animation: 850ms ease-in-out 950ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish11:after {
    animation: 850ms ease-in-out 1035ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish12:after {
    animation: 850ms ease-in-out 1120ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish13:after {
    animation: 850ms ease-in-out 1205ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish14:after {
    animation: 850ms ease-in-out 1290ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish15:after {
    animation: 850ms ease-in-out 1375ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish16:after {
    animation: 850ms ease-in-out 1460ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish17:after {
    animation: 850ms ease-in-out 1545ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish18:after {
    animation: 850ms ease-in-out 1630ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish19:after {
    animation: 850ms ease-in-out 1715ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish20:after {
    animation: 850ms ease-in-out 1800ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish21:after {
    animation: 850ms ease-in-out 1885ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish22:after {
    animation: 850ms ease-in-out 1970ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish23:after {
    animation: 850ms ease-in-out 2055ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish24:after {
    animation: 850ms ease-in-out 2140ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish25:after {
    animation: 850ms ease-in-out 2225ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish26:after {
    animation: 850ms ease-in-out 2310ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish27:after {
    animation: 850ms ease-in-out 2395ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish28:after {
    animation: 850ms ease-in-out 2480ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish29:after {
    animation: 850ms ease-in-out 2565ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish30:after {
    animation: 850ms ease-in-out 2650ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish31:after {
    animation: 850ms ease-in-out 2735ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish32:after {
    animation: 850ms ease-in-out 2820ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish33:after {
    animation: 850ms ease-in-out 2905ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish34:after {
    animation: 850ms ease-in-out 2990ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish35:after {
    animation: 850ms ease-in-out 3075ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish36:after {
    animation: 850ms ease-in-out 3160ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish37:after {
    animation: 850ms ease-in-out 3245ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish38:after {
    animation: 850ms ease-in-out 3330ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish39:after {
    animation: 850ms ease-in-out 3415ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish40:after {
    animation: 850ms ease-in-out 3500ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish41:after {
    animation: 850ms ease-in-out 3585ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish42:after {
    animation: 850ms ease-in-out 3670ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish43:after {
    animation: 850ms ease-in-out 3755ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish44:after {
    animation: 850ms ease-in-out 3840ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish45:after {
    animation: 850ms ease-in-out 3925ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish46:after {
    animation: 850ms ease-in-out 4010ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish47:after {
    animation: 850ms ease-in-out 4095ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish48:after {
    animation: 850ms ease-in-out 4180ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish49:after {
    animation: 850ms ease-in-out 4265ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(1).-finish50:after {
    animation: 850ms ease-in-out 4350ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish1.js-charup_jp {
    animation: 850ms ease-in-out 185ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish2.js-charup_jp {
    animation: 850ms ease-in-out 270ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish3.js-charup_jp {
    animation: 850ms ease-in-out 355ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish4.js-charup_jp {
    animation: 850ms ease-in-out 440ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish5.js-charup_jp {
    animation: 850ms ease-in-out 525ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish6.js-charup_jp {
    animation: 850ms ease-in-out 610ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish7.js-charup_jp {
    animation: 850ms ease-in-out 695ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish8.js-charup_jp {
    animation: 850ms ease-in-out 780ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish9.js-charup_jp {
    animation: 850ms ease-in-out 865ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish10.js-charup_jp {
    animation: 850ms ease-in-out 950ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish11.js-charup_jp {
    animation: 850ms ease-in-out 1035ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish12.js-charup_jp {
    animation: 850ms ease-in-out 1120ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish13.js-charup_jp {
    animation: 850ms ease-in-out 1205ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish14.js-charup_jp {
    animation: 850ms ease-in-out 1290ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish15.js-charup_jp {
    animation: 850ms ease-in-out 1375ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish16.js-charup_jp {
    animation: 850ms ease-in-out 1460ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish17.js-charup_jp {
    animation: 850ms ease-in-out 1545ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish18.js-charup_jp {
    animation: 850ms ease-in-out 1630ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish19.js-charup_jp {
    animation: 850ms ease-in-out 1715ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish20.js-charup_jp {
    animation: 850ms ease-in-out 1800ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish21.js-charup_jp {
    animation: 850ms ease-in-out 1885ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish22.js-charup_jp {
    animation: 850ms ease-in-out 1970ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish23.js-charup_jp {
    animation: 850ms ease-in-out 2055ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish24.js-charup_jp {
    animation: 850ms ease-in-out 2140ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish25.js-charup_jp {
    animation: 850ms ease-in-out 2225ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish26.js-charup_jp {
    animation: 850ms ease-in-out 2310ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish27.js-charup_jp {
    animation: 850ms ease-in-out 2395ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish28.js-charup_jp {
    animation: 850ms ease-in-out 2480ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish29.js-charup_jp {
    animation: 850ms ease-in-out 2565ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish30.js-charup_jp {
    animation: 850ms ease-in-out 2650ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish31.js-charup_jp {
    animation: 850ms ease-in-out 2735ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish32.js-charup_jp {
    animation: 850ms ease-in-out 2820ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish33.js-charup_jp {
    animation: 850ms ease-in-out 2905ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish34.js-charup_jp {
    animation: 850ms ease-in-out 2990ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish35.js-charup_jp {
    animation: 850ms ease-in-out 3075ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish36.js-charup_jp {
    animation: 850ms ease-in-out 3160ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish37.js-charup_jp {
    animation: 850ms ease-in-out 3245ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish38.js-charup_jp {
    animation: 850ms ease-in-out 3330ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish39.js-charup_jp {
    animation: 850ms ease-in-out 3415ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish40.js-charup_jp {
    animation: 850ms ease-in-out 3500ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish41.js-charup_jp {
    animation: 850ms ease-in-out 3585ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish42.js-charup_jp {
    animation: 850ms ease-in-out 3670ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish43.js-charup_jp {
    animation: 850ms ease-in-out 3755ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish44.js-charup_jp {
    animation: 850ms ease-in-out 3840ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish45.js-charup_jp {
    animation: 850ms ease-in-out 3925ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish46.js-charup_jp {
    animation: 850ms ease-in-out 4010ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish47.js-charup_jp {
    animation: 850ms ease-in-out 4095ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish48.js-charup_jp {
    animation: 850ms ease-in-out 4180ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish49.js-charup_jp {
    animation: 850ms ease-in-out 4265ms 1 both fadeIn; }
  .js-charup.-twolinettl01 > div:nth-of-type(2) > div:nth-of-type(2).-finish50.js-charup_jp {
    animation: 850ms ease-in-out 4350ms 1 both fadeIn; }

.js-slideinbtn.-left {
  transform: translateX(-110%); }

.js-slideinbtn.-right {
  transform: translateX(110%); }

.js-slideinbtn.js-ismove.-left {
  animation: 0.4s ease-in-out 0s 1 both scrollSlideInLeft; }
  @media all and (min-width: 800px) {
    .js-slideinbtn.js-ismove.-left {
      animation-duration: 0.45s; } }
  @media all and (min-width: 800px) {
    .js-slideinbtn.js-ismove.-left {
      animation-duration: 0.5s; } }

.js-slideinbtn.js-ismove.-right {
  animation: 0.4s ease-in-out 0s 1 both scrollSlideInRight; }
  @media all and (min-width: 800px) {
    .js-slideinbtn.js-ismove.-right {
      animation-duration: 0.45s; } }
  @media all and (min-width: 800px) {
    .js-slideinbtn.js-ismove.-right {
      animation-duration: 0.5s; } }

.draw-border {
  position: relative;
  transition: color 0.25s, background-color 0.25s; }
  .draw-border::before, .draw-border::after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    border: 2px solid transparent;
    width: 0;
    height: 0;
    box-sizing: border-box; }
  .draw-border::before {
    top: 0;
    left: 0; }
  .draw-border::after {
    bottom: 0;
    right: 0; }
  .draw-border:hover::before, .draw-border:hover::after {
    width: 100%;
    height: 100%; }
  .draw-border:hover::before {
    transition: width 0.25s ease-out,  height 0.25s ease-out 0.25s; }
  .draw-border:hover::after {
    transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s, height 0.25s ease-out 0.75s; }
  .draw-border.-bk {
    background-color: black;
    color: white; }
    .draw-border.-bk:hover {
      background-color: white;
      color: black; }
      .draw-border.-bk:hover::before {
        border-top-color: black;
        border-right-color: black; }
      .draw-border.-bk:hover::after {
        border-bottom-color: black;
        border-left-color: black; }

.hover-arc {
  display: inline-block;
  border-radius: 50%;
  position: relative; }
  .hover-arc:after {
    position: absolute;
    top: -9px;
    left: -9px;
    z-index: -1;
    width: 100%;
    height: 100%;
    padding: 9px;
    border-radius: 50%;
    box-shadow: 0 0 0 white;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: opacity 0.2s, box-shadow 0.2s;
    -moz-transition: opacity 0.2s, box-shadow 0.2s;
    transition: opacity 0.2s, box-shadow 0.2s;
    pointer-events: none;
    opacity: 0; }
  .hover-arc:hover:after {
    opacity: 1; }
  .hover-arc.-blue02:after {
    box-shadow: 0 0 0 #1888a1; }
  .hover-arc.-blue02:hover:after {
    box-shadow: 3px 3px 0 #1888a1; }

.hover-burst, .hover-burst-anime {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  text-align: center;
  text-decoration: none; }
  .hover-burst:after, .hover-burst-anime:after {
    position: absolute;
    top: -1px;
    left: -1px;
    padding: 1px;
    z-index: -1;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    pointer-events: none; }
  .hover-burst:hover .icon-fill-bk, .hover-burst-anime:hover .icon-fill-bk {
    fill: white; }
  .hover-burst:hover .icon-fill-wh, .hover-burst-anime:hover .icon-fill-wh {
    fill: black; }
  .hover-burst:hover:after, .hover-burst-anime:hover:after {
    pointer-events: none; }
  .hover-burst.-wh, .hover-burst-anime.-wh {
    box-shadow: 0 0 0 2px white; }
    .hover-burst.-wh:after, .hover-burst-anime.-wh:after {
      background-color: white; }
  .hover-burst.-blue02, .hover-burst-anime.-blue02 {
    box-shadow: 0 0 0 2px #1888a1; }
    .hover-burst.-blue02:after, .hover-burst-anime.-blue02:after {
      background-color: #1888a1; }

.hover-burst {
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s; }
  .hover-burst:after {
    -webkit-transition: -webkit-transform 0.2s, opacity 0.3s;
    -moz-transition: -moz-transform 0.2s, opacity 0.3s;
    transition: transform 0.2s, opacity 0.3s; }
  .hover-burst:hover:after {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; }
  .hover-burst.-scale11:hover:after {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }
  .hover-burst.-scale115:hover:after {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15); }
  .hover-burst.-scale12:hover:after {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }
  .hover-burst.-scale13:hover:after {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3); }
  .hover-burst.-scale15:hover:after {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5); }

.hover-burst-anime.-scale115:hover:after {
  -webkit-animation: hoverBurstAfterScale115 0.2s linear 1, hoverBurstAfterOpacity 0.3s linear 1;
  animation: hoverBurstAfterScale115 0.2s linear 1, hoverBurstAfterOpacity 0.3s linear 1; }

.move-items1 {
  -webkit-animation: floatHorizontal01 12s ease-in-out infinite alternate;
  animation: floatHorizontal01 12s ease-in-out infinite alternate; }
  .move-items1 img {
    -webkit-animation: floatVertical01 10s ease-in-out infinite alternate;
    animation: floatVertical01 10s ease-in-out infinite alternate; }

.move-items1-delay {
  -webkit-animation: floatHorizontal01 12s ease-in-out -3s infinite alternate;
  animation: floatHorizontal01 12s ease-in-out -3s infinite alternate; }
  .move-items1-delay img {
    -webkit-animation: floatVertical01 10s ease-in-out -3s infinite alternate;
    animation: floatVertical01 10s ease-in-out -3s infinite alternate; }

.move-items2 {
  -webkit-animation: floatHorizontal02 12s ease-in-out infinite alternate;
  animation: floatHorizontal02 12s ease-in-out infinite alternate; }
  .move-items2 img {
    -webkit-animation: floatVertical02 10s ease-in-out infinite alternate;
    animation: floatVertical02 10s ease-in-out infinite alternate; }

.move-items2-delay {
  -webkit-animation: floatHorizontal02 12s ease-in-out -4.5s infinite alternate;
  animation: floatHorizontal02 12s ease-in-out -4.5s infinite alternate; }
  .move-items2-delay img {
    -webkit-animation: floatVertical02 10s ease-in-out -4.5s infinite alternate;
    animation: floatVertical02 10s ease-in-out -4.5s infinite alternate; }

.js-revealtext, .js-revealtext::after {
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-duration: 800ms;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }

.js-revealtext {
  position: relative;
  opacity: 0; }
  .js-revealtext.js-isdisplay {
    animation-name: clipText; }
  .js-revealtext::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2b2b2b;
    transform: scaleX(0);
    transform-origin: 0 50%;
    pointer-events: none; }
    .js-revealtext::after.js-isdisplay {
      animation-name: textRevealer; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes slideUp {
  0% {
    transform: translateY(20px); }
  100% {
    transform: translateY(0); } }

@keyframes drawerIn {
  0% {
    transform: translateY(-100vh);
    background: color-base01(0); }
  1% {
    background: color-base01(0.95); }
  100% {
    background: color-base01(0.95);
    transform: translateY(0); } }

@keyframes drawerOut {
  0% {
    transform: translateY(0);
    background: color-base01(0.95); }
  99% {
    background: color-base01(0.95); }
  100% {
    background: color-base01(0);
    transform: translateY(-100vh); } }

@keyframes moveLineUpDonw10 {
  0% {
    height: 35px;
    transform: translate(-50%, 0); }
  50% {
    height: 45px;
    transform: translate(-50%, -10px); }
  100% {
    height: 35px;
    transform: translate(-50%, 0); } }

@keyframes moveArwUpDonw10 {
  0% {
    transform: translate(-50%, 0); }
  50% {
    transform: translate(-50%, -10px); }
  100% {
    transform: translate(-50%, 0); } }

@keyframes scrollSlideInLeft {
  0% {
    transform: translateX(-105%); }
  100% {
    transform: translateX(0); } }

@keyframes scrollSlideInRight {
  0% {
    transform: translateX(105%); }
  100% {
    transform: translateX(0); } }

@keyframes hoverCardSeparator {
  0% {
    height: 0%; }
  100% {
    height: 100%; } }

@keyframes hoverCardBtmBorder {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

@keyframes hoverCircleBorder {
  0% {
    width: 0;
    background-color: #2b2b2b; }
  100% {
    width: 100%;
    background-color: #2b2b2b; } }

@keyframes textNoise1-line1 {
  0% {
    clip: rect(16px, 9999px, 33px, 0); }
  5% {
    clip: rect(2px, 9999px, 29px, 0); }
  10% {
    clip: rect(60px, 9999px, 53px, 0); }
  15% {
    clip: rect(2px, 9999px, 32px, 0); }
  20% {
    clip: rect(42px, 9999px, 41px, 0); }
  25% {
    clip: rect(43px, 9999px, 53px, 0); }
  30% {
    clip: rect(16px, 9999px, 2px, 0); }
  35% {
    clip: rect(23px, 9999px, 56px, 0); }
  40% {
    clip: rect(24px, 9999px, 24px, 0); }
  45% {
    clip: rect(26px, 9999px, 60px, 0); }
  50% {
    clip: rect(59px, 9999px, 44px, 0); }
  55% {
    clip: rect(46px, 9999px, 31px, 0); }
  60% {
    clip: rect(26px, 9999px, 7px, 0); }
  65% {
    clip: rect(49px, 9999px, 45px, 0); }
  70% {
    clip: rect(35px, 9999px, 44px, 0); }
  75% {
    clip: rect(19px, 9999px, 27px, 0); }
  80% {
    clip: rect(58px, 9999px, 35px, 0); }
  85% {
    clip: rect(24px, 9999px, 19px, 0); }
  90% {
    clip: rect(59px, 9999px, 11px, 0); }
  95% {
    clip: rect(44px, 9999px, 33px, 0); }
  100% {
    clip: rect(7px, 9999px, 40px, 0); } }

@keyframes textNoise2-line1 {
  0% {
    clip: rect(36px, 9999px, 45px, 0); }
  5% {
    clip: rect(41px, 9999px, 52px, 0); }
  10% {
    clip: rect(37px, 9999px, 7px, 0); }
  15% {
    clip: rect(31px, 9999px, 12px, 0); }
  20% {
    clip: rect(50px, 9999px, 35px, 0); }
  25% {
    clip: rect(35px, 9999px, 6px, 0); }
  30% {
    clip: rect(44px, 9999px, 35px, 0); }
  35% {
    clip: rect(46px, 9999px, 20px, 0); }
  40% {
    clip: rect(9px, 9999px, 41px, 0); }
  45% {
    clip: rect(56px, 9999px, 49px, 0); }
  50% {
    clip: rect(21px, 9999px, 18px, 0); }
  55% {
    clip: rect(49px, 9999px, 54px, 0); }
  60% {
    clip: rect(60px, 9999px, 20px, 0); }
  65% {
    clip: rect(25px, 9999px, 15px, 0); }
  70% {
    clip: rect(24px, 9999px, 56px, 0); }
  75% {
    clip: rect(36px, 9999px, 34px, 0); }
  80% {
    clip: rect(16px, 9999px, 42px, 0); }
  85% {
    clip: rect(40px, 9999px, 48px, 0); }
  90% {
    clip: rect(15px, 9999px, 25px, 0); }
  95% {
    clip: rect(16px, 9999px, 43px, 0); }
  100% {
    clip: rect(36px, 9999px, 61px, 0); } }

@keyframes textNoise1-line2 {
  0% {
    clip: rect(41px, 9999px, 67px, 0); }
  5% {
    clip: rect(15px, 9999px, 91px, 0); }
  10% {
    clip: rect(77px, 9999px, 62px, 0); }
  15% {
    clip: rect(31px, 9999px, 79px, 0); }
  20% {
    clip: rect(5px, 9999px, 90px, 0); }
  25% {
    clip: rect(11px, 9999px, 21px, 0); }
  30% {
    clip: rect(6px, 9999px, 62px, 0); }
  35% {
    clip: rect(91px, 9999px, 6px, 0); }
  40% {
    clip: rect(7px, 9999px, 46px, 0); }
  45% {
    clip: rect(14px, 9999px, 39px, 0); }
  50% {
    clip: rect(96px, 9999px, 8px, 0); }
  55% {
    clip: rect(71px, 9999px, 50px, 0); }
  60% {
    clip: rect(30px, 9999px, 99px, 0); }
  65% {
    clip: rect(31px, 9999px, 76px, 0); }
  70% {
    clip: rect(75px, 9999px, 58px, 0); }
  75% {
    clip: rect(94px, 9999px, 18px, 0); }
  80% {
    clip: rect(37px, 9999px, 81px, 0); }
  85% {
    clip: rect(48px, 9999px, 35px, 0); }
  90% {
    clip: rect(44px, 9999px, 86px, 0); }
  95% {
    clip: rect(65px, 9999px, 24px, 0); }
  100% {
    clip: rect(67px, 9999px, 63px, 0); } }

@keyframes textNoise2-line2 {
  0% {
    clip: rect(25px, 9999px, 34px, 0); }
  5% {
    clip: rect(52px, 9999px, 87px, 0); }
  10% {
    clip: rect(46px, 9999px, 61px, 0); }
  15% {
    clip: rect(44px, 9999px, 40px, 0); }
  20% {
    clip: rect(21px, 9999px, 59px, 0); }
  25% {
    clip: rect(33px, 9999px, 74px, 0); }
  30% {
    clip: rect(6px, 9999px, 77px, 0); }
  35% {
    clip: rect(38px, 9999px, 77px, 0); }
  40% {
    clip: rect(39px, 9999px, 42px, 0); }
  45% {
    clip: rect(45px, 9999px, 15px, 0); }
  50% {
    clip: rect(80px, 9999px, 19px, 0); }
  55% {
    clip: rect(68px, 9999px, 83px, 0); }
  60% {
    clip: rect(100px, 9999px, 57px, 0); }
  65% {
    clip: rect(92px, 9999px, 94px, 0); }
  70% {
    clip: rect(72px, 9999px, 50px, 0); }
  75% {
    clip: rect(35px, 9999px, 96px, 0); }
  80% {
    clip: rect(3px, 9999px, 80px, 0); }
  85% {
    clip: rect(4px, 9999px, 34px, 0); }
  90% {
    clip: rect(86px, 9999px, 18px, 0); }
  95% {
    clip: rect(92px, 9999px, 59px, 0); }
  100% {
    clip: rect(35px, 9999px, 95px, 0); } }

@keyframes rotateLogo {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes clipText {
  0% {
    clip-path: inset(0 100% 0 0);
    opacity: 0; }
  1% {
    opacity: 1; }
  100% {
    clip-path: inset(0 0 0 0);
    opacity: 1; } }

@keyframes textRevealer {
  0%, 50% {
    transform-origin: 0 50%; }
  60%, 100% {
    transform-origin: 100% 50%; }
  60% {
    transform: scaleX(1); }
  100% {
    transform: scaleX(0); } }

@keyframes marquee01 {
  0% {
    transform: translate(100%, 0); }
  100% {
    transform: translate(-100%, 0); } }

@keyframes marquee01Copy {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-200%, 0); } }

@-webkit-keyframes floatHorizontal01 {
  0% {
    transform: translate3d(1vw, 0, 0); }
  50% {
    transform: translate3d(-1vw, 0, 0); }
  100% {
    transform: translate3d(1vw, 0, 0); } }

@keyframes floatHorizontal01 {
  0% {
    transform: translate3d(1vw, 0, 0); }
  50% {
    transform: translate3d(-1vw, 0, 0); }
  100% {
    transform: translate3d(1vw, 0, 0); } }

@-webkit-keyframes floatVertical01 {
  0% {
    transform: translate3d(0, 1vw, 0); }
  50% {
    transform: translate3d(0, -1vw, 0); }
  100% {
    transform: translate3d(0, 1vw, 0); } }

@keyframes floatVertical01 {
  0% {
    transform: translate3d(0, 1vw, 0); }
  50% {
    transform: translate3d(0, -1vw, 0); }
  100% {
    transform: translate3d(0, 1vw, 0); } }

@-webkit-keyframes floatHorizontal02 {
  0% {
    transform: translate3d(-0.3vw, 0, 0); }
  50% {
    transform: translate3d(0.3vw, 0, 0); }
  100% {
    transform: translate3d(-0.3vw, 0, 0); } }

@keyframes floatHorizontal02 {
  0% {
    transform: translate3d(-0.3vw, 0, 0); }
  50% {
    transform: translate3d(0.3vw, 0, 0); }
  100% {
    transform: translate3d(-0.3vw, 0, 0); } }

@-webkit-keyframes floatVertical02 {
  0% {
    transform: translate3d(0, -0.3vw, 0); }
  50% {
    transform: translate3d(0, 0.3vw, 0); }
  100% {
    transform: translate3d(0, -0.3vw, 0); } }

@keyframes floatVertical02 {
  0% {
    transform: translate3d(0, -0.3vw, 0); }
  50% {
    transform: translate3d(0, 0.3vw, 0); }
  100% {
    transform: translate3d(0, -0.3vw, 0); } }

@keyframes blurWh {
  0% {
    opacity: 0;
    color: rgba(255, 255, 255, 0);
    text-shadow: 0 0 100px white; }
  5% {
    text-shadow: 0 0 90px white; }
  15% {
    opacity: 1;
    color: rgba(255, 255, 255, 0); }
  20% {
    color: white;
    text-shadow: 0 0 0px white; }
  95% {
    color: white;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23), 0px 0px 14px rgba(0, 0, 0, 0.43); }
  100% {
    color: white;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23), 0px 0px 14px rgba(0, 0, 0, 0.43); } }

@keyframes oneCharUp {
  0% {
    transform: translate3d(0, 2em, 0);
    transition: transform 850ms cubic-bezier(0.6, 0, 0.2, 1); }
  100% {
    transform: translate3d(0, 0, 0);
    transition: transform 850ms cubic-bezier(0.6, 0, 0.2, 1); } }

@keyframes hoverBurstAfterScale11 {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); } }

@keyframes hoverBurstAfterScale115 {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15); } }

@keyframes hoverBurstAfterScale12 {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); } }

@keyframes hoverBurstAfterScale13 {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3); } }

@keyframes hoverBurstAfterScale15 {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5); } }

@keyframes hoverBurstAfterOpacity {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
