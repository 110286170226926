.c-movetext {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-column;
  @include flex-justify-center;
  @include align-items-center;
  position: relative;
  box-sizing: border-box;
  
  &:before, &:after {
    display: block;
    position: relative;
    width: 100vw;
    height: 98px; //140px * 0.7
    content: "";
    background-color: color-gray01(1);

    @include breakpoint-sp {
      height: 112px; //140px * 0.8
    }
    @include breakpoint-tb {
      height: 126px; //140px * 0.9
    }
    @include breakpoint-pc {
      height: 140px;
    }
  }
  
  &.-about {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);

    &:before, &:after {
      display: none;
    }
  }
  
  &_inner {
    position: relative;
  }

  &_item {
    &.-works {
      height: 53px; //106px * 0.5
  
      @include breakpoint-sp {
        height: 63px; //106px * 0.6
      }
      @include breakpoint-tb {
        height: 84px; //106px * 0.8
      }
      @include breakpoint-pc {
        height: 106px;
      }
    }

    &.-service {
      height: 64px; //129px * 0.5
  
      @include breakpoint-sp {
        height: 77px; //129px * 0.6
      }
      @include breakpoint-tb {
        height: 103px; //129px * 0.8
      }
      @include breakpoint-pc {
        height: 129px;
      }
    }

    &.-about {
      height: 64px; //129px * 0.5
  
      @include breakpoint-sp {
        height: 77px; //129px * 0.6
      }
      @include breakpoint-tb {
        height: 103px; //129px * 0.8
      }
      @include breakpoint-pc {
        height: 129px;
      }
    }

    img {
      width: auto;
      height: 100%;
    }
  }
}