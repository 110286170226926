.p0-splitbox {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-flex-start;
  @include align-items-stretch;
  position: relative;
  width: 100%;

  @include breakpoint-pc {
//    @include flex-wrap-nowrap;
  }
  
  ///// 逆順 /////
  &.-reverse {
    @include flex-row-reverse;
  }

  &.-reversepc {
    @include flex-direction-row;

    @include breakpoint-pc {
      @include flex-row-reverse;
    }
  }

  &.-reversew720 {
    @include flex-direction-row;

    @include breakpoint-w720 {
      @include flex-row-reverse;
    }
  }

  &.-reversesp {
    @include flex-row-reverse;

    @include breakpoint-pc {
      @include flex-direction-row;
    }
  }

  &.-reversecolsp {
    @include flex-column-reverse;

    @include breakpoint-pc {
      @include flex-direction-row;
    }
  }

  ///// 半分 /////
  &.-half {
    .p0-splitbox_item {
      width: 100%;

      @include breakpoint-pc {
        width: 50%;
      }
    }
    
    &.-gapx05 {
      $gap-x-pc: 60px / 2;
      $gap-x-sp: 5% / 2;

      @include breakpoint-pc {
        gap: 0 5%;
      }
      @include breakpoint-max {
        gap: 0 60px;
      }

      .p0-splitbox_item {
        width: 100%;

        @include breakpoint-pc {
          width: calc(50% - #{$gap-x-sp} );
        }
        @include breakpoint-max {
          width: calc(50% - #{$gap-x-pc} );
        }
      }
    }
  }
  
  ///// 半分のみ /////
  &.-halfonly {
    .p0-splitbox_item {
      width: 50%;
    }
    
    &.-gapx05 {
      $gap-x-pc: 60px / 2;
      $gap-x-sp: 5% / 2;
      gap: 0 5%;

      @include breakpoint-max {
        gap: 0 60px;
      }

      .p0-splitbox_item {
        width: calc(50% - #{$gap-x-sp} );

        @include breakpoint-max {
          width: calc(50% - #{$gap-x-pc} );
        }
      }
    }
  }
  
  ///// 格子配列のカード /////
  &.-card {
    gap: 5vw 5%;

    @include breakpoint-pc {
      @include flex-wrap-wrap;
    }
    @include breakpoint-max {
      gap: 80px 80px;
    }
    
    .p0-splitbox_item {
      &.-col4 {
        $gap-x-pc: 80px / 3 * 2;
        $gap-x-sp: 5% / 3 * 2;

        width: calc( (100% / 3) - #{$gap-x-sp} );

        @include breakpoint-max {
          width: calc( (100% / 3) - #{$gap-x-pc} );
        }
      
        &.-res {
          $gap-x-max: 80px / 3 * 2;
          $gap-x-pc: 5% / 3 * 2;
          $gap-x-tb: 5% / 2;

          width: 100%;
          
          @include breakpoint-tb {
            width: calc( 50% - #{$gap-x-tb} );
          }
          @include breakpoint-pc {
            width: calc( (100% / 3) - #{$gap-x-pc} );
          }
          @include breakpoint-max {
            width: calc( (100% / 3) - #{$gap-x-max} );
          }
        }
      }

      &.-col6 {
        $gap-x-pc: 80px / 2;
        $gap-x-sp: 5% / 2;

        width: calc( (100% / 2) - #{$gap-x-sp} );

        @include breakpoint-max {
          width: calc( (100% / 2) - #{$gap-x-pc} );
        }
      }
    }
  }


  ///// スライドショーのカード /////
  &.-slider {
    gap: 0 5%;

    @include breakpoint-max {
      gap: 0 80px;
    }
  }
  
  
  ///// 作品事例カテゴリーのカテゴリー一覧 /////
  &.-workstagcat {
    gap: 2.5vw 0;

    @include breakpoint-narrow {
      gap: 40px 0;
    }

    .p0-splitbox_item {
      padding: 10px 20px 15px;
      border-left: 1px solid color-wh(1);
      box-sizing: border-box;

      @include breakpoint-pc {
        padding: 15px 30px 20px;
      }
      @include breakpoint-narrow {
        padding: 15px 40px 20px;
      }
    }
  }

  
  ///// 格子配列のサークル /////
  &.-circle {
    .p0-splitbox_item {
      width: 100%;
      padding: 0 5px 7.5px;
      box-sizing: border-box;

      @include breakpoint-sp {
        width: 50%;
      }
      @include breakpoint-tb {
        padding: 0 1.3vw 1.7vw; //15px / 1120px * 100, 20px / 1120px * 100
      }
      @include breakpoint-contentinnerhalf {
        width: calc(100% / 3);
      }
      @include breakpoint-narrowinner {
        width: 25%;
      }
      @include breakpoint-narrow {
        padding: 0 15px 20px;
      }
    }
  }
  
  
  ///// ABOUT USのSPIRITS /////
  &.-aboutspirits {
    @include flex-justify-space-between;
    @include align-items-start;
    position: relative;
    margin-bottom: 30px;
    
    &:after {
      display: block;
      position: relative;
      width: 100%;
      height: 15px;
      content: "";
      margin-top: 30px;
      background-image: url("../images/about/dotline.png");
      background-position: left center;
      background-repeat: repeat-x;
      background-size: auto 100%;
      
      @include breakpoint-w720 {
        display: none;
        margin-top: 0;
      }
    }
    
    @include breakpoint-w720 {
      margin-bottom: 60px;
    }
    @include breakpoint-pc {
      margin-bottom: 80px;
    }
    @include breakpoint-narrowinner {
      margin-bottom: 100px;
    }
    @include breakpoint-contentinner {
      margin-bottom: 200px;
    }
    
    &.-last {
      @include breakpoint-w720 {
        margin-bottom: 30px;
      }
      @include breakpoint-pc {
        margin-bottom: 40px;
      }
      @include breakpoint-narrowinner {
        margin-bottom: 50px;
      }
      @include breakpoint-contentinner {
        margin-bottom: 100px;
      }

      &:after {
        display: none;
        margin-top: 0;
      }
    }

    .p0-splitbox_item {
      position: relative;

      &.-img {
        width: 100%;

        @include breakpoint-w720 {
          width: 47.5%;
        }
        @include breakpoint-contentinner {
          width: 39.1%;
        }
      }
      
      &.-txt {
        width: 100%;
        margin-bottom: 20px;

        @include breakpoint-tb {
          margin-bottom: 30px;
        }
        @include breakpoint-w720 {
          width: 47.5%;
          margin-bottom: 0;
        }
        @include breakpoint-contentinner {
          width: 52.5%;
        }
      }
    }
  }

  
  ///// ABOUT USのサービスの画像・テキスト /////
  &.-aboutservice {
    .p0-splitbox_item {
      box-sizing: border-box;

      &.-txt {
        width: 100%;
        margin-bottom: 35px;
        
        @include breakpoint-w720 {
          width: 740px / 1200px * 100%;
          margin-bottom: 0;
          padding-bottom: 100px;
        }
        @include breakpoint-pc {
          padding-bottom: 115px;
        }
      }

      &.-img {
        width: 100%;
        max-width: 240px;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 35px;
        }
        @include breakpoint-w720 {
          width: 460px / 1200px * 100%;
          max-width: none;
          margin-bottom: 0;
          margin-left: 0;
//          padding-bottom: 125px;
        }
        @include breakpoint-pc {
//          padding-bottom: 50px;
        }
        @media all and (min-width: 900px) {
//          padding-bottom: 0;
        }
        @include breakpoint-narrowinner {
//          padding-bottom: 0;
        }
      }
    }
  }

  ///// ABOUT USのMISSION下部の写真 /////
  &.-aboutmissionbtm {
    @include flex-justify-space-between;
    @include align-items-start;
    width: 100%;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    box-sizing: border-box;

    @include breakpoint-pc {
      padding-bottom: 20px;
    }
    @include breakpoint-contentinner {
      margin-left: auto;
      margin-right: 0;
    }
    @include breakpoint-content {
      margin-left: -4vw;
      margin-right: auto;
    }
    @include breakpoint-maxinner {
      margin-left: -7.5%;
    }

    .p0-splitbox_item {
      position: relative;
      box-sizing: border-box;

      &.-img1 {
        width: 265px / 430px * 100%;

        @include breakpoint-w720 {
          width: 440px / 650px * 100%;
        }
        @include breakpoint-narrow {
          width: 720px / 1120px * 100%;
        }
      }
      &.-img2 {
        width: 145px / 430px * 100%;
        padding-top: 35px;

        @include breakpoint-contentinnerhalf {
          padding-top: 40px;
        }
        @include breakpoint-w720 {
          width: 190px / 650px * 100%;
          padding-top: 90px;
        }
        @include breakpoint-pc {
          padding-top: 100px;
        }
        @include breakpoint-narrowinner {
          padding-top: 170px;
        }
        @include breakpoint-narrow {
          width: 310px / 1120px * 100%;
          padding-top: 180px;
        }
        @include breakpoint-contentinner {
          padding-top: 200px;
        }
      }
    }
  }

  ///// ABOUT USのSPIRITS下部の写真 /////
  &.-aboutspiritsbtm {
    @include flex-justify-space-between;
    @include align-items-start;
    z-index: 1;
    width: 100%;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 75px;
    box-sizing: border-box;

    @include breakpoint-pc {
      margin-bottom: 150px;
    }
    @include breakpoint-contentinner {
      margin-left: auto;
      margin-right: 0;
    }
    @include breakpoint-content {
      margin-left: 10.66vw;
      margin-right: auto;
    }
    @include breakpoint-maxinner {
      margin-left: 13.3%;
    }

    .p0-splitbox_item {
      position: relative;
      box-sizing: border-box;

      &.-img1 {
        width: 145px / 430px * 100%;
        padding-top: 35px;

        @include breakpoint-contentinnerhalf {
          padding-top: 40px;
        }
        @include breakpoint-w720 {
          width: 190px / 650px * 100%;
          padding-top: 90px;
        }
        @include breakpoint-pc {
          padding-top: 100px;
        }
        @include breakpoint-narrowinner {
          padding-top: 170px;
        }
        @include breakpoint-narrow {
          width: 310px / 1120px * 100%;
          padding-top: 180px;
        }
        @include breakpoint-contentinner {
          padding-top: 200px;
        }
      }
      &.-img2 {
        width: 265px / 430px * 100%;

        @include breakpoint-w720 {
          width: 440px / 650px * 100%;
        }
        @include breakpoint-narrow {
          width: 720px / 1120px * 100%;
        }
      }
    }
  }


  ///// 各カード /////
  &_item {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    @include breakpoint-tb {
    }
    @include breakpoint-pc {
    }
    
    //12等分の内、いくつ分の幅に相当するかで指定
    &.-col1 {
      width: calc(100% / 12);
    }
    
    &.-col2 {
      width: calc(100% / 6);
    }

    &.-col3 {
      width: 25%;
    }
    
    &.-col4 {
      width: calc(100% / 3);
      
      &.-res {
        width: 100%;
        
        @include breakpoint-tb {
          width: 50%;
        }
        @include breakpoint-pc {
          width: calc(100% / 3);
        }
      }
      
      &.-wtcat {
        width: 100%;
        
        @include breakpoint-contentinnerhalf {
          width: 50%;
        }
        @include breakpoint-narrow {
          width: calc(100% / 3);
        }
      }
    }
    
    &.-col6 {
      width: 50%;
    }
    
    &.-col9 {
      width: 75%;
    }

    &.-col12 {
      width: 100%;
    }


    ///// トップページのトピックスレイアウト用 /////
    // 固定幅
    &.-topicshead {
      @include flexbox;
      @include flex-shrink-0;
      @include flex-justify-center;
      @include align-items-center;
      @include flex-align-content-center;
      width: 100%;
      margin-bottom: 25px;

      @include breakpoint-narrowinner {
        @include flex-justify-flex-start;
        width: 200px;
        margin-bottom: 0;
      }
      @include breakpoint-narrow {
        width: 250px;
      }
    }

    // 可変幅
    &.-topicsbody {
      width: 100%;

      @include breakpoint-narrowinner {
        width: calc(100% - 200px);
      }
      @include breakpoint-narrow {
        width: calc(100% - 250px);
      }
    }


    ///// Worksのsingleページのレイアウト用 /////
    // 固定幅
    &.-workshead {
      width: 100%;
      box-sizing: border-box;

      @include breakpoint-pc {
        width: 260px;
      }
      @include breakpoint-narrow {
        width: 310px;
      }
      
      &.-lead {
        @include flexbox;
        @include flex-direction-column;
        @include flex-justify-flex-start;
        @include align-items-stretch;

        margin-bottom: 25px;
        padding: 15px 30px 15px 0;

        @include breakpoint-pc {
          margin-bottom: 0;
          padding: 30px 60px 30px 0;
        }
        @include breakpoint-content {
          padding-right: 70px;
        }
      }

      &.-comment {
        margin-bottom: 30px;
        
        @include breakpoint-pc {
          margin-bottom: 0;
        }
      }
    }

    // 可変幅
    &.-worksbody {
      width: 100%;
      box-sizing: border-box;

      @include breakpoint-pc {
        width: calc(100% - 260px);
      }
      @include breakpoint-narrow {
        width: calc(100% - 310px);
      }
      
      &.-comment {
        padding-left: 0;
        
        @include breakpoint-pc {
          padding-left: 60px;
        }
      }
    }
    
    // 絶対位置指定
    &.-absolute {
      position: absolute;

      // Worksのsingleページのcommentにある「page top」アンカーリンク
      &.-comment {
        bottom: 48px;
        left: 0;

        @include breakpoint-pc {
          bottom: 30px;
        }
      }
    }


    ///// Serviceページのレイアウト用 /////
    // 狭いボックス
    &.-servicenarrow {
      width: 100%;
      
      @include breakpoint-pc {
        width: (460px / 1200px) * 100%;
      }
    }

    // 広いボックス
    &.-servicewide {
      width: 100%;
      
      @include breakpoint-pc {
        width: (740px / 1200px) * 100%;
      }
    }
  }
}