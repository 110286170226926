.l-header {
  width: 100%;
  height: $drawer-space-sp;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;

  @include breakpoint-narrowinner {
    height: $drawer-space-pc;
  }
  
  &_inner {
    position: relative;
    width: 90%;
//    max-width: $max;  //ヘッダーグロナビの中身の最大横幅
    height: 100%;
    margin: 0 auto;

    @include breakpoint-max {
      width: calc(100% - 60px - 60px);
    }
  }
}