.c-flex {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include align-items-stretch;

  &_item {
    &.-box2 {
      width: 100%;

      @include breakpoint-pc {
        width: 47.5%;
      }
    }
  }
}

/*
.c-flex02 {
  @include flexbox;
  @include flex-direction-column;
  @include flex-wrap-wrap;

  @include breakpoint-pc {
    @include flex-direction-row;
    @include flex-wrap-nowrap;
    @include flex-justify-space-between;
  }
  &_item {
    &.-item01 {
      width: 60vw;
      max-width: 500px;
      margin: 0 auto;
      order: 2;
      display: none;
      @include breakpoint-pc {
        width: 40%;
        padding: 0 5% 0 0;
        order: 1;
        display: block;
      }
      @include breakpoint-pc {
        width: 50%;
      }
      @media (min-width:900px) {
        width: 500px;
        padding: 0 60px 0 0;
      }
    }
    &.-item02 {
      width: 100%;
      order: 1;
      margin-bottom: 6vw;
      @include breakpoint-pc {
        width: 60%;
        order: 2;
        margin-bottom: 0;
      }
      @include breakpoint-pc {
        width: 50%;
      }
      @media (min-width:900px) {
        width: 500px;
      }
    }
  }
}
*/