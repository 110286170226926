/* トップページのみで使用するCSS */
.p0-front {
  ///// Service /////
  &_about {
    // About Usボタンまで
    &_box1 {
      box-sizing: border-box;
    }
    
    // 写真＆動画エリア
    &_box2 {
      box-sizing: border-box;
      padding-top: 55px;

      @include breakpoint-contentinnerhalf {
        padding-top: 75px;
      }
      @include breakpoint-w720 {
        padding-top: 65px;
      }
      @include breakpoint-pc {
        padding-top: 120px;
      }
      @include breakpoint-narrowinner {
        padding-top: 180px;
      }
      @include breakpoint-max {
        padding-top: 200px;
      }
    }
    
    // 英字テキスト＆スクロールで背景色が変わっていくエリア
    &_box3 {
      position: relative;
      height: 100vh;

      @include breakpoint-pc {
//        padding: 950px 0 690px; //上下余白を、それぞれ3倍にしたバージョン(スクロール時のテキストの動きを変更することになったので、一旦撤回。念の為、確定するまではコメントアウトで残しておく)
//        padding: 390px 0 390px;
//        padding: 390px 0 150px;
      }
    }
    
    // リード文
    &_lead {
      box-sizing: border-box;
      margin-bottom: 30px;
      padding-top: 40px;

      @include breakpoint-contentinnerhalf {
        margin-bottom: 40px;
        padding-top: 60px;
      }
      @include breakpoint-pc {
        margin-bottom: 70px;
        padding-top: 50px;
      }
      @include breakpoint-narrowinner {
        margin-bottom: 70px;
        padding-top: 120px;
      }
      @include breakpoint-narrow {
        margin-bottom: 110px;
      }
      @include breakpoint-max {
        margin-bottom: 140px;
      }
    }
    
    // 本文
    &_txt {
      margin-bottom: 20px;

      @include breakpoint-pc {
        margin-bottom: 120px;
      }
      @include breakpoint-narrowinner {
        margin-bottom: 110px;
      }
      @include breakpoint-narrow {
        margin-bottom: 190px;
      }
    }
    
    // ノイズエフェクトテキスト
    &_noisetext {
      position: relative;
      top: 10vw;  //50px / 480px * 100
      width: 100%;

      @include breakpoint-tb {
        top: 50px;
      }
      @include breakpoint-contentinnerhalf {
        top: 30px;
      }
      @include breakpoint-w720 {
        top: 65px;
        width: calc(100% + 5vw);
        transform: translateX(-5vw);
      }
      @include breakpoint-pc {
        top: 0;
        width: 100%;
        transform: translateX(0);
        margin-bottom: 50px;
      }
      @include breakpoint-narrowinner {
        transform: translateX(0);
      }
      @include breakpoint-narrow {
        transform: translateX(-20px);
      }
      @include breakpoint-max {
        transform: translateX(-50px);
      }

      &.-sp {
        display: block;
        
        @include breakpoint-w720 {
          display: none;
        }
      }

      &.-w720 {
        display: none;
        
        @include breakpoint-w720 {
          display: block;
        }
        @include breakpoint-pc {
          display: none;
        }
      }

      &.-pc {
        display: none;
        
        @include breakpoint-pc {
          display: block;
        }
      }
    }
    
    // 花火
    &_firework {
      @include breakpoint-pc {
        margin-bottom: -90px;
      }

      &.-sp {
        display: block;
        
        @include breakpoint-pc {
          display: none;
        }
      }
      
      &.-pc {
        display: none;
        
        @include breakpoint-pc {
          display: block;
        }
      }
    }
    
    // 回転円ロゴ
    &_logo {
      position: relative;
      box-sizing: border-box;

      @include breakpoint-pc {
        margin-bottom: 110px;
        padding-top: 10px;
      }
      
      &.-sp {
        display: block;
        position: absolute;
        top: 10px;
        right: 4.1vw; //20px / 480px * 100
        
        @include breakpoint-tb {
          top: 5px;
          right: 4.1vw; //20px / 480px * 100
        }
        @include breakpoint-contentinnerhalf {
          top: -10px;
          right: -2.5vw; //-15px / 600px * 100
        }
        @include breakpoint-w720 {
          top: 5px;
        }
        @include breakpoint-pc {
          display: none;
        }
      }
      
      &.-pc {
        display: none;
        
        @include breakpoint-pc {
          display: block;
        }
        @include breakpoint-narrowinner {
          transform: translateX(2.5vw);
        }
        @include breakpoint-narrow {
        }
        @include breakpoint-contentinner {
          transform: translateX(0);
        }
      }
    }
    
    // 写真＆動画
    &_img {
      position: relative;
      width: 100%;
      
      img {
        width: 100%;
        height: auto;
      }
      
      // 各動画・画像のmargin-bottomは、maxまでは1000px時のPSDの値をもとに、vwで算出して設定する

      &.-i01 {
        width: 44.375vw; //213px / 480px * 100
        margin-bottom: 10.416vw; //50px / 480px * 100
        margin-left: auto;
        margin-right: -10.416vw;  //50px / 480px * 100(%)

        @include breakpoint-w720 {
          width: 75.8%;  //546px / 720px * 100(%)
          margin-bottom: 2.9vw; //29px / 1000px * 100
          margin-left: auto;
          margin-right: -10%;  //72px / 720px * 100(%)
        }
        @include breakpoint-max {
          margin-bottom: 50px;
        }
      }
      &.-i02 {
        width: 39.166vw; //188px / 480px * 100
        margin-bottom: 5vw; //50px / 1000px * 100
        margin-left: auto;
        margin-right: 0;  

        @include breakpoint-w720 {
          width: 63.2%;  //455px / 720px * 100(%)
          margin-bottom: 5vw; //50px / 1000px * 100
          margin-left: 14.5%;  //105px / 720px * 100(%)
          margin-right: auto;  
        }
        @include breakpoint-pc {
          margin-bottom: 50px;
        }
        @include breakpoint-max {
          margin-left: 14.5%;  //105px / 720px * 100(%)
          margin-right: auto;  
          width: 63.2%;  //455px / 720px * 100(%)
        }
      }
      &.-i03 {
        width: 24.375vw; //117px / 480px * 100
        margin-left: -6.66vw;  //-32px / 480px * 100(%)
        margin-right: auto;
        z-index: 2;

        @include breakpoint-w720 {
          width: 41.6%; //300px / 720px * 100(%)
          margin-left: auto;
          margin-right: 8.3%;  //60px / 720px * 100(%)
        }
        @include breakpoint-max {
          width: 41.6%; //300px / 720px * 100(%)
          margin-left: auto;
          margin-right: 8.3%;  //60px / 720px * 100(%)
        }
      }
      &.-i04 {
        margin-top: -20.83vw;//100px / 480px * 100(vw)
        margin-left: auto;
        margin-right: 2.08vw; //10px / 480px * 100(%)
        width: 26.875vw; //129px / 480px * 100
        margin-bottom: 10.5vw; //60px / 480px * 100
        z-index: 3;

        @include breakpoint-w720 {
          z-index: 1;
          width: 50%; //360px / 720px * 100(%)
          margin-top: -16.8vw;//273px / 1620px * 100(vw)
          margin-left: 11.1%;  //80px / 720px * 100(%)
          margin-right: auto;  
          margin-bottom: 4vw; //40px / 1000px * 100
        }
        @include breakpoint-pc {
          margin-top: -16.8vw;//273px / 1620px * 100(vw)
          margin-left: 11.1%;  //80px / 720px * 100(%)
          margin-right: auto;  
          width: 50%; //360px / 720px * 100(%)
          margin-bottom: 40px;
        }
        @include breakpoint-max {
          margin-top: -273px;
        }
      }

      &.-m01 {
        left: 2.7%; //20px / 720px * 100(%)
        width: 44.375vw; //213px / 480px * 100
        margin-bottom: 5.2vw; //25px / 480px * 100

        @include breakpoint-w720 {
          left: 2.7%; //20px / 720px * 100(%)
          width: 100%;
          margin-bottom: 7.6vw; //76px / 1000px * 100
        }
        @include breakpoint-max {
          margin-bottom: 130px;
        }
      }
      &.-m02 {
        left: -1.3%; //10px / 720px * 100(%)
        z-index: 3;
        width: 44.375vw; //213px / 480px * 100
        margin-bottom: 15vw; //72px / 480px * 100

        @include breakpoint-w720 {
          left: -1.3%; //10px / 720px * 100(%)
          width: 100%;
          margin-bottom: 3.1vw; //31px / 1000px * 100
        }
        @include breakpoint-max {
          margin-bottom: 50px;
        }
      }
      &.-m03 {
        left: 1.3%; //10px / 720px * 100(%)
        width: 44.375vw; //213px / 480px * 100
        margin-top: 6.25vw;  //30px / 480px * 100
        margin-bottom: 8.33vw; //40px / 480px * 100

        @include breakpoint-w720 {
          left: 1.3%; //10px / 720px * 100(%)
          width: 100%;
          margin-top: 100px;
          margin-bottom: 4.1vw; //41px / 1000px * 100
        }
        @include breakpoint-max {
          left: 1.3%; //10px / 720px * 100(%)
          margin-top: 100px;
          margin-bottom: 70px;
        }
      }
      &.-m04 {
        right: 13.1%; //95px / 720px * 100(%)
        width: 44.375vw; //213px / 480px * 100
        margin-bottom: 5vw; //50px / 1000px * 100

        @include breakpoint-w720 {
          right: 13.1%; //95px / 720px * 100(%)
          width: 100%;
          margin-bottom: 5vw; //50px / 1000px * 100
        }
        @include breakpoint-pc {
          margin-bottom: 50px;
        }
        @include breakpoint-max {
          right: 13.1%; //95px / 720px * 100(%)
          margin-bottom: 50px;
        }
      }
    }
  }

  
  ///// Project /////
  &_project {
    box-sizing: border-box;
    padding-bottom: 110px; 
  
    @include breakpoint-pc {
      padding-bottom: 130px; 
    }
    @include breakpoint-narrowinner {
      padding-bottom: 150px; 
    }
    @include breakpoint-narrow {
      padding-bottom: 170px; 
    }
  }


  ///// Service /////
  &_service {
    &_slide {
      @include breakpoint-pc {
        width: 100%;
        height: 100%;
      }

      &:hover, &:focus {
        .p0-front_service_slide_bg {
          filter: alpha(opacity=60);
          opacity: 0.6;
        }
      }
      
      &_inner {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background-color: color-gray02(1);
        transition: transform 0.3s ease-in-out;

        @include breakpoint-pc {
          border-radius: 25px;
        }
        @include breakpoint-narrowinner {
          border-radius: 30px;
        }
        @include breakpoint-narrow {
          border-radius: 35px;
        }
        @include breakpoint-max {
          border-radius: 50px;
        }
      }
      
      &_imgsize {
        display: block;
        
        @include breakpoint-pc {
          display: none;
        }
      }
      
      &_bg {
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: opacity 0.3s ease-in-out;

        @include breakpoint-pc {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      
      &.swiper-slide-prev, &.swiper-slide-next {
        .p0-front_service_slide_inner {
          @include breakpoint-pc {
            transform: translateX(-100px);
          }
        }
      }
    }

    &_slug {
      position: absolute;
      right: 15px;
      bottom: 15px;

      @include breakpoint-contentinnerhalf {
        right: 18px;
        bottom: 18px;
      }
      @include breakpoint-pc {
        right: 20px;
        bottom: 20px;
      }
      @include breakpoint-narrowinner {
        right: 25px;
        bottom: 25px;
      }
      @include breakpoint-narrow {
        right: 30px;
        bottom: 30px;
      }
      @include breakpoint-max {
        right: 40px;
        bottom: 40px;
      }
    }
  }
  
  ///// All Works /////
  &_works {
//    overflow: hidden;
    width: 100%;
    
    &_inner {
      height: 100%;
    }
    
    &_boxes {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-stretch;
      width: 100%;
      height: 100%;
    }
    
    &_box1 {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-column;
      @include flex-justify-center;
      @include align-items-stretch;
      position: relative;
      width: 100%;
      background-color: color-gray01(1);

      @include breakpoint-narrowinner {
        width: 58%;
      }
      
      &:before, &:after {
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        content: "";
        background-color: color-gray01(1);
      }
      &:before {
        width: 100vw;
        left: 0;
        transform: translateX(-100%);
      }
      &:after {
        width: 215px;
        right: 0;
        transform: translateX(100%);
      }
    }
    
    &_box2 {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      padding: 0 0 0 0;
      box-sizing: border-box;
      background-color: color-gray01(1);

      @include breakpoint-pc {
        padding: 0 0 0 0;
      }
      @include breakpoint-narrowinner {
        width: 42%;
        padding: 110px 0 70px 0;
        background-color: transparent;
      }
      @include breakpoint-narrow {
        padding-left: 40px;
      }

      &:before, &:after {
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        content: "";
        background-color: color-gray01(1);

        @include breakpoint-narrowinner {
          display: none;
        }
      }
      &:before {
        width: 100vw;
        left: 0;
        transform: translateX(-100%);
      }
      &:after {
        width: 215px;
        right: 0;
        transform: translateX(100%);
      }
    }

    &_masktxt {
      width: auto;
      margin-left: auto;
      margin-right: auto;
      padding: 70px 0 40px 0;
      box-sizing: border-box;
//      background-color: color-gray01(1);
      background-image: url("../images/front/works/works_bg1.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      
      @include breakpoint-narrowinner {
        @include flex-shrink-0;
        width: 100%;
        margin-left: 0;
        padding: 120px 0 70px 0;
      }

      img {
        width: 100%;
        max-width: 581px;
        height: auto;
  
        @include breakpoint-pc {
          width: 100%;
        }
      }
    }
    
    &_allworks {
      @include flex-shrink-0;
      padding-bottom: 110px;
      box-sizing: border-box;
      background-color: color-gray01(1);
      text-align: center;

      @include breakpoint-narrowinner {
        padding-bottom: 140px;
        text-align: left;
      }
      
      &.-ni {
        display: none;

        @include breakpoint-narrowinner {
          display: block;
        }
      }
      
      &.-sp {
        display: block;

        @include breakpoint-narrowinner {
          display: none;
        }
      }
    }

    &_tags {
/*
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-stretch;
*/
      display: inline-block;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin: 0 auto 45px;
      line-height: 1;

      @include breakpoint-narrowinner {
        left: 0;
        transform: translateX(0);
//        @include flex-justify-flex-start;
        margin-bottom: 0;
      }
      
    }
    
    &_tagline {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-stretch;
      line-height: 1;

      &.-line01 {
        margin-bottom: 5px;

        @include breakpoint-narrow {
          margin-bottom: 10px;
        }
      }

      &.-line02 {
        @include align-items-baseline;
        margin-bottom: 5px;

        @include breakpoint-pc {
          margin-bottom: 10px;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 5px;
        }
        @include breakpoint-narrow {
          margin-bottom: 10px;
        }
      }

      &.-line03 {
        margin-bottom: 7px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 0;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 10px;
        }
      }

      &.-line04 {
        margin-bottom: 2px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 0;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 2px;
        }
        @include breakpoint-narrow {
          margin-bottom: 4px;
        }
      }

      &.-line05 {
        margin-bottom: 0;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 5px;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 0;
        }
      }

      &.-line06 {
        margin-bottom: 5px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 0;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 5px;
        }
      }

      &.-line07 {
        //この行は、margin-bottom無し
        margin-bottom: 0;
      }

      &.-line08 {
        margin-bottom: 5px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 0;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 5px;
        }
      }

      &.-line09 {
        margin-bottom: 10px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 0;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 10px;
        }
      }

      &.-line10 {
        margin-bottom: 10px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 0;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 10px;
        }
      }

      &.-line11 {
        margin-bottom: 10px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 15px;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 15px;
        }
      }

      &.-line12 {
        margin-bottom: 3px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 0;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 5px;
        }
      }

      &.-line13 {
        margin-bottom: 1px;

        @include breakpoint-contentinnerhalf {
          @include align-items-end;
          margin-bottom: 10px;
        }
        @include breakpoint-narrowinner {
          @include align-items-stretch;
          margin-bottom: 0;
        }
      }

      &.-line14 {
        margin-bottom: 10px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 0;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 15px;
        }
      }

      &.-line15 {
        margin-bottom: 15px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 3px;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 20px;
        }
      }

      &.-line16 {
        margin-bottom: 10px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 0;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 10px;
        }
      }

      &.-line17 {
        margin-bottom: 10px;

        @include breakpoint-contentinnerhalf {
          margin-bottom: 0;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 15px;
        }
      }

      &.-line18 {
        //この行は、margin-bottom無し
        margin-bottom: 0;
      }
    }
    
    &_tag {
      position: relative;
      box-sizing: border-box;
      line-height: 1;
      
      // 599px未満、1000px以上の場合のみ表示
      &.-disppat1 {
        display: block;

        @include breakpoint-contentinnerhalf {
          display: none;
        }
        @include breakpoint-narrowinner {
          display: block;
        }
      }
      
      // 600px〜999pxの場合のみ表示
      &.-disppat2 {
        display: none;

        @include breakpoint-contentinnerhalf {
          display: block;
        }
        @include breakpoint-narrowinner {
          display: none;
        }
      }

      //各タグの上下左右余白調整
      &.-tag01 {
        margin-left: 4.166666666666667vw;  //20px / 480px * 100

        @include breakpoint-tb {
          margin-left: 20px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 0;
        }
        @include breakpoint-narrowinner {
          margin-left: 20px;
        }
      }
      &.-tag02 {
        margin-left: 6.25vw;  //30px / 480px * 100

        @include breakpoint-tb {
          margin-left: 30px;
        }
        @include breakpoint-w720 {
          margin-left: 60px;
        }
        @include breakpoint-narrowinner {
          margin-left: 35px;
        }
      }
      &.-tag03 {
        margin-left: 10.416666666666667vw;  //50px / 480px * 100

        @include breakpoint-tb {
          margin-left: 50px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 60px;
        }
        @include breakpoint-w720 {
          margin-left: 70px;
        }
        @include breakpoint-narrowinner {
          margin-left: 65px;
        }
      }
      &.-tag04 {
        margin-left: 20.833333333333333vw;  //100px / 480px * 100

        @include breakpoint-tb {
          margin-left: 100px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 50px;
        }
        @include breakpoint-w720 {
          margin-left: 90px;
        }
        @include breakpoint-narrowinner {
          margin-left: 120px;
        }
      }
      &.-tag05 {
        margin-left: 33.333333333333333vw;  //160px / 480px * 100

        @include breakpoint-tb {
          margin-left: 160px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 80px;
        }
        @include breakpoint-w720 {
          margin-left: 120px;
        }
        @include breakpoint-narrowinner {
          margin-left: 180px;
        }
      }
      &.-tag06 {
        margin-left: 0;

        @include breakpoint-tb {
          margin-left: 0;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 15px;
        }
        @include breakpoint-w720 {
          margin-left: 45px;
        }
        @include breakpoint-narrowinner {
          margin-left: 5px;
        }
      }
      &.-tag07 {
        margin-left: 43.75vw;  //210px / 480px * 100

        @include breakpoint-tb {
          margin-left: 210px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 120px;
        }
        @include breakpoint-w720 {
          margin-left: 135px;
        }
        @include breakpoint-narrowinner {
          margin-left: 240px;
        }
      }
      &.-tag08 {
        margin-left: 6.25vw;  //30px / 480px * 100

        @include breakpoint-tb {
          margin-left: 30px;
        }
        @include breakpoint-contentinnerhalf {
          transform: translateY(3px);
          margin-left: 75px;
        }
        @include breakpoint-w720 {
          margin-left: 90px;
        }
        @include breakpoint-narrowinner {
          transform: translateY(0);
          margin-left: 35px;
        }
      }
      &.-tag09 {
        margin-left: 41.666666666666667vw;  //200px / 480px * 100

        @include breakpoint-tb {
          margin-left: 200px;
        }
        @include breakpoint-contentinnerhalf {
          transform: translateY(-4px);
          margin-left: 25px;
        }
        @include breakpoint-w720 {
          margin-left: 40px;
        }
        @include breakpoint-narrowinner {
          transform: translateY(0);
          margin-left: 230px;
        }
      }
      &.-tag10 {
        margin-left: 22.916666666666667vw;  //110px / 480px * 100

        @include breakpoint-tb {
          margin-left: 110px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 120px;
        }
        @include breakpoint-w720 {
          margin-left: 170px;
        }
        @include breakpoint-narrowinner {
          margin-left: 130px;
        }
      }
      &.-tag11 {
        margin-left: 18.75vw;  //90px / 480px * 100

        @include breakpoint-tb {
          margin-left: 90px;
        }
        @include breakpoint-contentinnerhalf {
          transform: translateY(4px);
          margin-left: 0;
        }
        @include breakpoint-w720 {
          margin-left: 10px;
        }
        @include breakpoint-narrowinner {
          transform: translateY(0);
          margin-left: 105px;
        }
      }
      &.-tag12 {
        margin-left: 9.375vw;  //45px / 480px * 100

        @include breakpoint-tb {
          margin-left: 45px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 65px;
        }
        @include breakpoint-w720 {
          margin-left: 110px;
        }
        @include breakpoint-narrowinner {
          margin-left: 55px;
        }
      }
      &.-tag13 {
        margin-left: 35.416666666666667vw;  //170px / 480px * 100

        @include breakpoint-tb {
          margin-left: 170px;
        }
        @include breakpoint-contentinnerhalf {
          transform: translateY(10px);
          margin-left: 140px;
        }
        @include breakpoint-narrowinner {
          transform: translateY(0);
          margin-left: 200px;
        }
      }
      &.-tag14 {
        margin-left: 0;
        
        @include breakpoint-tb {
          margin-left: 0;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 120px;
        }
        @include breakpoint-w720 {
          margin-left: 165px;
        }
        @include breakpoint-narrowinner {
          margin-left: 0;
        }
      }
      &.-tag15 {
        margin-left: 37.5vw;  //180px / 480px * 100

        @include breakpoint-tb {
          margin-left: 180px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 20px;
        }
        @include breakpoint-narrowinner {
          margin-left: 210px;
        }
      }
      &.-tag16 {
        margin-left: 9.375vw;  //45px / 480px * 100

        @include breakpoint-tb {
          margin-left: 45px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 0;
        }
        @include breakpoint-w720 {
          margin-left: 10px;
        }
        @include breakpoint-narrowinner {
          margin-left: 50px;
        }
      }
      &.-tag17 {
        margin-left: 22.916666666666667vw;  //110px / 480px * 100

        @include breakpoint-tb {
          margin-left: 110px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 160px;
        }
        @include breakpoint-w720 {
          margin-left: 210px;
        }
        @include breakpoint-narrowinner {
          margin-left: 130px;
        }
      }
      &.-tag18 {
        margin-left: 16.666666666666667vw;  //80px / 480px * 100

        @include breakpoint-tb {
          margin-left: 80px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 25px;
        }
        @include breakpoint-w720 {
          margin-left: 30px;
        }
        @include breakpoint-narrowinner {
          margin-left: 100px;
        }
      }
      &.-tag19 {
        margin-left: 5.208333333333333vw;  //25px / 480px * 100

        @include breakpoint-tb {
          margin-left: 25px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 50px;
        }
        @include breakpoint-w720 {
          margin-left: 100px;
        }
        @include breakpoint-narrowinner {
          margin-left: 30px;
        }
      }
      &.-tag20 {
        margin-left: 6.25vw;  //30px / 480px * 100

        @include breakpoint-tb {
          margin-left: 30px;
        }
        @include breakpoint-contentinnerhalf {
          margin-left: 50px;
        }
        @include breakpoint-w720 {
          margin-left: 55px;
        }
        @include breakpoint-narrowinner {
          margin-left: 35px;
        }
      }
    }
    
    ///// 1000未満時のみ、All Worksボタンの下に別途画像を表示する /////
    &_btmimg {
      display: block;
      width: 100%;
      height: 188px;
      background-image: url("../images/front/works/works_bg3.jpg");
      background-position: center bottom;
//      background-position: center top 80%;  //2022/12/13変更前
      background-repeat: no-repeat;
      background-size: cover;
      
      @include breakpoint-w720 {
        height: 225px;
      }
      @include breakpoint-pc {
        height: 250px;
      }
      @include breakpoint-narrowinner {
        display: none;
      }
    }
  }
}