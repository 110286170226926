/* カーブするテキストで使用するCSS */
.p0-textcurve {
  position: relative;
  top: -5vw; //textを下に5vw下にずらしている分、全体を5vw上にずらす
  left: 50%;
  transform: translateX(-50%);
  width: 289.285714285714286vw;  //3240px / 1120px * 100
  height: 35.714285714285714vw;  //400px / 1120px * 100
  margin-bottom: -10px; //枠外にはみ出した左右は、PSDより下までSVGがあるので、その分(10px)詰める
/*
  @include breakpoint-contentinnerhalf {
    width: 1782px;  // 3240px * 0.55 = 1782px
    height: 350px;  //220px / 400px = 0.55
  }
  @include breakpoint-pc {
    width: 2268px;  // 3240px * 0.7 = 2268px
    height: 350px;  //280px / 400px = 0.7
  }
  @include breakpoint-narrowinner {
    width: 2835px;  // 3240px * 0.875 = 2835px
    height: 350px;  //350px / 400px = 0.875
  }
*/
  @include breakpoint-narrowinner {
    top: 0; //1000px以上は、textもずらしていないので、top: 0に戻す
    width: 3240px;
    height: 400px;  //350px(SVGのheight) + 50px(フォントサイズ)
    margin-bottom: -20px; //枠外にはみ出した左右は、PSDより下までSVGがあるので、その分(20px)詰める
  }
  
  text {
    transform: translateY(5vw);

    @include breakpoint-narrowinner {
      transform: translateY(0);
    }
  }
}