.c-modal {
  $video-pad-x-pc: 160px;
  $video-pad-x-sp: 0;

  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-column;
  @include flex-justify-center;
  @include align-items-center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 100% !important;
//  width: 90vw;
//  max-width: 90vw !important;
  height: 85vh;
  max-height: 85vh !important;
  padding: 0 !important;
  box-sizing: border-box;
  background-color: transparent !important;

  @include breakpoint-pc {
    width: calc(100% - #{$video-pad-x-pc} - #{$video-pad-x-pc});
    max-width: calc(100% - #{$video-pad-x-pc} - #{$video-pad-x-pc}) !important;
  }

  //動画モーダルウィンドウ
  &.-movie {
      //videoタグをobject-fitにすると、c-modal内に、動画ではないがクリックしてもモーダルが閉じないエリアができてしまうので、それを防ぐためにc-modal内にクリックするとモーダルが閉じるdivを背景に敷き詰める
    .c-modal_closebg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .c-modal_inner {
      position: relative;
      max-width: 100%;
      max-height: 100%;

      // video
      video {
        position: relative;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
  
  //開くボタン(トリガー)
  &_openbtn {
    cursor: pointer;
  }
  
  &_close {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-end;
    @include align-items-center;
//    opacity: 0;
  }
  
  //閉じるボタン(幅・高さを確保するためだけのダミーボタン)
  .remodal-close {
    position: relative;
    @include flex-shrink-0;
    position: relative;
    transform: translateY(-100%);
    width: 28px;
    height: 24px;
    background-image: url("../images/common/icon/modal_close.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    
    &:before {
      display: none;
    }

    &:focus {
      outline: 0;
    }
  }
}


//モーダルオープン時の、モーダルの上下左右余白
.remodal-wrapper {
  max-height: 100%;
  padding: 30px 0 !important;
  
  @include breakpoint-max {
    padding: 30px 0 !important;
  }
}

//モーダルオープン時の、モーダル以外の全面背景色設定
.remodal-overlay {
  background-color: rgba(0, 0, 0, 1) !important;
  cursor: pointer;
}