//header nav menu
$drawer-space-sp: 94px;
$drawer-space-pc: 115px;


//スライドインボタン設定
$btn2-slide-width-narrowinner: 47.5%;
$btn2-slide-width-w720: 52.5%;
$btn2-slide-width-contentinnerhalf: 60%;
$btn2-slide-width-tb: 330px / 540px * 100%;
$btn2-slide-width-sp: 100%;

$btn2-slide-height-narrowinner: 100px;
$btn2-slide-height-pc: 100px;
$btn2-slide-height-contentinnerhalf: 70px;
$btn2-slide-height-tb: 60px;
$btn2-slide-height-sp: 60px;


//effect
$charup-base-delay: 100;  //基準のdelay(単位ms)
//$charup-base-delay: 500;  //基準のdelay、デフォルト値(単位ms)
$charup-duration: 850; //1文字がアップ表示されるのにかかる所要時間(単位ms)
//$charup-duration: 115;  //デフォルト値