.p0-sidescroll {
  &_wrapper {
    @include flexbox;
    @include flex-justify-flex-start;
//    @include flex-justify-center;
    @include align-items-center;
    
    &.-frontabout {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    &.-workstag {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
/*
      background-image: url("../images/front/works/works_bg2.jpg");
      background-position: center center;
      background-repeat: repeat-x;
      background-size: auto 100%;
*/
    }
  }
  
  &_list {
    @include flexbox;
    @include flex-justify-center;
    @include align-items-center;
    position: relative;
    top: 0;
    left: 0;
    gap: 0 0.27em;  //10px(PSD上のスペース幅) / 74px(1emを指定した時にあくスペース幅)
  }
  
  &_item {
    @include flexbox;
    @include flex-justify-center;
    @include align-items-center;
  }
}