@mixin btnsize($pc, $sp) {
  width: 100%;
  @include breakpoint-sp {
    max-width: $sp;
  }
  @include breakpoint-pc {
    width: $pc;
  }
}


.p0-btn {
  display: block;
  position: relative;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;

  &_inner, a {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-center;
    box-sizing: border-box;
  }
  
  &.-bk {    
    a {
      background-color: color-bk(1);
      border-color: color-bk(1);
      border-width: 2px;
      border-style: solid;
      color: color-wh(1);
      
      &:hover {
        background-color: color-wh(1);
        color: color-bk(1);
        
        .p0-icon {
          color: color-bk(1);
        }
      }
    }
    
    .p0-icon {
      color: color-wh(1);
    }
  }
  
  &.-center {
    text-align: center;
  }
  
  ///// 黒背景のお問い合わせボタン /////
  &.-btn1 {
    @include font-a1min();
    @include fontSize(2.4, 2.4);

    .p0-btn_inner {
      padding: 14px 0 15px;
    }
    .p0-btn_txt {
      margin-left: 15px;
    }
  }


  ///// 茶色背景ボタン /////
  &.-btn2 {
    height: $btn2-slide-height-sp;
    @include font-bebas();
    @include fontSizeBebas(2, 1.7);
    line-height: 1;
    letter-spacing: 0.05em;
    background-color: color-wh(1);
//    z-index: 1;

    @include breakpoint-tb {
      height: $btn2-slide-height-tb;
    }
    @include breakpoint-contentinnerhalf {
      height: $btn2-slide-height-contentinnerhalf;
    }
    @include breakpoint-pc {
      height: $btn2-slide-height-pc;
    }
    @include breakpoint-narrowinner {
      height: $btn2-slide-height-narrowinner;
    }
    
    &.-bottom {
      position: absolute;
      bottom: 0;
    }

    &.-ajusthalf {
      margin-bottom: calc( -#{$btn2-slide-height-sp} / 2 );

      @include breakpoint-tb {
        margin-bottom: calc( -#{$btn2-slide-height-tb} / 2 );
      }
      @include breakpoint-contentinnerhalf {
        margin-bottom: calc( -#{$btn2-slide-height-contentinnerhalf} / 2 );
      }
      @include breakpoint-pc {
        margin-bottom: calc( -#{$btn2-slide-height-pc} / 2 );
      }
      @include breakpoint-narrowinner {
        margin-bottom: calc( -#{$btn2-slide-height-narrowinner} / 2 );
      }
    }
    
    .p0-btn_wrap {
      position: absolute;
      width: 100vw;
      height: 100%;
      overflow: hidden;
    }

    .p0-btn_inner {
      height: 100%;
      padding: 0 40px;
      box-sizing: border-box;
      color: color-wh(1);
      background-color: color-brown01(1);

    	.gradient{
    		display: block;
    		position: absolute;
        top: 0;
    		left: 0;
        width: 2340px;  //780px * 3(PSDにあるのは中央から左側のみ、それを反転させたものを右側にもつけているのでボタンの幅の2倍にする)
        height: 100%;		
    		bottom: auto;
    		margin: auto;
    		z-index: 0;    
    		@include sweepButtonGradient();
    		transform: translateX(-100%);
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
        -webkit-transition-duration: 0s;
        transition-duration: 0s;
        -webkit-transition-property: transform;
        transition-property: transform;
    	}
    	&:hover, &:focus{
    		.gradient{
      		transform: translateX(100vw);
          -webkit-transition-duration: 1.2s;
          transition-duration: 1.2s;
    		}
    	}
    }

    .p0-btn_txt {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include align-items-center;
      position: relative;
      z-index: 1;

      &:after {
        position: relative;
        transform: translateY(1px);
        content: "";
        width: 10px;
        height: 16px;
        margin-left: 10px;
        background-image: url("../images/common/icon/right_dottri_wh.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        @include breakpoint-pc {
          width: 11px;
          height: 18px;
        }
      }
    }
    
    &.-slide {
      width: $btn2-slide-width-sp;
      max-width: 335px;

      @include breakpoint-tb {
        width: $btn2-slide-width-tb;
        max-width: none;
      }
      @include breakpoint-contentinnerhalf {
        width: $btn2-slide-width-contentinnerhalf;
      }
      @include breakpoint-w720 {
        width: $btn2-slide-width-w720;
      }
      @include breakpoint-narrowinner {
        width: $btn2-slide-width-narrowinner;
      }
    }
    
    &.-left {      
      margin-right: auto;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-110%);
        content: "";
        width: 100vw;
        height: 100%;
        background-color: color-wh(1);
      }
      
      .p0-btn_wrap {
        right: 0;
      }

      .p0-btn_inner, a {
        @include flex-justify-flex-end;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          transform: translateX(-110%);
          content: "";
          width: 100vw;
          height: 100%;
          background-color: color-brown01(1);
        }
      }
    }
    
    &.-right {
      margin-left: auto;

      &:before {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(110%);
        content: "";
        width: 100vw;
        height: 100%;
        background-color: color-wh(1);
      }
      
      .p0-btn_wrap {
        left: 0;
      }

      .p0-btn_inner, a {
        @include flex-justify-flex-start;

        &:before {
          position: absolute;
          top: 0;
          right: 0;
          transform: translateX(110%);
          content: "";
          width: 100vw;
          height: 100%;
          background-color: color-brown01(1);
        }
        
      	&:hover, &:focus{
        }
      }
    }
  }


  ///// Contact Us丸ボタン /////
  &.-btn3 {
    $btn3-width-pc: 176px; // 180px - (枠線の太さ2px * 2) 高さには上と下、幅には左と右、それぞれ2箇所あるので*2する
    $btn3-width-sp: 176px; // 180px - (枠線の太さ2px * 2)
    $btn3-height-pc: 176px; // 180px - (枠線の太さ2px * 2)
    $btn3-height-sp: 176px; // 180px - (枠線の太さ2px * 2)

    position: relative;
    width: $btn3-width-sp;
    height: $btn3-height-sp;
    border-radius: 100vh;
    background-color: color-blue02(1);
    
    &:hover {
//      background-color: transparent;
    }
    
    @include breakpoint-pc {
      width: $btn3-width-pc;
      height: $btn3-height-pc;
    }

    &.-ajusthalf {
      margin-bottom: calc( -#{$btn3-height-sp} / 2 );

      @include breakpoint-pc {
        margin-bottom: calc( -#{$btn3-height-pc} / 2 );
      }
    }

    .p0-btn_inner {
      position: relative;
      width: 100%;
      height: 100%;
      color: color-wh(1);
      background-color: color-blue02(1);

      &:before {
        position: absolute;
        top: -37px;  //線の高さ分、上にずらす(35px + 枠線の太さ2px)
        left: 50%;
        z-index: 2; //上に移動した時、白矢印の上に被せることで、丸の外はcolor-blue02の線が表示されている状態にする。線の長さは_effect.scssで変化させる
        transform: translate(-50%, 0);
        content: "";
        width: 2px;
        height: 35px;
        background-color: color-blue02(1);
        animation: 2s linear 0s infinite both running moveLineUpDonw10;
      }
    }
    
    .p0-btn_arw {
      position: absolute;
      top: -2px;
      left: 50%;
      z-index: 1; //上に移動した時、枠外の線の下のz-indexにすることで、白い線が枠外には表示されない状態にする。
      transform: translate(-50%, 0);
      width: 22px;
      height: auto;
      animation: 2s linear 0s infinite both running moveArwUpDonw10;
    }

    .p0-btn_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 37px;
      height: auto;
    }

    .p0-btn_txt {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include font-linuxldc();
      @include fontSize(2, 2);
      line-height: 1;
      letter-spacing: 0.01em;
    }
  }
  
  ///// READ MOREボタン /////
  &.-btn4 {
    width: 280px;
    @include font-bebas();
    @include fontSizeBebas(2, 1.4); //20px * 0.7 = 14px
    line-height: 1;
    letter-spacing: 0.05em;
    background-color: color-wh(1);
    cursor: pointer;

    .p0-btn_inner {
      padding: 8px 0 9px;
      color: color-wh(1);
      background-color: color-brown01(1);
    }

    .p0-btn_txt {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include align-items-center;
      position: relative;
      z-index: 1;
      padding-left: 8px;

      &:after {
        position: relative;
        transform: translateY(1px);
        content: "";
        width: 16px;
        height: 10px;
        margin-left: 12px;
        background-image: url("../images/common/icon/down_dottri_wh.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        @include breakpoint-pc {
          width: 18px;
          height: 11px;
          margin-left: 18px;
        }
      }
    }
  }


  ///// page topへボタン(アイコン＋テキスト) /////
  ///// (フッターのアイコンのみのものは、_footer.scssにて定義) /////
  &.-btn5 {
    .p0-btn_icon {
      margin-left: -8px;
      @include fontSize(3, 2.4);

      @include breakpoint-pc {
        margin-left: -10px;
      }
    }

    .p0-btn_inner {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-center;
    }
    
    .p0-btn_txt {
      @include font-bebas();
      @include fontSizeBebas(1.4, 1.12);
      line-height: 1;
      letter-spacing: 0.03em;
    }
  }


  ///// 下線のみボタン(黒) /////
  &.-btn6 {
    padding: 6px 16px 10px;
    border-bottom: 1px solid color-black01(1);
    box-sizing: border-box;
    @include font-ntsan("b");
    @include fontSize(2.3, 1.84);  //23px * 0.8
    @include lineHeight(1.85, 1.6);
    text-align: center;

    @include breakpoint-pc {
      padding: 8px 20px;
      text-align: left;
    }
  }


  ///// お問い合わせフォーム送信ボタン /////
  &.-btn7 {
    position: relative;
    height: 50px;
    background-image: url("../images/contact/btn_circle.png");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;

    @include breakpoint-pc {

    }

    &:before, &:after {
      display: block;
      position: absolute;
      content: "";
      pointer-events: none;
      transition: transform 0.1s linear;      
    }

    &:before {
      top: calc(50% - 1px);
      right: 18px;
      z-index: -1;
      width: 66px;
      height: 2px;
      background-color: color-blue02(1);
    }

    &:after {
      top: 0;
      right: 18px;
      width: 32px;
      height: 100%;
      background-image: url("../images/contact/btn_arw_wh.png");
      background-position: right center;
      background-repeat: no-repeat;
      background-size: auto 16px;
    }

    &:hover {
      &:before {
        transform: translateX(5px);
      }

      &:after {
        transform: translateX(5px);
      }
    }

    input {
      position: relative;
      height: 100%;
      padding-right: 110px;
      box-sizing: border-box;
      @include font-bebas();
      @include fontSizeBebas(2, 1.6); //20px * 0.8 = 16px
      line-height: 1;
      letter-spacing: 0.05em;
      vertical-align: middle;
      color: color-blue02(1);
    }
  }


  ///// 下線のみボタン(緑) /////
  &.-btn8 {
    padding: 0 20px;
    border-bottom: 2px solid color-blue02(1);
    box-sizing: border-box;
    @include font-ntsan("m");
    @include fontSize(1.6, 1.28); //16px * 0.8
    @include lineHeight(1.85, 1.6);
    letter-spacing: 0.05em;
    text-align: center;
    color: color-blue02(1);
    
    input, a {
      color: color-blue02(1);
    }
  }

}


///// 横並びボタン /////
.p0-flexbtns {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include align-items-stretch;

  @include breakpoint-pc {
    @include flex-wrap-nowrap;
    @include flex-direction-row;
  }
  
  &.-left {
    @include flex-justify-flex-start;
  }
  
  &.-center {
    @include flex-justify-center;
    @include align-items-center;
    
    @include breakpoint-pc {
      @include align-items-stretch;
    }
  }
  
  &.-right {
    @include flex-justify-flex-end;
  }
  
  &_item {
    margin-bottom: 20px;
    
    @include breakpoint-pc {
      margin-bottom: 0;
      margin-right: 2vw;
    }
    @include breakpoint-content {
      margin-right: 20px;
    }
    
    &:last-of-type {
      margin-bottom: 0;

      @include breakpoint-pc {
        margin-right: 0;
      }
      @include breakpoint-content {
        margin-right: 0;
      }
    }
  }
}