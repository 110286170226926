.p2-ftnav {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-center;
  @include align-items-center;
  @include font-ntsan("r");
  font-size: 1.1rem;
  color: color-wh(1);

  @include breakpoint-narrowinner {
    font-size: 1.3rem;
  }

  &_inner {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    @include flex-justify-center;
    @include align-items-start;
  
    @include breakpoint-tb {
      @include flex-direction-row;
      @include align-items-center;
    }
  }

  &_item {
    margin: 0 2px;

    @include breakpoint-contentinnerhalf {
      margin: 0 4px;
    }

    a {
      padding: 5px 5px;
      box-sizing: border-box;
      color: color-wh(1);

      @include breakpoint-contentinnerhalf {
        padding: 5px 10px;
      }
    }
  }
}