.l-wrap {
  overflow: hidden;
}


.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative;
//  overflow: hidden;

  @include breakpoint-pc {
    padding: 60px 0;
  }

  &.-visible {
    overflow: visible;
  }

  &.-hidden {
    overflow: hidden;
  }

  &.-pad0 {
    padding: 0;
  }

  &.-pt0 {
    padding: 0 0 30px;

    @include breakpoint-pc {
      padding: 0 0 60px;
    }
  }

  &.-pb0 {
    padding: 30px 0 0;

    @include breakpoint-pc {
      padding: 60px 0 0;
    }
  }

  &.-smallSpace {
    padding: 15px 0;

    @include breakpoint-pc {
      padding: 30px 0;
    }

    &.-pb0 {
      padding: 15px 0 0;

      @include breakpoint-pc {
        padding: 30px 0 0;
      }
    }
  }

  &.-largeSpace {
    padding: 45px 0;

    @include breakpoint-pc {
      padding: 50px 0;
    }
    @include breakpoint-narrowinner {
      padding: 80px 0;
    }
    @include breakpoint-narrow {
      padding: 90px 0;
    }

    &.-pb0 {
      padding: 45px 0 0;

      @include breakpoint-pc {
        padding: 50px 0 0;
      }
      @include breakpoint-narrowinner {
        padding: 80px 0 0;
      }
      @include breakpoint-narrow {
        padding: 90px 0 0;
      }
    }
  }

  &.-hugeSpace {
    padding: 50px 0;

    @include breakpoint-narrowinner {
      padding: 90px 0;
    }
    @include breakpoint-narrow {
      padding: 120px 0;
    }

    &.-pb0 {
      padding: 50px 0 0;

      @include breakpoint-narrowinner {
        padding: 90px 0 0;
      }
      @include breakpoint-narrow {
        padding: 120px 0 0;
      }
    }
  }

  &.-gitSpace {
    padding: 75px 0;

    @include breakpoint-contentinnerhalf {
      padding: 80px 0;
    }
    @include breakpoint-w720 {
      padding: 52px 0;
    }
    @include breakpoint-pc {
      padding: 95px 0;
    }
    @include breakpoint-narrowinner {
      padding: 120px 0;
    }

    &.-pb0 {
      padding: 75px 0 0;

      @include breakpoint-contentinnerhalf {
        padding: 80px 0 0;
      }
      @include breakpoint-w720 {
        padding: 52px 0 0;
      }
      @include breakpoint-pc {
        padding: 95px 0 0;
      }
      @include breakpoint-narrowinner {
        padding: 120px 0 0;
      }
    }
  }

  &.-lowerpage {
    padding: 40px 0;

    @include breakpoint-pc {
      padding: 80px 0;
    }

    &.-pb0 {
      padding: 40px 0 0;

      @include breakpoint-pc {
        padding: 80px 0 0;
      }
    }
  }


  ///// Hidden /////
  &_hidden {
    overflow: hidden;
  }


  ///// Wrap /////
  &_wrap {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
    &.-max {
      width: 100%;

      @include breakpoint-max {
        width: $max;
      }
    }  
  }


  ///// セクション背景色 /////
  &.-bgtype01 {
    background-color: color-wh(1);
  }
  
  &.-bgtype02 {
    background-color: color-gray01(1);
  }
  
  &.-bgtype03 {
    background-image: url("../images/front/works/works_bg2.jpg");
    background-position: center center;
    background-repeat: repeat-x;
    background-size: auto 100%;
    
    &.-marquee {
      background-position: center top;
      background-size: auto 200%;
    }
  }
  
  &.-bgtype04 {
    background:linear-gradient(90deg, color-white01(1) 0%, color-white01(1) 45.5%, color-wh(1) 45.5%, color-wh(1) 100%);
  }
  
  &.-bgtype05 {
    background-image: url("../images/about/spacer02.jpg");
    background-position: center center;
    background-repeat: repeat-x;
    background-size: auto 100%;
    
    &.-marquee {
      background-position: center top;
      background-size: auto 117%; //480px / 410px = 117%
    }
  }

  
  ///// セクション内の一部のみ背景色を変える場合 /////
  &_bg {
    position: relative;
    width: 100%;

    &.-bgtype01 {
      background-color: color-wh(1);
    }

    &.-bgtype02 {
      background-color: color-gray01(1);
    }

    &.-bgtype03 {
      background: linear-gradient(180deg, transparent 10vw, color-gray01(1) 10vw);  // 170px / 1620px * 100 = 10.4938271604938

      @include breakpoint-max {
        background: linear-gradient(180deg, transparent 170px, color-gray01(1) 170px);
      }
    }

    &.-bgtype04 {
      background:linear-gradient(90deg, color-white01(1) 0%, color-white01(1) 45.5%, color-wh(1) 45.5%, color-wh(1) 100%);
      
      &.-zim1 {
        z-index: -1;
      }
    }

    &.-bgtype05 {
      background-image: url("../images/front/works/works_bg2.jpg");
      background-position: center center;
      background-repeat: repeat-x;
      background-size: auto 100%;
    }
  }


  ///// セクション毎のz-index指定用 /////
  ///// (各セクション、最下部ボタンが下にはみ出すので、z-indexの重なり順を、下から上へと大きくしていく必要がある) /////
  $i: 1;
  @for $value from 1 through 10 {
    &.-zi {
      &#{ $value * $i } {
        z-index: $value * $i;
      }
    }
  }

  &_content {
    width: 90%;
    margin: 0 auto;
    position: relative;
//    overflow: hidden;

    @include breakpoint-content {
      width: $contentinner;
    }

    &.-narrow {
      @include breakpoint-narrow {
        width: $narrowinner;
      }
    }

    &.-cards {
      @include breakpoint-content {
        width: 90%;
      }
      @include breakpoint-maxinner {
        width: $cards;
      }
    }

    &.-wide {
      @include breakpoint-content {
        width: 90%;
      }
      @include breakpoint-max {
        width: $maxinner;
      }
    }

    &.-full {
      width: 100%;

      @include breakpoint-content {
        width: 100%;
      }
    }

    &.-wide_full {
      width: 100%;
      
      .l-section_content_inner {
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        &.-narrow {
          @include breakpoint-narrow {
            width: $narrowinner;
          }
        }

        &.-contentinner {
          width: 100%;

          @include breakpoint-contentinner {
            width: $narrow;
          }
        }
      }

      @include breakpoint-max {
        width: $maxinner;
      
        .l-section_content_content {
          width: 100%;
        }
      }
    }

    &.-visible {
      overflow: visible;
    }
    
    $i: 1;
    @for $value from 1 through 10 {
      &.-zi {
        &#{ $value * $i } {
          z-index: $value * $i;
        }
      }
    }
  }
}
