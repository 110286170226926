.p0-topics {
  ///// お知らせ一覧 /////
  &_list {
    display: block;

    &_item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-center;
      position: relative;
      overflow: hidden;
      padding: 10px 10px;
      border-bottom: 1px solid color-gray03(1);
      box-sizing: border-box;
      
      @include breakpoint-narrowinner {
        padding: 17px 10px;
      }

      &:last-of-type {
        border-style: none;
      }
    }

    &_dt {
      @include flex-shrink-0;
      width: 75px;
      padding-right: 20px;
      box-sizing: border-box;

      @include breakpoint-tb {
        width: 85px;
      }
      @include breakpoint-contentinnerhalf {
        width: 105px;
      }
      @include breakpoint-pc {
        width: 110px;
        padding: 0 20px 0 0;
      }
    }
    
    &_ttl {
      box-sizing: border-box;
      @include ellipsisOneLine();

      @include breakpoint-pc {
      }
    }
  }
  
  ///// お知らせ記事ページ /////
  &_article {
  }
}