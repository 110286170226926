.p2-ftsns {
  &_inner {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-center;
  
    @include breakpoint-pc {
    }
  }

  &_item {
    width: 46px;  // 50px - (shadow 2px * 2)
    height: 46px;
    margin: 0 10px;
    border-radius: 50%;
    box-sizing: border-box;

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
  &_icon {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-center;
    width: 100%;
    height: 100%;
  }
  
  .icon-fill-bk {
    fill: color-bk(1);
  }
  .icon-fill-wh {
    fill: color-wh(1);
  }
  
  #icon-fb {
    width: 23px;
    height: 23px;
  }
  
  #icon-ig {
    width: 21px;
    height: 21px;
  }
  
  #icon-tw {
    width: 20px;
    height: 17px;
  }
}