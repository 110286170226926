.u-pos {
  &_absolute {
    position: absolute;
    
    &.-w100per {
      width: 100%;
    }
  }
  
  &_relative {
    position: relative;
    
    &.-w100per {
      width: 100%;
    }
  }
  
  &_fixed {
    position: fixed;
  }
  
  
  ///// 左右中央寄せ、上下中央寄せ、上下左右中央寄せ /////
  &_center {
    &_h {
      @include posCenterHV("h");
    }
    &_v {
      @include posCenterHV("v");
    }
    &_hv {
      @include posCenterHV("hv");
    }
  }


  ///// 高さの半分ずらして配置する /////
  &_ajusthalf {
    &_plus {
      @include posAjustHalf("plus");
    }
    &_minus {
      @include posAjustHalf("minus");
    }
  }
}