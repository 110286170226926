.p3-text {
  position: relative;

  ///// テキストシャドウ /////
  &.-shadow {
    &.-bk01 {
      -moz-text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.23), 0px 0px 5px rgba(0, 0, 0, 0.5);
      -webkit-text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.23), 0px 0px 5px rgba(0, 0, 0, 0.5);
      -ms-text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.23), 0px 0px 5px rgba(0, 0, 0, 0.5);
      text-shadow: 0px 0px 18px rgba(0, 0, 0, 0.23), 0px 0px 5px rgba(0, 0, 0, 0.5);
    }

    &.-bk02 {
      -moz-text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.18), 0px 0px 14px rgba(0, 0, 0, 0.43);
      -webkit-text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23), 0px 0px 14px rgba(0, 0, 0, 0.43);
      -ms-text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23), 0px 0px 14px rgba(0, 0, 0, 0.43);
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23), 0px 0px 14px rgba(0, 0, 0, 0.43);
    }

    &.-wh {
      -moz-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      -webkit-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      -ms-text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.58), 0px 0px 3px rgba(255, 255, 255, 0.58), 0px 0px 16px rgba(255, 255, 255, 1);
    }
  }
  
  // テキスト寄せ
  &.-left {
    text-align: left;
  }
  
  &.-center {
    text-align: center;
  }

  &.-right {
    text-align: right;
  }

  &.-justify {
    text-align: justify;
  }
  
  &.-pcj_spc {
    text-align: center;

    @include breakpoint-pc {
      text-align: justify;
    }
  }
  
  &.-pcc_w720j {
    text-align: justify;

    @include breakpoint-w720 {
      text-align: center;
    }
  }

  // テキストカラー
  &.-transparent {
    color: transparent;
  }
  
  &.-wh {
    color: color-wh(1);
  }
  &.-bk {
    color: color-bk(1);
  }

  &.-black01 {
    color: color-black01(1);
  }
  &.-black02 {
    color: color-black02(1);
  }
  &.-black03 {
    color: color-black03(1);
  }

  &.-gray01 {
    color: color-gray01(1);
  }
  &.-gray02 {
    color: color-gray02(1);
  }
  &.-gray03 {
    color: color-gray03(1);
  }
  &.-gray04 {
    color: color-gray04(1);
  }
  
  &.-blue01 {
    color: color-blue01(1);
  }
  &.-blue02 {
    color: color-blue02(1);
  }
  &.-blue03 {
    color: color-blue03(1);
  }

  &.-brown01 {
    color: color-brown01(1);
  }
  
  &.-italic {
    font-style: italic !important;
  }

  ///// 装飾 /////
  &.-decounderline {
    text-decoration: underline;
  }


  ///// リード文 /////
  // トップページのAbout Usのリード文
  &.-lead01 {
    position: relative;
    @include font-a1min();
    font-size: 5.8vw;  //28px / 480px * 100
    line-height: 1.7;
    letter-spacing: 0.05em;

    @include breakpoint-tb {
      font-size: 2.8rem;
    }
    @include breakpoint-pc {
      font-size: 3rem;
      line-height: 2.4;
    }
    @include breakpoint-narrowinner {
      font-size: 3.6rem;
      line-height: 2.2;
    }
    @include breakpoint-narrow {
      font-size: 4rem;
      line-height: 2.1;
    }
    @include breakpoint-max {
      font-size: 4.4rem;
      line-height: 2;
    }
  }

  // トップページのサービスのリード文
  &.-lead02 {
    position: relative;
    @include font-a1min();
    font-size: 2.8rem;
    line-height: 1.7;
    letter-spacing: 0.05em;

    @include breakpoint-tb {
      font-size: 2.8rem;
    }
    @include breakpoint-pc {
      font-size: 2.8rem;
      line-height: 2;
    }
    @include breakpoint-narrowinner {
      font-size: 3.6rem;
      line-height: 2.2;
    }
    @include breakpoint-narrow {
      font-size: 4rem;
      line-height: 2;
    }
    @include breakpoint-max {
      font-size: 4.4rem;
      line-height: 1.8;
    }
  }

  // GET IN TOUCHの「お問い合わせはこちら」(PSDとは違うフォントサイズ(2rem)にすることになった。)
  &.-lead03 {
    position: relative;
    @include font-a1min();
    font-size: 1.8rem;
    @include lineHeight(1.8, 1.8);
    letter-spacing: 0.01em;

    @include breakpoint-pc {
      font-size: 1.8rem;
    }
    @include breakpoint-narrowinner {
      font-size: 2rem;
    }
  }

  // サービスの各親タグの説明リード文
  &.-lead04 {
    @include font-a1min();
    @include fontSize(3.4, 2.8);  //34px * 0.7 = 23.8
    @include lineHeight(2, 1.7);
    letter-spacing: 0.05em;

    &.-ajusttop {
      @include ajustLHTop(2, 1.7);
    }
    
    &.-spcenter {
      
    }
  }

  // AboutのSPIRITSの各項リード文
  &.-lead05 {
    @include font-a1min();
    font-size: 2.8rem;
    @include lineHeight(1.6, 1.6);
    letter-spacing: 0.05em;

    @include breakpoint-pc {
    }
    @include breakpoint-narrowinner {
      font-size: 3.6rem;
    }
    @include breakpoint-narrow {
      font-size: 4.2rem;
    }

    &.-ajusttop {
      @include ajustLHTop(1.6, 1.6);
    }
    
    &.-w720left {
      text-align: center;

      @include breakpoint-w720 {
        text-align: left;
      }
    }
  }


  ///// テキスト /////
  //トップページのAbout Us本文、サービス本文など
  &.-t01 {
    @include font-ntsan("m");
    font-size: 1.4rem;
    line-height: 2.1;
    letter-spacing: 0.02em;

    @include breakpoint-w720 {
      line-height: 2.3;
    }
    @include breakpoint-narrowinner {
      line-height: 2.8;
    }
    @include breakpoint-narrow {
      font-size: 1.6rem;
      line-height: 2.75;
    }
    @include breakpoint-max {
      font-size: 1.8rem;
      line-height: 2.4;
    }
  }

  //プライバシーポリシーページの各項目本文など
  &.-t02 {
    @include font-ntsan("r");
    @include fontSize(1.3, 1.2);
    @include lineHeight(2.3, 2.3);
    letter-spacing: 0.05em;

    @include breakpoint-pc {

    }
  }

  //トップページのトピックスタイトルなど
  &.-t03 {
    @include font-ntsan("r");
    font-size: 1.3rem;
    @include lineHeight(2.3, 2.3);
    letter-spacing: 0.03em;

    @include breakpoint-contentinnerhalf {
      font-size: 1.4rem;
    }
    @include breakpoint-narrowinner {
      font-size: 1.5rem;
    }
    @include breakpoint-narrow {
      font-size: 1.6rem;
    }
  }

  //作品事例記事ページの本文など
  &.-t04 {
    @include font-ntsan("m");
    font-size: 1.4rem;
    line-height: 2.1;
    letter-spacing: 0.02em;

    @include breakpoint-w720 {
      line-height: 2.3;
    }
    @include breakpoint-narrowinner {
      line-height: 2.8;
    }
    @include breakpoint-narrow {
      font-size: 1.6rem;
      line-height: 2.75;
    }
  }

  //トップページのブログの執筆者など
  &.-t05 {
    @include font-ntsan("b");
    font-size: 1.4rem;
    @include lineHeight(2.3, 2.3);
    letter-spacing: 0.03em;

    @include breakpoint-w720 {
      font-size: 1.5rem;
    }
    @include breakpoint-pc {
      font-size: 1.4rem;
    }
    @include breakpoint-narrowinner {
      font-size: 1.5rem;
    }
    @include breakpoint-narrow {
      font-size: 1.6rem;
    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  //作品事例ページの作品一覧各タイトルなど
  &.-t06 {
    @include font-ntsan("b");
    @include fontSize(1.8, 1.44);
    @include lineHeight(2.1, 2.1);
    letter-spacing: 0;

    @include breakpoint-pc {

    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
    &.-lh110 {
      @include lineHeight(1.1, 1.1);
    }
    &.-lh120 {
      @include lineHeight(1.2, 1.2);
    }
  }

  //トップページの作品一覧各本文、ブログ一覧の各タイトルなど
  &.-t07 {
    @include font-ntsan("b");
    font-size: 1.5rem;
    line-height: 1.6;
    letter-spacing: 0;

    @include breakpoint-w720 {
      font-size: 1.6rem;
      line-height: 1.625;
    }
    @include breakpoint-pc {
      font-size: 1.5rem;
      line-height: 1.6;
    }
    @include breakpoint-narrowinner {
      font-size: 1.6rem;
      line-height: 1.625;
    }
    @include breakpoint-narrow {
      font-size: 1.8rem;
      line-height: 1.65;
    }
    @include breakpoint-max {
      font-size: 2.2rem;
      line-height: 1.75;
    }
  }

  //トップページの作品一覧各タイトルなど
  &.-t08 {
    @include font-ntsan("b");
    font-size: 1.6rem;
    @include lineHeight(1.625, 1.625);
    letter-spacing: 0;

    @include breakpoint-w720 {
      font-size: 1.8rem;
    }
    @include breakpoint-pc {
      font-size: 1.6rem;
    }
    @include breakpoint-narrowinner {
      font-size: 1.8rem;
    }
    @include breakpoint-narrow {
      font-size: 2rem;
    }
    @include breakpoint-max {
      font-size: 2.4rem;
    }
  }

  //トップページの作品一覧各サブタイトルなど
  &.-t09 {
    @include font-ntsan("m");
    font-size: 1rem;
    @include lineHeight(1.8, 1.8);
    letter-spacing: 0.03em;

    @include breakpoint-narrow {
      font-size: 1.1rem;
    }
  }

  //トップページの作品一覧縦書きPROJECTなど
  &.-t10 {
    @include font-bebasneue("r");
    font-size: 1.6rem;
    @include lineHeight(1, 1);
    letter-spacing: 0.03em;

    @include breakpoint-narrowinner {
      font-size: 1.7rem;
    }
    @include breakpoint-narrow {
      font-size: 2rem;
    }
    @include breakpoint-max {
      font-size: 2.4rem;
    }
  }

  //トップページの作品一覧縦書きPROJECTナンバーなど
  &.-t11 {
    @include font-rockvillesr();
    font-size: 1.6rem;
    @include lineHeight(1, 1);
    letter-spacing: 0.03em;

    @include breakpoint-narrowinner {
      font-size: 1.7rem;
    }
    @include breakpoint-narrow {
      font-size: 2rem;
    }
    @include breakpoint-max {
      font-size: 2.4rem;
    }
  }

  //トップページのサービススライドショーのslugなど
  &.-t12 {
    @include font-din1451lt();
    font-size: 1rem;
    @include lineHeight(1, 1);
    letter-spacing: 0;

    @include breakpoint-tb {
      font-size: 1.6rem;
    }
    @include breakpoint-pc {
      font-size: 1.7rem;
    }
    @include breakpoint-narrowinner {
      font-size: 2.2rem;
    }
    @include breakpoint-narrow {
      font-size: 2.4rem;
    }
    @include breakpoint-max {
      font-size: 3.6rem;
    }
  }

  //トップページのAll Worksの「すべての作品を見る」など
  &.-t13 {
    @include font-a1min();
    font-size: 2.6rem;
    @include lineHeight(1.3, 1.3);
    letter-spacing: 0;

    @include breakpoint-narrow {
      font-size: 3.2rem;
    }
  }

  &.-t14 {
    @include font-din1451lt();
    font-size: 7vw;  //34px / 480px * 100
    @include lineHeight(1, 1);
    letter-spacing: 0;

    @include breakpoint-tb {
      font-size: 3.4rem;
    }
    @include breakpoint-narrow {
      font-size: 4rem;
    }
  }

  &.-t15 {
    @include font-sprmin("m");
    font-size: 3.5vw;  //17px / 480px * 100
    @include lineHeight(1, 1);
    letter-spacing: 0;

    @include breakpoint-tb {
      font-size: 1.7rem;
    }
    @include breakpoint-narrow {
      font-size: 2rem;
    }
  }

  &.-t16 {
    @include font-linuxldc();
    @include fontSize(1.4, 1.12);
    @include lineHeight(1.4, 1.4);
  }

  &.-t17 {
    @include font-dnp-shunmin();
    @include fontSize(1.6, 1.28);
    line-height: 2.25;
    letter-spacing: 0;
  }

  &.-t18 {
    @include font-ntsan("b");
    @include fontSize(3.6, 2.88); //36px * 0.8 = 28.8
    @include lineHeight(1.8, 1.4);
    letter-spacing: 0.01em;
  }

  &.-t19 {
    @include font-ntsan("b");
    @include fontSize(2, 1.6); //20px * 0.8 = 16
    line-height: 1.2;
    letter-spacing: 0.01em;

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t20 {
    @include font-linuxldc();
    @include fontSize(1.7, 1.36); //17px * 0.8 = 13.6
    @include lineHeight(1.4, 1.4);
  }

  &.-t21 {
    @include font-linuxldc();
    @include fontSize(1.6, 1.28); //16px * 0.8 = 12.8
    @include lineHeight(2, 2);
    
    &.-ls005 {
      letter-spacing: 0.05em;
    }
  }

  &.-t22 {
    @include font-ntsan("r");
    @include fontSize(1.1, 1);
    @include lineHeight(1.8, 1.8);
  }

  &.-t23 {
    @include font-a1min();
    @include fontSize(1.2, 1);
    @include lineHeight(1.8, 1.8);
    letter-spacing: 0;
  }

  &.-t24 {
    @include font-a1min();
    @include fontSize(1.4, 1.12);
    @include lineHeight(1.8, 1.8);

    &.-lh250 {
      @include lineHeight(2.5, 2.5);
      
      &.-ajusttop {
        @include ajustLHTop(2.5, 2.5);
      }
    }

    &.-ls005 {
      letter-spacing: 0.05em;
    }
  }

  &.-t25 {
    @include font-a1min();
    @include fontSize(1.1, 1);
    @include lineHeight(1.8, 1.8);

    &.-ls005 {
      letter-spacing: 0.05em;
    }
  }

  &.-t26 {
    @include font-a1min();
    @include fontSize(1.8, 1.44);
    @include lineHeight(1.4, 1.4);

    &.-ls005 {
      letter-spacing: 0.05em;
    }
  }

  &.-t27 {
    @include font-din1451lt();
    @include fontSize(1.6, 1.28);
    @include lineHeight(1, 1);
    letter-spacing: 0.05em;
  }

  //作品事例ページのピックアップ本文など
  &.-t28 {
    @include font-ntsan("r");
    font-size: 1.3rem;
    line-height: 1.9;
    letter-spacing: 0.05em;

    @include breakpoint-contentinnerhalf {
      font-size: 1.4rem;
      line-height: 1.8;
    }
    @include breakpoint-w720 {
      font-size: 1.3rem;
      line-height: 1.9;
    }
    @include breakpoint-narrowinner {
      font-size: 1.3rem;
      line-height: 2.15;
    }
    @include breakpoint-narrow {
      font-size: 1.4rem;
      line-height: 2.1;
    }
  }

  //お問い合わせページのlabelなど
  &.-t29 {
    @include font-a1min();
    @include fontSize(1.9, 1.52);  //19px * 0.8 = 15.2
    @include lineHeight(2, 2);
    letter-spacing: 0.05em;
  }

  //お問い合わせ完了ページの電話番号など
  &.-t30 {
    @include font-bebas();
    @include fontSizeBebas(4, 3.2);
    line-height: 1;
    letter-spacing: 0.05em;
  }

  //お問い合わせ完了ページの曜日・時間など
  &.-t31 {
    @include font-ntsan("b");
    @include fontSize(1.6, 1.28);
    @include lineHeight(2.75, 2.1);
    letter-spacing: 0.05em;
  }

  //お問い合わせ完了ページのメールアドレスなど
  &.-t32 {
    @include font-din1451lt();
    @include fontSize(3, 2.4);
    @include lineHeight(1.5, 1.4);
    letter-spacing: 0.05em;
  }

  //ABOUT USページの会社情報thなど
  &.-t33 {
    @include font-ntsan("m");
    @include fontSize(1.5, 1.2);
    @include lineHeight(1.5, 1.5);
    letter-spacing: 0.03em;
  }

  //ABOUT USページのSPIRITS各項本文など
  &.-t34 {
    @include font-ntsan("m");
    font-size: 1.4rem;
    line-height: 2.1;
    letter-spacing: 0.02em;
    
    @include breakpoint-w720 {
      font-size: 1.3rem;
      line-height: 1.9;
    }
    @include breakpoint-pc {
      font-size: 1.4rem;
      line-height: 2;
    }
    @include breakpoint-narrowinner {
      line-height: 2.55;
    }
    @include breakpoint-narrow {
      font-size: 1.6rem;
      line-height: 2.75;
      letter-spacing: 0.05em;
    }
  }

  //ABOUT USページのメンバー氏名英語表記(氏名漢字の上)など
  &.-t35 {
    @include font-bebasneue("b");
    font-size: 1.5rem;
    line-height: 1;
    letter-spacing: 0.05em;

    @include breakpoint-contentinnerhalf {
      font-size: 1.8rem;
    }
    @include breakpoint-w720 {
      font-size: 2rem;
    }
    @include breakpoint-narrowinner {
      font-size: 2.4rem;
    }
  }

  //ABOUT USページのメンバー氏名英語表記(氏名漢字の上)など
  &.-t36 {
    @include font-a1min();
    font-size: 2.4rem;
    @include lineHeight(1, 1);
    letter-spacing: 0.05em;

    @include breakpoint-contentinnerhalf {
      font-size: 2.4rem;
    }
    @include breakpoint-contentinnerhalf {
      font-size: 2.8rem;
    }
    @include breakpoint-w720 {
      font-size: 3.2rem;
    }
    @include breakpoint-narrowinner {
      font-size: 3.6rem;
    }
    @include breakpoint-narrow {
      font-size: 4rem;
    }
  }

  //worksの年表記 - 2種フォント混合(英字は「Linux Libertine Display Capitals」、日本語は「しっぽり明朝」)
  &.-t37 {
    @include font-linuxldc-sprmin();
    @include fontSize(1.7, 1.36); //17px * 0.8 = 13.6
    @include lineHeight(1.4, 1.4);
  }

  ///// マスクテキスト /////
  &.-mask01 {
    @include font-bebas();
    font-size: 12.5vw;  //48px / 480px * 1.25(Bebasは1.25倍する必要がある) * 100
    line-height: 1; //PSD上ではline-height: 1.27位だが、webフォント化するとbebasのフォントサイズがPSDのものとは違うサイズを指定しないと同じ高さにならない為に大きくなるよう自動調整しているので、line-height: 1にすると、ちょうどPSD通りの行間になる。
    letter-spacing: 0.01em;
  	color: transparent;
  	-moz-background-clip: text;
  	-webkit-background-clip: text;

    @include breakpoint-sp {
      font-size: 13vw;  //50px / 480px * 1.25(Bebasは1.25倍する必要がある) * 100
    }
    @include breakpoint-tb {
      @include fontSizeBebasOnesize(5.1);
    }
    @include breakpoint-contentinnerhalf {
      @include fontSizeBebasOnesize(6.4);
    }
    @include breakpoint-w720 {
      @include fontSizeBebasOnesize(6.9);
    }
    @include breakpoint-narrowinner {
      @include fontSizeBebasOnesize(9.3);
    }
    @include breakpoint-narrow {
      @include fontSizeBebasOnesize(11.4);
    }
    
    &.-bgimg01 {
//      background-color: transparent;
      background-image: url("../images/front/works/works_bg2.jpg");
      background-position: center center;
      background-repeat: repeat-x;
      background-size: auto 100vh;
    }
  }


  ///// ノイズテキスト /////
  &.-noise01 {
    @include font-bebas();
    font-size: 7.8125vw;  //30px / 480px * 1.25(Bebasは1.25倍する必要がある) * 100
    line-height: 1.1; //PSD上ではline-height: 1.4位だが、webフォント化するとbebasのフォントサイズがPSDのものとは違うサイズを指定しないと同じ高さにならない為に大きくなるよう自動調整しているので、line-height: 1.1にすると、ちょうどPSD通りの行間になる。
    letter-spacing: 0.01em;

    @include breakpoint-tb {
      @include fontSizeBebasOnesize(2.9);
//      @include fontSizeBebasOnesize(3);
    }
    @include breakpoint-w720 {
      @include fontSizeBebasOnesize(2.9);
//      @include fontSizeBebasOnesize(3);
    }
    @include breakpoint-narrowinner {
      @include fontSizeBebasOnesize(3.7);
    }
    @include breakpoint-narrow {
      @include fontSizeBebasOnesize(4.2);
    }
    @include breakpoint-max {
      @include fontSizeBebasOnesize(5);
    }
    
    &:before, &:after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      color: color-bk(1);
      background: color-white01(1);
      overflow: hidden;
      clip: rect(0, 900px, 0, 0); 
    }
    &:before {
      left: -2px;
      text-shadow: 1px 0 #0042ff; 
      animation: textNoise2-line2 3s infinite linear alternate-reverse;
      
      @include breakpoint-w720 {
        animation: textNoise2-line1 3s infinite linear alternate-reverse;
      }
    }
    &:after {
      left: 2px;
      text-shadow: -1px 0 #ff0000;
      animation: textNoise1-line2 2s infinite linear alternate-reverse;
      
      @include breakpoint-w720 {
        animation: textNoise1-line1 2s infinite linear alternate-reverse;
      }
    }
  }


  ///// マーキーテキスト /////
  &.-marquee01, &.-sidescroll01 {
    position: relative;
    @include font-bebas();
    @include fontSizeBebasOnesize(7.5072); //3.128px / 5px * 12px
//    @include fontSizeBebasOnesize(3.128);
    line-height: 1;
    letter-spacing: 0.01em;

    @include breakpoint-pc {
      @include fontSizeBebasOnesize(8.34); //3.475px / 5px * 12px
//      @include fontSizeBebasOnesize(3.475);
    }
    @include breakpoint-narrowinner {  
      @include fontSizeBebasOnesize(10.4448); //4.352px / 5px * 12px
//      @include fontSizeBebasOnesize(4.352);
    }
    @include breakpoint-narrow {
      @include fontSizeBebasOnesize(12);
//      @include fontSizeBebasOnesize(5);
    }
  }


  ///// 波打つ文章 /////
  &.-wave01 {
    @include font-bebasneue("l");
//    font-size: 5rem;
    font-size: 18vw;  //36px / 480px * 100
    line-height: 1;
    letter-spacing: 0.03em;
    text-align: center;

    @include breakpoint-tb {
      font-size: 8.2rem;
//      font-size: 3.6rem;
    }
    @include breakpoint-contentinnerhalf {
      font-size: 6.8rem;
    }
    @include breakpoint-w720 {
      font-size: 5.8rem;
//      font-size: 3.8rem;
    }
    @include breakpoint-pc {
      font-size: 5.3rem;
//      font-size: 4rem;
    }
    @include breakpoint-narrowinner {
      font-size: 5rem;
    }

    &.-gray03 {
      fill: color-gray03(1);
    }
    
    & .-shorten {
      letter-spacing: -0.1em;
    }
  }


  ///// 日付 /////
  // TOPICS一覧日付
  &.-dt01 {
    @include font-bebas();
    font-size: 1.3rem;
    line-height: 1;
    letter-spacing: 0.03em;
    
    @include breakpoint-contentinnerhalf {
      font-size: 1.4rem;
    }
    @include breakpoint-narrowinner {
      font-size: 1.5rem;
    }
    @include breakpoint-narrow {
      font-size: 1.6rem;
    }

    &.-lh185 {
      @include lineHeight(1.85, 1.85);
    }
  }

  &.-dt02 {
    @include font-bebas();
    @include fontSizeBebas(1.4, 1.4);
    line-height: 1;
    letter-spacing: 0.03em;
  }


  ///// リスト /////
  &.-list01 {
    margin-left: 20px;
    @include font-ntsan("b");
    @include fontSize(1.6, 1.5);
    @include lineHeight(1.875, 1.875);
    letter-spacing: 0.05em;

    @include breakpoint-pc {
    }
    
    & > li {
      margin-bottom: 15px;

      @include breakpoint-pc {
        margin-bottom: 30px;
      }
      
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    div {
      padding-left: 10px;
      box-sizing: border-box;
    }
  }
  
  &.-list02 {
    margin-left: 18px;
    @include font-ntsan("r");
    @include fontSize(1.3, 1.2);
    @include lineHeight(2.3, 2.3);
    letter-spacing: 0.05em;

    @include breakpoint-pc {

    }

    & > li {
      margin-bottom: 10px;

      @include breakpoint-pc {
        margin-bottom: 20px;
      }
      
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &.-list03 {
    margin-left: 0;
    @include font-ntsan("b");
    @include fontSize(1.6, 1.5);
    @include lineHeight(1.1, 1.1);
    letter-spacing: 0.05em;

    @include breakpoint-pc {
    }
    
    & > li {
      margin-bottom: 10px;
      
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    div {
    }
  }


  ///// 指定行数で以下略 /////
  $i: 1;
  @for $value from 1 through 10 {
    &.-line {
      &#{ $value * $i } {  
        @include ellipsisSetLine($value * $i);
      }
    }
  }


  ///// text mask /////
  &.-mask {
  	color: transparent;
  	-moz-background-clip: text;
  	-webkit-background-clip: text;
  }


  ///// 句読点 /////
  &_punctuation {
    //「A-OTF A1明朝 Std」の句読点は広いので、60%letter-spacingを詰める
    &.-a1min {
      &.-l {
        margin-left: -0.2em;
      }
      &.-r {
        letter-spacing: -0.55em;
      }
      &.-rh { //-rの半分詰める
        letter-spacing: -0.275em;
      }
      &.-x {
        margin-left: -0.2em;
        letter-spacing: -0.55em;
      }
    }
  }
  
  
  ///// リンク /////
  &.-link01 {
    &:after {
      display: inline-block;
      content: "";
      width: 12px;
      height: 12px;
      margin-left: 6px;
      background-image: url("../images/common/icon/exsite_bk.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}