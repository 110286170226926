@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Shippori+Mincho:wght@400;500&display=swap');

@mixin font-gothic() {
  font-family:"Helvetica Neue","Segoe UI","Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
}


//Bebas
@font-face {
  font-family: "Bebas Regular";
  src: url("/assets/font/Bebas-Regular.eot?") format("eot"),
    url("/assets/font/Bebas-Regular.woff") format("woff"),
    url("/assets/font/Bebas-Regular.ttf") format("truetype"),
    url("/assets/font/Bebas-Regular.svg#Bebas-Regular") format("svg");
  font-weight:normal;
  font-style:normal;
}
@mixin font-bebas() {
  font-family: 'Bebas Regular';
  font-weight: normal;
  font-style: normal;
}


//Bebas Neue Regular
@font-face {
  font-family: "Bebas Neue Regular";
  src: url("/assets/font/BebasNeue-Regular.eot?") format("eot"),
    url("/assets/font/BebasNeue-Regular.woff") format("woff"),
    url("/assets/font/BebasNeue-Regular.ttf") format("truetype"),
    url("/assets/font/BebasNeue-Regular.svg#BebasNeue-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

//Bebas Neue Light
@font-face {
  font-family: "Bebas Neue Light";
  src: url("/assets/font/BebasNeue-Light.eot?") format("eot"),
    url("/assets/font/BebasNeue-Light.woff") format("woff"),
    url("/assets/font/BebasNeue-Light.ttf") format("truetype"),
    url("/assets/font/BebasNeue-Light.svg#BebasNeue-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

//Bebas Neue Book
@font-face {
  font-family: "Bebas Neue Book";
  src: url('/assets/font/bebas_neue_book-webfont.eot');
  src: url('/assets/font/bebas_neue_book-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/font/bebas_neue_book-webfont.woff2') format('woff2'),
    url('/assets/font/bebas_neue_book-webfont.woff') format('woff'),
    url('/assets/font/bebas_neue_book-webfont.svg#bebas_neuebook') format('svg');
  font-weight: normal;
  font-style: normal;
}

//Bebas Neue
@mixin font-bebasneue($status) {
  font-family: 'Bebas Neue Regular';
  font-weight: normal;
  font-style: normal;

  @if $status == 'l' {  //Light
    font-family: 'Bebas Neue Light';
    font-weight: 300;
  } @else if $status == 'r' { //Regular
    font-family: 'Bebas Neue Regular';
    font-weight: 400;
  } @else if $status == 'b' { //Book
    font-family: 'Bebas Neue Book';
    font-weight: 400;
  }
}


//Rockville Solid
@font-face {
  font-family: "RockvilleSolidRegular";
  src: url("/assets/font/Rockville_Solid.eot?") format("eot"),
    url("/assets/font/Rockville_Solid.woff") format("woff"),
    url("/assets/font/Rockville_Solid.ttf") format("truetype"),
    url("/assets/font/Rockville_Solid.svg#RockvilleSolidRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@mixin font-rockvillesr() {
  font-family: 'RockvilleSolidRegular';
  font-weight: normal;
  font-style: normal;
}

//Linux Libertine Display Capitals
@font-face {
  font-family: "Linux Libertine Display Capitals";
  src: url("/assets/font/LinLibertine_aDRS.eot?") format("eot"),
    url("/assets/font/LinLibertine_aDRS.woff") format("woff"),
    url("/assets/font/LinLibertine_aDRS.ttf") format("truetype"),
    url("/assets/font/LinLibertine_aDRS.svg#LinLibertineDisplayCapitals") format("svg");
  font-weight: normal;
  font-style: normal;
}
@mixin font-linuxldc() {
  font-family: 'Linux Libertine Display Capitals';
  font-weight: normal;
  font-style: normal;
}


///// Google Fonts /////
//源の角ゴシック | noto sans
@mixin font-ntsan($status) {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' {
    font-weight: 500;
  } @else if $status == 'b' {
    font-weight: 700;
  }
}

//しっぽり明朝 | Shippori Mincho
@mixin font-sprmin($status) {
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' {
    font-weight: 500;
  }
}
///// Google Fonts /////

///// Adobe Fonts /////
//DNP 秀英にじみ明朝Std
@mixin font-dnp-shunmin() {
  font-family: dnp-shuei-nmincho-std,sans-serif;
  font-weight: 400;
  font-style: normal;
}

//AB-polcadot
@mixin font-abp() {
  font-family: ab-polcadot,sans-serif;
  font-weight: 400;
  font-style: normal;
}

//DIN 1451 LT Pro
@mixin font-din1451lt() {
  font-family: din-1451-lt-pro-engschrift,sans-serif;
  font-weight: 400;
  font-style: normal;
}
///// Adobe Fonts /////


///// 2種フォント混合(英字は「Linux Libertine Display Capitals」、日本語は「しっぽり明朝」) /////
@mixin font-linuxldc-sprmin() {
  font-family: 'Linux Libertine Display Capitals', 'Shippori Mincho', serif;
  font-weight: normal;
  font-style: normal;
}


///// X SERVER WebFont /////
@mixin font-a1min() {
  font-family: A1 Mincho, dnp-shuei-nmincho-std,sans-serif;
  font-weight: normal;
  font-style: normal;
}
///// X SERVER WebFont /////
