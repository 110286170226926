/*
.hovicon.effect-2 {
  color: #eea303;
  box-shadow: 0 0 0 3px #fff;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}
.hovicon.effect-2:after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  background: #fff;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s;
}
.hovicon.effect-2.sub-b:hover:after {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.2s;
  -moz-transition: -moz-transform 0.4s, opacity 0.2s;
  transition: transform 0.4s, opacity 0.2s;
}
.hovicon.effect-2.sub-b:hover, .hovicon.effect-2.sub-b:hover i {
  color: #fff;
}
*/



.c-circle {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid color-gray01(1);
  box-sizing: border-box;
  background-color: color-gray01(1);
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
  
  &:hover {
    background-color: color-wh(1);

    .c-circle_name {
      color: color-black01(1);
    }
    
    .c-circle_slug {
      color: color-brown01(1);
      
      &::before  {
        animation: 0.3s linear 0.1s 1 both hoverCircleBorder;
      }
            
      &_txt {
        opacity: 0;
        -webkit-transition: opacity 0.15s;
        -moz-transition: opacity 0.15s;
        transition: opacity 0.15s;
//        animation: 0.15s linear 0s 1 both fadeOut;
      }
      &_hovertxt {
        opacity: 1;
        -webkit-transition: opacity 0.2s;
        -moz-transition: opacity 0.2s;
        transition: opacity 0.2s;
        transition-delay: 0.15s;
//        animation: 0.5s linear 0.2s 1 both fadeIn;
      }
    }
    
    .c-circle_icon {
      color: color-gray01(1);
    }
  }
  
  &_info {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-column;
    @include flex-justify-center;
    @include align-items-stretch;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px - 2px;  //2pxは円のボーダー幅
    box-sizing: border-box;
    text-align: center;
  }

  &_name, &_slug, &icon {
    -webkit-transition: color 0.2s;
    -moz-transition: color 0.2s;
    transition: color 0.2s;
  }

  &_name {
    height: 2.4em;
    padding: 0 .5em;
    box-sizing: border-box;
    color: color-wh(1);
  }
  
  &_slug {
    margin-bottom: 15px;
    padding-bottom: 8px;
//    border-bottom: 1px solid color-wh(1);
    box-sizing: border-box;
    color: color-wh(1);
    
    &::before  {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 1px;
      background-color: color-wh(1);
    }
    
    &::after {
      display: none;
      position: absolute;
      top: 0;
      content: "WORKS";
      opacity: 0;
    }
    
    &_txt {
      position: relative;
      opacity: 1;
      -webkit-transition: opacity 0.2s;
      -moz-transition: opacity 0.2s;
      transition: opacity 0.2s;
      transition-delay: 0.15s;
    }
    
    &_hovertxt {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include align-items-center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      text-align: center;
      -webkit-transition: opacity 0.15s;
      -moz-transition: opacity 0.15s;
      transition: opacity 0.15s;

      &::after {
        font-size: 140%;
        font-family: 'Material Icons';
        content: '\e5cc\e5cc';
        letter-spacing: -0.75em;
      }
    }
  }
  
  &_icon {
    color: color-wh(1);
  }
}