$i: 5;
@for $value from 0 through 48 {
  .u-m#{ $value * $i } {
    margin: $i * $value + px!important;
  }
  .u-mtb#{ $value * $i } {
    margin-top: $i * $value + px!important;
    margin-bottom: $i * $value + px!important;
  }
  .u-mlr#{ $value * $i } {
    margin-left: $i * $value + px!important;
    margin-right: $i * $value + px!important;
  }
  .u-mt#{ $value * $i } {
    margin-top: $i * $value  + px!important;
  }
  .u-mb#{ $value * $i } {
    margin-bottom: $i * $value + px!important;
  }
  .u-ml#{ $value * $i } {
    margin-left: $i * $value + px!important;
  }
  .u-mr#{ $value * $i } {
    margin-right: $i * $value + px!important;
  }
  .u-p#{ $value * $i } {
    padding: $i * $value + px!important;
  }
  .u-pt#{ $value * $i } {
    padding-top: $i * $value + px!important;
  }
  .u-pb#{ $value * $i } {
    padding-bottom: $i * $value + px!important;
  }
  .u-pl#{ $value * $i } {
    padding-left: $i * $value + px!important;
  }
  .u-pr#{ $value * $i } {
    padding-right: $i * $value + px!important;
  }
}

.u-m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-m-pcAuto-spLeft {
  margin-left: 0 !important;
  margin-right: auto !important;

  @include breakpoint-pc {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.u-m-pcAuto-spRight {
  margin-left: auto !important;
  margin-right: 0 !important;

  @include breakpoint-pc {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

///// M1文字の1/3の幅を確保する /////
.u-mr-01em {
  margin-right: calc(0.1em);
}

$j: 5;
@for $value from 1 through 48 {
  .u-mb {
    &#{ $value * $j }_half {
      margin-bottom: $value * $j / 2 + px!important;

      @include breakpoint-pc {
        margin-bottom: $value * $j + px!important;
      }
    }
  }
}

$k: 15;
@for $value from 1 through 18 {
  .u-mb {
    &#{ $value * $k }_23rd {
      margin-bottom: $value * $k * 2 / 3 + px!important;

      @include breakpoint-pc {
        margin-bottom: $value * $k + px!important;
      }
    }
  }
}

$l: 10;
@for $value from 1 through 18 {
  .u-mb {
    &#{ $value * $l }_level {
      margin-bottom: $value * $l / 3 + px!important;

      @include breakpoint-contenthalf {
        margin-bottom: $value * $l / 2 + px!important;
      }
      @include breakpoint-pc {
        margin-bottom: $value * $l + px!important;
      }
    }
  }
}

$j: 5;
@for $value from 1 through 48 {
  .u-mb-minus {
    &#{ $value * $j }_half {
      margin-bottom: - $value * $j / 2 + px!important;

      @include breakpoint-pc {
        margin-bottom: - $value * $j + px!important;
      }
    }
  }
}

$j: 5;
@for $value from 1 through 48 {
  .u-pb {
    &#{ $value * $j }_half {
      padding-bottom: $value * $j / 2 + px!important;

      @include breakpoint-pc {
        padding-bottom: $value * $j + px!important;
      }
    }
  }
}


///// 汎用詳細指定 /////
.u-mb25_10 {
  margin-bottom: 10px !important;

  @include breakpoint-pc {
    margin-bottom: 25px !important;
  }
}
.u-mb25_15 {
  margin-bottom: 15px !important;

  @include breakpoint-pc {
    margin-bottom: 25px !important;
  }
}
.u-mb0_25 {
  box-sizing: border-box;
  margin-bottom: 25px; 

  @include breakpoint-pc {
    margin-bottom: 0; 
  }
}
.u-mb0_50 {
  box-sizing: border-box;
  margin-bottom: 50px; 

  @include breakpoint-pc {
    margin-bottom: 0; 
  }
}
.u-mb40_15 {
  box-sizing: border-box;
  margin-bottom: 15px; 

  @include breakpoint-pc {
    margin-bottom: 40px; 
  }
}
.u-mb20n_5 {
  box-sizing: border-box;
  margin-bottom: 5px; 

  @include breakpoint-narrow {
    margin-bottom: 20px; 
  }
}
.u-mb20n_13 {
  box-sizing: border-box;
  margin-bottom: 13px; 

  @include breakpoint-narrow {
    margin-bottom: 20px; 
  }
}
.u-mb65ni_30 {
  box-sizing: border-box;
  margin-bottom: 30px; 

  @include breakpoint-narrowinner {
    margin-bottom: 65px; 
  }
}

.u-pt120_60 {
  box-sizing: border-box;
  padding-top: 60px; 

  @include breakpoint-pc {
    padding-top: 120px; 
  }
}

.u-pt180_90 {
  box-sizing: border-box;
  padding-top: 90px; 

  @include breakpoint-pc {
    padding-top: 180px; 
  }
}

.u-pb190_200 {
  box-sizing: border-box;
  padding-bottom: 200px; 

  @include breakpoint-pc {
    padding-bottom: 190px; 
  }
}

.u-pb120ni_90pc_70 {
  box-sizing: border-box;
  padding-bottom: 70px; 

  @include breakpoint-pc {
    padding-bottom: 90px; 
  }
  @include breakpoint-narrowinner {
    padding-bottom: 120px; 
  }
  
  ///// ブログ無しの時、スライドインボタンを右に配置するので、動物装飾の位置も左右逆にするため、Topicsのテキストと動物装飾が近くなるため、余白を広げるための処置(以下が、その左右逆バージョン。ブログを実装したら、以下は消して良い) /////
  &.-rightver {
    @include breakpoint-narrowinner {
      padding-bottom: 150px; 
    }
  }
  ///// ブログ無しの時、スライドインボタンを右に配置するので、動物装飾の位置も左右逆にするため、Topicsのテキストと動物装飾が近くなるため、余白を広げるための処置((以上が、その左右逆バージョン。ブログを実装したら、以上は消して良い) /////
}

.u-pl10_0 {
  padding-left: 0;
  box-sizing: border-box;
  
  @include breakpoint-pc {
    padding-left: 10px;
  }
}

// 汎用マイナスマージン
.u-mb_minus10_half {
  margin-bottom: -5px; 

  @include breakpoint-pc {
    margin-bottom: -10px; 
  }
}

.u-mb_minus80_half {
  margin-bottom: -40px; 

  @include breakpoint-pc {
    margin-bottom: -80px; 
  }
}


///// 個別指定 /////
// headlineのttl01のタイプ1
.u-mb_hlttl01_type01 {
  box-sizing: border-box;
  margin-bottom: 20px; 

  @include breakpoint-pc {
    margin-bottom: 35px; 
  }
  @include breakpoint-narrowinner {
    margin-bottom: 45px; 
  }
  @include breakpoint-narrow {
    margin-bottom: 85px; 
  }
}

// headlineのttl01のタイプ1
.u-mb_hllowerkv01 {
  box-sizing: border-box;
  margin-bottom: 15px; 

  @include breakpoint-pc {
    margin-bottom: 35px; 
  }
}

// textのlead02のタイプ1
.u-mb_tlead02_type01 {
  box-sizing: border-box;
  margin-bottom: 10px; 

  @include breakpoint-pc {
    margin-bottom: 30px; 
  }
  @include breakpoint-narrowinner {
    margin-bottom: 40px; 
  }
  @include breakpoint-narrow {
    margin-bottom: 50px; 
  }
}

// トップページのABOUT USの下余白(480px未満時のみ)
.u-mb_frontaboutus {
  margin-bottom: 30px;

  @include breakpoint-tb {
    margin-bottom: 0; 
  }
}

// トップページSERVICEの上下余白
.u-pt_frontservice {
  box-sizing: border-box;
  padding-top: 70px; 

  @include breakpoint-narrowinner {
    padding-top: 90px; 
  }
  @include breakpoint-narrow {
    padding-top: 130px; 
  }
}
.u-pb_frontservice {
  box-sizing: border-box;
  padding-bottom: 80px; 

  @include breakpoint-pc {
    padding-bottom: 120px; 
  }
  @include breakpoint-narrowinner {
    padding-bottom: 140px; 
  }
  @include breakpoint-narrow {
    padding-bottom: 190px; 
  }
}


// About UsのSPのリード下余白
.u-mb_aslead {
  box-sizing: border-box;
  margin-bottom: 20px; 

  @include breakpoint-w720 {
    margin-bottom: 20px; 
  }
  @include breakpoint-narrowinner {
    margin-bottom: 25px; 
  }
  @include breakpoint-narrow {
    margin-bottom: 30px; 
  }
}


// About UsのSPのリード下余白
.u-mb_aslead {
  box-sizing: border-box;
  margin-bottom: 20px; 

  @include breakpoint-w720 {
    margin-bottom: 20px; 
  }
  @include breakpoint-narrowinner {
    margin-bottom: 25px; 
  }
  @include breakpoint-narrow {
    margin-bottom: 30px; 
  }
}


// About Usの最後のメンバーの下余白
.u-mb_memberlast {
  margin-bottom: 70px;
  
  @include breakpoint-tb {
    margin-bottom: 90px;
  }
  @include breakpoint-contentinnerhalf {
    margin-bottom: 130px;
  }
  @include breakpoint-pc {
    margin-bottom: 190px;
  }
  @include breakpoint-narrowinner {
    margin-bottom: 190px;
  }
}


// GET IN TOUCH
.u-mb_gitlead {
  margin-bottom: 0; 

  @include breakpoint-contentinnerhalf {
    margin-bottom: 5px; 
  }
  @include breakpoint-pc {
    margin-bottom: 10px; 
  }
  @include breakpoint-narrowinner {
    margin-bottom: 20px; 
  }
}
.u-mb_gitttl {
  margin-bottom: 65px;

  @include breakpoint-narrowinner {
    margin-bottom: 75px; 
  }
}


// All Worksのajax動的投稿取得の下余白(READ MOREボタンの有無で変化させる)
.u-pb_loadresult {
  padding-bottom: 40px; 

  @include breakpoint-pc {
    padding-bottom: 80px; 
  }
  
  //READ MOREボタン非表示の場合(該当する投稿を全件表示した場合)
  &.js-displayall {
    padding-bottom: 15px; 
  
    @include breakpoint-pc {
      padding-bottom: 30px; 
    }
  }
}


// Worksのsingleページ、WYSIWYG内の見出し下マージン
.u-worksttlset04_main {
  margin-top: 10px;
  margin-bottom: 15px;
  
  @include breakpoint-pc {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
.u-worksttlset04_sub {
  margin-bottom: 5px;
  
  @include breakpoint-pc {
    margin-bottom: 10px;
  }
}
