.p3-headline {
  position: relative;

  // テキスト寄せ
  &.-left {
    text-align: left;
  }
  
  &.-center {
    text-align: center;
  }

  &.-right {
    text-align: right;
  }

  &.-justify {
    text-align: justify;
  }


  // テキストカラー
  &.-wh {
    color: color-wh(1);
  }
  &.-bk {
    color: color-bk(1);
  }

  &.-black01 {
    color: color-black01(1);
  }
  &.-black02 {
    color: color-black02(1);
  }
  &.-black03 {
    color: color-black03(1);
  }

  &.-gray01 {
    color: color-gray01(1);
  }
  &.-gray02 {
    color: color-gray02(1);
  }
  &.-gray03 {
    color: color-gray03(1);
  }
  &.-gray04 {
    color: color-gray04(1);
  }
  
  &.-blue01 {
    color: color-blue01(1);
  }
  &.-blue02 {
    color: color-blue02(1);
  }
  &.-blue03 {
    color: color-blue03(1);
  }

  &.-brown01 {
    color: color-brown01(1);
  }


  ///// トップページのKV内のキャッチコピー /////
  &.-catchcopy01 {
    @include font-a1min();
    font-size: 12.5vw;  //340px未満辺りで改行が発生してしまうので、13vwより小さくする
    line-height: 1.525;
    letter-spacing: 0.03em;
    text-align: center;
//    color: color-white02(1);
//    mix-blend-mode: difference; //差の絶対値

    @media all and (min-width: 340px) {
      font-size: 13vw;  //67px / 480px * 100
    }
    @include breakpoint-tb {
      font-size: 6.2rem;
    }
    @include breakpoint-contentinnerhalf {
      font-size: 6.787rem;
    }
    @include breakpoint-w720 {
      text-align: left;
    }
    @include breakpoint-narrow {
      font-size: 8rem;
    }
  }
  
  
  ///// 下層ページのKV内のタイトル /////
  // 英字
  &.-lowerkv01 {
    @include font-bebas();
    @include fontSizeBebasOnesize(5);
    line-height: 1;
    letter-spacing: 0.01em;
    text-align: center;

    @include breakpoint-tb {
      text-align: left;
    }
    @include breakpoint-contentinnerhalf {
      @include fontSizeBebasOnesize(5.6);
    }
    @include breakpoint-w720 {
      @include fontSizeBebasOnesize(6.4);
    }
    @include breakpoint-pc {
      @include fontSizeBebasOnesize(7.2);
    }
    @include breakpoint-narrowinner {
      @include fontSizeBebasOnesize(8.2);
    }
    @include breakpoint-narrow {
      @include fontSizeBebasOnesize(9);
    }
  }
  //日本語
  &.-lowerkv02 {
    @include font-dnp-shunmin();
    font-size: 1.8rem;
    line-height: 1;
    text-align: center;

    @include breakpoint-tb {
      text-align: left;
    }
    @include breakpoint-contentinnerhalf {
      font-size: 2rem;
      text-align: left;
    }
    @include breakpoint-pc {
      font-size: 2.3rem;
    }
    @include breakpoint-narrowinner {
      font-size: 2.5rem;
    }
    @include breakpoint-narrow {
      font-size: 2.6rem;
    }
  }


  ///// 汎用タイトル /////
  &.-ttl01 {
    @include font-bebas();
    @include fontSizeBebas(4, 2.8); //40px * 0.7 = 28px
    line-height: 1;
    letter-spacing: 0.05em;
    
    &.-ajusttop {
      line-height: 0.6;
    }
  }
  
  &.-ttl02 {
    @include font-a1min();
    font-size: 2.8rem;
    line-height: 1.7;
    letter-spacing: 0.05em;

    @include breakpoint-pc {
      font-size: 3.6vw;
    }
    @include breakpoint-narrowinner {
      font-size: 3.6rem;
      line-height: 2.25;
    }
    @include breakpoint-narrow {
      font-size: 4rem;
    }
    
    &.-ajusttop {      
      margin-top: (1em - 1.7) / 2;
    
      @include breakpoint-narrowinner {
        margin-top: (1em - 2.25) / 2;
      }
    }
  }
  
  &.-ttl03 {
    @include font-a1min();
    @include fontSize(7, 4.9);  //70px * 0.7 = 49
    line-height: 1.4;
    letter-spacing: 0.05em;
  }


  ///// 汎用サブタイトル /////
  &.-subttl01 {
  }

  
  ///// 汎用タイトルセット /////
  &.-ttlset01 {
    &.-main {
      @include font-bebas();
      font-size: 4rem;
      line-height: 1;
      letter-spacing: 0.01em;
      
      @include breakpoint-pc {
        font-size: 4.4rem;
      }
      @include breakpoint-narrowinner {
        font-size: 5.4rem;
      }
      @include breakpoint-narrow {
        font-size: 6rem;
      }
  
      &.-separator {
        &:after {
          display: block;
          position: relative;
          content: "";
          width: 120px;
          height: 2px;
          margin-top: 15px;
          margin-left: auto;
          margin-right: auto;
          background-color: color-blue02(1);
  
          @include breakpoint-pc {
            margin-top: 30px;
          }
        }
      }
    }
    &.-sub {
      @include font-dnp-shunmin();
      font-size: 1.8rem;
      @include lineHeight(1.8, 1.8);
      letter-spacing: 0.03em;
  
      @include breakpoint-pc {
        font-size: 1.8rem;
      }
      @include breakpoint-narrowinner {
        font-size: 2.3rem;
      }
      @include breakpoint-narrow {
        font-size: 2.6rem;
      }
    }
  }

  &.-ttlset02 {
    &.-main {
      @include font-linuxldc();
      @include fontSize(7, 4.9);  //70px * 0.7 = 49
      line-height: 1;
      letter-spacing: 0.01em;
      
      &.-ajusttop {
        margin-top: -0.15em;
      }
    }
    &.-sub {
      @include font-dnp-shunmin();
      @include fontSize(2, 1.6);  //20px * 0.8 = 16
      line-height: 1;
      letter-spacing: 0.03em;
    }
  }

  &.-ttlset03 {
    &.-main {
      @include font-linuxldc();
      @include fontSize(4.6, 3.22);  //46px * 0.7 = 32.2
      line-height: 1;
      letter-spacing: 0.01em;
    }
    &.-sub {
      @include font-dnp-shunmin();
      @include fontSize(1.8, 1.44);  //18px * 0.8 = 16
      line-height: 1;
      letter-spacing: 0;
    }
  }

  &.-ttlset04 {
    &.-main {
      @include font-a1min();
      @include fontSize(2.8, 1.96);  //28px * 0.7 = 19.6
      @include lineHeight(2, 2);
      letter-spacing: 0.07em;
    }
    &.-sub {
      @include font-ntsan("b");
      @include fontSize(1.9, 1.52); //20px * 0.8 = 15.2
      @include lineHeight(2.3, 2.3);
      letter-spacing: 0.05em;
    }
  }


  ///// 2行タイトル /////
  &.-twolinettl01 {
    & > div {
      position: relative;

      & > div {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-row;
      }
    }
    
    // 1行目
    & > div:nth-of-type(1) {
      & > div {
        @include flex-justify-flex-start;
        @include align-items-center;
        position: relative;
        @include font-bebas();
        @include fontSizeBebasOnesize(2.8);
        line-height: 1;
        letter-spacing: 0.01em;

        @include breakpoint-contentinnerhalf {
          @include fontSizeBebasOnesize(3.2);
        }
        @include breakpoint-narrowinner {
          @include fontSizeBebasOnesize(4);
        }

        &:before {
          display: inline-block;
          position: relative;
          transform: translateY(1px);
          content: "";
          width: 8px;
          height: 12px;
          margin-right: 5px;
          background-image: url("../images/common/icon/right_dottri_bk.png");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
  
          @include breakpoint-contentinnerhalf {
            width: 9px;
            height: 15px;
            margin-right: 11px;
          }
          @include breakpoint-pc {
            width: 11px;
            height: 18px;
            margin-right: 9px;
          }
        }
      }
    }

    // 2行目
    & > div:nth-of-type(2) {
      & > div:nth-of-type(1) {
        @include flex-wrap-nowrap;
        @include flex-justify-flex-start;
        @include align-items-baseline;
        position: relative;
        width: 100vw;
        margin-top: -0.3em;
        margin-left: 1.69em;
        @include font-linuxldc();
        font-size: 3.3rem;
        line-height: 1;
        letter-spacing: 0.01em;

        @include breakpoint-contentinnerhalf {
          font-size: 3.8rem;
        }
        @include breakpoint-narrowinner {
          font-size: 4.6rem;
        }
  
        &:after {
          display: block;
          position: relative;
          content: "";
          width: 100vw;
          height: 1px;
          margin-left: 20px;
          background-color: color-black01(1);
  
          @include breakpoint-pc {
          }
        }
        
        &.-small {
          font-size: 3.3rem * 0.9;

          @include breakpoint-tb {
            font-size: 3.3rem;
          }
          @include breakpoint-contentinnerhalf {
            font-size: 3.8rem;
          }
          @include breakpoint-narrowinner {
            font-size: 4.6rem;
          }
        }
      }

      // 日本語
      & > div:nth-of-type(2) {
        display: inline-block;
        position: relative;
        left: 13px;
        top: 0;
        @include font-dnp-shunmin();
        font-size: 1.14rem;
        @include lineHeight(1, 1);
        letter-spacing: 0.03em;

        @include breakpoint-tb {
          position: absolute;
          left: auto;
          right: 0;
        }
        @include breakpoint-contentinnerhalf {
          font-size: 1.28rem;
        }
        @include breakpoint-narrowinner {
          font-size: 1.6rem;
        }
      }
    }
  }

  &.-twolinettl02 {    
    div:nth-of-type(1) {
      margin-bottom: 10px;
      @include font-linuxldc();
      @include fontSize(5, 3.5);  //50px * 0.7 = 35
      line-height: 1;
      letter-spacing: 0.01em;
    }

    div:nth-of-type(2) {
      @include font-dnp-shunmin();
      @include fontSize(2, 1.6);  //50px * 0.8 = 16
      line-height: 1;
      letter-spacing: 0.07em;
    }
  }


  ///// タグ名 /////
  &.-tagname01 {
    @include font-dnp-shunmin();
    @include fontSize(2.4, 1.8);
    line-height: 1;
    letter-spacing: 0.03em;
  }
  

  ///// 作品事例見出し、新着情報見出し、ブログ見出し(英語＋日本語横並びタイトル) /////
  &.-workssingle01, &.-topicssingle01, &.-blogsingle01 {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-baseline;
    position: relative;
    padding-bottom: 15px;
    box-sizing: border-box;
    
    &:after {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 1px;
      content: "";
      background-color: color-black01(1);
    }

    div:nth-of-type(1) {
      margin-right: 6px;
      @include font-bebas();
      @include fontSizeBebas(3.2, 2.24);  //32px * 0.7 = 22.4
      line-height: 1;
      letter-spacing: 0.01em;
    }
    
    div:nth-of-type(2) {
      @include font-dnp-shunmin();
      @include fontSize(1.4, 1.2);
      line-height: 1;
      letter-spacing: 0.01em;
    }
  }

  // 新着情報見出し、ブログ見出しは、英語と日本語の間の余白が広い
  &.-topicssingle01, &.-blogsingle01 {
    div:nth-of-type(1) {
      margin-right: 16px;
    }
  }
}