///// fadein effect /////
///// フェードインさせたいアイテムにjs-fadeinitemクラスを付ける /////
.js-fadeinitem {
  opacity: 0;
}
///// フェードインさせたい時に、js-fadeinクラスが追加される /////
.js-fadein {
  animation: 0.5s ease-in-out 0s 1 both fadeIn;
}

///// フェードイン&アップさせたいアイテムにjs-fadeinupitemクラスを付ける /////
.js-fadeinupitem {
  opacity: 0;
  transform: translateY(10px);
}
///// フェードイン&アップさせたい時に、js-fadeinupクラスが追加される /////
.js-fadeinup {
  animation: 0.5s ease-in-out 0s 1 both fadeInUp;
}

///// スライドアップさせたいアイテムにjs-slideupitemクラスを付ける /////
.js-slideupitem {
  transform: translateY(20px);
}
///// スライドアップさせたい時に、js-slideupクラスが追加される /////
.js-slideup {
  animation: 0.5s ease-in-out 0s 1 both slideUp;
}


///// ブラー表示させたいアイテムにjs-blurwhitemクラスを付ける /////
.js-blurwhitem {
	color: color-wh(1);
}
///// ブラー表示させたい時に、js-fadeinクラスが追加される /////
.js-blurWh {
	color: color-wh(1);
  animation: 10s ease-out 0s 1 both blurWh;
}


///// 一文字ずつアップして表示する /////
@mixin charUpTransiton($plus-delay) {
  $start-delay: $charup-base-delay + ($plus-delay * $charup-duration / 10); //1文字目が表示されるまでのdelay(単位はms)

  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      @if($i == 1) {
        animation-delay: #{$start-delay}ms ;
      } @else {
        animation-delay: #{$start-delay + ( ($i - 1) * ($charup-duration - ($charup-duration / 5)) / 10)}ms;  //2文字目以降、アニメーションの遅延について、(1文字がアップにかかる時間 - (1文字がアップにかかる時間 / 5))にする。それにより、前の文字のアップが終わる前に、次の文字のアップが始まるようにする。
      }
    }
  }
}

.js-charupitem {
  position: relative;
  
  .js-charup_container {
    overflow: hidden;

    span {
      display: inline-block;
      transform: translate3d(0, 2em, 0);
    }
  }
  
  //2行タイトルとの併用時
  &.-twolinettl01 {
    div:nth-of-type(1) {
      &:before {
        opacity: 0;
      }
    }
    div:nth-of-type(2) {
      &:after {
        opacity: 0;
      }
    }
  }
}

.js-charup {
  position: relative;
	z-index: 3;

  & .js-charup_container {
    span {
      animation: oneCharUp #{$charup-duration}ms 1 ease both;
      will-change: animation;
      @include charUpTransiton(0);
    }

    @for $i from 0 through 20 {
      &.-delay#{$i} {
        span {
          @include charUpTransiton($i);
        }
      }
    }
  }
  
  //2行タイトルとの併用時(1行目左のドット三角アイコンと、2行目右の下線の表示を制御)
  &.-twolinettl01 {
    & > div:nth-of-type(1) {
      & > div {
        &:before {
          animation: 0.3s ease-in-out #{$charup-base-delay * $charup-base-delay / 100}ms 1 both fadeIn;
        }
      }
    }

    & > div:nth-of-type(2) {
      & > div:nth-of-type(1) {
        @for $i from 1 through 50 {
          $finish-delay: ($charup-base-delay * $charup-base-delay / 100) + ($i * $charup-duration / 10);
  
          &.-finish#{$i} {
            &:after {
              animation: #{$charup-duration}ms ease-in-out #{$finish-delay}ms 1 both fadeIn;
            }
          }
        }
      }
      & > div:nth-of-type(2) {
        @for $i from 1 through 50 {
          $finish-delay: ($charup-base-delay * $charup-base-delay / 100) + ($i * $charup-duration / 10);
  
          &.-finish#{$i} {
            &.js-charup_jp {
              animation: #{$charup-duration}ms ease-in-out #{$finish-delay}ms 1 both fadeIn;
            }
          }
        }
      }
    }
  }
}


////// 画面下から25%に来たら、スライドインする /////
.js-slideinbtn {
  &.-left {
    transform: translateX(-110%);
  }

  &.-right {
    transform: translateX(110%);
  }

  &.js-ismove {
    &.-left {
      animation: 0.4s ease-in-out 0s 1 both scrollSlideInLeft;

      @include breakpoint-pc {
        animation-duration: 0.45s;
      }
      @include breakpoint-pc {
        animation-duration: 0.5s;
      }
    }

    &.-right {
      animation: 0.4s ease-in-out 0s 1 both scrollSlideInRight;

      @include breakpoint-pc {
        animation-duration: 0.45s;
      }
      @include breakpoint-pc {
        animation-duration: 0.5s;
      }
    }
  }
}


///// ホバー時に枠線を描く /////
.draw-border {
  position: relative;
  transition: color 0.25s, background-color 0.25s;

  &::before, &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    border: 2px solid transparent;
    width: 0;
    height: 0;
    box-sizing: border-box;
  }

  &::before {
    top: 0;
    left: 0;
  }

  &::after {
    bottom: 0;
    right: 0;
  }

  &:hover::before, &:hover::after {
    width: 100%;
    height: 100%;
  }

  &:hover::before {
    transition:
      width 0.25s ease-out, 
      height 0.25s ease-out 0.25s;
  }

  &:hover::after {
    transition:
      border-color 0s ease-out 0.5s,
      width 0.25s ease-out 0.5s,
      height 0.25s ease-out 0.75s;
  }
  
  // 黒背景ボタン→黒枠白背景ボタン
  &.-bk {
    background-color: color-bk(1);
    color: color-wh(1);

    &:hover {
      background-color: color-wh(1);
      color: color-bk(1);
      
      &::before {
        border-top-color: color-bk(1);
        border-right-color: color-bk(1);
      }
      
      &::after {
        border-bottom-color: color-bk(1);
        border-left-color: color-bk(1);
      }
    }
  }
}


///// ホバー時、右下に弧を表示 /////
.hover-arc {
  display: inline-block;
  border-radius: 50%;
  position: relative;

  &:after {
    position: absolute;
    top: -9px;
    left: -9px;
    z-index: -1;
    width: 100%;
    height: 100%;
    padding: 9px;
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(255, 255, 255, 1);
    content:'';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: opacity 0.2s, box-shadow 0.2s;
    -moz-transition: opacity 0.2s, box-shadow 0.2s;
    transition: opacity 0.2s, box-shadow 0.2s;
    pointer-events: none;
    opacity: 0;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
  
  &.-blue02 {
    &:after {
      box-shadow: 0 0 0 color-blue02(1);
    }
    &:hover:after {
      box-shadow: 3px 3px 0 color-blue02(1);
    }
  }
}


///// ホバー時、弾ける(transition版) /////
.hover-burst, .hover-burst-anime {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;

  &:after {
    position: absolute;
    top: -1px;
    left: -1px;
    padding: 1px;
    z-index: -1;
    content:'';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    pointer-events: none;
  }
  
  &:hover {
    .icon-fill-bk {
      fill: color-wh(1);
    }

    .icon-fill-wh {
      fill: color-bk(1);
    }
  }

  &:hover:after {
    pointer-events: none;
  }
  
  // 背景＆枠線の色
  &.-wh {
    box-shadow: 0 0 0 2px color-wh(1);
    
    &:after {
      background-color: color-wh(1);
    }
  }

  &.-blue02 {
    box-shadow: 0 0 0 2px color-blue02(1);
    
    &:after {
      background-color: color-blue02(1);
    }
  }
}
.hover-burst {
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;

  &:after {
    -webkit-transition: -webkit-transform 0.2s, opacity 0.3s;
    -moz-transition: -moz-transform 0.2s, opacity 0.3s;
    transition: transform 0.2s, opacity 0.3s;
  }

  &:hover:after {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }


  // 広がる波の拡大率
  &.-scale11 {
    &:hover:after {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  &.-scale115 {
    &:hover:after {
      -webkit-transform: scale(1.15);
      -moz-transform: scale(1.15);
      -ms-transform: scale(1.15);
      transform: scale(1.15);
    }
  }

  &.-scale12 {
    &:hover:after {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  &.-scale13 {
    &:hover:after {
      -webkit-transform: scale(1.3);
      -moz-transform: scale(1.3);
      -ms-transform: scale(1.3);
      transform: scale(1.3);
    }
  }

  &.-scale15 {
    &:hover:after {
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -ms-transform: scale(1.5);
      transform: scale(1.5);
    }
  }
}
///// hover時一方通行版(animation使用、マウスを外した時にはアニメーションしない) /////
.hover-burst-anime {
  &.-scale115 {
    &:hover:after {
      -webkit-animation: hoverBurstAfterScale115 0.2s linear 1, hoverBurstAfterOpacity 0.3s linear 1;
      animation: hoverBurstAfterScale115 0.2s linear 1, hoverBurstAfterOpacity 0.3s linear 1;
    }
  }
}


///// アイテム(画像、動画など)を動かす //////
.move-items1 {  
  -webkit-animation: floatHorizontal01 12s ease-in-out infinite alternate;
  animation: floatHorizontal01 12s ease-in-out infinite alternate;
  
  img {
    -webkit-animation: floatVertical01 10s ease-in-out infinite alternate;
    animation: floatVertical01 10s ease-in-out infinite alternate;
  }
}
.move-items1-delay {
  -webkit-animation: floatHorizontal01 12s ease-in-out -3s infinite alternate;
  animation: floatHorizontal01 12s ease-in-out -3s infinite alternate;
  
  img {
    -webkit-animation: floatVertical01 10s ease-in-out -3s infinite alternate;
    animation: floatVertical01 10s ease-in-out -3s infinite alternate;
  }
}

.move-items2 {
  -webkit-animation: floatHorizontal02 12s ease-in-out infinite alternate;
  animation: floatHorizontal02 12s ease-in-out infinite alternate;
  
  img {
    -webkit-animation: floatVertical02 10s ease-in-out infinite alternate;
    animation: floatVertical02 10s ease-in-out infinite alternate;
  }
}
.move-items2-delay {
  -webkit-animation: floatHorizontal02 12s ease-in-out -4.5s infinite alternate;
  animation: floatHorizontal02 12s ease-in-out -4.5s infinite alternate;
  
  img {
    -webkit-animation: floatVertical02 10s ease-in-out -4.5s infinite alternate;
    animation: floatVertical02 10s ease-in-out -4.5s infinite alternate;
  }
}


///// 覆いを取ると、クリップマスクテキストを表示 /////
.js-revealtext, .js-revealtext::after {
	animation-delay: 0s;
	animation-iteration-count: 1;
	animation-duration: 800ms;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
}

.js-revealtext {
	position: relative;
  opacity: 0;

  &.js-isdisplay {
  	animation-name: clipText;
  }
	
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: color-gray01(1);
		transform: scaleX(0);
		transform-origin: 0 50%;
		pointer-events: none;

    &.js-isdisplay {
  		animation-name: textRevealer;
    }
	}
}
///// 覆いを取ると、クリップマスクテキストを表示 /////


///// フェードイン・アウト /////
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

///// フェードインアップ /////
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

///// スライドアップ /////
@keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}


//// ドロワーイン /////
@keyframes drawerIn {
  0% {
    transform: translateY(-100vh);
    background: color-base01(0);
  }
  1% {
    background: color-base01(0.95);
  }
  100% {
    background: color-base01(0.95);
    transform: translateY(0);
  }
}
@keyframes drawerOut {
  0% {
    transform: translateY(0);
    background: color-base01(0.95);
  }
  99% {
    background: color-base01(0.95);
  }
  100% {
    background: color-base01(0);
    transform: translateY(-100vh);
  }
}


///// 常時上下動 /////
// 丸枠外にはみ出した線を上下動(線の長さも変える必要がある)
@keyframes moveLineUpDonw10 {
  0% {
    height: 35px;
    transform: translate(-50%, 0);
  }
  50% {
    height: 45px;
    transform: translate(-50%, -10px);
  }
  100% {
    height: 35px;
    transform: translate(-50%, 0);
  }
}
// 丸枠内の矢印を上下動(位置を動かすのみ)
@keyframes moveArwUpDonw10 {
  0% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(-50%, -10px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}


///// スクロール時、左からスライドイン /////
@keyframes scrollSlideInLeft {
  0% {
    transform: translateX(-105%);
  }
  100% {
    transform: translateX(0);
  }
}


///// スクロール時、右からスライドイン /////
@keyframes scrollSlideInRight {
  0% {
    transform: translateX(105%);
  }
  100% {
    transform: translateX(0);
  }
}


///// カードのホバー時のアニメーション /////
@keyframes hoverCardSeparator {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}
@keyframes hoverCardBtmBorder {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}


///// サークルのホバー時のアニメーション /////
@keyframes hoverCircleBorder {
  0% {
    width: 0;
    background-color: color-gray01(1);
  }
  100% {
    width: 100%;
    background-color: color-gray01(1);
  }
}


///// テキストノイズのアニメーション(1行表示と2行表示でパラメータを変えている) /////
@keyframes textNoise1-line1 {
  $steps: 20;

  @for $i from 0 through $steps{
    #{percentage($i*(1/$steps))}{
      clip: rect(random(65)+px, 9999px, random(65)+px, 0);
    }
  }
}
@keyframes textNoise2-line1 {
  $steps:20;

  @for $i from 0 through $steps{
    #{percentage($i*(1/$steps))} {
      clip: rect(random(65)+px, 9999px, random(65)+px, 0);
    }
  }
}

@keyframes textNoise1-line2 {
  $steps: 20;

  @for $i from 0 through $steps{
    #{percentage($i*(1/$steps))}{
      clip: rect(random(100)+px, 9999px, random(100)+px, 0);
    }
  }
}
@keyframes textNoise2-line2 {
  $steps:20;

  @for $i from 0 through $steps{
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100)+px, 9999px, random(100)+px, 0);
    }
  }
}


///// ロゴ回転のアニメーション /////
@keyframes rotateLogo {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}


///// 覆いを取ると、クリップマスクテキストを表示 /////
@keyframes clipText {
	0% {
		clip-path: inset(0 100% 0 0);
		opacity: 0;
	}
	1% {
		opacity: 1;
	}

	100% {
		clip-path: inset(0 0 0 0);
		opacity: 1;
	}
}

@keyframes textRevealer {
	0%, 50% {
		transform-origin: 0 50%;
	}
	60%, 100% {
		transform-origin: 100% 50%;		
	}
	60% {
		transform: scaleX(1);
	}
	100% {
		transform: scaleX(0);
	}
}


///// テキストを流して表示する /////
@keyframes marquee01 {
  0% {
    transform: translate(100%, 0);  //アニメーション開始地点をブラウザ右端にする
  }
  100% {
    transform: translate(-100%, 0);  //アニメーションの終点をブラウザ左端からmarquee01の幅の分だけ左に移動した位置にする
  }
}
@keyframes marquee01Copy {
  0% {
    transform: translate(0, 0);  //アニメーション開始地点をブラウザ右端にする
  }
  100% {
    transform: translate(-200%, 0);  //アニメーションの終点をブラウザ左端からmarquee01の幅の分だけ左に移動した位置にする
  }
}


///// アイテム(画像、動画など)を動かす //////
@-webkit-keyframes floatHorizontal01 {
  0% {
    transform: translate3d(1vw, 0, 0);
  }
  50% {
    transform: translate3d(-1vw, 0, 0);
  }
  100% {
    transform: translate3d(1vw, 0, 0);
  }
}
@keyframes floatHorizontal01 {
  0% {
    transform: translate3d(1vw, 0, 0);
  }
  50% {
    transform: translate3d(-1vw, 0, 0);
  }
  100% {
    transform: translate3d(1vw, 0, 0);
  }
}

@-webkit-keyframes floatVertical01 {
  0% {
    transform: translate3d(0, 1vw, 0);
  }
  50% {
    transform: translate3d(0, -1vw, 0);
  }
  100% {
    transform: translate3d(0, 1vw, 0);
  }
}
@keyframes floatVertical01 {
  0% {
    transform: translate3d(0, 1vw, 0);
  }
  50% {
    transform: translate3d(0, -1vw, 0);
  }
  100% {
    transform: translate3d(0, 1vw, 0);
  }
}
@-webkit-keyframes floatHorizontal02 {
  0% {
    transform: translate3d(-0.3vw, 0, 0);
  }
  50% {
    transform: translate3d(0.3vw, 0, 0);
  }
  100% {
    transform: translate3d(-0.3vw, 0, 0);
  }
}
@keyframes floatHorizontal02 {
  0% {
    transform: translate3d(-0.3vw, 0, 0);
  }
  50% {
    transform: translate3d(0.3vw, 0, 0);
  }
  100% {
    transform: translate3d(-0.3vw, 0, 0);
  }
}

@-webkit-keyframes floatVertical02 {
  0% {
    transform: translate3d(0, -0.3vw, 0);
  }
  50% {
    transform: translate3d(0, 0.3vw, 0);
  }
  100% {
    transform: translate3d(0, -0.3vw, 0);
  }
}
@keyframes floatVertical02 {
  0% {
    transform: translate3d(0, -0.3vw, 0);
  }
  50% {
    transform: translate3d(0, 0.3vw, 0);
  }
  100% {
    transform: translate3d(0, -0.3vw, 0);
  }
}


///// ブラー表示(白) /////
@keyframes blurWh {
	0% {
  	opacity: 0;
  	color: color-wh(0);
  	text-shadow: 0 0 100px color-wh(1);
  }
	5% {
  	text-shadow: 0 0 90px color-wh(1);
	}
	15% {
  	opacity: 1;
  	color: color-wh(0);
  }
	20% {
  	color: color-wh(1);
  	text-shadow: 0 0 0px color-wh(1);
  }
	95% {
  	color: color-wh(1);
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23), 0px 0px 14px rgba(0, 0, 0, 0.43);
  }
	100% {
  	color: color-wh(1);
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.23), 0px 0px 14px rgba(0, 0, 0, 0.43);
  }
}


///// タイトルアニメーション /////
@keyframes oneCharUp {
	0% {
    transform: translate3d(0, 2em, 0);
    transition: transform #{$charup-duration}ms cubic-bezier(0.6, 0, 0.2, 1);
  }
	100% {
    transform: translate3d(0, 0, 0);
    transition: transform #{$charup-duration}ms cubic-bezier(0.6, 0, 0.2, 1);
  }
}


///// ホバー時、弾けるアニメーション(一方通行アニメーション版) /////
@keyframes hoverBurstAfterScale11 {
	0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
	100% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes hoverBurstAfterScale115 {
	0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
	100% {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
  }
}
@keyframes hoverBurstAfterScale12 {
	0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
	100% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes hoverBurstAfterScale13 {
	0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
	100% {
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }
}
@keyframes hoverBurstAfterScale15 {
	0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
	100% {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
  }
}
@keyframes hoverBurstAfterOpacity {
	0% {
    opacity: 1;
  }
	100% {
    opacity: 0;
  }
}
