.p1-dnav {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-column;
  @include flex-justify-center;
  @include align-items-center;
//  overflow: hidden;
  width: 100%;
  height: 100vh;
//  padding-top: 75px;
  padding-bottom: 40px;
  box-sizing: border-box;

  @include breakpoint-pc {
//    padding-top: 150px;
  }
  @media all and (min-height: 890px) {
    @include flex-justify-flex-start;
    padding-bottom: 0;
    padding-top: 150px;
  }

  &_content {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 305px;
    max-width: 90%;
    margin: 0 auto;
  }

  ///// メニューリスト /////
  &_list {
    width: 100%;
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-space-between;
    @include align-items-stretch;
    box-sizing: border-box;

    @include breakpoint-pc {
    }
    @include breakpoint-content {
    }
    
    &.-full {
      width: 100%;
    }
    
    &_col {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-column;
      @include flex-justify-space-between;
      @include align-items-stretch;
    }
  }

  &_item {
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
    box-sizing: border-box;

    @include breakpoint-pc {
      margin-bottom: 10px;
    }
    
    &:last-of-type {
    }
  }
  
  &_link {
    color: color-black01(1);
  }

  &_txt {
    display: block;
    line-height: 1;
    letter-spacing: 0.01em;

    &.-en {
      margin-bottom: 5px;
      @include font-bebas();
      @include fontSizeBebas(3.6, 3.6);
//      @include fontSize(4.4, 4.4);  //PSD上では36pxだが、幅・高さを測ると、CSS上では44pxで指定した時のフォントサイズがPSD上の幅・高さと一致するので、4.4で指定した
    }

    &.-jp {
      @include font-a1min();
      @include fontSize(1.3, 1.3);
    }
  }
}


.l-header {
  &.js-isscroll {
    .p1-dnav {
      @include breakpoint-pc {
      }
      
      .p1-dnav_list.-btn {
      }
    }
    
  }

  &.js-noscroll {
    .p1-dnav { 
      @include breakpoint-pc {
      }
    }
  }
}
