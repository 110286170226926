.p1-drawer {
//  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transform: translateY(-100vh);
  background-color: color-wh(0.94);
  pointer-events: all;

  @include breakpoint-pc {
  }
  @include breakpoint-content {
  }
  
  &.is-open {
    animation: 0.6s ease-out 0s 1 both drawerIn;
//    background: color-base01(0.95);
//    transform: translateY(0);
//    transition: all linear;
  }
  
  &.is-close {
    animation: 0.4s ease-in 0s 1 both drawerOut;
  }

  ///// ハンバーガーアイコンの設置スペース /////
  &-open {
    @include flexbox;
    @include flex-justify-center;
    @include align-items-center;
    position: relative;
//    position: absolute;
//    top: 10px;
//    right: 5vw;
    z-index: 1200;  // 重なり順を一番上に
    width: 40px;
    height: 34px;
    box-sizing: border-box;
    cursor: pointer;

    @include breakpoint-narrowinner {
      width: 50px;
      height: 42px;
    }

    ///// ハンバーガーメニューのアイコン /////
    & span, & span:before, & span:after {
      display: block;
      position: absolute;
      height: 6px;
      width: 100%;
      content: '';
      border-radius: 3px;
      background-color: color-white02(1);
//      background-color: color-bk(1);
      transition: 0.5s;
    }

    & span {
      transition: 0.5s;

      &::before {
        bottom: 14px;
        transform-origin: top left;

        @include breakpoint-narrowinner {
          bottom: 18px;
        }
      }
      &::after {
        top: 14px;
        transform-origin: bottom left;

        @include breakpoint-narrowinner {
          top: 18px;
        }
      }
    }
    
    ///// オープン時(×ボタン) /////
    &.is-open {
      span {
        background-color: color-white02(0);
//        background-color: color-bk(0);
        transition: 0.01s;  //閉じる時のみ、瞬時に中央の横棒のみ透明にする
      }
      
      span::before {
        transform: translate(8px, 3px) rotate(40deg);

        @include breakpoint-narrowinner {
          transform: translate(8px, 3px) rotate(40deg);
        }
      }
      span::after {
        transform: translate(8px, -1px) rotate(-40deg);

        @include breakpoint-narrowinner {
          transform: translate(8px, -2px) rotate(-40deg);
        }
      }
    }
  }
}
