.l-footer {
  padding: 120px 0 20px 0;
  box-sizing: border-box;
  background-color: color-gray01(1);

  @include breakpoint-contentinnerhalf {
    padding: 130px 0 40px 0;
  }
  
  &_gototop {
    display: inline-block;
    position: absolute;
    bottom: 40px; //40px部分は、l-footerのpadding-bottomと合わせる。
    right: 5%;
    transform: translate(15px, 20px); //15pxはアイコン内の右余白、20pxはアイコン内の下余白

    @include breakpoint-max {
      bottom: 40px; //40px部分は、l-footerのpadding-bottomと合わせる。
      right: 60px;
      transform: translate(15px, 20px); //15pxはアイコン内の右余白、20pxはアイコン内の下余白
    }
    @include breakpoint-max {
      bottom: 40px; //40px部分は、l-footerのpadding-bottomと合わせる。
      right: 60px;
      transform: translate(15px, 20px); //15pxはアイコン内の右余白、20pxはアイコン内の下余白
    }
    
    a {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  
  &_btm {
    display: block;
    position: relative;
    width: 100%;
    height: 30px;
    content: "";
    background-color: color-bk(1);
    
    @include breakpoint-narrowinner {
      height: 50px;
    }
  }
}