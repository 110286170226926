@function color-wh($opacity) {
  @return rgba(255, 255, 255, $opacity);
}
@function color-bk($opacity) {
  @return rgba(0, 0, 0, $opacity);
}

@function color-white01($opacity) {
  @return rgba(248, 248, 248, $opacity);  //#f8f8f8
}
@function color-white02($opacity) {
  @return rgba(233, 233, 233, $opacity);  //#e9e9e9、color settingには無い色、トップページKVのテキスト色(差の絶対値として使用)
}

@function color-gray01($opacity) {
  @return rgba(43, 43, 43, $opacity);  //#2b2b2b、Base Color1
}
@function color-gray02($opacity) {
  @return rgba(153, 153, 153, $opacity);  //#999999、Base Color2
}
@function color-gray03($opacity) {
  @return rgba(195, 195, 195, $opacity);  //#c3c3c3、Base Color3
}
@function color-gray04($opacity) {
  @return rgba(219, 219, 219, $opacity);  //#d4d4d4、Base Color4
}
@function color-gray05($opacity) {
  @return rgba(212, 212, 212, $opacity);  //#dbdbdb、color settingには無い色、一覧の代替画像枠線色
}

@function color-blue01($opacity) {
  @return rgba(0, 70, 86, $opacity);  //#004656、Accent Color1-1
}
@function color-blue02($opacity) {
  @return rgba(24, 136, 161, $opacity);  //#1888a1、Accent Color1-2
}
@function color-blue03($opacity) {
  @return rgba(61, 231, 247, $opacity);  //#3de7f7、Accent Color1-3
}

@function color-brown01($opacity) {
  @return rgba(139, 102, 78, $opacity);  //#8b664e、Accent Color2-1
}
@function color-brown02($opacity) {
  @return rgba(213, 172, 114, $opacity);  //#d5ac72、Accent Color2-2
}

@function color-black01($opacity) {
  @return rgba(21, 21, 21, $opacity);  //#151515、Text Color1
}
@function color-black02($opacity) {
  @return rgba(51, 51, 51, $opacity);  //#333333、Text Color2、color settingには無い色、本文文字色
}
@function color-black03($opacity) {
  @return rgba(85, 85, 85, $opacity);  //#555555、Text Color3
}

@function color-red01($opacity) {
  @return rgba(255, 0, 0, $opacity);  //#ff0000、Error Color1
}



// Gradient mixin for Sweep button
@mixin sweepButtonGradient(){
    background: rgb(0,0,0);
  background: -moz-linear-gradient(90deg,  color-brown01(1) 0%, color-brown02(1) 94%, color-brown01(1) 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,color-brown01(1)), color-stop(94%,color-brown02(1)), color-stop(100%,color-brown01(1)));
  background: -webkit-linear-gradient(90deg,  color-brown01(1) 0%,color-brown02(1) 94%,color-brown01(1) 100%);
  background: -o-linear-gradient(90deg,  color-brown01(1) 0%,color-brown02(1) 94%,color-brown01(1) 100%);
  background: -ms-linear-gradient(90deg,  color-brown01(1) 0%,color-brown02(1) 94%,color-brown01(1) 100%);
  background: linear-gradient(90deg,  color-brown01(1) 0%,color-brown02(1) 94%,color-brown01(1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b664e', endColorstr='#8b664e',GradientType=1 );
}
/* 2022/12/09変更前
@mixin sweepButtonGradient(){
    background: rgb(0,0,0);
  background: -moz-linear-gradient(90deg,  color-brown01(1) 0%, color-blue01(1) 94%, color-brown01(1) 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,color-brown01(1)), color-stop(94%,color-blue01(1)), color-stop(100%,color-brown01(1)));
  background: -webkit-linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  background: -o-linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  background: -ms-linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  background: linear-gradient(90deg,  color-brown01(1) 0%,color-blue01(1) 94%,color-brown01(1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004656', endColorstr='#004656',GradientType=1 );
}
*/


/*
// Gradient mixin for Rise button
@mixin riseButtonGradient(){
  background: color-brown01(1);
  background: -moz-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: -webkit-gradient(linear, left, right, color-stop(0%, color-brown01(1)), color-stop(25%, rgba(53,50,48,1)), color-stop(43.5%, color-blue01(1)), color-stop(50%, color-blue01(1)), color-stop(56.5%, color-blue01(1)), color-stop(75%, rgba(53,50,48,1)), color-stop(100%, color-brown01(1)),);
  background: -webkit-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: -o-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: -ms-linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%, color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  background: linear-gradient(90deg, color-brown01(1) 0%, rgba(53,50,48,1) 25%,  color-blue01(1) 43.5%, color-blue01(1) 50%, color-blue01(1) 56.5%, rgba(53,50,48,1) 75%, color-brown01(1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b664e', endColorstr='#004656',GradientType=1 );
}
*/
