.c-pickup {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
//  height: 100%;
  padding: 0;
  box-sizing: border-box;

  @include breakpoint-pc {
    padding: 0;
  }
  @include breakpoint-narrow {
    padding: 0;
  }
  @include breakpoint-max {
    padding: 0 40px;
  }

  &_head {
    margin-bottom: 15px;

    @include breakpoint-contentinnerhalf {
      margin-bottom: 25px;
    }
    @include breakpoint-w720 {
      margin-bottom: 15px;
    }
    @include breakpoint-pc {
      margin-bottom: 35px;
    }
    @include breakpoint-max {
      margin-bottom: 55px;
    }
  }

  &_body {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-stretch;
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint-w720 {
      width: 100%;
    }
  }
  
  &_ttl {
    margin-bottom: 0;
    @include ellipsisSetLine(2);

    @include breakpoint-tb {
      margin-bottom: 3px;
    }
    @include breakpoint-contentinnerhalf {
      margin-bottom: 10px;
    }
    @include breakpoint-w720 {
      margin-bottom: 15px;
    }
    @include breakpoint-pc {
      margin-bottom: 10px;
    }
  }
  
  &_client {
    &:before {
      display: inline-block;
      position: relative;
      content: "Client:";
      margin-right: 5px;
      @include font-linuxldc();
      font-size: 1.4rem;
      font-style: italic;
      letter-spacing: 0.01em;

      @include breakpoint-contentinnerhalf {
        font-size: 1.6rem;
      }
      @include breakpoint-narrowinner {
        font-size: 1.7rem;
      }
    }
  }

  &_imgbox {
    position: relative;
    overflow: visible;
    width: 100%;
    height: 60vw;  //286px / 480px * 100
    margin-bottom: 20px;

    @include breakpoint-w720 {
      width: 380px / 720px * 100%;
      height: auto;
      margin-bottom: 0;
    }
    @include breakpoint-pc {
      width: 440px / 800px * 100%;
    }
    @include breakpoint-narrowinner {
      width: 620px / 1000px * 100%;
    }
    @include breakpoint-narrow {
      width: 720px / 1120px * 100%;
    }

    &.-img {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      img {
        width: 100%;
        height: auto;
      }
    }

    &.-video {
      img {
        width: 100%;
        height: auto;
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  
  &_txtbox {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    @include flex-justify-flex-start;
//    @include flex-justify-flex-end;
    @include align-items-stretch;
    position: relative;
    width: 100%;
    padding-bottom: 55px;
    box-sizing: border-box;

    @include breakpoint-tb {
      padding-bottom: 60px;
    }
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-w720 {
      width: 305px / 720px * 100%;
      padding-bottom: 0;
      padding-left: 40px;
    }
    @include breakpoint-pc {
      width: 320px / 800px * 100%;
    }
    @include breakpoint-narrowinner {
      width: 330px / 1000px * 100%;
      padding-left: 50px;
    }
    @include breakpoint-narrow {
      width: 340px / 1120px * 100%;
      padding-left: 60px;
    }
  }
  
  &_tag {
    display: none;
    position: absolute;
    
    @include breakpoint-pc {
      display: block;
      top: -20px;
      left: 15px;
      opacity: 1;
    }
    @include breakpoint-narrowinner {
      left: auto;
      top: 10px;
      right: 3vw;
    }
    @include breakpoint-narrow {
//      right: 50px / $max * 100vw;
      right: -20px;
    }
    @include breakpoint-max {
      top: 30px;
      right: -20px;
    }

    img {
      width: auto;
      height: 100%;
    }
  }
  
  &_txt {
    display: block;
    position: relative;
    @include ellipsisSetLine(3);

    @include breakpoint-w720 {
      top: 35px;
      @include ellipsisSetLine(6);
    }
    @include breakpoint-pc {
      top: 55px;      
    }
    @include breakpoint-narrowinner {
      top: 130px;
    }
    @include breakpoint-narrow {
      top: 190px;
    }
    @include breakpoint-max {
//      bottom: 100px;
    }
  }
  
  &_nav {
    display: block;
    position: relative;
    z-index: 11;
    margin-top: 35px;
    
    @include breakpoint-tb {
      display: inline-block;
    }
    @include breakpoint-contentinnerhalf {
      margin-top: 40px;
    }
    @include breakpoint-w720 {
      margin-top: 0;
      margin-left: -5px;
    }
  }
}