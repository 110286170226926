.p2-copyright {
  @include font-din1451lt();
  font-size: 1.2rem;
  color: color-wh(1);
  text-align: center;
  line-height: 1;
  letter-spacing: 0.01em;
  
  @include breakpoint-narrowinner {
    font-size: 1.4rem;
  }
  @include breakpoint-narrow {
    font-size: 1.7rem;
  }
}