.p4-posthead {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include align-items-center;
  
  &_author {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-center;
    
    @include breakpoint-narrow {
    }

    &_img {
      width: 36px;
      height: 36px;
      overflow: hidden;
      margin-right: 16px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      box-sizing: border-box;
      
      @include breakpoint-w720 {
        width: 48px;
        height: 48px;
      }
      @include breakpoint-pc {
        width: 48px;
        height: 48px;
        margin-right: 20px;
      }
      @include breakpoint-narrowinner {
        width: 48px;
        height: 48px;
      }
      @include breakpoint-narrow {
        width: 60px;
        height: 60px;
      }

      img {
        width: 100%;
        height: auto;
        opacity: 0;
      }
    }
  }
}

.p4-postarea {
  p:first-of-type {
    img {
      margin: 0;
      
      @include breakpoint-pc {
        margin: 0;
      }
    }
  }
  
  & img {
    width: 100%;
    height: inherit;
    margin: 0;
//    margin: 10px 0;
    
    @include breakpoint-pc {
//      margin: 20px 0;
    }
  }

  a {
    text-decoration: underline;
  }
}