$checkbox-gap-y: 20px;

.p5-form {
  width: 100%;

  .p5-form_box {
    &.-privacy {
    }
    
    &.-last {
    }
  }

  &_ttl {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-center;
    position: relative;
    padding: 0 10px;
    box-sizing: border-box;
    
    &.-center {
      @include flex-justify-center;
    }

    &.-required {
      &:after {
        display: block;
        position: relative;
        content: "";
        width: 60px;
        height: 16px;
        margin-left: 8px;
        line-height: 1;
        background-image: url("../images/contact/required.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        @include breakpoint-pc {

        }
      }
    }
    
    &.-checkboxttl {
      margin-bottom: 15px;
      
      @include breakpoint-pc {
        margin-bottom: 30px;
      }
    }
  }
  
  &_inputbox {
    @include font-ntsan("b");
    @include fontSize(1.8, 1.44);
    @include lineHeight(2.4, 2.1);
    letter-spacing: 0.05em;
    color: color-black01(1);
  }
  
  &_checkboxes {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include align-items-stretch;
    padding: 0 10px;
    box-sizing: border-box;
    gap: $checkbox-gap-y 30px;
    
    @include breakpoint-pc {
      gap: $checkbox-gap-y 40px;
    }
    @include breakpoint-narrow {
      gap: $checkbox-gap-y 60px;
    }
    
    &.-center {
      @include flex-justify-center;
    }

    label {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include align-items-center;

      // p3-text -t04と同じ文字関連の設定を以下に記述する
      @include font-ntsan("m");
      font-size: 1.4rem;
      line-height: 2.1;
      letter-spacing: 0.02em;
      color: color-black01(1);
  
      @include breakpoint-w720 {
        line-height: 2.3;
      }
      @include breakpoint-narrowinner {
        line-height: 2.8;
      }
      @include breakpoint-narrow {
        font-size: 1.6rem;
        line-height: 2.75;
      }    }
    
    .error {
      margin-top: -5px - $checkbox-gap-y;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &_input {
    padding: 2px 10px;

    @include breakpoint-pc {
      padding: 3px 10px;
    }

    &:focus {
      background-color: color-gray04(0.3);
    }

    &.-txt {
      width: 100%;
      border-bottom: 2px solid color-gray01(1);
    }
    
    &.-txtarea {
      width: 100%;
      height: 320px;
      border: 1px solid color-gray02(1);
      background-color: color-wh(1);
    }

    &.-check {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }
  }
  
  & .-link {
    margin-bottom: 15px;

    & a {
      width: 100%;
      max-width: 270px;
      display: block;
      line-height: 1;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      padding: 8px 5%;
      color: color-wh(1);
      background: color-base09(1);
      border-radius: 100px;
      @include font-ntsan("m");
      @include breakpoint-pc {
        width: 310px;
        max-width: 310px;
        padding: 10px 20px;
        font-size: 1.7rem;
      }
    }
  }

  &_btns {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include align-items-stretch;

    @include breakpoint-pc {
    }
  }

  &_btn {
    &.-submit {
      
    }
    
    &.-back {
      &:hover {
        filter: alpha(opacity=70);
        opacity: 0.7;
      }
    }
  }
  
  ///// 完了ページに表示する連絡先 /////
  &_contactinfo {
    padding: 20px 30px 22px;
    box-sizing: border-box;
    text-align: center;
    background-color: color-wh(1);

    @include breakpoint-pc {
      padding: 40px 60px 45px;
    }
  }
}


///// MW WPFORM用指定 /////
.l-wrap {
  .mw_wp_form {
    .horizontal-item {
      & + .horizontal-item {
        margin-left: 0 !important;
      }
    }
  
    .mwform-checkbox-field {
      line-height: 1;
    }
    
    //お問い合わせページのエラーメッセージ
    .errorbox {
      input {
        border-bottom-color: color-red01(1);
      }
      
      textarea {
        border-color: color-red01(1);
      }
    }

    .error {
      display: block;
      width: 100%;
      padding: 10px 10px;
      box-sizing: border-box;
      @include font-ntsan("b");
      @include fontSize(1.4, 1.12);  //14px * 0.8 = 11.2
      @include lineHeight(2, 2);
      letter-spacing: 0.05em;
      color: color-red01(1);
      
      @include breakpoint-pc {
        padding: 15px 10px;
      }
    }


    ///// 入力ページ /////
    &.mw_wp_form_input {
      & .p5-form_display {
        &.-inputpage {
          display: block;
        }
        
        &.-confirmpage, &.-completepage {
          display: none;
        }
      }
      
      & .p5-form_box {
        &.-privacy {
          .error {
            text-align: center;
          }
        }
      }

      & .p5-form_btn.-back {
        display: none;
      }

      & .p5-form_thx {
        display: none;
      }
    }


    ///// 確認ページ /////
    &.mw_wp_form_confirm {
      & .p5-form_display {
        &.-confirmpage {
          display: block;
        }
        
        &.-inputpage, &.-completepage {
          display: none;
        }
      }
      
      & .p5-form_ttl {
        &.-required {
          &:after {
            display: none;
          }
        }

        &.-checkboxttl {
          margin-bottom: 0;
          
          @include breakpoint-pc {
            margin-bottom: 0;
          }
        }
      }

      & .p5-form_inputbox {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid color-gray03(1);
      }

      & .p5-form_btn.-confirm, & .p5-form_btn.-top {
        display: none;
      }

      & .error {
        display: none;
      }

      & .p5-form_box {
        &.-privacy {
          display: none;
        }
        
        &.-last {
          & .p5-form_inputbox {
            border-bottom-style: none;
          }
        }
        
        &.-btns {
          padding-top: 10px;
          box-sizing: border-box;

          @include breakpoint-pc {
            padding-top: 20px;
          }

          & .p5-form_btn.-submit {
            margin-bottom: 15px;
            
            @include breakpoint-pc {
              margin-bottom: 30px;
            }
          }
        }
      }

      & .p5-form_thx {
        display: none;
      }
    }


    ///// 完了ページ /////
    .mw_wp_form_complete {
      & .p5-form_display {
        &.-completepage {
          display: block;
        }
        
        &.-inputpage, &.-confirmpage {
          display: none;
        }
      }
      
      & .p5-form_btn.-back, & .p5-form_btn.-confirm {
        display: none;
      }

      & ~ .p5-form_intro {
        display: none;
      }
    }
  }
}
