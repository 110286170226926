.p4-pager {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-justify-center;
  @include align-items-center;
  margin: 0 auto;
  padding: 50px 0;
  box-sizing: border-box;
  @include font-bebas();
  @include fontSizeBebas(1.5, 1.3);
  line-height: 1;
  letter-spacing: 0.03em;
  color: color-blue02(1);
  text-align: center;
  line-height: 1;

  @include breakpoint-pc {
    padding: 100px 0;
  }
  
  ///// TOPICSページ用 /////
  &.-topics {
    padding: 35px 0 0;

    @include breakpoint-pc {
      padding: 70px 0 0;
    }
  }

  .page-numbers {
    display: none;
    width: auto;
    margin: 0 9px;
    padding: 0;
    box-sizing: border-box;
    color: color-txt08(1);
    @include breakpoint-tb {
//      margin: 0 6px;
//      padding: 6px 0;
    }
    @include breakpoint-pc {
//      margin: 0 12px;
    }
    &.current {
      display: block;
    }
    &.dots {
      display: none;
    }
    &.prev, &.next {
//    &.prev, &.next, &.first, &.last {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-justify-center;
      @include align-items-center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: color-blue02(1);
      
      @include breakpoint-pc {
        width: 30px;
        height: 30px;
      }
    }
    &.prev {
    }
    &.next {
    }
/*
    &.first {
      background-image: url("../images/common/icon_tri_gr.png");
      transform: rotate(180deg); //lastと同じ画像を左右反転して使いたい場合は、この行を有効にする
    }
    &.last {
      background-image: url("../images/common/icon_tri_gr.png");
    }
*/

    &.-dummy {
      display: block;
      width: 24px;
      height: 24px;
      
      @include breakpoint-pc {
        width: 30px;
        height: 30px;
      }
    }
    
    & .pagehead {
      display: block;
      margin-bottom: 5px;
      
      @include breakpoint-pc {
        margin-bottom: 10px;
      }
    }
    
    & .pagebody {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-justify-center;
      @include align-items-center;
    }

    & .slash {
      display: inline-block;
      padding: 0 5px;
      box-sizing: border-box;
      @include font-ntsan("m");
      @include fontSize(1.5, 1.3);
    }
  }
  
  /* pager非表示時(1ページのみの場合) */
  &-nodisp {
    height: 15px;
    @include breakpoint-pc {
      height: 30px;
    }
  }
}