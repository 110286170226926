.p1-gnav {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-flex-end;
  @include align-items-center;
//  overflow: hidden;
  position: fixed;
  top: 27px;
  right: 5%;
  z-index: 1001;
  mix-blend-mode: difference; //差の絶対値

  @include breakpoint-max {
    right: 60px;
  }
  
  ///// コンタクト＆バーガーメニューオープンボタン /////
  &_btns {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-end;
    @include align-items-center;
    @include flex-align-content-center;
  }
  
  &_btn {
    &.-contact {
      margin-right: 25px;
      transition: 0.4s;

      @include breakpoint-pc {
        margin-right: 25px;
      }
    }
  }
  
  &.is-open {
    .p1-gnav_btn {
      &.-contact {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}