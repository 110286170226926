.p3-box {
  position: relative;
  
  &.-flexbox {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-space-between;
    @include align-items-stretch;
  }
  
  &.-sidescroll {
    position: relative;
    height: 100vh;
//    background-color: color-gray01(1);
    
    &:before {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: 300vw;
      content: "";
      background-color: color-gray01(1);
    }
  }
  
  ///// 会社情報 /////
  &.-company {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    
    table {
      display: block;
      width: 100%;

      @include breakpoint-tb {
        display: table;
      }
      
      tbody {
        display: block;

        @include breakpoint-tb {
          display: table-row;
        }
      }
      
      tr {
        display: block;

        @include breakpoint-tb {
          display: table-row-group;
        }
      }

      th, td {
        display: block;
        padding: 10px 0 20px;
        box-sizing: border-box;

        @include breakpoint-tb {
          display: table-cell;
          padding: 10px 0;
        }
        @include breakpoint-pc {
          padding: 15px 10px;
        }
      }
      th {
        width: 90px;
        padding-bottom: 0;
        text-align: left;

        @include breakpoint-tb {
          padding-bottom: 10px;
        }
        @include breakpoint-contentinnerhalf {
          width: 110px;
        }
        @include breakpoint-pc {
          width: 130px;
        }
        @include breakpoint-narrowinner {
          width: 150px;
        }
      }
    }
  }
  
  
  ///// メンバー /////
  &.-member {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-space-between;
    @include align-items-stretch;
    position: relative;

    .p3-box_img {
      position: relative;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      @include breakpoint-tb {
        width: 380px / 720px * 100%;
        margin-left: 0;
      }
      @include breakpoint-narrowinner {
        width: 470px;
      }
      
      img {
        width: 228px;
        height: auto;
        
        @include breakpoint-tb {
          width: 100%;
        }
      }
      
      &_name {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-67.5%, 0);
        @include font-bebas();
        @include fontSizeBebasOnesize(3.2);
        color: color-blue03(0.7);
        line-height: 0.87;
        text-align: left;
        @include tategaki("sideways");

        @include breakpoint-tb {
          top: auto;
          bottom: 0;
          transform: translate(-15px, 22.5%);
          @include fontSizeBebasOnesize(3.5);
          text-align: center;
          @include tategaki-off();
        }
        @include breakpoint-contentinnerhalf {
          transform: translate(-18px, 22.5%);
          @include fontSizeBebasOnesize(4.5);
        }
        @include breakpoint-w720 {
          transform: translate(-20px, 22.5%);
          @include fontSizeBebasOnesize(5.3);
        }
        @include breakpoint-pc {
          transform: translate(-25px, 22.5%);
          @include fontSizeBebasOnesize(6);
        }
        @include breakpoint-narrowinner {
          transform: translate(-30px, 70%);
          @include fontSizeBebasOnesize(8);
        }
        @include breakpoint-narrow {
          transform: translate(-40px, 72.5%);
        }
        @include breakpoint-contentinner {
          transform: translate(-30px, 75%);
        }
        
        span {
          display: inline-block;
          margin-bottom: 5px;
          
          @include breakpoint-tb {
            display: block;
            margin-bottom: 0;
          }
          
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    
    .p3-box_info {
      position: relative;
      width: 100%;
      padding-top: 18px;
      box-sizing: border-box;
      text-align: center;

      @include breakpoint-tb {
        width: 300px / 720px * 100%;
        text-align: left;
      }
      @include breakpoint-narrowinner {
        width: calc( 100% - 470px - 60px ); //470px = 画像幅、60px = 中央余白
      }
      
      &_name {
        &.-en {
          margin-bottom: 5px;
          
          @include breakpoint-w720 {
            margin-bottom: 10px;
          }
          @include breakpoint-narrowinner {
            margin-bottom: 20px;
          }
        }
        
        &.-jp {
          margin-bottom: 5px;
          
          @include breakpoint-w720 {
            margin-bottom: 10px;
          }
          @include breakpoint-narrowinner {
            margin-bottom: 15px;
          }
          @include breakpoint-narrow {
            margin-bottom: 20px;
          }
        }
      }
      
      &_job {
        margin-bottom: 10px;
        
        @include breakpoint-contentinnerhalf {
          margin-bottom: 25px;
        }
        @include breakpoint-pc {
          margin-bottom: 15px;
        }
        @include breakpoint-narrowinner {
          margin-bottom: 25px;
        }
      }

      &_sns {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-row;
        @include flex-justify-center;
        @include align-items-center;
        gap: 13px 13px;

        @include breakpoint-tb {
          @include flex-justify-flex-start;
        }

        &_item {
          width: 19px;
          height: auto;
          margin: 0;
          box-sizing: border-box;
      
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
          }
          
          img {
            width: 100%;
            height: auto;
          }
        }
        
        &_icon {
          @include flexbox;
          @include flex-wrap-wrap;
          @include flex-direction-row;
          @include flex-justify-center;
          @include align-items-center;
          width: 100%;
          height: 100%;
        }
        
        .icon-fill-bk {
          fill: color-bk(1);
        }
        .icon-fill-wh {
          fill: color-wh(1);
        }
        
        #icon-fb {
          width: 18px;
          height: 18px;
        }
        
        #icon-ig {
          width: 19px;
          height: 19px;
        }
        
        #icon-tw {
          width: 20px;
          height: 17px;
        }
      }
    }

    .p3-box_txt {
      width: 100%;
      margin-top: 20px;
      box-sizing: border-box;

      @include breakpoint-contentinnerhalf {
        margin-top: 30px;
      }
      @include breakpoint-w720 {
        padding-left: 10%;
      }
      @include breakpoint-pc {
        padding-left: 25%;
      }
      @include breakpoint-narrowinner {
        margin-top: 50px;
        padding-left: 30%;
      }
      @include breakpoint-narrow {
        padding-left: 32%;
      }
      @include breakpoint-max {
        padding-left: 35%;
      }
    }
  }

  
  ///// Works汎用繰り返しボックス /////
  &.-workstxtimgs {
    margin-bottom: 50px;
    
    @include breakpoint-pc {
      margin-bottom: 100px;
    }
  }
}