.p0-marquee {
  ///// 流れて表示される文字 /////
  @include flexbox;
  @include flex-justify-center;
//  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: auto;

  &_txt {
    flex: 0 0 auto;

    ///// マーキーテキスト01 /////
    &.-marquee01 {
      position: relative;
      animation: 24s marquee01 -12s linear infinite;  //テキストを流して表示する。24秒かけて移動する。奇数番目は12秒前にずらす
      white-space: nowrap;

      &:nth-child(odd) {
        @include breakpoint-pc {
          animation-duration: 30s;
          animation-delay: -15s;
        }
        @include breakpoint-max {
          animation-duration: 36s;
          animation-delay: -18s;
        }
      }
      &:nth-child(even) {
        animation: 24s marquee01Copy linear infinite; //テキストを流して表示する。24秒かけて移動する。12秒ずらして流す

        @include breakpoint-pc {
          animation-duration: 30s;
        }
        @include breakpoint-max {
          animation-duration: 36s;
        }
      }
    }

    ///// マーキーテキスト02 /////
    &.-marquee02 {
      position: relative;
      animation: 48s marquee01 -24s linear infinite;  //テキストを流して表示する。48秒かけて移動する。奇数番目は24秒前にずらす
      white-space: nowrap;

      &:nth-child(odd) {
        @include breakpoint-pc {
          animation-duration: 60s;
          animation-delay: -30s;
        }
        @include breakpoint-max {
          animation-duration: 72s;
          animation-delay: -36s;
        }
      }
      &:nth-child(even) {
        animation: 48s marquee01Copy linear infinite; //テキストを流して表示する。48秒かけて移動する。24秒ずらして流す

        @include breakpoint-pc {
          animation-duration: 60s;
        }
        @include breakpoint-max {
          animation-duration: 72s;
        }
      }
    }
  }
}
