// google icon settings
.p0-icon {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-center;
  @include align-items-start; //Material Icon使用時は、align-items-centerだと上下中央揃えにならない。align-items-startだと、ちょうど上下中央あたりに来る
//  @include align-items-center;

  // カラー
  &.-bk {
    color: color-bk(1);
  }
  &.-wh {
    color: color-wh(1);
  }
  &.-white02 {
    color: color-white02(1);
  }
  &.-blue02 {
    color: color-blue02(1);
  }
  &.-blue03 {
    color: color-blue03(1);
  }

  // マージン
  &.-ml1 {
    &::before {
      margin-left: 1px;
    }
  }
  &.-mr1 {
    &::before {
      margin-right: 1px;
    }
  }

  // サイズ
  &.-fs120per {
    font-size: 120%;
  }
  &.-b-fs120per {
    &::before {
      font-size: 120%;
    }
  }

  &.-fs20 {
    @include fontSize(2, 2);
  }
  &.-b-fs20 {
    &::before {
      @include fontSize(2, 2);
    }
  }

  &.-fs26 {
    @include fontSize(2.6, 2.6);
  }
  &.-b-fs26 {
    &::before {
      @include fontSize(2.6, 2.6);
    }
  }

  &.-fs30 {
    @include fontSize(3, 3);
  }
  &.-b-fs30 {
    &::before {
      @include fontSize(3, 3);
    }
  }

  &.-fs42 {
    @include fontSize(4.2, 4.2);
  }
  &.-b-fs42 {
    &::before {
      @include fontSize(4.2, 4.2);
    }
  }

  &.-fs56 {
    @include fontSize(5.6, 5.6);
  }
  &.-b-fs56 {
    &::before {
      @include fontSize(5.6, 5.6);
    }
  }

  &.-fs60 {
    @include fontSize(6, 6);
  }
  &.-b-fs60 {
    &::before {
      @include fontSize(6, 6);
    }
  }

  &.-headercontact {
    font-size: 5.2rem;
    
    @include breakpoint-narrowinner {
      font-size: 6rem;
    }
  }

  // サービスページのサークル内アイコン
  &.-servicecircle {
    @include fontSize(4.2, 3.36); //42px * 0.8 = 33.6px
  }

  // 回転
  &.-rotate90 {
    transform: rotate(90deg);
  }
  &.-rotate180 {
    transform: rotate(180deg);
  }
  &.-rotate270 {
    transform: rotate(270deg);
  }

  // アイコン
  // >
  &.-chevronleft {
    &::before {
      font-family: 'Material Icons';
      content: '\e5cb';
    }
  }

  // <
  &.-chevronright {
    &::before {
      font-family: 'Material Icons';
      content: '\e5cc';
    }
  }

  &.-mailoutline {

  }
}