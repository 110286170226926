.p2-ftinfo {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-center;
  @include align-items-center;

  @include breakpoint-contentinnerhalf {
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include align-items-start;
  }

  &_item {
    box-sizing: border-box;

    &.-item1 {
      transform: translateX(20px);
      
      @include breakpoint-contentinnerhalf {
        transform: translateX(0);
        padding-right: 20px;
      }
    }

    &.-item2 {
      @include ajustLHTop(1.2, 1.2);
      text-align: center;

      @include breakpoint-contentinnerhalf {
        text-align: left;
      }
      @include breakpoint-pc {
//        padding-right: 60px;
      }
    }
  }

  &_img {
    &.-logo {
      width: 109px;
      margin-bottom: 15px;

      @include breakpoint-contentinnerhalf {
        margin-top: 3px;
        margin-bottom: 0;
      }
      @include breakpoint-narrowinner {
        width: 120px;
        margin-top: 1px;
      }
    }
  }

  &_txt {
    @include font-din1451lt();
    font-size: 1.5rem;
    line-height: 1.5;
    color: color-wh(1);
    letter-spacing: 0.01em;

    @include breakpoint-narrowinner {
      font-size: 1.9rem;
      line-height: 1.2;
    }

    &.-name {
      @include breakpoint-pc {
      }
    }
    
    &.-tel {      
      a {
        color: color-wh(1);
      }
    }
  }
}