body {
  width: 100%;
  @include font-ntsan("m");
  font-size: 1rem;
  line-height: 1;
  color: color-black02(1);
  background-color: color-white01(1);
}

a {
  color: color-black02(1);
  text-decoration: none;
}

::selection {
  background: color-blue01(1);
}
::-moz-selection {
  background: color-blue01(1);
}

//電話番号リンク
.js-tellink {
  a {
    color: inherit;
  }
}
