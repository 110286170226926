.c-tag {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-center;
  @include align-items-stretch;
  gap: 5px 5px;
  
  &.-oneline {
    overflow: hidden;
    height: 19px; //タグのフォントサイズ + 上下余白 + 枠線(SP版デザインが不明のため、こちらの20pxは暫定値)

    @include breakpoint-pc {
      height: 20px; //タグのフォントサイズ + 上下余白 + 枠線
    }
  }
  
  &.-workssingle {
    @include flex-justify-flex-start;
    
    & .c-tag_item {
      margin-right: 5px;
      padding: 1px 0;
      border-style: none;
      @include font-ntsan("r");
      @include fontSize(1.1, 1);
    }
  }

  &_item {
    display: inline-block;
    padding: 1px 5px 4px 5px;
    border: 2px solid transparent;
    border-radius: 5px;
    box-sizing: border-box;
    @include font-ntsan("b");
    @include fontSize(1.1, 1);
    line-height: 1;

    @include breakpoint-pc {

    }
    
    // works Pick Up
    &.-pickup {
      color: color-wh(1);
      background-color: color-blue02(1);
    }
    
    // works親カテゴリ
    &.-works1 {
      color: color-wh(1);
      background-color: color-brown01(1);
      border-color: color-brown01(1);
    }
    
    // works子カテゴリ
    &.-works2 {
      color: color-brown01(1);
      background-color: transparent;
      border-color: color-brown01(1);
    }

    // ブログタグ親カテゴリ
    &.-blog1 {
      color: color-wh(1);
      border-color: color-brown01(1);
      background-color: color-brown01(1);
    }

    // ブログタグ子カテゴリ
    &.-blog2 {
      color: color-brown01(1);
      border-color: color-brown01(1);
      background-color: transparent;
    }
  }
}